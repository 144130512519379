<ul class="list-inline pull-right">
  <ng-container *ngFor="let config of _buttons">
    <li  class="list-inline-item">
      <button
        role="button"
        [type]="config.type || 'button'"
        (click)="ngOnChangeButton($event, config.callback)"
        class="btn btn-primary"
        [class.btn-danger]="config.text == 'close'"
        [disabled]="config.disabled | async"
        [attr.aria-label]="config.ariaLabel"
      >
        <button-icon-loader
          *ngIf="config.icon"
          [defaultIcon]="config.icon"
          class="pr-1"
          [isLoading]="config.isProcessing | async"
        ></button-icon-loader>
        <span >{{ config.text | translate }}</span>
      </button>
    </li>
  </ng-container>
</ul>