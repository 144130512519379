import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorizedClient } from '../objects/authorized-client';
import { ListAuthorizedClientsResponseBody } from '../objects/api-response-body';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ClientAuthService {
  /**
   * @param {HttpClient} http
   */
  constructor(
    protected readonly http: HttpClient
  ) {}

  /**
   * Retrieve all the authorized children for the client
   *
   * @return {Observable<AuthorizedClient[]>}
   */
  getAuthorizedChildren(): Observable<AuthorizedClient[]> {
    return this.http.post<ListAuthorizedClientsResponseBody>(`${environment.url}/data-sharing/clients/children`, null, {
      observe: 'response',
    }).pipe(
      map((response) => response.body.items.map((data) => new AuthorizedClient(data)))
    );
  }
}