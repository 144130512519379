<div class="float-left w-25">
  <fieldmagic-relate-input
    moduleName="warehouses"
    placeholder="select_warehouse"
    ($onChange)="onWarehouseChange($event)"
    [bDisplayLabel]="false">
  </fieldmagic-relate-input>
</div>

<fieldmagic-pagination-links>
  <table class="table table-bordered">
    <thead>
      <tr>
        <th class="text-center">{{ "inventory_levels" | translate }}</th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngIf="list$ | async as list; else loader">
        <tr *ngIf="list.length < 1">
          <td class="text-center">{{ "no_record_found" | translate }}</td>
        </tr>
        <tr
          *ngFor="let row of list"
          [attr.data-row]="index"
          class="d-flex flex-row"
        >
          <td data-col="warehouse_name" class="d-flex flex-column w-100">
            <strong class="col-label">{{
              "warehouse_name" | translate
            }}</strong>
            <span class="col-value">{{ row.warehouse_name }}</span>
          </td>
          <td data-col="warehouse_name" class="d-flex flex-column w-100">
            <strong class="col-label">{{ "location" | translate }}</strong>
            <span class="col-value">{{
              row.location | placeholdWithString
            }}</span>
          </td>
          <td data-col="warehouse_name" class="d-flex flex-column w-100">
            <strong class="col-label">{{
              "Min Stock Level" | translate
            }}</strong>
            <span class="col-value" decimalPipe>{{ row.min_stock_level }}</span>
          </td>
          <td data-col="warehouse_name" class="d-flex flex-column w-100">
            <strong class="col-label">{{
              "Min Reorder Qty" | translate
            }}</strong>
            <span class="col-value" decimalPipe>{{
              row.min_reorder_level
            }}</span>
          </td>
          <td data-col="stock_level" class="d-flex flex-column w-100">
            <strong class="col-label">{{
              "current_stock_level" | translate
            }}</strong>
            <span class="col-value" decimalPipe>{{
              row.current_stock_level
            }}</span>
          </td>
          <td data-col="stock_level" class="d-flex flex-column w-100">
            <strong class="col-label">{{ "On Order" | translate }}</strong>
            <span class="col-value" decimalPipe>{{ row.on_order }}</span>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</fieldmagic-pagination-links>
<ng-template #loader>
  <tr class="text-center">
    <td colspan="2">
      <inline-view-loader
        [isLoading]="true"
        [hasText]="false"
      ></inline-view-loader>
    </td>
  </tr>
</ng-template>
