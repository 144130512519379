<div class="row">

  <div class="col-6">
    <h3 class="title">
      <fa-icon [icon]="['fas', 'tag']"></fa-icon>&nbsp;
      <span>{{ 'manage_tags' | translate }}</span>
    </h3>
  </div>

  <div class="col-6">
    <ul class="list-inline pull-right">
      <li class="list-inline-item">
        <button id="btnManageTagsClose" type="button" (click)="closeDialog()" class="btn btn-danger">
          <fa-icon [icon]="['fas', 'times']"></fa-icon>
          <span class="pl-1">{{ 'close' | translate }}</span>
        </button>
      </li>
    </ul>
  </div>

  <hr>

  <div class="col-12">

    <mat-form-field class="example-chip-list">
      <mat-chip-list #chipList aria-label="Fruit selection">

        <fm-dynamic-chip *ngFor="let tag of arTags"
          [removable]="true"
          [tag]="tag"
          (removeEvent)="removeTag($event)"
          (saveEvent)="saveTag($event)">
        </fm-dynamic-chip>

        <mat-chip *ngIf="bAddPlaceHolder" class="placeholder-chip" (click)="focusOnChipInput(chipListInput)">
          <fa-icon [icon]="['fas', 'plus']"></fa-icon>
        </mat-chip>

        <mat-chip *ngIf="bLoading">
          <fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
        </mat-chip>

        <input
          #chipListInput
          (focus)="togglePlaceHolder(true)"
          (blur)="togglePlaceHolder(false)"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addTag($event)">
      </mat-chip-list>
    </mat-form-field>

  </div>
</div>
