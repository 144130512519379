import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '../../../../../shared/modules/font-awesome.module';
import { NumericTextboxModule } from 'ngx-numeric-textbox';
import { SharedModule } from '../../../../../shared/modules/shared.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PopoverModule } from 'ngx-bootstrap';
import { ErrorHandlerModule } from '../../../../../shared/modules/error-handler.module';
import { DataSharingModule } from '../../../../../features/data-sharing/data-sharing.module';
import { JobEditComponent } from '../components/job-edit/job-edit.component';
import { LinkChecklistComponent } from '../components/link-checklist/link-checklist.component';
import { QuoteEditComponent } from '../components/quote-edit/quote-edit.component';
import { QuoteDetailsComponent } from '../components/quote-details/quote-details.component';
import { TaskUpsertComponent } from '../components/task-upsert/task-upsert.component';
import { EditModule } from '../../../../../shared/modules/edit.module';
import { TaskDetailsNoActivities } from './task-details-no-activities.component';
import { TaskScheduleDialogComponent } from '../../task-schedule-dialog/task-schedule-dialog.component';
import { TaskDuplicateDialog } from '../../task-duplicate-dialog/task-duplicate-dialog.component';

@NgModule({
  declarations: [
    JobEditComponent,
    LinkChecklistComponent,
    QuoteEditComponent,
    QuoteDetailsComponent,
    TaskDetailsNoActivities,
    TaskUpsertComponent,
    TaskScheduleDialogComponent,
    TaskDuplicateDialog
  ],
  entryComponents: [
    JobEditComponent,
    LinkChecklistComponent,
    QuoteEditComponent,
    QuoteDetailsComponent,
    TaskDetailsNoActivities,
    TaskUpsertComponent,
    TaskScheduleDialogComponent,
    TaskDuplicateDialog,
  ],
  exports: [
    JobEditComponent,
    LinkChecklistComponent,
    QuoteEditComponent,
    QuoteDetailsComponent,
    TaskDetailsNoActivities,
    TaskUpsertComponent,
    CommonModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FontAwesomeModule,
    PopoverModule,
    ErrorHandlerModule,
    ErrorHandlerModule,
    DataSharingModule,
    NumericTextboxModule,
    EditModule
  ],
  imports: [
    CommonModule,
    SharedModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FontAwesomeModule,
    PopoverModule,
    ErrorHandlerModule,
    PopoverModule.forRoot(),
    ErrorHandlerModule,
    DataSharingModule,
    NumericTextboxModule,
    EditModule
  ],
})
export class TaskDetailsNoActivitiesModule { }
