import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get, merge, isNil, toString } from 'lodash';
import moment, { Moment } from 'moment';
import { Observable, of } from 'rxjs';
import { LooseObject } from '../../../objects/loose-object';
import { map, take, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { SharingDialogService } from '../../../features/data-sharing/services/sharing-dialog.service';
import { CalendarPageDirection, CalendarPagination } from '../full-calendar/classes/calendar-pagination';
import { JobMetadata, JobMetadataC } from '../full-calendar/classes/metadata/job-metadata';
import { TaskMetadata, TaskMetadataC } from '../full-calendar/classes/metadata/task-metadata';
import { OpportunityMetadata, OpportunityMetadataC } from '../full-calendar/classes/metadata/opportunity-metadata';
import { ActionsForChildClientOptions } from '../../../features/data-sharing/objects/services/sharing-data';

const kBaseAPI = environment.url;

@Injectable()
export class CalendarApiService {

  /**
   * Start date for the calendar view.
   *
   * @type {Moment}
   */
  public objStartDate: Moment;

  /**
   * End date for the calendar view.
   *
   * @type {Moment}
   */
  public objEndDate: Moment;

  /**
   * Pagination for the calendar view.
   *
   * @type {CalendarPagination}
   */
  public objPagination: CalendarPagination = new CalendarPagination();

  /**
   * List of last fetched resource ids.
   *
   * @type {string[]}
   */
  public arLastFetchedResourceIds: string[] = [];

  /**
   * Resources Size Per Page
   *
   * @type {number}
   */
  public numResourcesSizePerPage: number = 10;

  /**
   * Start range of the shown entries.
   *
   * @returns {number}
   */
  get numShowStart(): number {
    return (this.objPagination.current_page * this.numResourcesSizePerPage) + 1;
  }

  /**
   * End range of the shown entries.
   *
   * @returns {number}
   */
  get numShowEnd(): number {
    let page = ((this.objPagination.current_page * this.numResourcesSizePerPage) + this.numResourcesSizePerPage);
    return page > this.objPagination.total ? this.objPagination.total : page;
  }

  /**
   * Date format for scheduling apis.
   *
   * @returns {string}
   */
  get dateFormat() {
    return 'YYYY-MM-DD';
  }

  constructor(
    protected http: HttpClient,
    protected sharingDialogs: SharingDialogService,
  ) {}

  /**
   * Sets the current page to access.
   *
   * @param {CalendarPageDirection} strDirection
   *
   * @returns {void}
   */
  setPage(strDirection: CalendarPageDirection): void{
    if (strDirection == 'next') {
      this.objPagination.current_page++;
    } else if (strDirection == 'prev') {
      this.objPagination.current_page--;
    } else {
      this.objPagination.current_page = Math.ceil(this.objPagination.total / this.numResourcesSizePerPage) - 1;
    }
  }

  /**
   * Schedule the selected task.
   *
   * @param {string[]} strTaskIds
   * @param {number} numEstimatedDuration
   * @param {string} strDueDate
   * @param {string} strUserId
   *
   *
   * @returns {Observable<{message: string}>}
   */
  scheduleTask(
    tasks: ScheduleTaskData[],
    options: ScheduleTaskOptions
  ) {
    options = merge<Partial<ScheduleTaskOptions>, ScheduleTaskOptions>({
      rescheduled: false,
      parent_module_name: 'jobs',
    }, options);

    const ids = tasks.map((task) => task.id);

    return this.http.patch(
      `${kBaseAPI}/scheduler/calendar/schedule`,
      new URLSearchParams({
        ids: JSON.stringify(ids),
        due_date: options.due_date,
        [options.assigned_type == 'team' ? 'team_id': 'user_id']: options.assigned_id,
        ...(options.duration && {
          estimated_duration: options.duration.toString(),
        }),
        ... (options.notify_via_push && {
          notify_via_push: '1',
          push_notification_body: options.push_notification_body,
          ... (options.notification_note) && {
            notification_note: options.notification_note
          }
        }),
        ... (options.for_child_client_id && {
          'other_client_id': options.for_child_client_id,
        }),
      }),
    ).pipe(
      tap(() => {
        if (! options.rescheduled && options.for_child_client_id) {
          const parentIds = tasks.map((task) => task.parent_id);

          this.sharingDialogs.attemptToShowShareFilesDialog(parentIds, options.parent_module_name)
            .pipe(
              take(1),
            ).subscribe();
        }
      }),
      map((content) => get(content, 'tasks', [])),
    );
  }

  duplicateTask(
    strTaskId: string,
    numEstimatedDuration: number,
    strDueDates: string[],
    strField: string,
    strId: string
  ) {
    return this.http.post(`${kBaseAPI}/scheduler/calendar/tasks/${strTaskId}/duplicate`, new URLSearchParams({
      estimated_duration: numEstimatedDuration.toString(),
      due_dates: JSON.stringify(strDueDates),
      [strField]: strId,
    }));
  }

  /**
   * Schedule the selected job.
   *
   * @param {string[]} strJobIds
   * @param {number} numEstimatedDuration
   * @param {string} strDueDate
   * @param {string} strUserId
   *
   * @returns {Observable<{message: string}>}
   */
  scheduleJob(
    strJobIds: string[],
    numEstimatedDuration: number = 1,
    strDueDate: string,
    strUserId: string
  ): Observable<{message: string}> {
    return this.http.patch<{message: string}>(`${kBaseAPI}/scheduler/calendar/schedule/jobs`, new URLSearchParams({
      ids: JSON.stringify(strJobIds),
      estimated_duration: numEstimatedDuration.toString(),
      due_date: strDueDate,
      user_id: strUserId,
    }));
  }

  /**
   * Schedule the selected quote.
   *
   * @param {string[]} strOpportunityIds
   * @param {number} numEstimatedDuration
   * @param {string} strDueDate
   * @param {string} strUserId
   *
   * @returns {Observable<{message: string}>}
   */
  scheduleQuote(
    strOpportunityIds: string[],
    numEstimatedDuration: number = 1,
    strDueDate: string,
    strUserId: string
  ): Observable<{message: string}> {
    return this.http.patch<{message: string}>(`${kBaseAPI}/scheduler/calendar/schedule/opportunities`, new URLSearchParams({
      ids: JSON.stringify(strOpportunityIds),
      estimated_duration: numEstimatedDuration.toString(),
      due_date: strDueDate,
      user_id: strUserId,
    }));
  }

  /**
   * List of jobs for scheduling.
   *
   * @param {LooseObject} objFilter
   *
   * @returns {Observable<{items: JobMetadataC[], pagination: CalendarPagination}>}
   */
  jobsForScheduling(objFilter: LooseObject): Observable<{items: JobMetadataC[], pagination: CalendarPagination}> {
    const body = new URLSearchParams(
      this.stringifyContent(objFilter)
    );

    return this.http.post<{items: JobMetadataC[], pagination: CalendarPagination}>(
      `${kBaseAPI}/scheduler/jobs/for-scheduling`, body.toString()
    ).pipe(
      tap((result) => {
        this.objPagination = {
          ...this.objPagination,
          ...result.pagination
        };
      }),
      map(response => {
        response.items = response.items.map(item => new JobMetadataC(item));
        return response;
      })
    );
  }

  /**
   * List of opportunities for scheduling.
   *
   * @param {Moment} strDateFrom
   * @param {LooseObject} objFilter
   *
   * @returns {Observable<{items: OpportunityMetadataC[], pagination: CalendarPagination}>}
   */
  opportunitiesForScheduling(objFilter: LooseObject): Observable<{items: OpportunityMetadataC[], pagination: CalendarPagination}> {
    const body = new URLSearchParams(
      this.stringifyContent(objFilter)
    );

    return this.http.post<{items: OpportunityMetadataC[], pagination: CalendarPagination}>(
      `${kBaseAPI}/scheduler/opportunities/for-scheduling`, body.toString()
    ).pipe(
      tap(result => {
        this.objPagination = {
          ...this.objPagination,
          ...result.pagination
        };
      }),
      map(response => {
        response.items = response.items.map(item => new OpportunityMetadataC(item));
        return response;
      })
    );
  }

    /**
   * List of tasks for scheduling.
   *
   * @param {LooseObject} objFilter
   *
   * @returns {Observable<{items: TaskMetadataC[], pagination: CalendarPagination}>}
   */
    tasksForScheduling(objFilter: LooseObject): Observable<{items: TaskMetadataC[], pagination: CalendarPagination}> {
      const body = new URLSearchParams(
        this.stringifyContent(objFilter)
      );

      return this.http.post<{items: TaskMetadataC[], pagination: CalendarPagination}>(
        `${kBaseAPI}/scheduler/tasks/for-scheduling`, body.toString()
      ).pipe(
        tap(result => {
          this.objPagination = {
            ...this.objPagination,
            ...result.pagination
          };
        }),
        map(response => {
          response.items = response.items.map(item => new TaskMetadataC(item));
          return response;
        })
      );
    }

    /**
     * Creates the params for the for-scheduling APIs.
     *
     * @param {LooseObject} objFilter
     *
     * @returns {LooseObject}
     */
    private stringifyContent(objFilter: LooseObject): LooseObject {
      let objParams = {
        page: this.objPagination.current_page.toString()
      };

      if (objFilter) {
        Object.keys(objFilter).map(item => {
          objParams[`filter[${item}]`] = JSON.stringify(objFilter[item]);
        })
      }
      return objParams;
    }

  unscheduleTask(strTaskId: string, options: UnscheduleTaskOptions = {}) {
    const payload = new URLSearchParams({
      ... (options.for_child_client_id && {
        'other_client_id': options.for_child_client_id,
      }),
    });

    return this.http.patch(`${kBaseAPI}/scheduler/calendar/tasks/${strTaskId}/cancel-schedule`, payload.toString()).pipe(
      map((content) => get(content, 'task')),
    );
  }

  setResourcesSizePerPage(numSizePerPage: number) {
    this.numResourcesSizePerPage = numSizePerPage;
    this.objPagination.current_page = 0;
  }

  getResources(objUserSearchData: UserSearchData, options: GetResourcesOptions = {}) {
    const getResourceFilter = (): ResourceFilter => {
      let strFilterKey: string = objUserSearchData.module === 'departments' ? 'filter[department_id]' : 'filter[user_id]';

      if (options.view == 'teams') {
        strFilterKey = 'filter[team_id]';
      }

      return { [strFilterKey]: objUserSearchData.id };
    };

    const payload = new URLSearchParams({
      ... (options.for_child_client_id && {
        'other_client_id': options.for_child_client_id,
      }),
    });

    return this.http.post(`${kBaseAPI}/scheduler/calendar/resources`, payload.toString(), {
      params: {
        'view': options.view,
        'page': this.objPagination.current_page.toString(),
        'size': this.numResourcesSizePerPage.toString(),
        ...(!isNil(objUserSearchData) && getResourceFilter())
      },
    }).pipe(
      tap((result: any) => {
        this.objPagination = {...this.objPagination, ...result.pagination};
        this.arLastFetchedResourceIds = result.items ? result.items.map(user => {
          return user.id;
        }) : [];
      })
    );
  }

  getEvents(strUserIds: string[] = [], options: GetEventsOptions = {}) {

    if (strUserIds.length < 1) {
      strUserIds = this.arLastFetchedResourceIds;
    }

    const due_within = {
      from: this.objStartDate.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS),
      to: this.objEndDate.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS),
    }

    return this.http.post(`${kBaseAPI}/scheduler/calendar/events`, new URLSearchParams({
      due_within: JSON.stringify(due_within),
      view: options.view,
      ...(strUserIds && {ids: JSON.stringify(strUserIds)}),
      ...(options.job_id && {
        job_id: options.job_id,
      }),
      ...(options.for_child_client_id && {
        'other_client_id': options.for_child_client_id,
      }),
    }));
  }

  scheduleActivityLog(
    strIds: string[],
    numEstimatedDuration: number,
    strDueDate: string,
    strUserId: string
  ) {
    return this.http.patch(`${kBaseAPI}/scheduler/calendar/schedule`, new URLSearchParams({
      ids: JSON.stringify(strIds),
      estimated_duration: numEstimatedDuration.toString(),
      due_date: strDueDate,
      user_id: strUserId,
    }));
  }

  resetPage() {
    this.objPagination = new CalendarPagination();
  }
}

export interface Resources {
  display_name: string;
  email_address: string;
  id: string;
};

type GetResourcesOptions =  ActionsForChildClientOptions;
type GetEventsOptions = ActionsForChildClientOptions & {
  /**
   * Retrieve events for specific job
   */
  job_id?: string;
};
type UnscheduleTaskOptions = ActionsForChildClientOptions;

type UserSearchData = {
  /**
   * @type {string}
   */
  id: string;
  /**
   * @type {string}
   */
  module: string;
}

type ResourceFilter = {
  /**
   * @type {string}
   */
  [key: string]: string;
}

type ScheduleTaskData = {
  /**
   * @type {string}
   */
  id: string;
  /**
   * @type {string}
   */
  parent_id: string;
};

type ScheduleTaskOptions = {

  /**
   * @type {string}
   */
  assigned_id: string;

  /**
   * @type {'user' | 'team'}
   */
  assigned_type: 'user' | 'team';

  /**
   * @type {string}
   */
  due_date: string;
  /**
   * @type {number}
   */
  duration?: number;
  /**
   * Indicates that the task is meant for rescheduling.
   *
   * (Defaults to false)
   *
   * @type {boolean}
   */
  rescheduled?: boolean;
  /**
   * View calendar for specific child client
   *
   * @type {string|undefined}
   */
  for_child_client_id?: string;
  /**
   * Identifies to which parent module does the tasks belongs. Defaults to `jobs`
   *
   * Note: This is used by the data-sharing feature
   *
   * @type {string|undefined}
   */
  parent_module_name?: string;

  /// when a task is scheduled notify the assignee of the task
  notify_via_push?: boolean;

  /// a custom message for push notification
  notification_note? : string;

  //details for the custom message
  push_notification_body?: string;

  notification_message?: string;
};
