<header>
  <div class="d-flex header-container header-label">
    <span class="title">
      <fa-icon [icon]="['fas', 'exchange-alt']" class="pr-2"></fa-icon>
      <span>{{ 'stock-transfers' | translate }}</span>
    </span>
    <ng-container *fcHasPermission="'stock_management'">
      <div class="ml-auto d-flex">
        <ul class="list-inline pull-right">
          <li class="list-inline-item">
            <button (click)="onSubmit('draft')" class="btn btn-primary" [disabled]="bSubmitted">
              <button-icon-loader [defaultIcon]="['fas', 'clipboard']" [isLoading]="bSubmitted"></button-icon-loader>
              <span class="pl-1">{{ 'save_as_draft' | translate }}</span>
            </button>
          </li>
          <li class="list-inline-item">
            <button (click)="onSubmit('complete')" class="btn btn-primary" [disabled]="bSubmitted">
              <button-icon-loader [defaultIcon]="['fas', 'clipboard-check']" [isLoading]="bSubmitted"></button-icon-loader>
              <span class="pl-1">{{ 'save_as_complete' | translate }}</span>
            </button>
          </li>
          <li class="list-inline-item">
            <fieldmagic-primary-button
              id="btnCancel"
              (click)="cancelDialog()"
              icon="times"
              variant="error"
              label="close"
            ></fieldmagic-primary-button>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
  <hr />
</header>

<ng-container *fcHasPermission="'stock_management'; else notPermitted">
<mat-dialog-content *ngIf="!bLoading" class="mat-dialog-content {{ matDialogContentClass }}">
  <div *ngIf="bUpdate" class="col-md-12">
    <div class="row record-container mb-16 pr-0 pl-0">
      <div class="col-md-6">
        <label class="labels w-100">{{ 'status' | translate }}</label>
        <span class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts long-text">
          {{ objStockTransferDetails.status | translate }}
        </span>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <form [formGroup]="stockTransferForm">
      <div class="row mt-2">
        <div class="col-md-6">
          <label class="labels w-100">{{ 'warehouse_source' | translate }}</label>
          <ng-select
            [items]="objStockTransferRelate.warehouse_source.source | async"
            [loading]="objStockTransferRelate.warehouse_source.loader"
            [typeahead]="objStockTransferRelate.warehouse_source.typehead"
            bindLabel="name"
            bindValue="id"
            [clearable]="false"
            formControlName="warehouse_source_id"
            (change)="onChangeWarehouse('warehouse_source', $event)"
            appendTo="body"
            [ngClass]="{ 'is-invalid': isFormInvalid(stockTransferForm, 'warehouse_source_id') }"
          >
          </ng-select>
        </div>
        <div class="col-md-6">
          <label class="labels w-100">{{ 'warehouse_destination' | translate }}</label>
          <ng-select
            [items]="objStockTransferRelate.warehouse_destination.source | async"
            [loading]="objStockTransferRelate.warehouse_destination.loader"
            [typeahead]="objStockTransferRelate.warehouse_destination.typehead"
            bindLabel="name"
            bindValue="id"
            [clearable]="false"
            formControlName="warehouse_destination_id"
            (change)="onChangeWarehouse('warehouse_destination', $event)"
            appendTo="body"
            [ngClass]="{ 'is-invalid': isFormInvalid(stockTransferForm, 'warehouse_destination_id') }"
          >
          </ng-select>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="hasValidStockTransfer" class="col-md-12 mt-3">
    <!-- <div class="stock-transfer-quanitites"> -->
      <table class="table table-bordered w100p">
        <thead>
          <tr>
            <th class="w40p fs12">{{ 'product' | translate }}<required-tag></required-tag></th>
            <th class="w20p fs12">{{ 'quantity' | translate }}<required-tag></required-tag></th>
            <th class="w15p fs12">{{ 'source_quantity' | translate }}</th>
            <th class="w15p fs12">{{ 'destination_quantity' | translate }}</th>
            <th class="w10p"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let config of arPaginationCurrentData; let i = index;">
            <tr>
              <td colspan="5" class="p-0 w45p">
                <form [formGroup]="config.form">
                  <table class="table table-bordered w100p m-0">
                    <tr>
                      <td class="w40p">
                        <ng-select
                          [items]="config.field.source | async"
                          [loading]="config.field.loader"
                          [typeahead]="config.field.typehead"
                          class="w-100 item-{{ config.id }}"
                          bindLabel="text"
                          bindValue="id"
                          [clearable]="false"
                          formControlName="item_id"
                          (change)="onChangeItem(config, $event)"
                          [ngClass]="{ 'is-invalid': isFormInvalid(config.form, 'item_id') }"
                        >
                          <ng-template ng-option-tmp let-item="item">
                            <span>{{ item.text | translate }} <span>( {{ item.code }} )</span></span>
                          </ng-template>
                          <ng-template ng-label-tmp let-item="item">
                            <span class="font-size-12">{{ item.text }}</span>
                          </ng-template>
                        </ng-select>
                      </td>
                      <td class="w20p">
                        <input
                          type="number"
                          formControlName="quantity"
                          class="form-control font-size-11 input-height-40 quantity-{{ config.id }}"
                          [ngClass]="{ 'is-invalid': isFormInvalid(config.form, 'quantity') }"
                          (change)="updateConfig(config)"
                        />
                      </td>
                      <td class="w15p text-center">
                        <span *ngIf="objStockLevelQuantity['warehouse_source_' +config.id]">
                          <inline-view-loader [isLoading]="objStockLevelQuantity['warehouse_source_' +config.id]['loading']" [hasText]="false" ></inline-view-loader>
                          <span *ngIf="!objStockLevelQuantity['warehouse_source_' +config.id]['loading']">
                            <span class="fs12 pr-2">{{ objStockLevelQuantity['warehouse_source_' +config.id]['quantity'] }}</span>
                            <fa-icon *ngIf="!objStockLevelQuantity['warehouse_source_' +config.id]['has_warehouse'] && config.form.controls.item_id.value"
                              class="text-danger fs12 pr-2"
                              [icon]="['fal', 'exclamation-circle']"
                              matTooltip="{{ 'no_stock_level_on_warehouse' | translate}}">
                            </fa-icon>
                          </span>
                        </span>
                      </td>
                      <td class="w15p text-center">
                        <span *ngIf="objStockLevelQuantity['warehouse_destination_' +config.id]">
                          <inline-view-loader [isLoading]="objStockLevelQuantity['warehouse_destination_' +config.id]['loading']" [hasText]="false" ></inline-view-loader>
                          <span *ngIf="!objStockLevelQuantity['warehouse_destination_' +config.id]['loading']">
                            <span class="fs12 pr-2">{{ objStockLevelQuantity['warehouse_destination_' +config.id]['quantity'] }}</span>
                          </span>
                        </span>
                      </td>
                      <td class="w10p text-center">
                        <fa-icon class="text-danger pointer fs16 pr-2" (click)="removeStockTransferQuantity(config)" [icon]="['fal', 'minus-circle']"></fa-icon>
                        <fa-icon class="text-success pointer fs16" (click)="addStockTransferQuantity()" [icon]="['fal', 'plus-circle']"></fa-icon>
                      </td>
                    </tr>
                  </table>
                </form>
              </td>
            </tr>
          </ng-container>
          <tr>
            <td colspan="5">
              <div class="row">
                <div class="col-md-6 align-bottom">
                  <span>
                    {{ 'list_view_summary' | translate:  { fromRecords: intPaginationFromData, toRecords: intPaginationToData, totalRecords: arStockTransferQuantityForm.length } }}
                  </span>
                </div>
                <div class="col-md-6">
                  <nav class="pagination-section">
                    <ul class="pagination">
                      <li [ngClass]="previousButton" class="page-item">
                        <a class="page-link" aria-label="first" (click)="getPaginatioRecord('prev')">
                          <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                        </a>
                      </li>
                      <li [ngClass]="nextButton" class="page-item">
                        <a class="page-link" aria-label="first" (click)="getPaginatioRecord('next')">
                          <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    <!-- </div> -->
  </div>
</mat-dialog-content>
</ng-container>

<ng-template #notPermitted>
  <span>{{ 'forbidden_error_stockmanagement' | translate }}</span>
</ng-template>