import { Component, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { isNil, toString } from 'lodash';
import { BehaviorSubject } from 'rxjs';

type OnChangeHandler = (value?: string) => void;
type OnTouchedHandler = () => void;

@Component({
  selector: 'fieldmagic-textarea-input',
  template: `
    <div class="w-100 d-flex flex-column d-flex-gap">
      <textarea
        class="form-control font-size-11 {{ expandableKey }}"
        [ngClass]="{'input-height-40' : expandable}"
        [disabled]="isDisabled$ | async"
        (change)="onChange($event.target.value)"
        (keyup)="onKeyUp($event.target.value)"
        (blur)="onTouched()"
        [attr.maxLength]="max"
        [attr.rows]="rows"
        [value]="value"
        (focus)="(expandable) ? onFocusIn() : ''"
        (focusout)="(expandable) ? onFocusOut() : ''"
      ></textarea>
      <div
        *ngIf="max != undefined"
        class="d-flex w-100 justify-content-end"
      >
        <small class="text-help">{{ remaining$ | async }}/{{max}}</small>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaInputComponent),
      multi: true,
    },
  ],
})
export class TextareaInputComponent implements ControlValueAccessor {
  @Input() id: string;

  @Input() max: number;

  @Input() rows: number = 5;

  @Input() expandable: boolean = false;


  readonly isDisabled$ = new BehaviorSubject<boolean>(false);

  readonly remaining$ = new BehaviorSubject<number>(0);

  public expandableKey: string = this.generateRandomId();

  _value: string;
  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this._value = toString(value).trim();
  }

  /**
   * Callback when input is touched
   */
  onTouched: OnTouchedHandler = () => { };

  onChange: OnChangeHandler = (_) => { };

  /**
   * @inheritdoc
   */
  registerOnChange(fn: OnChangeHandler): void {
    this.onChange = (value: string) => {
      this._computeRemaining(value);
      fn(toString(value).trim());
    };
  }

  /**
   * @inheritdoc
   */
  registerOnTouched(fn: OnTouchedHandler): void {
    this.onTouched = fn;
  }

  /**
   * {@inheritdoc}
   */
  setDisabledState(disabled: boolean): void {
    this.isDisabled$.next(disabled);
  }

  /**
   * @inheritdoc
   */
  writeValue(value?: string): void {
    this._computeRemaining(value);
    this.value = value;
  }

  onKeyUp(value: string): void {
    this._computeRemaining(value);
  }

  private _computeRemaining(value: string | undefined): void {
    value = toString(value);

    const max = this.max;

    if (value.length < 1 || isNil(max) || max < 1) {
      return this.remaining$.next(0);
    }

    const remaining = max - value.length;

    this.remaining$.next(remaining);
  }

  /**
   * To increase text area row
   */
  onFocusIn() {
    let element = document.querySelector('.' +this.expandableKey);
    element.setAttribute("style", "height: 100px !important");
  }

  /**
   * To decrease text area row
   */
  onFocusOut() {
    let element = document.querySelector('.' +this.expandableKey);
    element.setAttribute("style", "height: 40px !important");
  }

  /**
   * Generate random id for query selector
   *
   * @returns { string }
   */
  protected generateRandomId(): string {
    var randomText = '';
    for (var i = 0; i < 9; i++) {
      randomText += 'abcdefghijklmnopqrstuvwxyz'.charAt(Math.floor(Math.random() * 26));
    }
    return randomText;
  }
}
