import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ListingService } from '../../../services/listing.service';
import { FolderTreeSource, DynamicFlatNode } from '../_class/folders-tree-source';
import { ItemFoldersService } from '../_services/item-folders.service';
import { RecordService } from '../../../services/record.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { FormControl } from '@angular/forms';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { SearchService } from '../../../services/search.service';
import { NotificationService } from '../../../services/notification.service';
import { get } from 'lodash';
import { filled, isId } from '../../../shared/utils/common';

@Component({
  selector: 'app-static-folders',
  templateUrl: './static-folders.component.html',
  styleUrls: ['./static-folders.component.scss']
})
export class StaticFoldersComponent implements OnInit, OnDestroy {

  /**
   * This will emit the selected product.
   *
   * @var EventEmitter
   */
  @Output() objProductSelected = new EventEmitter<any>();

  /**
   * Holds the ListingService instance.
   *
   * @var {ListingService}
   */
  list: ListingService;

  /**
   * Holds the NotificationService instance.
   *
   * @var {NotificationService}
   */
  notif: NotificationService;

  /**
   * Holds the MatDialog instance.
   *
   * @var {MatDialog}
   */
  dialog: MatDialog;

  /**
   * Variable that will hold the tree's controls.
   *
   * @var {FlatTreeControl<DynamicFlatNode>}
   */
  treeControl: FlatTreeControl<DynamicFlatNode>;

  /**
   * Datasource for the tree.
   *
   * @var {FolderTreeSource}
   */
  dataSource: FolderTreeSource;

  /**
   * Holds the RecordService instance.
   *
   * @var {RecordService}
   */
  record: RecordService;

  /**
   * Holds the ItemFoldersService instance.
   *
   * @var {ItemFoldersService}
   */
  folder: ItemFoldersService;

  /**
   * Should the products be sales.
   *
   * @var {boolean}
   */
  sales: boolean = true;

  /**
   * If the products shown should only be labor.
   *
   * @var {boolean}
   */
  labor: boolean = true;

  /**
   * If were gonna hide the input quantity.
   *
   * @var {boolean}
   */
  hideQuantity: boolean = false;

  /**
   * The search folder input control.
   *
   * @var {string}
   */
  objSearchFolder: FormControl = new FormControl(null);

  /**
   * List of subscriptions.
   *
   * @var Subscription[]
   */
  arSubscriptions: Subscription[] = [];

  /**
   * Flag if there are items that have
   * already been selected.
   *
   * @returns {boolean}
   */
  get bHasSelectedItems() {
    return this.dataSource.data.filter(item => {
      return item.include
    }).length > 0;
  }

  search: SearchService

  constructor(
    public dialogRef: MatDialogRef<StaticFoldersComponent>,
    @Inject(MAT_DIALOG_DATA) public objDialogData: any,
    list: ListingService,
    dialog: MatDialog,
    folder: ItemFoldersService,
    record: RecordService,
    local: LocalStorageService,
    search: SearchService,
    notif: NotificationService
  ) {
    this.list = list;
    this.notif = notif;
    this.record = record;
    this.search = search;
    this.folder = folder;
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(
      (node: DynamicFlatNode) => node.level,
      (node: DynamicFlatNode) => node.is_expandable
    );

    if (this.objDialogData.sales) {
      this.sales = this.objDialogData.sales;
    }

    this.labor = this.objDialogData.labor;

    this.hideQuantity = this.objDialogData.hideQuantity;

    this.dataSource = new FolderTreeSource(this.treeControl, folder, local, this.labor);
    this.dialog = dialog;

    this.refreshList();
  }

  ngOnDestroy(): void {
    this.arSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.arSubscriptions.push(this.objSearchFolder.valueChanges
      .pipe(
        debounceTime(500),
        tap(term => {
          if (term) {
            this.dataSource.bHideUnallocated = true;
          } else {
            this.dataSource.bHideUnallocated = false;
          }
        }),
        switchMap(term => {

          if (term) {
            return this.search.global(term, ['items', 'item_folders'])
          } else {
            return of(null);
          }

        })
      )
      .subscribe(results => {

        this.dataSource.data = [];
        this.dataSource.numPage = null;

        if (results == null) {
          this.dataSource.loadPages();
        } else {
          let arFolderIds = results.map(item => {
            if (item.module == 'item_folders') {
              return item.id;
            }
          }).filter(item => { return item != null});

          let arItemIds = results.map(item => {
            if (item.module == 'items') {
              return item.id;
            }
          }).filter(item => { return item != null});

          this.dataSource.loadPages(arFolderIds, arItemIds);
        }

      }));
  }

  /**
   * Refresh the list of folders.
   *
   * @returns {void}
   */
  refreshList() {
    this.dataSource.loadPages();
  }

  /**
   * Checks if the current node selected has a child.
   *
   * @param {number} level
   * @param {DynamicFlatNode} node
   *
   * @returns {boolean}
   */
  hasChild = (level: number, node: DynamicFlatNode) => node.is_expandable;

  /**
   * Toggles all descendants to be included.
   *
   * @param {DynamicFlatNode} objNode
   *
   * @returns {void}
   */
  toggleDescendants(objNode: DynamicFlatNode): void {
    if (this.treeControl) {
      this.treeControl.getDescendants(objNode).map(item => {
        item.include = !objNode.include;
        return item;
      });
    }
  }

  /**
   * Passes the selected products to the dialog.
   *
   * @returns {void}
   */
  addProducts(objNode: DynamicFlatNode, strMode: string = 'items', numCount: number = 1): void {

    if (strMode == 'folder') {
      this.notif.sendConfirmation('add_folder_content_confirm')
      .pipe(
        filter(response => {
          return response.answer === true
        })
      )
      .subscribe(() => {
        this.sendProductToParent(this.treeControl.getDescendants(objNode));
      })
    }

    if (strMode == 'item') {
      this.sendProductToParent([objNode], numCount);
    }

  }

  /**
   * Send to the parent component.
   *
   * @param {DynamicFlatNode} objNode
   * @param {number} numCount
   */
  sendProductToParent(objNode: DynamicFlatNode[] = [], numCount: number = 1) {
    this.record.getProducts({
      pricebook_id: get(this.objDialogData, 'pricebook_id'),
      customer_id: get(this.objDialogData, 'customer_id'),
      sales_only: this.sales,
      ids: objNode.map((item) => get(item, 'id')).filter((id) => filled(id) && isId(id)),
      override_unit_price_with_discount_price: this.objDialogData.override_unit_price_with_discount_price,
    })
      .subscribe(arResponse => {
        arResponse.forEach(item => {
          this.objProductSelected.next({...item, ...{quantity: numCount}});
        })
      });
  }

  /**
   * Loads stock levels info.
   *
   * @param {DynamicFlatNode} objNode
   */
  loadStockLevels(objNode: DynamicFlatNode) {
    if (objNode.metadata && objNode.metadata['labor'] === false && !objNode.metadata['stock_levels']) {
      this.folder.getItemStocks(objNode.metadata['id']).subscribe(response => {
        if (response && response['items']) {
          objNode.metadata['stock_levels'] = response['items'];
        }
      });
    }
  }

}
