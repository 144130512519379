<ng-container *ngIf="module != 'items'">
  <button *ngIf="showTagButtonInModule" matTooltip="{{ 'tags' | translate }}"
    class="btn btn-warning btn-sm text-white align-with-parent"
    id="btnOpenTags"
    (click)="checkRecords()">
    <fa-icon [icon]="['fas', 'tag']" [fixedWidth]="true" aria-hidden="true"></fa-icon>
  </button>
</ng-container>

<ng-container *ngIf="module == 'items'">
<button (click)="checkRecords()" id="btnOpenTags" class="btn btn-warning mr-2 extras-button-padding text-white">
  <fa-icon [icon]="['fas', 'tag']" [fixedWidth]="true" aria-hidden="true"></fa-icon>
</button>
</ng-container>

