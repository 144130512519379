import { Injectable } from '@angular/core';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ArrService {

  constructor() { }

  /**
   * Check if the given key is set and not empty
   *
   * @param objData
   * @param strKey
   * @param fallBack
   */
  keyFallsBackTo<T>(objData: Array<[]> | Object, strKey: string = '', fallBack: T = null): T {
    return (objData !== undefined && objData !== null && objData !== '' && objData[strKey] !== undefined && objData[strKey] !== null && objData[strKey] !== '') ? objData[strKey] : fallBack;
  }

  /**
   * This will compile all unique value in an array
   *
   * @param array
   * @param strKey
   * @returns
   */
  getUniqueValues(array: Array<object>, strKey: string) {
    var arResult: Array<string> = [];
    array.forEach(function(item) {
        if (item.hasOwnProperty(strKey) && !arResult.includes(item[strKey])) {
          arResult.push(item[strKey]);
        }
    });

    return arResult;
  }

  /**
   * Get the difference of two object
   * @param objectData1
   * @param objectData2
   * @param path
   * @returns
   */
  findDifference(
    objectData1: any,
    objectData2: any,
    path: string[] = []
  ): { path: string; value1: any; value2: any }[] {
    const differences: { path: string; value1: any; value2: any }[] = [];

    const keys = Object.keys(objectData1);

    keys.forEach((key) => {
      const value1 = get(objectData1, [key]);
      const value2 = get(objectData2, [key]);
      const currentPath = [...path, key];

      if (Array.isArray(value1) && Array.isArray(value2)) {
        if (value1.length !== value2.length) {
          differences.push({
            path: currentPath.join('.'),
            value1,
            value2,
          });
        } else {
          value1.forEach((item: any, index: number) => {
            differences.push(
              ...this.findDifference(item, value2[index], [...currentPath, `[${index}]`])
            );
          });
        }
      } else if (
        value1 &&
        value2 &&
        typeof value1 === 'object' &&
        typeof value2 === 'object'
      ) {
        differences.push(...this.findDifference(value1, value2, currentPath));
      } else if (value1 != value2) {
        differences.push({
          path: currentPath.join('.'),
          value1,
          value2,
        });
      }
    });

    return differences;
  }
}
