
<header>
  <div class="d-flex">
    <h3 class="title">
        <fa-icon [icon]="['fas', 'shopping-bag']" ></fa-icon>&nbsp;
        <span *ngIf="strViewType == 'edit'" >{{ 'edit_purchase_order'| translate }} </span>
        <span *ngIf="strViewType == 'add'">{{ 'create_purchase_order'| translate }} </span>
    </h3>
    <ng-container *fcHasPermission="'purchase_orders' + ((strViewType == 'add') ? ':create' : ':edit')">
      <div class="ml-auto d-flex">
        <ul class="list-inline pull-right">
          <li class="list-inline-item">
            <form-real-time-pdf-preview
              [disabled]="isPreviewTemplate || !bInvoiceLineLoaded || bSubmitted || (whenInProgress$ | async)"
              (click)="onPreview()"
              [trigger-event]="realTimePreviewTemplate.asObservable()"
              (onComplete)="whenInProgress$.next(false)">
            </form-real-time-pdf-preview>
          </li>
          <li class="list-inline-item">
            <form-templates-save-and-preview-btn
              [disabled]="isPreviewTemplate || !bInvoiceLineLoaded || bSubmitted || (whenInProgress$ | async)"
              (click)="onSubmitAndPreview()"
              [trigger-event]="previewTemplate.asObservable()">
            </form-templates-save-and-preview-btn>
          </li>
          <li class="list-inline-item">
            <button (click)="savePurchaseOrder()" class="btn btn-primary" [disabled]="bSubmitted || !bInvoiceLineLoaded || (whenInProgress$ | async)">
              <fa-icon *ngIf="!bSubmitted" [icon]="['fas', 'save']"></fa-icon>
              <fa-icon *ngIf="bSubmitted" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
              <span class="pl-1">{{ 'save' | translate }}</span>
            </button>
          </li>
          <li class="list-inline-item">
            <fieldmagic-primary-button
              [disabled]="bSubmitted || !bInvoiceLineLoaded || (whenInProgress$ | async)"
              (click)="cancelDialog()"
              variant="error"
              icon="times"
              label="close"
              id="exit-dialog"
            ></fieldmagic-primary-button>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
</header>

<hr class="separator"/>

<ng-container *fcHasPermission="'purchase_orders' + ((strViewType == 'add') ? ':create' : ':edit'); else createPODisabled">
<mat-dialog-content *ngIf="purchaseOrderForm" class="mobile-view-dialog">
<app-error-handler onlyFor="edit_form" [isAction]="true" alignment="center">
  <div *ngIf="purchaseOrderForm.length != 0" class="mt-3">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li *ngFor="let item of purchaseOrderForm" class="nav-item text-size">
        <a class="nav-link" [ngClass]="{'active': purchaseOrderForm[0]['label'] == item.label}" id="{{item.label}}-tab" data-toggle="tab" href="#{{item.id}}" role="tab" aria-selected="true" translate>{{ item.label }}</a>
      </li>
    </ul>
    <div class="tab-content">
      <div *ngFor="let item of purchaseOrderForm" [ngClass]="{'active': purchaseOrderForm[0]['label'] == item.label, 'show': purchaseOrderForm[0]['label'] == item.label}" class="tab-pane row p-4" [id]="item.id" role="tabpanel">
        <form [formGroup]="item.groups">
          <div class="row">
            <ng-container *ngFor="let data of item.fields">
              <ng-container *ngIf="(( data.label != 'created_by' && data.label != 'modified_by' && data.label != 'updated_at' && data.label != 'created_at') && strViewType == 'add') || strViewType == 'edit'">
                <div *ngIf="data.key != 'line_items' else lineItems" class="col-{{data.space}} mb-3">
                  <app-edit-field [item]="data" [form]="item.groups" [bSubmitted]="bSubmitted" [strMode]="strViewType" strModule="purchase_orders" (parentEvent)="doSomethingInParent($event)" [strRecordId]="" (strCurrentValue)="getRelateText(data, $event)"></app-edit-field>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div align="center">
    <fa-icon *ngIf="purchaseOrderForm.length == 0" [icon]="['fas', 'spinner']" [spin]="true" class="loading-spinner" aria-hidden="true"></fa-icon>
  </div>
</app-error-handler>
</mat-dialog-content>

<ng-template #lineItems>
<!-- Flexible and Milestone Invoicing Field -->
<div class="col-12">
  <div class="row">
    <div class="col-6">
      <label class="labels">
        {{ 'line_items' | translate }}
      </label>
    </div>
    <div class="col-6">
      <button type="button" (click)="addLineItemFromWorkOrder()" class="btn btn-success pull-right mb-2">
          <fa-icon [icon]="['fas', 'plus']"></fa-icon> {{ 'add_from_work_order' | translate }}
        </button>
    </div>
    <div class="col-12 mb-2">
      <browse-products (objProductSelected)="addLineAttribute($event, true)" [bLabor]="false" [bSales]="false"></browse-products>
    </div>
    <div class="col-12">

      <div class="row">

        <div class="col-12">
          <div class="d-flex flex-row border">
            <div class="daggable-table-box-header" style="width: 8%"></div>
            <div class="draggable-table-box-header" [ngStyle]="{ width: bDepartmentTracking ? '23%' : '36%'}">
              <b>{{ 'description' | translate }}</b>
            </div>
            <div *ngIf="bDepartmentTracking" class="draggable-table-box-header" style="width: 13%">
              <b>{{ 'department' | translate }}<required-tag></required-tag></b>
            </div>
            <div class="draggable-table-box-header" style="width: 10%">
              <b>{{ 'quantity' | translate }}</b>
            </div>
            <div class="draggable-table-box-header" style="width: 10%">
              <b>{{ 'unit_cost' | translate }}</b>
            </div>
            <div class="draggable-table-box-header" style="width: 13%">
              <b>{{ 'tax_code' | translate }}<required-tag></required-tag></b>
            </div>
            <div class="draggable-table-box-header" style="width: 13%">
              <b>{{ 'account_code' | translate }}<required-tag></required-tag></b>
            </div>
            <div class="draggable-table-box-header" style="width: 10%">
              <b>{{ 'amount' | translate }}</b>
            </div>
          </div>
        </div>

        <div *ngIf="bInvoiceLineLoaded" class="col-12">
          <div cdkDropList (cdkDropListDropped)="drop($event)" class="row">

            <ng-container *ngFor="let attr of arLineAttributes; index as i">
              <div cdkDrag class="col-12" [shAttachMenu]="contextMenuComponent?.menu" [shMenuData]="contextMenuService.formatMenuData(attr, i)" (click)="onClickedLineItem($event, attr)">
                <div class="d-flex flex-row border border-top-0 bg-white" [ngClass]="{'multiple-selected': contextMenuService.isLineItemSelected(attr, selectedLineItems)}">

                  <div class="draggable-table-box center-items pr-2 pl-2" style="width: 2%;">
                    <fa-icon
                      cdkDragHandle
                      style="cursor: grab;"
                      [icon]="['fas', 'grip-vertical']">
                    </fa-icon>
                  </div>

                  <div class="draggable-table-box center-items" style="width: 6%;">

                    <fa-icon *ngIf="arLineAttributes.length > 1" (click)="removeAttribute(attr)"
                      class="pl-1 pr-1 pb-2 pt-2 text-danger pointer"
                      [icon]="['fal', 'minus-circle']">
                    </fa-icon>

                    <a *ngIf="attr['purchase_order']['item_id']"
                      href="/#/items/{{ attr['purchase_order']['item_id'] }}"
                      class="pl-1 pr-1 pb-2 pt-2"
                      target="_blank">
                      <fa-icon
                        matTooltipClass="tooltip-space"
                        matTooltip="
                        {{ 'click_to_view' | translate }}&#13;&#13;
                        {{ 'product' | translate }}: {{ attr['item']['text'] }}&#13;
                        {{ 'code' | translate }}: {{ attr['purchase_order']['item_code'] | placeholdWithString }}&#13;
                        {{ 'preferred_supplier' | translate }}: {{ attr['purchase_order']['preferred_supplier_text'] | placeholdWithString }}&#13;
                        {{ 'current_stock_level' | translate }}: {{ attr['current_stock_level'] }}&#13;
                        {{ 'supplier_notes' | translate }}: {{ getNotesOfSupplier(attr['supplier_info'], attr) | placeholdWithString }}"
                        class="text-primary"
                        [icon]="['fas', 'info-circle']">
                      </fa-icon>
                    </a>

                    <fa-icon
                      *ngIf="attr.related_products.length > 0"
                      matTooltip="{{ 'add_related_products' | translate }}"
                      class="pl-1 pr-1 pb-2 pt-2 pointer text-success"
                      [icon]="['fas', 'plus-circle']"
                      (click)="addRelated(attr, i)">
                    </fa-icon>

                    <fa-icon *ngIf="!attr['purchase_order']['job_id']"
                      [popover]="selectRelateJob"
                      [outsideClick]="true"
                      containerClass="m-2"
                      matTooltip="{{'select_job_to_relate_on_line_item' | translate }}"
                      class="pl-1 pr-1 pb-2 pt-2 text-secondary pointer"
                      [icon]="['fas','briefcase']">
                    </fa-icon>

                    <fa-icon *ngIf="attr['purchase_order']['job_id'] && attr['job_data']"
                      [popover]="selectRelateJob"
                      [outsideClick]="true"
                      containerClass="m-2"
                      placement="top"
                      matTooltip="{{'selected_job_number' | translate }}: #{{ attr['job_data']['job_number']}}"
                      class="pl-1 pr-1 pb-2 pt-2 text-success pointer"
                      [icon]="['fas','briefcase']">
                    </fa-icon>

                    <ng-template #selectRelateJob>
                      <small class="mb-2">{{ 'select_job' | translate }}</small>
                      <ng-select
                        style="width: 200px; margin-top: 5px; margin-bottom: 5px;"
                        [items]="attr['job']['source'] | async"
                        [typeahead]="attr['job']['typehead']"
                        [loading]="attr['job']['loader']"
                        [clearable]="true"
                        (change)="selectJob($event, attr, i)"
                        [ngModelOptions]="{standalone: true}"
                        bindLabel="job_number"
                        [bindValue]
                        placeholder="{{ 'job' | translate }}"
                        [(ngModel)]="attr['job_data']"
                        [id]="attr['type'] + 'job'"
                        [name]="attr['type'] + 'job'"
                        (open)="triggerSubject(attr['job']['typehead'])">
                      </ng-select>
                    </ng-template>

                  </div>
                  <div class="draggable-table-box" [ngStyle]="{ width: bDepartmentTracking ? '23%' : '36%'}">
                    <textarea
                      class="form-control po-task-desc-{{i}}" style="font-size: 12px;"
                      placeholder="{{ 'description' | translate }}"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="attr['purchase_order']['description']"
                      [ngClass]="{'is-invalid': (validateField(attr['purchase_order']['description'])) && isTouched}"
                      (focus)="increaseRow(i)"
                      (focusout)="decreaseRow(i)"
                      (ngModelChange)="markAsDirty()"
                      #invoiceLineDesc>
                    </textarea>
                  </div>
                  <div *ngIf="bDepartmentTracking" class="draggable-table-box" style="width: 13%">
                    <ng-select
                      [items]="attr['department_obv'] | async"
                      [typeahead]="attr['department_typehead']"
                      [loading]="attr['department_loader']"
                      [clearable]="true"
                      (change)="selectDepartment($event, attr)"
                      [ngModelOptions]="{standalone: true}"
                      [bindValue]
                      placeholder="{{ 'select_department' | translate }}"
                      [(ngModel)]="attr['department']"
                      [id]="attr['type'] + 'department'"
                      [name]="attr['type'] + 'department'"
                      (open)="initRelateRecords('arLineAttributes', 'departments', 'department', '', objCacheData['departments'], i)"
                      [ngClass]="{'is-invalid': validateField(attr['purchase_order']['department_id']) && isTouched}">
                      <ng-template ng-option-tmp let-item="item" class="value-text">
                          {{ item.department_name | translate }}
                      </ng-template>
                      <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          {{ item.text | translate }}
                      </ng-template>
                    </ng-select>
                  </div>
                  <div class="draggable-table-box" style="width: 10%">
                    <fieldmagic-decimal-input
                      customClass="form-control font-size-12 input-height-40"
                      (change)="quantityChange($event, attr)"
                      placeholder="{{ 'quantity' |  translate }}"
                      style="font-size: 12px;"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="attr['purchase_order']['quantity']">
                    </fieldmagic-decimal-input>
                  </div>
                  <div class="draggable-table-box" style="width: 10%">
                    <fc-currency-input
                      [places]="4"
                      id="unit_cost_{{ i }}"
                      [(ngModel)]="attr['purchase_order']['unit_cost']"
                      [ngModelOptions]="{standalone: true}"
                      [customClass]="'form-control font-size-12 input-height-40'"
                      (change)="unitCostChange($event, attr)"
                      decimalPipe>
                    </fc-currency-input>
                  </div>
                  <div class="draggable-table-box" style="width: 13%">
                    <ng-select
                      class="h-40"
                      [items]="attr['taxcode_obv'] | async"
                      [typeahead]="attr['taxcode_typehead']"
                      [loading]="attr['taxcode_loader']"
                      [clearable]="true"
                      (change)="selectATaxcode($event, attr)"
                      [ngModelOptions]="{standalone: true}"
                      bindLabel="text"
                      [bindValue]
                      placeholder="{{ 'tax_code' | translate }}"
                      [(ngModel)]="attr['tax']"
                      [id]="attr['type'] + 'tax_code'"
                      [name]="attr['type'] + 'tax_code'"
                      (open)="initRelateRecords('arLineAttributes', 'tax_codes', 'taxcode', arTaxCodeFilter, objCacheData['tax_codes'], i)"
                      [ngClass]="{'is-invalid': (validateField(attr['purchase_order']['tax_code_id']) || attr['tax'] == null) && isTouched}">
                    </ng-select>
                  </div>
                  <div class="draggable-table-box" style="width: 13%">
                    <ng-select
                      class="h-40"
                      [items]="attr['account_code_obv'] | async"
                      [typeahead]="attr['account_code_typehead']"
                      [loading]="attr['account_code_loader']"
                      [clearable]="true"
                      (change)="selectAccountCode($event, attr)"
                      [ngModelOptions]="{standalone: true}"
                      bindLabel="name"
                      [bindValue]
                      placeholder="{{ 'account_code' | translate }}"
                      [(ngModel)]="attr['account_code']"
                      [id]="attr['type'] + 'account_code'"
                      [name]="attr['type'] + 'account_code'"
                      (open)="initRelateRecords('arLineAttributes', 'account_codes', 'account_code', arAccountCodeFilter, objCacheData['account_codes'], i)"
                      [ngClass]="{'is-invalid': (validateField(attr['purchase_order']['account_code_id']) || attr['account_code'] == null) && isTouched}">
                    </ng-select>
                  </div>
                  <div class="draggable-table-box" style="width: 10%">
                    <fc-currency-input
                      customClass="form-control font-size-12 input-height-40"
                      [(ngModel)]="attr['amount']"
                      (change)="onLineTotalChange($event.target, {attr: attr, index: i})"
                    ></fc-currency-input>
                  </div>
                </div>
              </div>
            </ng-container>

            <div class="col-12 text-center" *ngIf="arLineAttributes.length < 1" [shAttachMenu]="contextMenuComponent?.menu" [shMenuData]="contextMenuService.formatMenuData([], -1)">
              <div class="d-flex flex-row border border-top-0 bg-white">
                <div class="draggable-table-box center-items pr-2 pl-2" style="width: 100%">
                  <span>{{ 'no_invoice_line' | translate }}</span>
                </div>
              </div>
            </div>

            <div *ngIf="!bInvoiceLineLoaded" class="col-12 text-center" style="font-size: 16px;">
              <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
            </div>

          </div>
        </div>

      </div>

      <div *ngIf="bInvoiceLineLoaded" class="row">
        <div class="col-12">
          <div class="d-flex flex-row border border-top-0">
            <div class="draggable-table-box right-items" style="width: 90%;">
              <span class="p-2">{{ 'total_ex_tax' | translate }}</span>
            </div>
            <div class="draggable-table-box left-items" style="width: 10%;">
              <span class="ml-2">{{ computeInvoiceLineExcludedTax() | currency: {decimal_places: 2} }}</span>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex flex-row border border-top-0">
            <div class="draggable-table-box right-items" style="width: 90%;">
              <span class="p-2  float-right">{{ 'tax' | translate }}</span>
            </div>
            <div class="draggable-table-box left-items" style="width: 10%;">
              <span class="ml-2">{{ computeInvoiceLineTax() | currency: {decimal_places: 2} }}</span>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex flex-row border border-top-0">
            <div class="draggable-table-box right-items" style="width: 90%;">
              <span class="p-2 float-right">{{ 'tax_adjustment_amount' | translate }}</span>
            </div>
            <div class="draggable-table-box center-items" style="width: 10%;">
              <fc-currency-input
                step="0.01"
                [withControls]="true"
                customClass="form-control font-size-12 input-height-40"
                [(ngModel)]="totalTaxAdjustment"
                (change)="onTaxAdjustment()">
              </fc-currency-input>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex flex-row border border-top-0">
            <div class="draggable-table-box right-items" style="width: 90%;">
              <span class="p-2  float-right">{{ 'total_inc_tax' | translate }}</span>
            </div>
            <div class="draggable-table-box center-items" style="width: 10%;">
              <fc-currency-input
                id="invoiceLineIncludedTaxField"
                [(ngModel)]="invoiceLineIncludedTax"
                customClass="form-control input-height-40 font-size-12">
              </fc-currency-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</ng-template>
</ng-container>

<ng-template #createPODisabled>
  <span id="btnCreatePODisabled" class="disabled"
    matTooltip="{{ 'forbidden_action_error' | translate }}">
    {{ 'forbidden_action_error' | translate }}
  </span>
</ng-template>

<fc-context-menu (isClicked)="doSomethingFromContextMenu($event)"></fc-context-menu>
