import { TagDisplay } from './tag-display';

export class RecordTag {
  id: string;
  tags: TagDisplay[];

  constructor(id: string, tags: TagDisplay[]) {
    this.id = id;
    this.tags = tags;
  }
}