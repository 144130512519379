

  <div
    [popper]="navPopper"
    [popperShowOnStart]="false"
    [popperTrigger]="'hover'"
    [popperHideOnClickOutside]="false"
    [popperHideOnMouseLeave]="false"
    [popperHideOnScroll]="false"
    [popperPlacement]="'right-start'"
    [popperDisabled]="!bAboveBreakPoint"
    [popperDisableAnimation]="true"
    popperApplyClass="popper-nav"
    popperApplyArrowClass="popper-arrow-nav">

    <a id="linkCollapsible" class="nav-link rounded-0 p-3 pointer"
      [ngClass]="{'parent-nav': layoutDashboardService.strActiveParent == metadata.name}"
      (click)="bIsCollapsed = !bIsCollapsed">

      <span class="d-inline-flex align-middle">
        <fa-layers class="p-2 s32 align-middle fa-fw">
          <fa-icon [icon]="['fas', 'circle']" class="text-white"></fa-icon>
          <fa-icon [icon]="['fal', 'circle']" class="navigation-border"></fa-icon>
          <fa-icon [icon]="metadata.icon" class="navigation-logo" transform="shrink-9"></fa-icon>
        </fa-layers>
        <span [id]="'navSpan' + metadata.name" *ngIf="showNavFont" class="font-weight-medium pl-2 mt-1">
          {{ metadata.name | translate }}
        </span>
      </span>

      <span *ngIf="!bAboveBreakPoint" class="float-right pt-1">
        <fa-icon *ngIf="bIsCollapsed" [icon]="['fas', 'chevron-down']"></fa-icon>
        <fa-icon *ngIf="!bIsCollapsed" [icon]="['fas', 'chevron-up']"></fa-icon>
      </span>

    </a>
  </div>

  <div *ngIf="!bAboveBreakPoint" id="collapseBasic" [collapse]="bIsCollapsed" [isAnimated]="true">
    <div class="container-fluid bg-primary text-white">
      <div *ngIf="metadata.panel1.length > 0" class="row">
        <ng-container [ngTemplateOutlet]="panel1"></ng-container>
      </div>
      <hr *ngIf="metadata.panel2.length > 0 && metadata.panel1.length > 0" class="bg-white">
      <div *ngIf="metadata.panel2.length > 0" class="row pb-3">
        <ng-container [ngTemplateOutlet]="panel2"></ng-container>
      </div>
    </div>
  </div>

  <popper-content #navPopper>
    <div class="container-fluid default-panel-width z-3 position-relative overflow-hidden" [ngClass]="{'no-second-panel-width': metadata.panel1.length == 0}">
      <div class="row">
        <div *ngIf="metadata.panel1.length > 0" class="col-md">
          <div class="row text-left">
            <ng-container [ngTemplateOutlet]="panel1"></ng-container>
          </div>
        </div>
        <div *ngIf="metadata.panel2.length > 0" class="col-md" [ngClass]="{'border-left': metadata.panel1.length > 0}">
          <div class="row text-left">
            <ng-container [ngTemplateOutlet]="panel2"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </popper-content>


<ng-template #panel1>
  <div *ngFor="let plink1 of metadata.panel1" class="col-md-12 nav-hover">
    <a [id]="'navLink' + plink1.label" [attr.href]="'/#' + plink1.direct[0]"
      class="pl-2 stretched-link link-hover"
      (click)="navPopper.hide(); openDialog(plink1, $event)">
      {{ plink1.label | translate  }}
      <fa-icon *ngIf="plink1.restrict"
        [id]="'navIcon' + plink1.label"
        [icon]="['fas', 'crown']"
        class="float-right text-warning">
      </fa-icon>
    </a>
  </div>
</ng-template>

<ng-template #panel2>
  <div *ngFor="let plink2 of metadata.panel2" class="col-md-12 nav-hover">
    <a [id]="'navLink' + plink2.label" href="javascript:void(0)"
      class="stretched-link link-hover"
      (click)="navPopper.hide(); openDialog(plink2)">
      <fa-icon [id]="'navIcon' + plink2.label" [icon]="plink2.icon" class="mr-2"></fa-icon>
      {{ plink2.label | translate  }}
      <fa-icon *ngIf="plink2.restrict"
        [id]="'navIcon' + plink2.label"
        [icon]="['fas', 'crown']"
        class="float-right text-warning">
      </fa-icon>
    </a>
  </div>
</ng-template>
