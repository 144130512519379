import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Config } from 'protractor';
import { environment } from '../../environments/environment';
import { LooseObject } from '../objects/loose-object';

const kBaseUrl: string = `${environment.url}/materials`;

@Injectable({
  providedIn: 'root'
})

export class MaterialsService {

  constructor (private http: HttpClient) {}

  createFromWorkOrder(strJobId: string, arWorkOrderLineItems: LooseObject[] = []): Observable<Config> {
    let body = new URLSearchParams();
    body.append('job_id', strJobId);
    body.append('work_order_items', JSON.stringify(arWorkOrderLineItems));

    return this.http.post<Response>(`${kBaseUrl}/create_from_wo`, body);
  }

  getPurchaseOrderLineItemsByJob(jobId: string) {

    let body = new URLSearchParams();
    body.append('job_id', jobId);

    return this.http.post<Response>(`${kBaseUrl}/get_purchase_order_line_items_by_job`, body);
  }

  getWorkOrderLineItems(strJobId: string): Observable<Config> {
    let body = new URLSearchParams();
    body.append('job_id', strJobId);

    return this.http.post<Response>(`${kBaseUrl}/get_work_order_items`, body);
  }

  /**
   * retrieve materials linked to purchase order line item without supplier invoice
   *
   * @param strJobId
   * @returns
   */
  getMaterialsWithoutSupplierInvoice(strJobId: string): Observable<Config> {
    let body = new URLSearchParams();
    body.append('job_id', strJobId);

    return this.http.post<Response>(`${kBaseUrl}/get_materials_without_supplier`, body);
  }
}

