import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditModule } from './edit.module';
import { SharedModule } from './shared.module';
import { NgDragDropModule } from 'ng-drag-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { WorkOrderItemsComponent } from '../components/view/jobs/work-order-items/work-order-items.component';
import { RouterModule } from '@angular/router';
import { SharedInputModule } from './../../shared/modules/shared-input.module';
import { ProductFoldersModule } from '../../features/product-folders/product-folders.module';
import { ShContextMenuModule } from 'ng2-right-click-menu';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    EditModule,
    SharedModule.forRoot(),
    NgDragDropModule.forRoot(),
    DragDropModule,
    SharedInputModule,
    ProductFoldersModule,
    ShContextMenuModule,
  ],
  exports: [
    WorkOrderItemsComponent
  ],
  declarations: [
    WorkOrderItemsComponent,
  ],
  entryComponents: [
    WorkOrderItemsComponent
  ],
})
export class WorkOrderItemsModule { }
