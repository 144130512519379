import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MinimumSubscriptionPlanRequiredDirective } from '../../shared/directives/MinimumSubscriptionRequired/minimum-subscription-required.directive';
import { AccessLevelRequiredDirective } from '../../shared/directives/access-level-required.directive';
import { HasPermissionDirective } from '../directives/has-permission.directive';
import { HasWidgetPermissionDirective } from '../directives/has-widget-permission.directive';
import { RowDeleteButtonDirective } from '../directives/listing/row/delete-button.directive';
import { ListRowDirective } from '../directives/listing/row.directive';
import { EchartsDirective } from '../directives/echarts.directive';

@NgModule({
  declarations: [
    MinimumSubscriptionPlanRequiredDirective,
    AccessLevelRequiredDirective,
    HasPermissionDirective,
    HasWidgetPermissionDirective,
    RowDeleteButtonDirective,
    ListRowDirective,
    EchartsDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MinimumSubscriptionPlanRequiredDirective,
    AccessLevelRequiredDirective,
    HasPermissionDirective,
    HasWidgetPermissionDirective,
    RowDeleteButtonDirective,
    ListRowDirective,
    EchartsDirective,
  ],
})
export class DirectivesModule { }
