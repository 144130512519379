<header>
  <div class="d-flex header-container header-label">
    <span class="title">
      <fa-icon [icon]="['fas', 'receipt']" class="pr-2"></fa-icon>
      <span>{{ 'create_materials_to_job' | translate }}</span>
    </span>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button class="btn btn-primary" [disabled]="selectedLineItems.length < 1" (click)="addMaterialsToJob()">
              <fa-icon [icon]="['fas', 'save']"></fa-icon>
              <span class="pl-1">{{ 'create_materials' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <fieldmagic-primary-button
            id="exit-dialog"
            variant="error"
            (click)="cancelDialog()"
            icon="times"
            label="close"
          ></fieldmagic-primary-button>
        </li>
      </ul>
    </div>
  </div>
  <hr />
</header>

<mat-dialog-content>
  <table class="table table-bordered">
    <thead>
      <tr class="text-center font-weight-bold">
        <td width="3%">
          <input type="checkbox" [(ngModel)]="isSelectAll" (change)="onChangeCheckbox($event, [], true)">
        </td>
        <td width="20%">{{ 'product' | translate }}</td>
        <td width="25%">{{ 'description' | translate }}</td>
        <td width="8%">{{ (isFromJob ? 'purchase_order' : 'job') | translate }}</td>
        <td width="8%">{{ 'supplier_invoice' | translate }}</td>
        <td width="6%">
          {{ 'ordered' | translate }}
        </td>
        <td width="6%">
          {{ 'used' | translate }}
        </td>
        <td width="10%">{{ 'unit_cost' | translate }}</td>
        <td width="10%">{{ 'amount' | translate }}</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="lineItems.length < 1" class="text-center">
        <td colspan="9">{{ 'no_items' | translate }}</td>
      </tr>
      <ng-container *ngFor="let lineItem of lineItems; index as i">
        <tr [ngClass]="{'disabled-row': lineItem.material_id}" [matTooltip]="(lineItem.material_id ? 'material_already_created_for_related_job' : '') | translate">
          <td class="text-center">
            <input *ngIf="lineItem.material_id" type="checkbox" [disabled]="lineItem.material_id">
            <input *ngIf="!lineItem.material_id" [checked]="isLineItemSelected(lineItem)" type="checkbox" (change)="onChangeCheckbox($event, lineItem, false)">
          </td>
          <td class="text-center">
            <span>
              {{ getProductDisplayText(lineItem) | ellipsis | placeholdWithString }}
            </span>
          </td>
          <td class="text-center">
            <span>
              {{ lineItem.description | ellipsis | placeholdWithString }}
            </span>
          </td>
          <td class="text-center">
            <a *ngIf="!isFromJob && lineItem.job_number" [routerLink]="['/jobs/'+lineItem.job_id]" target="_blank">
              <span>#{{ lineItem.job_number }}</span>
            </a>
            <a *ngIf="isFromJob && lineItem.po_number" [routerLink]="['/purchase_orders/'+lineItem.purchase_order_id]" target="_blank">
              <span>{{ lineItem.po_number }}</span>
            </a>
          </td>
          <td class="text-center">
            <a [routerLink]="['/purchase_orders/'+lineItem.supplier_invoice_id]" target="_blank">
              <span>{{ lineItem.supplier_invoice_text }}</span>
            </a>
          </td>
          <td class="text-center">
            <span>{{ formatNumber(lineItem.quantity) }}</span>
          </td>
          <td class="text-center">
            <fieldmagic-decimal-input
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="lineItems[i]['quantity_used']"
              (change)="usedQuantityChanged($event, lineItem, i)"
              customClass="form-control font-size-12"
              [ngClass]="{'text-secondary': hasMaterialRecord(lineItem)}"
              [disabled]="hasMaterialRecord(lineItem)"
            ></fieldmagic-decimal-input>
          </td>
          <td class="text-center"><span>{{ lineItem.unit_cost | currency }}</span></td>
          <td class="text-center"><span>{{ lineItem.total_price | currency }}</span></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</mat-dialog-content>