import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { LocalStorageService } from '../../../services/local-storage.service';
import { AccountService } from '../../../services/account.service';
import { AccountingSystemService } from '../../../integrations/accounting_systems/shared/services/accounting_system.service';
import { AcsConfig, AcsSelectedRecord } from '../../../module/acs-support/acs-support';
import { BehaviorSubject } from 'rxjs';
import { LooseObject } from '../../../objects/loose-object';
import { ActivitiesService } from '../../../services/activities.service';
import moment from 'moment';
import { NotificationService } from '../../../services/notification.service';
import { AcsSupportService } from '../../../module/acs-support/acs-support.service';


@Injectable()
export class LayoutDashboardService {
  public showedAside = false;
  public minimizedAside = false;
  public showHeaderSearchForm = false;
  public bSupportWindowMinimize = true;
  private _objAcsConfig: AcsConfig;

  public bCallInit = false;
  public bAfterCall = false;
  public objSelectedRecord: AcsSelectedRecord = null;

  /**
   * Save in this service the currently viewed record
   * so our dashboards would know to preselect.
   *
   * @var {LooseObject}
   */
  public objCurrentViewedRecord: LooseObject = null;

  /**
   * Holder for which is parent is active.
   *
   * @var {string}
   */
  public strActiveParent: string = null;

  acsStatus: BehaviorSubject<boolean>;

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private accountService: AccountService,
    private accounting: AccountingSystemService,
    private activitiesService: ActivitiesService,
    private notifService: NotificationService,
    private acsSupportService: AcsSupportService,
    ) {
    if (this.localStorageService.getItem("navbar") !== null) {
      this.minimizedAside = JSON.parse(this.localStorageService.getItem("navbar"));
    }

    this.acsStatus = new BehaviorSubject<boolean>(false);
  }

  hideAside() {
    this.showedAside = false;
  }

  showAside() {
    this.showedAside = true;
  }

  toggleAside() {
    this.showedAside = !this.showedAside;
  }

  toggleSupportWindowMinimize() {
    this.bSupportWindowMinimize = !this.bSupportWindowMinimize;
  }

  minimizeAside() {
    this.minimizedAside = !this.minimizedAside;
    this.localStorageService.setItem("navbar", this.minimizedAside.toString());
  }

  showedHeaderSearchForm() {
    this.showHeaderSearchForm = !this.showHeaderSearchForm;
  }

  /**
   * Redirect the user back to the client selection page.
   *
   * @returns {void}
   */
  switchClient(): void {
    this.localStorageService.removeItem('xero_short_code');
    this.localStorageService.removeItem('last_dashboard_accessed');
    this.router.navigateByUrl('/account/selection');
    this.accountService.triggerSwitchAccount();
    this.accounting.clearStoredAccountingSystemIdentity();
  }

  /**
   * If searchable text has a multiple values.
   * extract the values that contains of search
   * term.
   * @param data
   * @param matchPercentage
   * @param term
   */
  extractSearchValues(data, matchPercentage, term): Array<Object> {
    let listOfHits = [];

    data['name'].forEach(searchValue => {
      // Get only if the searchable text contains the term
      if (searchValue.toLowerCase().includes(term.toLowerCase())) {
        let newHits = {
          id: data['id'],
          name: searchValue,
          client_id: data['client_id'],
          module: data['module'],
          match_percentage: matchPercentage
        };

        listOfHits.push(newHits);
      }
    });

    return listOfHits;
  }

  public get acsConfig(): AcsConfig {
    return this._objAcsConfig
  }

  public set acsConfig(objAcsConfig: AcsConfig) {
    this._objAcsConfig = objAcsConfig;
    this.acsStatus.next(objAcsConfig.enable);
  }

   /**
   * Log the call summary and save it as a phone call activity
   *
   * @return  {void}
   */
  logCallEvent(strCallNotes: string): void {
    const objModuleField = {
      contacts: 'contact_id',
      sites: 'site_id',
      customers: 'customer_id',
    };
    let objCallData = {
      "module_id": this.objSelectedRecord.id,
      "module_field": objModuleField[this.objSelectedRecord.module],
      "assigned_to": this.localStorageService.getItem('user_id'),
      "subject": "phone",
      "note": strCallNotes,
      "module": this.objSelectedRecord.module,
      "type": "event",
      "activity_date": moment.utc().format('YYYY-MM-DD HH:MM:SS')
    };
    this.activitiesService.createActivity(JSON.stringify(objCallData)).subscribe( data => {
      // This will close the call summary window.
      this.bAfterCall = false;
      this.bCallInit = false;
      this.notifService.notifySuccess('activity_created');
      this.acsSupportService.triggerPingAcs('activities');
    }, err => {
      if (err['error'] && err['error']['contact_id']) {
        this.notifService.notifyWarning('not_allowed', err['error']['contact_id'][0]);
      }
    });
  }
}
