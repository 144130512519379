<fieldmagic-dialog>
  <div class="dialog-header-content">
    <fieldmagic-dialog-title
      icon="filter"
      label="{{ bUpdate ? 'update_filter' : 'save_filter' }}"
   ></fieldmagic-dialog-title>
  </div>
  <div class="dialog-header-buttons">
    <fieldmagic-primary-button
      (click)="saveFilter()"
      icon="save"
      label="save"
    ></fieldmagic-primary-button>
    <fieldmagic-primary-button
      variant="error"
      (click)="dialogClose()"
      icon="times"
      label="close"
    ></fieldmagic-primary-button>
  </div>
  <div class="dialog-content">
    <div class="form-group mb-3">
      <label for="filter_name">{{ 'filter_name' | translate }}</label>
      <fc-text-input
        id="filter_name"
        [(ngModel)]="strFilterName"
        [maxlength]="'128'"
        required
      ></fc-text-input>
    </div>
    <div class="form-group mb-12">
      <span>{{ 'share_filter_with_all_users' | translate }}: </span><mat-slide-toggle [(ngModel)]="arFilter['share']"></mat-slide-toggle>
    </div>
    <span *ngFor="let filter of arFilterCommon" [ngSwitch]="filter.id">
      <b>{{ filter.id | translate }}</b>:
      <span *ngSwitchCase="'order_by'"> {{ filter.value.id | translate }}, {{ filter.value.sort | translate }} </span>
      <span *ngSwitchDefault>

        <ul *ngIf="checkType(filter.value) == 'array'">
          <li *ngFor="let arItem of filter.value">
            {{ arItem | translate }}
          </li>
        </ul>

        <span *ngIf="checkType(filter.value) == 'string'">
          {{ filter.value | translate }}
        </span>

      </span>
      <br />
    </span>
  </div>
</fieldmagic-dialog>
