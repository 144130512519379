<div class="row">

  <div class="col-6">
    <h3 class="title">
      <fa-icon [icon]="['fas', 'tag']"></fa-icon>&nbsp;
      <span>{{ 'manage_tags' | translate }}</span>
    </h3>
  </div>

  <div class="col-6">
    <ul class="list-inline pull-right">
      <li class="list-inline-item">
        <button id="btnTagRecordClose" type="button" (click)="closeDialog()" class="btn btn-danger">
          <fa-icon [icon]="['fas', 'times']"></fa-icon>
          <span class="pl-1">{{ 'close' | translate }}</span>
        </button>
      </li>
      <li class="list-inline-item">
        <button id="btnTagRecordClear" type="button" (click)="clearTags()" class="btn btn-warning">
          <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
          <span class="pl-1">{{ 'clear_tags' | translate }}</span>
        </button>
      </li>
      <li class="list-inline-item">
        <button id="btnTagRecordSave" type="button" (click)="saveTags()" class="btn btn-primary">
          <fa-icon [icon]="['fas', 'save']"></fa-icon>
          <span class="pl-1">{{ 'save' | translate }}</span>
        </button>
      </li>
    </ul>
  </div>

</div>

<div class="row">
  <div *ngIf="bLoading" class="col-12">
    <div class="d-flex justify-content-center">
      <fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
    </div>
  </div>
  <div class="col-12">
    <mat-chip-list>
      <mat-chip *ngFor="let tag of arTags" [style.background]="tag.color" (click)="setIncluded(tag)">
        <input type="checkbox" class="mr-2" [(ngModel)]="tag.include">{{ tag.name }}
      </mat-chip>
    </mat-chip-list>
  </div>
</div>
