import { Component, OnInit, ViewChild, ElementRef, HostListener, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Select } from '../../../objects/select';
import { RecordService } from '../../../services/record.service';
import { switchMap, tap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { StatusCode } from '../../../lists/status-code';
import { NotificationService } from '../../../services/notification.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { filled } from '../../../shared/utils/common';
import { get } from 'lodash';


@Component({
  selector: 'app-create-checklist',
  templateUrl: './create-checklist.component.html',
  styleUrls: ['./create-checklist.component.scss']
})
export class CreateChecklistComponent implements OnInit {

  @ViewChild('ChecklistName') nameInput: ElementRef;

  public bSubmitted: boolean = false;
  public bShowLoader: boolean = false;
  public editText = false;
  public strChecklistName = '';
  public strId = null;
  public checklistName;
  public assetType;


  public objAssetType = {
    typehead: new Subject<string>(),
    loader: false,
    placeholder: 'select_asset_type',
    name: 'asset_type_id',
    module: 'asset_types',
    value: 'assetType',
  }
  public bEdited: boolean = false;

  /**
   * What type of checklist to be created.
   *
   * @var {string}
   */
  public checklistType: string;

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  readonly disableChecklistTypeField$ = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<CreateChecklistComponent>,
    public router: Router,
    public recordService: RecordService,
    private notifService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public dialogData: {checklist_type: string}) { }

  ngOnInit() {

    if (
      filled(get(this.dialogData, 'checklist_type')) &&
      checklist_types.includes(this.dialogData.checklist_type)
    ) {
      this.checklistType = this.dialogData['checklist_type'];
      this.disableChecklistTypeField$.next(true);
    }

    this.objAssetType['obv'] = new Observable<Select[]>();

    // Set the input observable to trigger this API call.
    this.objAssetType['obv'] = this.objAssetType['typehead'].pipe(
      // Trigger only ever 400 milisecond.
      debounceTime(400),
      // Trigger only when the value is different from the previous.
      distinctUntilChanged(),
      // Show the loader.
      tap(() => {
        this.objAssetType['loader'] = true;

      }),
      // Get the response from the API.
      switchMap(term => this.recordService.getRecordRelate('asset_types', term, '', false, false, 10, false).pipe(
        // Hide the loader once done.
        tap(() => {
          this.objAssetType['loader'] = false;
        })
      ))
    );

    this.dialogRef.backdropClick().subscribe(_ => {
      this.cancelDialog();
    });
  }

    /**
     * Closes the dialog
     */
    cancelDialog() {
      if (this.bEdited) {
        // Pop-up modal for confirmation
        this.notifService.sendConfirmation('confirm_cancel')
          .filter(confirmation => confirmation.answer === true)
          .subscribe(() => {
            this.dialogRef.close({ status: 'cancel' });
          });
      } else {
        this.dialogRef.close({status : 'cancel'});
      }
    }

    // Return Enum value of checklist type
    getEnumeratedTypeList(checklistType) {

      switch(checklistType) {
        case 'job_checklist' :
          return "job";
        case 'assets_checklist':
          return "asset";
        case 'quote_checklist':
          return 'opportunity';
        default:
          return false;
      }

    }

  // When submit button is triggered
  onSubmit() {

      // Check if there's name and valid checklist type
      if (this.getEnumeratedTypeList(this.checklistType) && this.checklistName != undefined && this.checklistName != "") {

          let arData = {
            'name' : this.checklistName,
            'type' : this.getEnumeratedTypeList(this.checklistType),
            'asset_type_id' : this.assetType,
            'is_checklist_enabled' : true
          }

          // Show loading icon in save button.
          this.bShowLoader = true;

          // If asasset type is empty
          if (arData['type'] === 'asset' && arData['asset_type_id'] === undefined) {
            this.notifService.notifyWarning('complete_checklist_fields');
            // Show loading icon in save button.
            this.bShowLoader = false;
          } else {
            this.recordService.saveRecord('checklists', arData).subscribe( result => {
                if (result.status === StatusCode.kResponseCreated) {
                  this.notifService.sendNotification('created', 'checklist_created_success', 'success');
                  this.dialogRef.close();
                  if (result['body']['id'] !== undefined) {
                      // When a checklist is created, redirect to update mode
                      this.router.navigate(['admin/checklists/edit/' + result['body']['id']]);
                  }
                } else {
                  this.notifService.notifyWarning('error_500_record');
                }
                // Hide loading icon in save button.
                this.bShowLoader = false;
            });
          }

      } else {
        this.notifService.notifyWarning('complete_checklist_fields');
      }
    }

    // When the ng-select dropdown is clicked/opened, we fake a user input
    // with no characters.
    public triggerSubject(typehead: Subject<string>) {
      // We trigger the typehead to execute a search.
      typehead.next("");
    }

    /**
     * Mark as edited
     *
     * @returns {void}
     */
    markAsEdited(): void {
      this.bEdited = true;
    }
}

/**
 * Allowed checklists types.
 *
 * @var {string[]}
 */
const checklist_types = [
  'assets_checklist',
  'job_checklist',
  'quote_checklist'
];
