<div class="row">
  <div class="col-sm-12">
    <div class="d-flex">
      <fa-icon *ngIf="icon" [icon]="['fal', icon]" [ngStyle]="style" class="header-icon"></fa-icon>
      <span class="header-label truncate">{{ label }}</span>
      <span class="header-buttons">
        <button *ngIf="save" type="button" (click)="onChange('save')" class="btn ml-1 btn-primary button-save" [disabled]="saveConfig.disable">
          <fa-icon [icon]="['fas', saveConfig.loading ? 'spinner' : saveConfig.icon]"  [spin]="saveConfig.loading"></fa-icon>
          <span class="pl-1">{{ saveConfig.label | translate }}</span>
        </button>
        <button *ngIf="cancel" type="button" (click)="onChange('cancel')" class="btn ml-1 btn-danger button-cancel">
          <fa-icon [icon]="['fas', 'times']"></fa-icon>
          <span class="pl-1">{{ 'cancel' | translate }}</span>
        </button>
      </span>
    </div>
  </div>
</div>