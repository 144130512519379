import { Injectable } from '@angular/core';
import { ClientStoreService } from './client-store.service';
import { LocalStorageService } from './local-storage.service';
import { sprintf } from 'sprintf-js';
import { filled } from '../shared/utils/common';
import { LooseObject } from '../objects/loose-object';

@Injectable()
export class ContextMenuService {

  constructor (
    private clientStoreService: ClientStoreService,
    private localStorageService: LocalStorageService,
  ) {}

  setCopiedLineItem(lineItem: Array<LooseObject> = []) {
    let clientId = this.clientStoreService.getActiveClient().client_id;
    let copiedLineItemKey = sprintf('%s_copied_line_item', clientId);
    this.localStorageService.setJsonItem(copiedLineItemKey, lineItem);
  }

  getCopiedLineItem() {
    let clientId = this.clientStoreService.getActiveClient().client_id;
    let copiedLineItemKey = sprintf('%s_copied_line_item', clientId);

    return filled(this.localStorageService.getJsonItem(copiedLineItemKey)) ? this.localStorageService.getJsonItem(copiedLineItemKey) : [];
  }

  formatMenuData(lineItem: LooseObject, index: number, additionalData: any) {
    return {
      'line_item': lineItem,
      'index': index,
      'additional_data': additionalData
    };
  }

  /**
   * This is when line item was clicked we should add this to
   * selected itemto be able to select a multiple line item
   * @param event
   * @param currentLineItem
   * @param selectedLineItem
   * @returns
   */
  selectLineItem(event: MouseEvent, currentLineItem: LooseObject, selectedLineItem: Array<LooseObject> = []) {
    if (event.ctrlKey || event.metaKey) {
      // Check if the currentLineItem is already selected
      if (this.isLineItemSelected(currentLineItem, selectedLineItem)) {
        // Deselect the item if it's already selected
        selectedLineItem = selectedLineItem.filter(i => i['select_line_id'] !== currentLineItem['select_line_id']);
      } else {
        // Select the item
        selectedLineItem.push(currentLineItem);
      }
    } else {
      selectedLineItem = [];
    }

    return selectedLineItem;
  }

  isLineItemSelected(currentLineItem: LooseObject, selectedLineItem: Array<LooseObject> = []) {
    return selectedLineItem.findIndex(item => item['select_line_id'] == currentLineItem['select_line_id']) >= 0;
  }
}
