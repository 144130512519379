import { Component, OnInit, Inject, NgZone, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { isEmpty, get } from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { LooseObject } from '../../../../../objects/loose-object';
import { ClientStoreService } from '../../../../../services/client-store.service';
import { ListingService } from '../../../../../services/listing.service';
import { RecordService } from '../../../../../services/record.service';
import { CalendarService } from '../../../services/calendar.service';
import { ViewService } from '../../../../../services/view.service';
import { TaskDuplicateDialog } from '../../task-duplicate-dialog/task-duplicate-dialog.component';
import { TaskScheduleDialogComponent } from '../../task-schedule-dialog/task-schedule-dialog.component';
import { DateService } from '../../../../../services/helpers/date.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-task-details-no-activities',
  templateUrl: './task-details-no-activities.component.html',
  styleUrls: ['../task-details-dialog.component.scss'],
})
export class TaskDetailsNoActivities implements OnInit {

  /**
   * By default, the job information tab should be displayed to the user.
   *
   * @type {String}
   */
  activeTab: 'job_info' | 'task_list' | 'quote_info' | 'checklist' = 'job_info';

  /**
   * Enable job/opportunity editing
   */
  bEditMode = false;

  /**
   * Enable task editing
   */
  bUpsertTaskMode = false;

  /**
   * Checks if the user made at least one edit to any task.
   * Later, when dialog is closed by clicking on close button
   * or backdrop (not by schedule/duplicate/unschedule task),
   * checks if true then calls refetchEvents().
   */
  bEditedTask = false;

  jobDetails$: Observable<LooseObject>;
  opportunityDetails$: Observable<LooseObject>;
  taskDetails$: Observable<LooseObject>;
  activities$: Observable<LooseObject>;
  tasks$: Observable<LooseObject>;
  checklistList$: Observable<LooseObject>;
  bActivitiesLoaded = false;

  selectedTask: object;
  strCustomerName: string;

  /**
   * determine current checklist pagination
   */
  objChecklistPagination = {
    next: null,
    previous: null,
    first: null,
    current: null
  };

  /**
   * determine if the link checklist form is open
   */
  bLinkChecklist: boolean = false;

  /**
   * Contains info of jobs linked to the opportunity
   *
   * @type {{job_id: string, job_number: string}[]}
   */
  arOpportunityJobInfo: { job_id: string, job_number: string }[];

  /**
   * The parent module of the task, either 'jobs' or 'opportunities'.
   *
   * @type {'jobs' | 'opportunities'}
   */
  strModule: 'jobs' | 'opportunities' = 'jobs';

  /**
   * Contains the record details of either the job or opportunity (quote).
   *
   * @type {object}
   */
  objRecordDetails: object;

  /**
   * A flag that indicates that the current user is viewing this task
   * in behalf of the child client and this will only be valid if
   * the trigger came from the scheduled task (via calendar click)
   */
  readonly isBehalfOfChildClient: boolean =  ! isEmpty(this.dialogData.for_child_client_id)
    && this.dialogData.scheduled_task;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData,
    public viewService: ViewService,
    public recordService: RecordService,
    public listService: ListingService,
    protected calendarService: CalendarService,
    protected dialogRef: MatDialogRef<TaskDetailsNoActivities>,
    protected ngZone: NgZone,
    protected dialog: MatDialog,
    protected clients: ClientStoreService,
    protected date: DateService,
    protected translateService: TranslateService,
  ) { }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  cancelDialog(): void {
    this.dialogRef.close({
      action: null,
      editedTask: this.bEditedTask,
      data: {},
      user: {}
    });
  }

  ngOnInit() {
    let modulePipe$: Observable<LooseObject>;
    if (get(this.dialogData, 'opportunity')) {
      this.activeTab = 'quote_info';
      this.strModule = 'opportunities';
      this.initOpportunityDetails();
      modulePipe$ = this.opportunityDetails$;
    }

    if (get(this.dialogData, 'job')) {
      this.initJobDetails();
      modulePipe$ = this.jobDetails$;
    }

    modulePipe$.pipe(
      take(1),
    ).subscribe(response => {
      this.objRecordDetails = response.record_details;
      this.strCustomerName = this.objRecordDetails['customer_text'];
      this.viewService.setViewResult(response);
      this.initChecklistList();
    });

    this.initActivities();
    this.initTasks();
    this.initCurrentTaskDetails();

    this.dialogRef.backdropClick().subscribe(response => {
      this.cancelDialog();
    });
  }

  initJobDetails() {
    this.jobDetails$ = this.recordService.getRecord(
      'jobs',
      this.dialogData.job.id,
      true,
      {},
      'view',
      'get',
      {
        ... (this.isBehalfOfChildClient && {
          on_behalf_of_client: this.dialogData.for_child_client_id,
        }),
      }
    ).pipe(
      shareReplay()
    );
  }

  /**
   * Initializes the opportunity details
   *
   * @return {void}
   */
  initOpportunityDetails(): void {
    this.opportunityDetails$ = this.recordService.getRecord(
      'opportunities',
      this.dialogData.opportunity.id,
      true,
      {},
      'view',
      'get',
      {
        ... (this.isBehalfOfChildClient && {
          on_behalf_of_client: this.dialogData.for_child_client_id,
        }),
      }
    ).pipe(
      shareReplay()
    );
  }

  /**
   * Get all activities except tasks
   *
   * @return  {void}    Update the observable value that will update the UI data
   */
  initActivities(): void {
    let objFilter: LooseObject = this.initFilter({
      activity_type: [{ op: "ne", value: [{ label: "task", value: "task" }] }]
    });

    this.activities$ = this.initActivitiesSource(objFilter);
  }

  /**
   * Get all the tasks for the job/opportunity except email, messages, notes, and events
   *
   * @return  {void}    Update the observable value that will update the UI data
   */
  initTasks(numPage: number = 1): void {
    let objFilter: LooseObject = this.initFilter({
      activity_type: [{ op: "eq", value: [{ label: "task", value: "task" }] }]
    });

    this.tasks$ = this.initTasksSource(objFilter, numPage);
  }

  /**
   * Gets the source observable filter
   *
   * @return  {LooseObject}
   */
  initFilter(objFilter: LooseObject): LooseObject {
    let objBaseFilter: LooseObject = {
      ... (this.dialogData.id && {
        id: [{
          op: "ne",
          value: this.dialogData.id,
        }],
      }),
      ...(this.dialogData.job && {
        job_id: [{ id: this.dialogData.job.id, name: this.dialogData.job.job_number }]
      }),
      ...(this.dialogData.opportunity && {
        opportunity_id: [{ id: this.dialogData.opportunity.id, name: this.dialogData.opportunity.opportunity_number }]
      }),
    };

    return Object.assign(objBaseFilter, objFilter);
  }

  /**
   * Get the source module of the activities
   *
   * @return {Observable<object[]>}
   */
  initActivitiesSource(objFilter: LooseObject): Observable<object[]> {
    return this.listService.fetchDataAdvanceSearch(
      {},
      'activities',
      objFilter,
      { "id": "updated_at", "sort": "desc" },
      null,
      10,
      {
        ... (this.isBehalfOfChildClient && {
          on_behalf_of_client: this.dialogData.for_child_client_id,
        }),
      }
    ).pipe(
      shareReplay(),
      map((response) => {
        let transformedActivities = response['data'].map((activity) => {
          if (activity.activity_type === 'email') {
            activity.to = JSON.parse(activity.to);
            activity.cc = JSON.parse(activity.cc);
            activity.bcc = JSON.parse(activity.bcc);
          }
          return activity;
        });
        response['data'].data = transformedActivities;
        return response;
      })
    );
  }

  /**
   * Get all tasks of either the job or opportunity
   *
   * @return {Observable<object[]>}
   */
  initTasksSource(objFilter: LooseObject, numPage: number = 1): Observable<object[]> {
    return this.listService.fetchDataAdvanceSearch(
      { pageNum: numPage },
      'activities',
      objFilter,
      { id: 'updated_at', sort: 'desc' },
      null,
      5,
      {
        ... (this.isBehalfOfChildClient && {
          on_behalf_of_client: this.dialogData.for_child_client_id,
        }),
      }
    ).pipe(
      shareReplay()
    );
  }

  /**
   * Initialize current task details, if the current view
   * is a job, simply dont retrieve a task.
   *
   * @return {void}
   */
  initCurrentTaskDetails(): void {
    if (this.dialogData.id && this.dialogData.metadata_type === 'task') {
      this.taskDetails$ = this.recordService.getRecord(
        'activities',
        this.dialogData.id,
        true,
        {},
        'view',
        'get',
        {
          ... (this.isBehalfOfChildClient && {
            on_behalf_of_client: this.dialogData.for_child_client_id,
          }),
        }
      ).pipe(
        shareReplay()
      );
    }
  }

  /**
   * Changes the currently displayed tab.
   *
   * @returns {void}
   */
  switchTab(tabName): void {
    this.activeTab = tabName;

    if ((tabName === 'task_list' && this.bUpsertTaskMode)
      || (tabName === 'job_info' && this.bEditMode)
      || (tabName === 'quote_info' && this.bEditMode)) {
      return;
    }

    this.bUpsertTaskMode = false;
    this.bEditMode = false;
    this.bLinkChecklist = false;
  }

  setEditMode(bEditMode: boolean): void {
    this.bEditMode = bEditMode;
  }

  onDetailsEditClose(formSave: boolean): void {
    if (formSave) {
      // Update UI Details
      switch (this.activeTab) {
        case 'job_info':
          this.initJobDetails();
          break;
        case 'quote_info':
          this.initOpportunityDetails();
          break;
      }
    }

    this.bEditMode = false;
  }

  openUpsertTaskView(selectedTask: LooseObject = {}): void {
    if (isEmpty(selectedTask)) {
      selectedTask = {
        job_id: get(this.dialogData, 'job.id', null),
        opportunity_id: get(this.dialogData, 'opportunity.id', null)
      };
    }

    this.selectedTask = selectedTask;
    this.bUpsertTaskMode = true;
    this.activeTab = 'task_list';
  }

  /**
   * Triggers when upsert task form is closed.
   *
   * @returns {void}
   */
  onUpsertTaskClose(taskFormSave: boolean): void {
    if (taskFormSave) {
      this.bEditedTask = true;
      setTimeout(() => {
        this.initCurrentTaskDetails();
        this.initTasks();
      }, 3500);
    }
    this.bUpsertTaskMode = false;
  }

  /**
   * Updates the last created activity if the recent
   * activities current page is 1.
   *
   * @returns {void}
   */
  displayLatestActivities(): void {
    setTimeout(() => {
      this.initActivities();
    }, 3500);
  }

  getColorClass(strChecklistStatus: 'awaiting_completion' | 'pass' | 'fail'): string {
    switch (strChecklistStatus) {
      case 'awaiting_completion':
        return 'text-warning';
      case 'pass':
        return 'text-success';
      case 'fail':
        return 'text-danger';
    }
  }

  /**
   * Gets the translatable text for the checklist type for display
   *
   * @param {'job' | 'opportunity' | 'asset'} strType
   *
   * @returns {string}
   */
  getChecklistType(strType: 'job' | 'opportunity' | 'asset'): string {
    switch (strType) {
      case 'job':
        return 'job_checklist';
      case 'opportunity':
        return 'quote_checklist';
      case 'asset':
        return 'asset_checklist'
      default:
        return '';
    }
  }

  /**
   * Closes the dialog.
   *
   * @see {@link https://github.com/angular/components/issues/9676} Fixes a bug where
   * the dialog box isn't closing immediately (and will take about 20 seconds to
   * close) even after calling dialogRef.close
   */
  dialogClose(strStatus, arData = null): void {
    this.ngZone.run(() => {
      this.dialogRef.close({ status: strStatus, data: arData });
    });
  }

  /**
   * Duplicate the task
   *
   * @todo Add tests to the closing of dialog. It shouldn't be closed when the user
   * cancels the duplication of task.
   *
   * @param taskMetadata
   *
   * @returns {void}
   */
  duplicateEvent(taskMetadata: LooseObject): void {
    const event = {
      taskMetadata: taskMetadata,
      tasks: this.dialogData.tasks,
      record_details: this.viewService.getRecord().record_details,
      module: this.strModule
    }

    let dialog = this.dialog.open(TaskDuplicateDialog, {
      height: 'auto',
      width: '600px',
      panelClass: ['w-50'],
      data: {
        fcEvent: event,
        view: this.dialogData.view
      },
      autoFocus: false
    });

    dialog
      .afterClosed()
      .pipe(
        filter(duplicate => duplicate !== undefined && duplicate !== null)
      )
      .subscribe(duplicate => {
        this.dialogRef.close(duplicate);
      });
  }

  /**
   * Sets a new due date, duration, and technician to task
   * and changes the task_progress field to "scheduled"
   *
   * @param taskMetadata
   *
   * @returns {void}
   */
  scheduleTask(taskMetadata: LooseObject): void {
    const event = {
      taskMetadata: taskMetadata,
      tasks: this.dialogData.tasks,
      record_details: this.viewService.getRecord().record_details,
      module: this.strModule
    }

    let dialog = this.dialog.open(TaskScheduleDialogComponent, {
      height: 'auto',
      width: '600px',
      panelClass: ['w-50'],
      data: {
        view: this.dialogData.view,
        fcEvent: event ,
        ... (this.dialogData.for_child_client_id && {
          on_behalf_of_client: this.dialogData.for_child_client_id,
        }),
      },
      autoFocus: false
    });

    dialog
      .afterClosed()
      .pipe(
        filter(scheduledTask => scheduledTask !== undefined && scheduledTask !== null)
      )
      .subscribe(scheduledTask => {
        this.dialogRef.close(scheduledTask);
      });
  }

  /**
   * Removes the event from the calendar and sets its `task_progress`
   * field to "awaiting_scheduling"
   *
   * @param taskMetadata
   *
   * @returns {void}
   */
  unscheduleEvent(taskMetadata: LooseObject): void {
    const onComplete = (response) => this.dialogRef.close(response);

    this.calendarService.unscheduleTask({
      task: taskMetadata,
      tasks: this.dialogData.tasks,
      record_details: this.viewService.getRecord().record_details,
      module: this.strModule,
      on_behalf_of_child_client: this.isBehalfOfChildClient,
      child_client_id: this.dialogData.for_child_client_id,
      onComplete: onComplete,
    });
  }

  /**
   * Formats activities date for display
   *
   * @param strDate
   * @param strType
   *
   * @returns {void}
   */
  formatDate(strDate: string, strType: string): string {
    // Convert datetime to utc
    let utcTime = moment.utc(strDate);
    let strLocalTime = this.date.convertUtcToLocal(utcTime);

    // Convert to local time zone and display
    return strLocalTime.format('lll');
  }

  showOnCalendarEvent(jobTask): void {
    this.dialogRef.close({ action: 'show_on_calendar', data: jobTask });
  }

  /**
   * Check if the given task can be duplicated
   */
  shouldShowDuplicate(task: { duplicable: boolean }): boolean {
    return task.duplicable && ! this.dialogData.for_child_client_id;
  }

  /**
   * retrieve checklist list
   */
  initChecklistList(strPage: string = 'default'): void {
    const getRecordId = () => {
      let strModuleKey: 'job' | 'opportunity' = this.strModule === 'jobs' ? 'job' : 'opportunity';
      let strJobOrOpportunityId = get(this.dialogData, `${strModuleKey}.id`);

      // if viewing task from parent client, use parent ID if not empty, else use strJobOrOpportunityId
      if (this.objRecordDetails['customer_owner_client_id'] === this.clients.getActiveClient().client_id) {
        return this.objRecordDetails['parent_id'] || strJobOrOpportunityId
      } else {
        // if viewing task from child client, just use the strJobOrOpportunityId
        return strJobOrOpportunityId
      }
    };
    let objPagination = this.listService.beforeFetching(strPage);
    let strFilterKey: string = this.strModule === 'jobs' ? 'job_id' : 'opportunity_id';
    let objFilter: object = { [strFilterKey]: getRecordId() };

    this.checklistList$ = this.listService.fetchData(
      JSON.stringify(objPagination['objPage']),
      'checklist_responses',
      JSON.stringify(objFilter)
    ).pipe(
      map((response) => {
        this.listService.afterFetching(response, strPage);

        this.objChecklistPagination = {
          next: this.listService.strNextValue,
          previous: this.listService.strPrevValue,
          first: this.listService.strFirstValue,
          current: this.listService.strCurrentValue
        };

        response['data'].map( checklist => {
          if (checklist.type == 'asset') {
            checklist.period = this.convertPeriod(checklist.period);
          }

          return checklist
        })
        return response;
      })
    );
  }

  /**
   * translate asset checklist period
   */
  convertPeriod(period: string): string {
    if (period) {
      let arPeriod = JSON.parse(period);
      if (arPeriod.length > 0) {
        return arPeriod.map( strPeriod => {
          return this.translateService.instant(strPeriod);
        }).join(', ');
      }
    }

    return '';
  }

  /**
   * open link checklist form
   */
  openLinkChecklist() {
    this.bLinkChecklist = true;
    this.activeTab = 'checklist';
  }

  /**
   * close link checklist form
   * if linked successfully, refresh the checklist listing
   */
  closeLinkChecklist(bSuccess: boolean): void {
    if (bSuccess) {
      this.initChecklistList();
    }
    this.bLinkChecklist = false;
  }

  /**
   * Creates the URL for links to other modules
   *
   * @param {string} moduleName
   * @param {string|undefined} moduleId
   *
   * @returns {string}
   */
  createUrl(moduleName: string, moduleId: string | undefined = undefined): string {
    return this.calendarService.createUrl(moduleName, moduleId, this.isBehalfOfChildClient);
  }
}
