import { get } from 'lodash';
import { LooseObject } from '../loose-object';

export class SupplierInvoiceLine {
  item_id?: string;
  item_code?: string;
  item_name?: string;
  account_code?: string;
  account_code_id?: string;
  account_code_name?: string;
  tax_code?: string;
  tax_code_id?: string;
  tax_code_name?: string;
  tax_rate?: number;
  department_id?: string;
  department_name?: string;
  description?: string;
  quantity?: number;
  unit_cost?: number;
  current_stock_level?: number;
  purchase_order_line_item_id?: string;
  labor?: boolean;
  related_products?: LooseObject[];
  supplier_pricing?: LooseObject[];

  constructor(data: LooseObject){
    this.item_id = get(data, 'item_id');
    this.item_code = get(data, 'item_code');
    this.item_name = get(data, 'item_name');
    this.account_code = get(data, 'account_code');
    this.account_code_id = get(data, 'account_code_id');
    this.account_code_name = get(data, 'account_code_name');
    this.tax_code = get(data, 'tax_code');
    this.tax_code_id = get(data, 'tax_code_id');
    this.tax_code_name = get(data, 'tax_code_name');
    this.tax_rate = get(data, 'tax_rate');
    this.department_id = get(data, 'department_id');
    this.department_name = get(data, 'department_name');
    this.description = get(data, 'description');
    this.quantity = get(data, 'quantity');
    this.unit_cost = get(data, 'unit_cost');
    this.current_stock_level = get(data, 'current_stock_level');
    this.purchase_order_line_item_id = get(data, 'purchase_order_line_item_id');
    this.labor = get(data, 'labor');
    this.related_products = get(data, 'related_products', []);
    this.supplier_pricing = get(data, 'supplier_pricing', []);
  }
}