<header>
  <div class="d-flex wizard-header">
    <h3 class="dialog-title">
      <fa-icon [icon]="['fas', 'wrench']" transform="shrink-3" class="dialog-title"></fa-icon>
      <span>&nbsp;{{ (dialogData.isNew ? 'create' : 'update') | translate }} {{ 'advance_report' | translate }}</span>
    </h3>
    <ng-container>
      <div class="ml-auto d-flex">
        <ul class="list-inline pull-right">
          <li class="list-inline-item">
            <button type="button" (click)="closeDialog('cancel')" class="btn ml-1 btn-primary"
              id="wizardCreateDashboardCancelDialog">
              <fa-icon [icon]="['fas', 'times']"></fa-icon>
              <span class="pl-1">{{ 'cancel' | translate }}</span>
            </button>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
</header>
<hr>
<ng-container>
  <mat-dialog-content class="mobile-view-dialog">
    <mat-horizontal-stepper [linear]="true" class="creation-wizard-style" #stepper>
      <ng-template matStepperIcon="edit">
        <mat-icon>done</mat-icon>
      </ng-template>
      <mat-step [stepControl]="generalSetupForm">
        <form [formGroup]="generalSetupForm">
          <ng-template matStepLabel>{{ 'advance_report_lang.report_setup' | translate }}</ng-template>
          <div class="row mt-2">
            <div class="col-lg-6">
              <div>
                <label class="labels" for="name">
                  {{ 'advance_report_lang.name' | translate }}
                  <required-tag [hasSpace]="false"></required-tag>
                </label>
              </div>
              <div>
                <input formControlName="name" type="text" class="form-control font-size-11"
                  [ngClass]="{'is-invalid': generalSetupForm.controls['name']?.touched && generalSetupForm.controls['name']?.invalid}" />
                  <app-validation-messages [control]="generalSetupForm.get('name')">
                    <ng-template let-errorMessage>
                      <small class="text-danger">
                        {{ errorMessage }}
                      </small>
                    </ng-template>
                  </app-validation-messages>
              </div>
            </div>
            <div class="col-lg-6">
              <div>
                <label class="labels" for="module">
                  {{ 'module' | translate }}
                  <required-tag [hasSpace]="false"></required-tag>
                </label>
              </div>
              <div>
                <ng-container *ngIf="(moduleList$ | async)?.domains as moduleList">
                  <ng-select appendTo="body" formControlName="module" [items]="moduleList" [clearable]="false" bindValue="value" bindLabel="label"
                    [ngClass]="{'is-invalid': generalSetupForm.controls['module'].touched && generalSetupForm.controls['module'].invalid}"
                    class="font-size-11">

                    <ng-template ng-option-tmp let-item="item">
                      <span translate>{{ item.label }}</span>
                    </ng-template>

                    <ng-template ng-label-tmp let-item="item">
                      <span translate>{{ item.label }}</span>
                    </ng-template>

                  </ng-select>
                </ng-container>
                <app-validation-messages [control]="generalSetupForm.get('module')">
                  <ng-template let-errorMessage>
                    <small class="text-danger">
                      {{ errorMessage }}
                    </small>
                  </ng-template>
                </app-validation-messages>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-12">
              <div>
                <label class="labels" for="fields">
                  {{ 'fields' | translate }}
                </label>
              </div>
              <div>
                <ng-select appendTo="body" formControlName="fields" [items]="fieldList" [multiple]="true" [clearable]="true"
                  bindLabel="translated_label" bindValue="model" placeholder="">
                </ng-select>
                <app-validation-messages [control]="generalSetupForm.get('fields')" [messages]="multiselectMessages">
                  <ng-template let-errorMessage>
                    <small class="text-danger">
                      {{ errorMessage }}
                    </small>
                  </ng-template>
                </app-validation-messages>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-12">
              <div>
                <label class="labels" for="filters">
                  {{ 'filters' | translate }}
                </label>
              </div>
              <div>
                <advanced-searchbox *ngIf='fieldList' [openOnLoad]="false" [template]="fieldList" [model]="globalFilter"
                  placeholder="{{ 'search' | translate }}"></advanced-searchbox>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-lg-12">
              <button type="button" class="btn btn-primary btn-block btn-lg p-2"
                (click)="next(stepper, generalSetupForm)">
                <strong>{{ 'next' | translate }}</strong>
              </button>
            </div>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="chartSetupForm">
        <form [formGroup]="chartSetupForm">
          <ng-template matStepLabel>{{ 'advance_report_lang.charts_setup' | translate }}</ng-template>
          <div formArrayName="chart_configs">
            <div *ngFor="let config of chartConfigForms.controls; let chartIndex=index">
              <div class="row mt-3">
                <div class="col-xs-6 col-md-6 form-group">
                  <strong>{{ 'advance_report_lang.chart' | translate }} {{ chartIndex + 1 }}</strong>
                </div>
                <div class="col-xs-6 col-md-6 form-group">
                  <a href="javascript:void(0)" class="btn btn-danger btn-sm float-right" (click)="removeChart(chartIndex)">
                    <fa-icon [icon]="['fas', 'minus']"></fa-icon>&nbsp;{{ 'advance_report_lang.remove_chart' | translate }}
                  </a>
                </div>
              </div>
              <div [formGroupName]="chartIndex" class="row">
                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-lg-3">
                      <div>
                        <label class="labels" for="name">
                          {{ 'advance_report_lang.name' | translate }}
                          <required-tag [hasSpace]="false"></required-tag>
                        </label>
                      </div>
                      <div>
                        <input formControlName="name" type="text" class="form-control font-size-11"
                          [ngClass]="{'is-invalid': config.controls['name']?.touched && config.controls['name']?.invalid}" />
                        <app-validation-messages [control]="config.controls['name']">
                          <ng-template let-errorMessage>
                            <small class="text-danger">
                              {{ errorMessage }}
                            </small>
                          </ng-template>
                        </app-validation-messages>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div>
                        <label class="labels" for="grouping_type">
                          {{ 'advance_report_lang.grouping_type' | translate }}
                          <required-tag [hasSpace]="false"></required-tag>
                        </label>
                      </div>
                      <div>
                        <ng-select appendTo="body" formControlName="grouping_type" [items]="groupingTypes" [searchable]="false"
                          [ngClass]="{'is-invalid': config.controls['grouping_type']?.touched && config.controls['grouping_type']?.invalid}"
                          [clearable]="false" class="font-size-11">
                          <ng-template ng-option-tmp let-item="item">
                            <span>{{ 'advance_report_lang.grouping_types.' + item | translate }}</span>
                          </ng-template>

                          <ng-template ng-label-tmp let-item="item">
                            <span>{{ 'advance_report_lang.grouping_types.' + item | translate }}</span>
                          </ng-template>

                        </ng-select>
                        <app-validation-messages [control]="config.controls['grouping_type']">
                          <ng-template let-errorMessage>
                            <small class="text-danger">
                              {{ errorMessage }}
                            </small>
                          </ng-template>
                        </app-validation-messages>
                      </div>
                    </div>
                    <ng-container [ngSwitch]="config.controls['grouping_type'].value">
                      <ng-container *ngSwitchCase="'date_histogram'">
                        <div formGroupName="date_histogram" class="col-lg-3">
                          <div>
                            <label class="labels" for="field">
                              {{ 'field' | translate }}
                              <required-tag [hasSpace]="false"></required-tag>
                            </label>
                          </div>
                          <div>
                            <ng-select appendTo="body" formControlName="field" [items]="dateTimeFields" [clearable]="false"
                              bindLabel="translated_label" bindValue="model" placeholder="{{ 'advance_report_lang.datetime_field' | translate }}">
                            </ng-select>
                          </div>
                        </div>
                        <div formGroupName="date_histogram" class="col-lg-3">
                          <div>
                            <label class="labels" for="calendar_interval">
                              {{ 'advance_report_lang.calendar_interval' | translate }}
                              <required-tag [hasSpace]="false"></required-tag>
                            </label>
                          </div>
                          <div>
                            <ng-select appendTo="body" formControlName="calendar_interval" [items]="calendarInterval"
                              [clearable]="false" [searchable]="false" bindLabel="label">

                              <ng-template ng-option-tmp let-item="item">
                                <span>{{ item | translate }}</span>
                              </ng-template>

                              <ng-template ng-label-tmp let-item="item">
                                <span>{{ item | translate }}</span>
                              </ng-template>

                            </ng-select>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchCase="'histogram'">
                        <div formGroupName="histogram" class="col-lg-3">
                          <div>
                            <label class="labels" for="field">
                              {{ 'field' | translate }}
                              <required-tag [hasSpace]="false"></required-tag>
                            </label>
                          </div>
                          <div>
                            <ng-select appendTo="body" formControlName="field" [items]="numericFields" [clearable]="false"
                              bindLabel="translated_label" bindValue="model" placeholder="{{ 'advance_report_lang.numeric_field' | translate }}">
                            </ng-select>
                          </div>
                        </div>
                        <div formGroupName="histogram" class="col-lg-3">
                          <div>
                            <label class="labels" for="interval">
                              {{ 'advance_report_lang.interval' | translate }}
                              <required-tag [hasSpace]="false"></required-tag>&nbsp;
                              <fa-icon [icon]="['fas', 'info-circle']" class="change-pointer" matTooltip="{{ 'advance_report_lang.interval_tooltip' | translate }}"></fa-icon>
                            </label>
                          </div>
                          <div>
                            <input formControlName="interval" type="number"
                              class="form-control font-size-11"
                              [ngClass]="{'is-invalid': config.controls['histogram'].controls['interval']?.touched && config.controls['histogram'].controls['interval']?.invalid}" />
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchCase="'terms'">
                        <div formGroupName="terms" class="col-lg-3">
                          <div>
                            <label class="labels" for="field">
                              {{ 'field' | translate }}
                              <required-tag [hasSpace]="false"></required-tag>
                            </label>
                          </div>
                          <div>
                            <ng-select appendTo="body" formControlName="field" [items]="dropdownFields" [clearable]="false" bindLabel="translated_label"
                              bindValue="model">
                            </ng-select>
                          </div>
                        </div>
                        <div formGroupName="terms" class="col-lg-3">
                          <div>
                            <label class="labels" for="size">
                              {{ 'advance_report_lang.size' | translate }}
                              <required-tag [hasSpace]="false"></required-tag>
                            </label>
                          </div>
                          <div>
                            <input formControlName="size" type="number"
                              class="form-control font-size-11"
                              [ngClass]="{'is-invalid': config.controls['terms'].controls['size']?.touched && config.controls['terms'].controls['size']?.invalid}" />
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchCase="'filter_group'">
                        filter_group
                      </ng-container>
                    </ng-container>
                  </div>
                  <div formArrayName="datasets">
                    <table class="table table-bordered mt-3 mt-2 datasets-table" width="100%">
                      <thead>
                        <tr>
                          <th colspan="5" class="text-center">{{ 'advance_report_lang.datasets' | translate }}</th>
                          <th rowspan="2" class="text-center align-middle">
                            <a href="javascript:void(0)" class="btn btn-primary btn-sm" (click)="addDataset(chartIndex)">
                              <fa-icon [icon]="['fas', 'plus']"></fa-icon>&nbsp;{{ 'add' | translate }}
                            </a>
                          </th>
                        </tr>
                        <tr>
                          <th>{{ 'advance_report_lang.name' | translate }}</th>
                          <th>{{ 'advance_report_lang.chart_type' | translate }}</th>
                          <th>{{ 'advance_report_lang.color' | translate }}</th>
                          <th>{{ 'advance_report_lang.aggregation_type' | translate }}</th>
                          <th>{{ 'advance_report_lang.aggregated_field' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let dataset of datasetForm(chartIndex).controls; let datasetIndex=index">
                          <tr [formGroupName]="datasetIndex">
                            <td width="22%">
                              <div>
                                <input formControlName="name" type="text" class="form-control font-size-11"
                                  [ngClass]="{'is-invalid': dataset.controls['name']?.touched && dataset.controls['name']?.invalid}" />
                                <app-validation-messages [control]="dataset.controls['name']">
                                  <ng-template let-errorMessage>
                                    <small class="text-danger">
                                      {{ errorMessage }}
                                    </small>
                                  </ng-template>
                                </app-validation-messages>
                              </div>
                            </td>
                            <td width="22%">
                              <div>
                                <ng-select appendTo="body" formControlName="chart_type" [items]="chartTypes" [searchable]="false"
                                  [ngClass]="{'is-invalid': dataset.controls['chart_type']?.touched && dataset.controls['chart_type']?.invalid}"
                                  [clearable]="false" class="font-size-11">
                                  <ng-template ng-option-tmp let-item="item">
                                    <span>{{ 'advance_report_lang.chart_types.' + item | translate }}</span>
                                  </ng-template>

                                  <ng-template ng-label-tmp let-item="item">
                                    <span>{{ 'advance_report_lang.chart_types.' + item | translate }}</span>
                                  </ng-template>

                                </ng-select>
                                <app-validation-messages [control]="dataset.controls['chart_type']">
                                  <ng-template let-errorMessage>
                                    <small class="text-danger">
                                      {{ errorMessage }}
                                    </small>
                                  </ng-template>
                                </app-validation-messages>
                              </div>
                            </td>
                            <td width="4%" class="text-center">
                              <input type="color" formControlName="color" class="w-100" />
                            </td>
                            <td width="22%">
                              <div>
                                <ng-select appendTo="body" formControlName="type" [items]="aggregationType" [clearable]="false"
                                  [searchable]="false"
                                  [ngClass]="{'is-invalid': dataset.controls['type']?.touched && dataset.controls['type']?.invalid}"
                                  class="font-size-11">
                                  <ng-template ng-option-tmp let-item="item">
                                    <span>{{ 'advance_report_lang.aggregation_types.' + item | translate }}</span>
                                  </ng-template>

                                  <ng-template ng-label-tmp let-item="item">
                                    <span>{{ 'advance_report_lang.aggregation_types.' + item | translate }}</span>
                                  </ng-template>

                                </ng-select>
                                <app-validation-messages [control]="dataset.controls['type']">
                                  <ng-template let-errorMessage>
                                    <small class="text-danger">
                                      {{ errorMessage }}
                                    </small>
                                  </ng-template>
                                </app-validation-messages>
                              </div>
                            </td>
                            <td width="22%">
                              <div *ngIf="dataset.controls['type'].value && dataset.controls['type'].value !== 'value_count'">
                                <ng-select appendTo="body" formControlName="field" [items]="numericFields" [clearable]="false"
                                  bindLabel="translated_label" bindValue="model" placeholder="{{ 'field' | translate }}">
                                </ng-select>
                              </div>
                            </td>
                            <td width="8%" class="text-center">
                              <a href="javascript:void(0)" class="btn btn-danger btn-sm" (click)="removeDataset(chartIndex, datasetIndex)">
                                <fa-icon [icon]="['fas', 'minus']"></fa-icon>&nbsp;{{ 'remove' | translate }}
                              </a>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div *ngIf="(chartIndex+1) === chartConfigForms.controls.length" class="row mt-2">
                <div class="col-xs-12 col-md-12 form-group text-center">
                  <a href="javascript:void(0)" class="btn btn-primary" (click)="addChart()">
                    <fa-icon [icon]="['fas', 'plus']"></fa-icon>&nbsp;{{ 'advance_report_lang.add_chart' | translate }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-6">
              <button class="btn btn-warning btn-block btn-lg p-2" matStepperPrevious>{{ 'back' | translate }}</button>
            </div>
            <div class="col-lg-6">
              <button type="button" class="btn btn-primary btn-block btn-lg p-2"
                (click)="next(stepper, chartSetupForm)">
                <strong>{{ 'next' | translate }}</strong>
              </button>
            </div>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>{{ 'advance_report_lang.preview_dashboard' | translate }}</ng-template>
        <ng-container *ngIf='dashboardData$ | async as dashboardData; else resultsetLoading'>
          <ng-container *ngIf="resultSet?.length > 0; else resultsetEmpty">
            <div class="dataset-row" [ngClass]="dashboardData?.length % 2 === 0 ? 'even' : 'odd'">
              <div class="" *ngFor="let chartOption of dashboardData">
                <div *ngIf="chartOption" echarts [options]="chartOption" style="width: 100%; min-height: 400px;"></div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <div *ngIf="resultSet?.length > 0" class="row mt-2 scrollable-table">
          <div class="col-lg-12">
            <table class="table table-striped w100p resultset-table">
              <thead>
                <tr>
                  <th [attr.colspan]="generalSetupForm.get('fields').value.length" class="text-left">
                    <b>{{ 'result' | translate }}</b>
                  </th>
                </tr>
                <tr>
                  <th *ngFor="let field of generalSetupForm.get('fields').value">{{ fieldDetails[field].label | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of resultSet">
                  <td *ngFor="let field of generalSetupForm.get('fields').value">
                    {{ row | dotNotation: field | normalizeValue: fieldDetails[field].type }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-lg-6">
            <button class="btn btn-warning btn-block btn-lg p-2" matStepperPrevious>{{ 'back' | translate }}</button>
          </div>
          <div class="col-lg-6">
            <button type="button" class="btn btn-primary btn-block btn-lg p-2" (click)="saveDashboard()">
              <strong>{{ 'advance_report_lang.save_dashboard' | translate }}</strong>
            </button>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-dialog-content>
</ng-container>

<ng-template #resultsetLoading>
  <div class="row mt-2">
    <span class="no-content text-center">
      <inline-view-loader [isLoading]="true"></inline-view-loader> {{ 'loading' | translate }}
    </span>
  </div>
</ng-template>

<ng-template #resultsetEmpty>
  <div class="row mt-2">
    <span class="no-content text-center">
      <fa-icon [icon]="['far', 'exclamation-circle']"></fa-icon>{{ 'advance_report_lang.result_empty' | translate }}
    </span>
  </div>
</ng-template>
