import { Component, OnInit, OnDestroy } from '@angular/core';
import { LayoutDashboardService } from '../../../layout-dashboard.service';
import { BrowserService } from '../../../../../../services/browser/browser.service';
import { ClientStoreService } from '../../../../../../services/client-store.service';
import { AsideMeta } from '../../objects/aside-meta';
import { AsideLink } from '../../objects/aside-link';
import { PopupConfig } from '../../../../../../objects/dialogs/popup-config';
import { LinkFilter } from '../../objects/link-filter';
import { CreateJobWizardComponent } from '../../../../../../features/wizard/components/create-job-wizard/create-job-wizard.component';
import { CreateCustomerWizardComponent } from '../../../../../../features/wizard/components/create-customer-wizard/create-customer-wizard.component';
import { ADVANCED_PLAN, ENTERPRISE_PLAN } from '../../../../../../objects/subscription-plans';
import { CreateQuoteWizardComponent } from '../../../../../../features/wizard/components/create-quote-wizard/create-quote-wizard.component';
import { FormPopup } from '../../../../../../objects/centralized-forms/form-popup';
import { EditformComponent } from '../../../../../components/editform/editform.component';
import { EditRecurringJobsComponent } from '../../../../../components/widget/recurring-jobs/edit-recurring-jobs/edit-recurring-jobs.component';
import { CreateChecklistComponent } from '../../../../../../admin/checklists/create-checklist/create-checklist.component';
import { EditComponent as EditJobTemplatesComponent } from '../../../../../../admin/job-templates/edit/edit.component';
import { FormComponent as StockFormsComponent } from '../../../../../../module/stock-level/widgets/stock-transfer/dialog/form/form.component';
import { EditInvoiceComponent } from '../../../../../../module/jobs/customer-invoices/edit-invoice/edit-invoice.component';
import { EditPurchaseOrderComponent } from '../../../../../../module/jobs/purchase-orders/edit-purchase-order/edit-purchase-order.component';
import { EditSupplierInvoiceComponent } from '../../../../../../module/jobs/supplier-invoices/edit-supplier-invoice/edit-supplier-invoice.component';
import { EditRecurringInvoiceFormComponent } from '../../../../../components/widget/recurring_invoices/form/edit-recurring_invoice-form.component';
import { NewImportQueueFormComponent } from '../../../../../../admin/import/forms/new-import-queue-form/new-import-queue-form.component';
import { SyncInvoiceToAccountingComponent } from '../../../../../../module/jobs/sync-invoice-to-accounting/sync-invoice-to-accounting.component';
import { Client } from '../../../../../../objects/client';
import { filled } from '../../../../../utils/common';
import { get } from 'lodash'
import { Subscription } from 'rxjs';

const COMMON_DIALOG_SETTING = {
  maxWidth: '100%',
  width: '100%',
  height: 'auto',
  disableClose: true
};

const COMMON_IMPORT_SETTING = {
  width: '80%',
  height: 'auto',
  disableClose: true,
  panelClass: ['w-100'],
}

@Component({
  selector: 'dashboard-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss']
})
export class AsideComponent implements OnInit, OnDestroy {

  /**
   * The current active client.
   *
   * @var {Client}
   */
  public objClient: Client;

  /**
   * Subscription that is called whenever the active client is updated.
   *
   * @type {Subscription}
   */
  private activeClientSub: Subscription;

  /**
   * Check if the browser is internet explorer.
   *
   * @var {boolean}
   */
  public bIsInternetExplorer6to11: boolean = this.browserService.isInternetExplorer6to11();

  /**
   * The links to redirect to from the navigation.
   *
   * @var {AsideMeta[]}
   */
  public arLinks: AsideMeta[] = [
    new AsideMeta(
      'contacts',
      ['fas', 'address-book'],
      [
        new AsideLink('leads', ['/leads']),
        new AsideLink('customers', ['/customers'], {filter: JSON.stringify(new LinkFilter('is_customer', true, 'boolean'))}),
        new AsideLink('sites', ['/sites']),
        new AsideLink('suppliers', ['/customers'], {filter: JSON.stringify(new LinkFilter('is_supplier', true, 'boolean'))}),
        new AsideLink('contacts', ['/contacts']),

      ],
      [
        new AsideLink('create_customer', new PopupConfig(
          CreateCustomerWizardComponent,
          { width: '60%', height: 'auto' },
          { width: '70%', height: 'auto' },
          { data: { opened_from_mega_menu: true } }
        ), null, ['fas', 'magic']),
        new AsideLink('add_lead', new PopupConfig(
          EditformComponent, null, null, new FormPopup('leads', {}, {}, '', 'add'), true
        ), null),
        new AsideLink('add_site', new PopupConfig(
          EditformComponent, null, null, new FormPopup('sites', {}, {}, '', 'add'), true
        ), null),
        new AsideLink('add_customer', new PopupConfig(
          EditformComponent, null, null, new FormPopup('customers', {}, {}, '', 'add'), true
        ), null),
        new AsideLink('add_contact', new PopupConfig(
          EditformComponent, null, null, new FormPopup('contacts', {}, {}, '', 'add'), true
        ), null),
        new AsideLink('import_customers', new PopupConfig(
          NewImportQueueFormComponent, null, null, {
            ...COMMON_IMPORT_SETTING,
            ...{data: {module: "customers"}}
          }, true
        ), null, ['fas', 'download']),
        new AsideLink('import_contacts', new PopupConfig(
          NewImportQueueFormComponent, null, null, {
            ...COMMON_IMPORT_SETTING,
            ...{data: {module: "contacts"}}
          }, true
        ), null, ['fas', 'download']),
        new AsideLink('import_sites', new PopupConfig(
          NewImportQueueFormComponent, null, null, {
            ...COMMON_IMPORT_SETTING,
            ...{data: {module: "sites"}}
          }, true
        ), null, ['fas', 'download']),
      ]
    ),
    new AsideMeta(
      'jobs',
      ['fas', 'wrench'],
      [
        new AsideLink('jobs', ['/jobs']),
        new AsideLink('recurring_jobs', ['/recurring_jobs']),
        new AsideLink('job_checklists', ['/admin/checklists'], {filter: JSON.stringify(new LinkFilter('type', 'job', 'string'))}, ['fas', 'plus-circle'], ADVANCED_PLAN),
        new AsideLink('job_scheduler', ['/calendar']),
        new AsideLink('job_templates', ['/admin/job_templates']),
        new AsideLink('project_templates', ['/admin/project_templates'])
      ],
      [
        new AsideLink('create_job', new PopupConfig(CreateJobWizardComponent, {
          width: '60%',
          height: 'auto'
        }, {
          width: '70%',
          height: 'auto'
        }, { panelClass: ['job-wizard-step'], data: { record_type: 'jobs', opened_from_mega_menu: true }}), null, ['fas', 'magic']),
        new AsideLink('add_job', new PopupConfig(
          EditformComponent, null, null, new FormPopup('jobs', {}, {}, '', 'add'), true
        )),
        new AsideLink('add_recurring_job', new PopupConfig(
          EditRecurringJobsComponent, null, null, {
            ...{COMMON_DIALOG_SETTING},
            maxWidth: '100%',
            width: '80%',
            height: 'auto',
            data: {
              strModule: 'recurring_jobs',
              record_id: null,
              module: null,
              recurring_job: {},
              view_type: 'add'
            },
          }, true
        )),
        new AsideLink('add_job_checklist', new PopupConfig(
          CreateChecklistComponent, null, null, {
            width: '600px',
            height: 'auto',
            disableClose: true,
            data: {checklist_type: 'job_checklist'}
          }
        ), null, ['fas', 'plus-circle'], ADVANCED_PLAN),
        new AsideLink('add_job_template', new PopupConfig(
          EditJobTemplatesComponent, null, null, {
            panelClass: 'height-auto-mat-dialog',
            maxWidth: '98%',
            maxHeight: '98%',
            width: '98%',
            height: 'auto',
            disableClose: true,
            data: {
              record_id: null,
              module: null,
              job_template: {},
              view_type: 'add',
              opened_from_mega_menu: true
            },
          }, true
        )),
        new AsideLink('add_project_template', ['/admin/project_templates/create']),
        new AsideLink('import_jobs', ['/admin/import'], {add_form: 'open', module: 'jobs'}, ['fas', 'download'])
      ]
    ),
    new AsideMeta(
      'sales',
      ['fas', 'dollar-sign'],
      [
        new AsideLink('opportunities', ['/opportunities']),
        new AsideLink('quote_checklists', ['/admin/checklists'], {filter: JSON.stringify(new LinkFilter('type', 'opportunity', 'string'))}, ['fas', 'plus-circle'], ADVANCED_PLAN),
        new AsideLink('quote_templates', ['/admin/quote_templates']),
      ],
      [
        new AsideLink('create_opportunities', new PopupConfig(CreateQuoteWizardComponent, {
          width: '60%',
          height: 'auto'
        }, {
          width: '70%',
          height: 'auto'
        }, {data: { opened_from_mega_menu: true }}), null, ['fas', 'magic']),
        new AsideLink('add_opportunities', new PopupConfig(
          EditformComponent, null, null, new FormPopup('opportunities', {}, {}, '', 'add'), true
        ), {from: 'create_quote'}),
        new AsideLink('add_quote_checklist', new PopupConfig(
          CreateChecklistComponent, null, null, {
            width: '600px',
            height: 'auto',
            disableClose: true,
            data: {checklist_type: 'quote_checklist'}
          }
        ), null, ['fas', 'plus-circle'], ADVANCED_PLAN),
      ]
    ),
    new AsideMeta(
      'scheduling',
      ['fas', 'calendar-alt'],
      [],
      [
        new AsideLink('generate_recurring_invoices', ['/recurring_invoices/generate'], null, ['fas', 'dollar-sign']),
        new AsideLink('generate_scheduled_jobs', ['/schedule_recurring_jobs'], null, ['fas', 'briefcase']),
      ]
    ),
    new AsideMeta(
      'assets',
      ['fas', 'briefcase'],
      [
        new AsideLink('assets', ['/assets'], null, null, ADVANCED_PLAN),
        new AsideLink('asset_types', ['/admin/asset_types'], null, null, ADVANCED_PLAN),
        // TODO Leaving for future devs to reecurring maintenance.
        //new AsideLink('recurring_maintenance', ['/jobs']),
        new AsideLink('asset_checklists', ['/admin/checklists'], {filter: JSON.stringify(new LinkFilter('type', 'asset', 'string'))}, null, ADVANCED_PLAN),
        // TODO: Leaving this for other devs to add the asset transfers.
        //new AsideLink('asset_transfers', ['/jobs']),
      ],
      [
        new AsideLink('add_asset', new PopupConfig(
          EditformComponent, null, null, new FormPopup('assets', {}, {}, '', 'add'), true
        ), null, ['fas', 'plus-circle'], ADVANCED_PLAN),
        new AsideLink('add_asset_type', new PopupConfig(
          EditformComponent, null, null, new FormPopup('asset_types', {}, {}, '', 'add'), true
        ), null, ['fas', 'plus-circle'], ADVANCED_PLAN),
        new AsideLink('add_asset_checklist', new PopupConfig(
          CreateChecklistComponent, null, null, {
            width: '600px',
            height: 'auto',
            disableClose: true,
            data: {checklist_type: 'assets_checklist'}
          }
        ), null, ['fas', 'plus-circle'], ADVANCED_PLAN),
        new AsideLink('import_assets', new PopupConfig(
          NewImportQueueFormComponent, null, null, {
            ...COMMON_IMPORT_SETTING,
            ...{data: {module: "assets"}}
          }, true
        ), null, ['fas', 'download']),
      ]
    ),
    new AsideMeta(
      'inventory',
      ['fas', 'store'],
      [
        new AsideLink('product_catalogue', ['/items']),
        new AsideLink('pricebooks',['/admin/pricebooks']),
        new AsideLink('warehouses', ['/warehouses'], null, null, ENTERPRISE_PLAN),
        new AsideLink('inventory_levels',['/stock_levels'], null, null, ENTERPRISE_PLAN),
      ],
      [
        new AsideLink('add_product', new PopupConfig(
          EditformComponent, null, null, new FormPopup('items', {}, {}, '', 'add'), true
        ), null),
        new AsideLink('add_pricebook', new PopupConfig(
          EditformComponent, null, null, new FormPopup('pricebooks', {}, {}, '', 'add'), true
        ), null),
        new AsideLink('add_warehouse', new PopupConfig(
          EditformComponent, null, null, new FormPopup('warehouses', {}, {}, '', 'add')
        ), null),
        new AsideLink('create_stocktake', ['/stock_levels/stocktake'], null, ['fas', 'people-carry'], ENTERPRISE_PLAN),
        new AsideLink('stocktake_history', ['/stock_levels/stocktake/history'], null, ['fas', 'history'], ENTERPRISE_PLAN),
        new AsideLink('stock_transfer', new PopupConfig(
          StockFormsComponent, null, null, {
            width: '800px',
            height: 'auto',
            data: {}
          }
        )),
        new AsideLink('reorder', ['/stock_levels/reorder'], null, ['fas', 'box-open'], ENTERPRISE_PLAN),
        new AsideLink('import_products', new PopupConfig(
          NewImportQueueFormComponent, null, null, {
            ...COMMON_IMPORT_SETTING,
            ...{data: {module: "items"}}
          }, true
        ), null, ['fas', 'download']),
        new AsideLink('import_pricebooks', new PopupConfig(
          NewImportQueueFormComponent, null, null, {
            ...COMMON_IMPORT_SETTING,
            ...{data: {module: "pricebooks"}}
          }, true
        ), null, ['fas', 'download']),
        new AsideLink('import_stock_levels', new PopupConfig(
          NewImportQueueFormComponent, null, null, {
            ...COMMON_IMPORT_SETTING,
            ...{data: {module: "stock_levels"}}
          }, true
        ), null, ['fas', 'download']),
        new AsideLink('import_supplier_pricing', new PopupConfig(
          NewImportQueueFormComponent, null, null, {
            ...COMMON_IMPORT_SETTING,
            ...{data: {module: "supplier_pricing"}}
          }, true
        ), null, ['fas', 'download']),
      ]
    ),
    new AsideMeta(
      'accounting',
      ['fas', 'wallet'],
      [
        new AsideLink('customer_invoices', ['/customer_invoices']),
        new AsideLink('recurring_customer_invoices', ['/recurring_invoices']),
        new AsideLink('purchase_orders', ['/purchase_orders']),
        new AsideLink('supplier_invoices',['/supplier_invoices']),
        new AsideLink('credit_notes',['/credit_notes']),
      ],
      [
        new AsideLink('add_invoice', new PopupConfig(
          EditInvoiceComponent, null, null, {
            ...{COMMON_DIALOG_SETTING},
            data: {
              strModule: 'customer_invoices',
              record_id : '',
              view_type : 'add'
            }
          }, true
        )),
        new AsideLink('add_recurring_invoice', new PopupConfig(
          EditRecurringInvoiceFormComponent, null, null, {
            maxWidth: '100%',
            width: '100%',
            height: 'auto',
            disableClose: true,
            data: {
              strModule: 'recurring_invoices',
              isNew: true,
              moduleID: null,
              moduleName: null,
              recordID: null
            }
          }, true
        )),
        new AsideLink('add_purchase_order', new PopupConfig(
          EditPurchaseOrderComponent, null, null,
          {
            maxWidth: '100%',
            width: '100%',
            height: 'auto',
            disableClose: true,
            data: {
              strModule: 'purchase_orders',
              record_id : '',
              view_type : 'add'
            }
          }, true
        )),
        new AsideLink('add_supplier_invoice', new PopupConfig(
          EditSupplierInvoiceComponent, null, null, {
            maxWidth: '100%',
            width: '100%',
            height: 'auto',
            disableClose: true,
            data: {
              strModule: 'supplier_invoices',
              record_id : '',
              view_type : 'add'
            }
          }, true
        )),
        new AsideLink('sync_customer_invoices', new PopupConfig(
          SyncInvoiceToAccountingComponent, null, null, {
            maxWidth: '70%',
            width: '70%',
            height: 'auto',
            disableClose: true,
            data: {
              strModule: 'customer_invoices',
            }
          }, true
        ), {}, ['fa', 'sync-alt']),
        new AsideLink('sync_supplier_invoices', new PopupConfig(
          SyncInvoiceToAccountingComponent, null, null, {
            maxWidth: '70%',
            width: '70%',
            height: 'auto',
            disableClose: true,
            data: {
              strModule: 'supplier_invoices',
            }
          }, true
        ), {}, ['fa', 'sync-alt']),
      ]
    ),
    new AsideMeta(
      'resources',
      ['fas', 'project-diagram'],
      [
        new AsideLink('users', ['/admin/users']),
        new AsideLink('subcontractors', ['/subcontractors']),
        new AsideLink('teams', ['/admin/teams']),
        new AsideLink('timesheets', ['/timesheets']),
        new AsideLink('email_templates', ['/email_templates'])
      ],
      [
        new AsideLink('technician_live_map', ['/shared_location'], null, ['fas', 'map-marker-alt']),
        new AsideLink('invite_users', ['/admin/users/invites']),
        new AsideLink('add_subcontractor', new PopupConfig(
          EditformComponent, null, null, new FormPopup('subcontractors', {}, {}, '', 'add'), false
        ), null),
      ]
    ),
    new AsideMeta(
      'reporting',
      ['fas', 'file-chart-line'],
      [
        new AsideLink('dashboards', ['/dashboard']),
        // new AsideLink('advanced_reports', ['/dashboard/report/advanced']),
        new AsideLink('advanced_reports', ['/advance_reports']),
        new AsideLink('data_warehouse', ['/admin/integrations/connect_datawarehouse'])
      ],
      [
        new AsideLink('site_service_summary_report', ['/site_service_summary_report'], null, ['fas', 'sitemap']),
      ]
    ),
  ];

  constructor(
    public layoutDashboardService: LayoutDashboardService,
    private browserService: BrowserService,
    private clientStoreService: ClientStoreService
  ) {
    // whenever the active client is set, update mega menu view to display/hide options/links based on client config
    this.activeClientSub = this.clientStoreService.whenActiveClientIsSet$.subscribe(activeClient => {
      this.objClient = activeClient;
      this._toggleSyncCIandSItoAccountingOptions();
    });
   }

  ngOnInit(): void {
    this.objClient = this.clientStoreService.getActiveClient();
    this._toggleSyncCIandSItoAccountingOptions();
  }

  ngOnDestroy(): void {
    if (this.activeClientSub) {
      this.activeClientSub.unsubscribe();
    }
  }

  /**
   * FC-4835: Only display 'Create Customer Invoices to Accounting' and 'Create Supplier Invoices to Accounting' if client is connected to accounting
   *
   * @returns {void}
   */
  private _toggleSyncCIandSItoAccountingOptions(): void {
    if (!this._isClientConnectedToAccounting()) {
      const numAccountingIndex: number = this.arLinks.findIndex((item: AsideMeta) => item.name === 'accounting');

      if (numAccountingIndex > -1) {
        this.arLinks[numAccountingIndex].panel2 = this.arLinks[numAccountingIndex].panel2.filter(
          (item: AsideLink) => item.label !== 'sync_customer_invoices' && item.label !== 'sync_supplier_invoices'
        );
      }
    }
  }

  /**
   * Checks the local storage service if accounting_system_id has value
   *
   * @returns {boolean}
   */
  private _isClientConnectedToAccounting(): boolean {
    return filled(get(this.objClient, 'accounting_system_id'));
  }
}
