import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule, MatFormFieldModule, MatIconModule } from '@angular/material';
import { ColorPickerModule } from 'ngx-color-picker';
import { FmDynamicChip } from './components/fm-dynamic-chip';
import { FontAwesomeModule } from '../../shared/modules/font-awesome.module';
import { TranslateModule } from '@ngx-translate/core';
import { ManageTagsComponent } from './dialogs/manage-tags/manage-tags.component';
import { TagRecordsComponent } from './dialogs/tag-records/tag-records.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  entryComponents: [
    ManageTagsComponent,
    TagRecordsComponent
  ],
  declarations: [
    FmDynamicChip,
    ManageTagsComponent,
    TagRecordsComponent
  ],
  imports: [
    CommonModule,
    MatChipsModule,
    MatIconModule,
    MatFormFieldModule,
    ColorPickerModule,
    FontAwesomeModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPopoverModule
  ]
})
export class TagsModule { }
