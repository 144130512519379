<header>
  <div class="d-flex header-container header-label">
    <span class="title">
      <fa-icon [icon]="['fas', 'receipt']" class="pr-2"></fa-icon>
      <span>{{ 'create_materials_from_wo' | translate }}</span>
    </span>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button class="btn btn-primary" [disabled]="!hasSelected" (click)="addMaterials()">
              <fa-icon [icon]="['fas', 'save']"></fa-icon>
              <span class="pl-1">{{ 'create_materials' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <fieldmagic-primary-button
            (click)="cancelDialog()"
            variant="error"
            icon="times"
            label="close"
            id="exit-dialog"
          ></fieldmagic-primary-button>
        </li>
      </ul>
    </div>
  </div>
  <hr />
</header>

<mat-dialog-content>
  <table class="table table-bordered">
    <thead>
      <tr class="text-center font-weight-bold">
        <td width="5%">
          <input type="checkbox" [(ngModel)]="selectAll" (change)="onChangeCheckbox($event, {}, true)">
        </td>
        <td width="20%">{{ 'product' | translate }}</td>
        <td width="30%">{{ 'description' | translate }}</td>
        <td width="10%">{{ 'quantity' | translate }}</td>
        <td width="10%">{{ 'quantity_received' | translate }}</td>
        <td width="10%">{{ 'unit_cost' | translate }}</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="lineItems.length < 1 && loading" class="text-center">
        <td colspan="6"><fa-icon [icon]="['fas','spinner']" [spin]="true" aria-hidden="true" class="pr-1"></fa-icon></td>
      </tr>
      <tr *ngIf="lineItems.length < 1 && !loading" class="text-center">
        <td colspan="6">{{ 'no_items' | translate }}</td>
      </tr>
      <ng-container *ngFor="let lineItem of lineItems; index as i">
        <tr [ngClass]="{'disabled-row': lineItem.material_id}" [matTooltip]="(lineItem.material_id ? 'material_already_created_for_related_job' : '') | translate">
          <td class="text-center">
            <input *ngIf="lineItem.disabled" type="checkbox" disabled matTooltip="{{ lineItem.error | translate }}">
            <input *ngIf="!lineItem.disabled" [checked]="lineItem.selected" type="checkbox" (change)="onChangeCheckbox($event, lineItem, false)">
          </td>
          <td class="text-center">
            <span>{{ getProductDisplayText(lineItem) | ellipsis | placeholdWithString }}</span>
          </td>
          <td class="text-center">
            <span>{{ lineItem.description | ellipsis | placeholdWithString }}</span>
          </td>
          <td>
            <fieldmagic-decimal-input
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="lineItem['receive_quantity']"
              customClass="form-control font-size-12"
              [disabled]="lineItem['disabled']"
              [nonNegative]="true"
              [max]="lineItem['max_quantity']"
              (change)="onChangeQuantity(lineItem['receive_quantity'], lineItem)"
            ></fieldmagic-decimal-input>
          </td>
          <td class="text-center">
            <span>{{ lineItem.materials_quantity | placeholdWithString }}</span>
          </td>
          <td class="text-center">
            <span>{{ lineItem.unit_cost | currency }}</span>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</mat-dialog-content>