
    <button
      class="btn {{ variant$ | async }}"
      [ngClass]="klass"
      [disabled]="isInProgress || disabled"
    >
      <fieldmagic-icon
        *ngIf="(icon | filled) || isInProgress; else content;"
        [icon]="icon || 'spinner'"
        [spin]="isInProgress"
      >
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </fieldmagic-icon>
      <ng-template #content>
        <ng-content *ngIf="label | blank; else asLabel"></ng-content>
      </ng-template>
      <ng-template #asLabel>
        <span>{{ label | translate }}</span>
      </ng-template>
    </button>
  