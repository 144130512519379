import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../modules/shared.module';
import { FontAwesomeModule } from '../../../modules/font-awesome.module';
import { SavefilterdialogComponent } from '../savefilterdialog/savefilterdialog.component';
import { ArchiveComponent } from '../../notifications/archive/archive.component';
import { ListViewProfitAnalysisComponent } from '../profit-analysis/profit-analysis.component';
import { ButtonDownloadComponent } from '../button-download/button-download.component';
import { SharedInputModule } from '../../../modules/shared-input.module';
import { TagListModule } from '../../../../features/tags/components/tag-list/tag-list.module';
import { TagOptionsComponent } from '../../../../features/tags/components/tag-options/tag-options.component';

@NgModule({
  declarations: [
    ListComponent,
    SavefilterdialogComponent,
    ListViewProfitAnalysisComponent,
    ButtonDownloadComponent,
    ArchiveComponent,
    TagOptionsComponent
  ],
  imports: [
    CommonModule,
    SharedModule.forRoot(),
    FontAwesomeModule,
    SharedInputModule,
    RouterModule,
    TagListModule
  ],
  exports: [
    TagListModule,
    TagOptionsComponent,
    ListComponent,
    SavefilterdialogComponent,
    ButtonDownloadComponent
  ],
  entryComponents: [
    SavefilterdialogComponent,
    ArchiveComponent
  ]
})
export class ListModule { }
