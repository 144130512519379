<div class="form-group">
  <label *ngIf="bDisplayLabel" [attr.for]="moduleName">{{ placeholder | translate }}</label>
  <ng-select
  [items]="options$ | async"
  [typeahead]="search$"
  [loading]="isLoading"
  [clearable]="true"
  (open)="onOpen()"
  [(ngModel)]="selected"
  bindLabel="text"
  (change)="onChange($event)"
  (focus)="onTouched()"
  appendTo="body"
  placeholder="{{ placeholder | translate }}">
  <ng-template ng-option-tmp let-item="item" class="value-text">
    {{ item.text | translate }}
  </ng-template>

  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    {{ item.text | translate }}
  </ng-template>
</ng-select>
</div>