import { Component, Input, OnInit } from '@angular/core';
import { Relate } from '../../../../objects/relate';
import { TagDisplay } from '../../objects/tag-display';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { RecordService } from '../../../../services/record.service';
import { FormGroup } from '@angular/forms';
import { LooseObject } from '../../../../objects/loose-object';

@Component({
  selector: 'tag-form',
  templateUrl: './tag-form.component.html',
  styleUrls: ['./tag-form.component.scss']
})
export class TagFormComponent implements OnInit {

  /**
   * The form group of the parent.
   *
   * @var {FormGroup}
   */
  @Input('form') form: FormGroup;

  /**
   * The item of the form field.
   *
   * @var {LooseObject}
   */
  @Input('item') item: LooseObject;

  /**
   * The relate object that holds the api request of the
   * ng selet.
   *
   * @var {Relate}
   */
  public objTagRelate = new Relate<TagDisplay>();

  constructor(public record: RecordService) {}

  ngOnInit() {
    if (this.form && this.item) {
      this.form.controls[this.item.key].setValue(this.item.default_value);
      this.objTagRelate.value = this.item.default_value;

      this.objTagRelate.buildRelates(
        switchMap(strTerm => {
          return this.record.getRecordRelate('tags', strTerm, []).pipe(
            tap(() => {
              this.objTagRelate.loader = false
            }),
            finalize(() => this.objTagRelate.loader = false),
            map(response => {
              return response.map(item => {return new TagDisplay(item as any)})
            })
          )}
        ),
        this.item.default_value,
        true
      )
    }
  }

  /**
   * Sets the value of the form once
   * an option or tag is selected in the
   * ng-select form.
   *
   * @returns {void}
   */
  setValue(): void {
    this.form.controls[this.item.key].setValue(this.objTagRelate.value);
  }

}
