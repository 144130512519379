import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import * as moment from 'moment';
import { isEmpty } from 'lodash';
import { MatDialog } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';

import { AssetJobsComponent } from './asset-jobs/asset-jobs.component';
import { AssetStatusComponent } from './asset-status/asset-status.component';
import { EditformComponent } from './../../editform/editform.component'

import { ViewService } from '../../../../services/view.service';
import { WidgetService } from '../../../../services/widget.service';
import { NotificationService } from '../../../../services/notification.service';
import { RecordService } from '../../../../services/record.service';
import { ListingService } from '../../../../services/listing.service';
import { AssetService } from '../../../../services/asset.service';
import { RelateIds } from '../../../../lists/relate-ids';
import { ChecklistResponseComponent } from '../checklists/checklist-response/checklist-response.component';
import { LinkChecklistComponent } from '../checklists/link-checklist/link-checklist.component';
import { Router } from '@angular/router';
import { StatusCode } from '../../../../lists/status-code';
import { first, tap } from 'rxjs/operators';
import { iif } from 'rxjs';
import { AssetJobPhotosComponent } from './asset-job-photos/asset-job-photos.component';
import { LooseObject } from '../../../../objects/loose-object';


@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss'],
  providers: [ AssetService, ListingService ]
})
export class AssetComponent implements OnInit {
  @Input() strRecordId: string;
  @Input() strModule: string;
  @Output() refreshChecklist = new EventEmitter<any>();


  public strCreateLabel: string;
  public arFaultyAssets: any = [];
  public strFilterLabel: string;
  public strReqestModule: string;
  public arAssetList = [];
  public objOption = {};
  public bLoader: boolean = false;
  public bProcessed: boolean = true;

  public strFilterId: string;
	public strFilterType: string;
	public strFilterStatus: string;
  public strCurrentPage: string;
  public strRelatedId: any = RelateIds;

  public objAssetForm: FormGroup;
  public objAssetField = {
    asset_type_id: {
      key: 'asset_type_id',
      filter_key: 'asset_type_id',
      label: '',
      controlType: 'relate',
      required: false,
      module: 'asset_types',
      validator: [],
      default_value: '',
      default_text: ''
    },
    serial_number: {
      key: 'serial_number',
      filter_key: 'assets.serial_number',
      label: '',
      controlType: 'text',
      required: false,
      validator: [],
      default_value: '',
      default_text: ''
    }
  };

  public objStatusClass = {
    pass: 'text-success',
    fail: 'text-danger',
    not_tested: 'text-warning',
    awaiting_completion: 'text-warning'
  }

  /**
   * Is the snackbar loader already displayed?
   *
   * @type {Boolean}
   */
  isLoaderDisplayed: boolean = false;

  /**
   * Create a form control to create form group.
   *
   * @param object WidgetService
   */
  constructor(
    public widgetService: WidgetService,
    private notifService: NotificationService,
    private recordService: RecordService,
    public listingService: ListingService,
    public router: Router,
    public assetService: AssetService,
    public viewService: ViewService,
    private dialog: MatDialog
    ) {
  }

  /**
   * Change the label depending on the current module.
   */
  ngOnInit() {
    switch(this.strModule) {
      case 'sites':
        this.strCreateLabel = 'select_asset';
        this.strReqestModule = 'assets';
        this.strFilterId = 'asset_type_id';
        this.strFilterLabel = 'asset_type';
        break;

      case 'jobs':
        this.strCreateLabel = 'select_asset';
        this.strReqestModule = 'assets_jobs';
        this.strFilterId = 'serial_number';
        this.strFilterLabel = 'serial_number';
        break;
    }

    let arFormControl: any = {};
    arFormControl[this.strFilterId] = new FormControl(
      this.objAssetField[this.strFilterId]['default_value'],
      this.objAssetField[this.strFilterId]['validator']
    );
    this.objAssetForm = new FormGroup(arFormControl)
    this.objAssetForm.statusChanges.subscribe(result => {

      this.strFilterType = this.objAssetForm.controls[this.strFilterId].value;
      this.getRecord();
    });

    this.getRecord();
  }

  /**
   * Get record.
   * Check if asset type, status or next page has value to include it on the request.
   * Store the fetched record in a variable.
   * Set the pagination config.
   *
   * @param object filter
   *
   * @return void
   */
  getRecord(strPage = null): void {
    let objFilter = { [this.strRelatedId[this.strModule]]: this.strRecordId };

    if (!isEmpty(this.strFilterType)) { objFilter[this.objAssetField[this.strFilterId]['filter_key']] = this.strFilterType; }
    if (!isEmpty(this.strFilterStatus)) { objFilter['status'] = this.strFilterStatus; }
    if (!isEmpty(this.strCurrentPage) ) { objFilter['updated_at'] = this.strCurrentPage; }

    let strNav = (strPage) ? strPage : 'default';
    let objPagination = this.listingService.beforeFetching(strNav);
    this.bLoader = false;
    this.arAssetList = [];

		iif(
      () => this.strReqestModule == 'assets',
      this.listingService.fetchDataAdvanceSearch(objPagination['objPage'], this.strReqestModule, objFilter, { 'id': 'created_at', 'sort': 'desc' }),
      this.listingService.fetchData(JSON.stringify(objPagination['objPage']), this.strReqestModule, JSON.stringify(
        {
          ...objFilter,
          ...{
            'order_by': {
              id: 'created_at',
              sort: 'desc'
            }
          }
        }
      )),
    ).subscribe(response => {
      this.arAssetList = response['data']
			this.objOption = response['option'];
      this.bLoader = true;

			this.listingService.afterFetching(response, strNav);
    });
  }

	/**
	 * Paginate the listing.
   * Store the current page so that we can include it on the listing request.
	 *
	 * @param string navigation
   *
   * @return void
	 */
	setPage(strNav): void {

    let objPageFilter  = this.widgetService.beforePageLoad(strNav, 'assets');
    if (!isEmpty(objPageFilter.filter_clause)) {

      this.strCurrentPage = objPageFilter.filter_clause.updated_at;
      this.getRecord();
    }
  }

	/**
	 * Format the date
   *
	 * @param date
   *
   * @return string formatted date
	 */
	formatDate(strDate): string {

    let utcTime = moment.utc(strDate).toDate();
		return moment(utcTime).local().format('lll');
  }

  // Select multiple assets
  selectAssets() {

    let data = {
      job_record : this.viewService.getViewRecord()
    }
    this.displayDialog(data, AssetJobsComponent)
  }

  // Displays corresponding dialog and refreshes record when a data was saved
  displayDialog(objData, component) {

    let dialogConfig : {[k: string]: any} = {
      data: objData,
      disableClose: true
    };

    // IF MOBILE
    if(window.innerWidth <= 800 && window.innerHeight <= 1024) {
      // Display the pop up in full screen (WHOLE PAGE)
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
      dialogConfig.maxHeight = '100vh';
      dialogConfig.maxWidth = '100vw';
    } else {
      // display as pop up if not mobile
      dialogConfig.width = '80%';
      dialogConfig.height = 'auto';
    }

    let tabDialogRef = this.dialog.open(component, dialogConfig);

    tabDialogRef.afterClosed().first().subscribe(result => {
      if (result) {
        // Refresh job record
        this.getRecord();
      }
    });

  }

  /**
   * Updating asset records against a job
   *
	 * @param string record id
   *
   * @return void
   */
  saveAsset(strRecordId = null): void {

      if (strRecordId) {

        let objData = {
          strMode : 'edit',
          strModule : 'assets',
          strId: strRecordId,
        };

        this.displayDialog(objData, EditformComponent);

      }

  }

  /**
   * Delete record
   *
	 * @param string record id
   *
   * @return void
   */
  deleteRecord(strRecordId): void{

    // Send the dialog of confirmation.
    this.notifService.sendConfirmation('unlink_asset_confirm', 'unlink_asset').subscribe(
      confirmation => {
        if (confirmation.answer) {
          this.recordService.deleteRecord(this.strReqestModule, strRecordId)
          .pipe(
            tap({
              error: (err) => {
                if(err.status === StatusCode.kUnprocessableEntity) {
                  this.notifService.notifyWarning('failed_asset_delete_relation');
                }
              }
            })
          ).subscribe(result => {
            if (result.status === StatusCode.kResponseSuccess) {
              this.getRecord();
              this.notifService.notifySuccess('deleted_successful');
            } else {
              this.notifService.notifyWarning('failed_asset_delete');
            }
          });
        }
      }
    );
  }

  // Gets prompts available and call checklist response dialog
  displayAssetPrompts(arChecklistResponse, asset, arFaults = null) {
    this.recordService.getRecordBasedOnParent(true).subscribe( response => {
      let objParentRecord = response['record_details'];
      arChecklistResponse['type'] = 'asset';
      let objChecklist = {
          width: '1200px',
          height: 'auto',
          data: {
              checklist_response : arChecklistResponse,
              response_type : 'per_asset_prompts',
              asset_job_id : asset['id'],
              asset_id : asset['asset_id'],
              id : asset['id'],
              fault : arFaults,
              job_number :  objParentRecord['job_number'],
              module: 'jobs',
          },
          disableClose: true
      };

      let tabDialogRef = this.dialog.open(ChecklistResponseComponent, objChecklist);

      // Get response after closing or submitting files dialog
      tabDialogRef.afterClosed().subscribe(
        data => {
          if (data !== undefined && data['status'] === 'save') {
            this.getRecord();
          }
        }
      );
    });
  }

  // Creates asset and set against job or site record
  createAndLinkAsset() {
    this.recordService.getRecordBasedOnParent(true).subscribe( response => {
      let objParentRecord = response['record_details'];
      let popupConfig : {[k: string]: any} = {
        data: {
          arData: {
            site_id: (this.strModule == 'jobs') ? objParentRecord['site_id'] : objParentRecord['id'],
            site_text: objParentRecord['site_text'],
            customer_id: objParentRecord['customer_id'],
            customer_text: objParentRecord['customer_text'],
            asset_type_id: null,
            is_active: true,
          },
          strModule: 'assets',
          strMode: 'add'
        },
        disableClose: true
      };

      // IF MOBILE
      if(window.innerWidth <= 800 && window.innerHeight <= 1024) {
        // Display the pop up in full screen (WHOLE PAGE)
        popupConfig.width = '100%';
        popupConfig.height = '100%';
        popupConfig.maxHeight = '100vh';
        popupConfig.maxWidth = '100vw';
      } else {
        // display as pop up
        popupConfig.width = '80%';
        popupConfig.height = 'auto';
      }

      // This line initializes and opens dialog.
      let editRecordDialog = this.dialog.open(EditformComponent, popupConfig);

      editRecordDialog.afterClosed().first().subscribe(editResponse => {

        // Check if the asset was successfully created
        if (editResponse != undefined && editResponse['status'] === 'save' && this.strModule === 'jobs') {

          let arData = {
            asset_id : editResponse['data']['id'],
            job_id : this.strRecordId,
            status : 'awaiting_completion'
          }

          // link the created asset against the current job record
          this.recordService.saveRecord('assets_jobs', arData).subscribe( result => {
            setTimeout(() => {
              this.getRecord();
            }, 3500);
          });
        } else {
          setTimeout(() => {
            this.getRecord();
          }, 3500);
        }
      });
    });
  }


  // Displays related checklist prompts
  showPerAssetPrompts(asset) {

      let arChecklistResponse = {
        'job_id': this.strRecordId,
        'asset_type_id' : asset['asset_type_id']
      };

      // If checklist response is already set (has response already)
      if (asset['response'] !== "[]" && asset['response'] !== "") {

          arChecklistResponse['response'] = asset['response'];

          let faults = {
            'details' : asset['fault_details'],
            'products' : asset['product_id']
          };

          this.displayAssetPrompts(arChecklistResponse, asset, faults);

      } else {

          // Get checklist response that is connected with this asset type (Response is not yet saved)
          this.listingService.fetchData('', 'checklist_responses', JSON.stringify({'job_id': this.strRecordId, 'asset_type_id' : asset['asset_type_id']}))
          .subscribe( response => {

                // Check if there's any checklist that matches the asset type
                if (response['data'].length > 0) {

                  arChecklistResponse['response'] = response['data'][0]['questions'];
                  this.displayAssetPrompts(arChecklistResponse, asset)

                } else {

                  // Get all available checklists that are connected with this asset type (not yet linked)
                  this.listingService.fetchData('', 'checklists', JSON.stringify({ 'asset_type_id' : asset['asset_type_id'] }))
                  .subscribe(response => {
                    // If there are responses
                    if (response['data'].length > 0) {
                      this.notifService.sendConfirmation('available_checklist_for_asset', 'confirm_link')
                        .subscribe(confirmation => {
                          if (confirmation.answer) {
                            let objChecklist = {
                              width: '600px',
                              height: 'auto',
                              // Data to be passed on
                              data: {
                                job_id: this.strRecordId,
                                checklist_record: response['data']
                              },
                              disableClose: true
                            };

                            let tabDialogRef = this.dialog.open(LinkChecklistComponent, objChecklist);

                            // Get response after closing or submitting files dialog
                            tabDialogRef.afterClosed().subscribe(
                              data => {
                                if (data['status'] !== 'cancel') {
                                  // Refresh job record
                                  this.refreshChecklist.emit({ widget: 'checklists' });
                                }
                              }
                            );
                          }
                        });
                    } else {
                      // If there's no asset type checklist related to the asset job's asset type
                      this.notifService.sendNotification('no_checklist_related_header', 'no_checklist_related', 'success');
                      this.updateStatus(asset);
                    }
                  });
                }
          });
      }
  }

  /* Checks if there are faulty assets in the current record, convert them to quote */
  convertToQuote() {
    this.bProcessed = false;
    this.recordService.getRecordBasedOnParent(true).subscribe( response => {
      this.assetService.getAllFaultyAssets(JSON.stringify(response['record_details'])).subscribe(response => {
        this.notifService.sendNotification(response['header'], response['message'], response['message_type']);
        this.bProcessed = true;
        if (response['data'] != undefined) {
          if (response['data']['opportunity_id'] != undefined) {
            this.router.navigate(['opportunities/' + response['data']['opportunity_id']], { queryParams: { open : response['data']['quote_id']} });
          }
        }
      });
    });
  }

  // Updates asset's status
  updateStatus(asset) {

    let tabDialogRef = this.dialog.open(AssetStatusComponent, {
      width: '600px',
      height: 'auto',
      data: { asset_data : asset },
      disableClose: true
    });

    tabDialogRef.afterClosed().first().subscribe(result => {
      if (result) {
        if (result['status'] === 'save' && result['new_status'] != undefined) {
          this.recordService.saveRecord('assets_jobs', { status : result['new_status'] }, asset['id']).subscribe( result => {
            if (result.status === StatusCode.kResponseSuccess) {
              this.getRecord();
              this.notifService.notifySuccess('asset_status_updated');
            } else {
              this.notifService.notifyWarning('failed_to_save');
            }
          });
        }
      }
    });

  }

  openAssetJobPhotosDialog(asset) {
    let assetJobPhotosDialog = this.dialog.open(AssetJobPhotosComponent, {
      width: '70vw',
      height: 'auto',
      data: asset,
      disableClose: true
    });

    assetJobPhotosDialog.afterClosed().first().subscribe(response => {
      if (response == 'saved') {
        this.onRefresh();
      }
    });
  }

  /**
   * Handles refresh list event
   *
   * @returns {void}
   */
  onRefresh(): void {
    this.getRecord();
  }

  ngOnChanges(): void {
    if (this.strReqestModule) {
      this.getRecord();
    }
  }
}
