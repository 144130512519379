<ng-container>
  <div class="row">
    <div class="col-lg-6 col-md-6 col-xs-12">
      <span style="font-size: 14px;">{{ 'select_pricebook' | translate }}</span>
      <ng-container *ngIf="pricebookField$ | async as metadata">
        <app-edit-field
          [item]="metadata"
          [form]="form"
        >
        </app-edit-field>
      </ng-container>
    </div>
    <div class="col-lg-6 col-md-6 col-xs-12 d-flex align-items-end justify-content-end">
      <fieldmagic-icon
        icon="sort-up"
        class="pointer text-primary font-size-12"
        (click)="sortMaterialsByItemCode()"
      >
        {{ 'Group materials by item code' | translate }}
      </fieldmagic-icon>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12 col-md-12 col-xs-12 mt-3">
      <browse-products
        [bLabor]="false"
        (objProductSelected)="onProductSelect($event)"
        [strPricebookId]="(pricebookChanges$ | async)"
      >
      </browse-products>
    </div>
  </div>

  <div class="row">
    <div class="col-12 mt-2">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th width="6%"></th>
            <th class="font-size-12" width="22%">{{ 'product' | translate }}</th>
            <th *ngIf="defaultJob | filled" class="font-size-12" width="14%">{{ 'task' | translate }}</th>
            <th class="font-size-12" width="10%">{{ 'quantity' | translate }}</th>
            <th class="font-size-12" width="10%">{{ 'unit_cost' | translate }}</th>
            <th class="font-size-12" width="10%">{{ 'unit_price' | translate }}</th>
            <th class="font-size-12" width="10%">{{ 'markup' | translate }}</th>
          </tr>
        </thead>

        <tbody *ngIf="fetching">
          <tr>
            <td
              [attr.colspan]="(defaultJob | filled) ?  7 : 6"
            >
              <fieldmagic-icon
                position="center"
                icon="spinner"
                [spin]="true"
              ></fieldmagic-icon>
            </td>
          </tr>
        </tbody>

        <tbody
          *ngIf="! fetching"
          cdkDropList
          (cdkDropListDropped)="onMaterialLineDrop($event)"
        >
          <ng-container *ngIf="materials.length; else emptyLineItem">
            <ng-container *ngFor="let material of materials; index as i">
              <tr
                (click)="onLineFocus(i)"
                cdkDrag
                [class.multiple-selected]="contextMenuService.isLineItemSelected(material, selectedLineItems)"
                [class.row-disabled]="material.is_disabled"
                [class.row-focused]="i === (focusedLineIndex$ | async)"
                [shAttachMenu]="contextMenuComponent?.menu"
                [shMenuData]="contextMenuService.formatMenuData(material, i)"
                (click)="onClickedLineItem($event, material)"
              >
                <td>
                  <div class="d-flex flex-row d-flex-gap">
                    <fieldmagic-icon
                      class="pointer"
                      icon="grip-vertical"
                      cdkDragHandle
                    ></fieldmagic-icon>
                    <fieldmagic-icon
                      class="pointer text-primary"
                      icon="info-circle"
                      matTooltip="{{ material.notes }}"
                    ></fieldmagic-icon>
                    <fieldmagic-icon
                      (click)="onMaterialRemove(i, material)"
                      variant="light"
                      icon="minus-circle"
                      class="text-danger pointer"
                      [class.disabled]="! material.is_deletable"
                    >
                    </fieldmagic-icon>
                    <fieldmagic-icon
                      *ngIf="! material.is_deletable"
                      icon="exclamation-circle"
                      class="text-danger"
                      matTooltip="{{ 'material_delete_not_allowed_due_to_linked_invoice' | translate: {invoice_number: material | data_get: 'customer_invoice_number'} }}"
                      (click)="onCustomerInvoiceEdit(material)"
                    ></fieldmagic-icon>
                  </div>
                </td>

                <td>
                  <ng-container *ngIf="material.type == 'product_catalog'">
                    <ng-select [items]="material.product.source | async"
                      [disabled]="! material.is_editable"
                      [class.is-invalid]="invalid && material.product.value == null"
                      bindLabel="text"
                      appendTo="body"
                      [typeahead]="material.product.typehead"
                      [loading]="material.product.loader"
                      [(ngModel)]="material.product.value"
                      [matTooltip]="material.product.value?.name"
                      (change)="onMaterialProductChange(i, material)"
                      (open)="material.product.loadDataOnClick('')">

                      <ng-template ng-option-tmp let-item="item">
                        <div>[{{ item.code }}] {{ item | itemName: {using: 'name'} }}</div>
                      </ng-template>
                    </ng-select>
                  </ng-container>
                  <ng-container *ngIf="material.type == 'once_off_purchase'">
                    <input
                      [class.is-invalid]="invalid && material.notes == null"
                      type="text"
                      class="form-control font-size-12 input-height-40"
                      [(ngModel)]="material.notes"
                      (change)="onMaterialDescriptionChange(i, material)"
                    />
                  </ng-container>
                </td>

                <td *ngIf="defaultJob | filled">
                  <ng-select [items]="material.task.source | async"
                    [disabled]="! material.is_editable"
                    bindLabel="activity_name"
                    appendTo="body"
                    [typeahead]="material.task.typehead"
                    [loading]="material.task.loader"
                    [(ngModel)]="material.task.value"
                    (open)="material.task.loadDataOnClick('')"
                    (change)="onMaterialTaskChange(i, material)"
                  >
                  </ng-select>
                </td>

                <td>
                  <fieldmagic-decimal-input
                    [disabled]="! material.is_editable"
                    [(ngModel)]="material.quantity"
                    (change)="onMaterialQuantityChange(i, material)"
                  ></fieldmagic-decimal-input>
                </td>

                <td>
                  <fc-currency-input
                    [disabled]="! material.is_editable"
                    [places]="4"
                    id="materials_unit_cost_{{ i }}"
                    [(ngModel)]="material.unit_cost"
                    [ngModelOptions]="{standalone: true}"
                    [nonNegative]="true"
                    [customClass]="'form-control font-size-12'"
                    (change)="onMaterialUnitCostChange(i, material)"
                  >
                  </fc-currency-input>
                </td>

                <td>
                  <fc-currency-input
                    [disabled]="! material.is_editable"
                    [places]="4"
                    id="materials_unit_price_{{ i }}"
                    [(ngModel)]="material.unit_price"
                    [ngModelOptions]="{standalone: true}"
                    [nonNegative]="true"
                    [customClass]="'form-control font-size-12'"
                    (change)="onMaterialUnitPriceChange(i, material)"
                  >
                  </fc-currency-input>
                </td>

                <td>
                  <fieldmagic-decimal-input
                    [disabled]="! material.is_editable"
                    [(ngModel)]="material.markup"
                    (change)="onMaterialMarkupChange(i, material)"
                  ></fieldmagic-decimal-input>
                </td>
              </tr>
            </ng-container>
          </ng-container>
          <ng-template #emptyLineItem>
            <tr [shAttachMenu]="contextMenuComponent?.menu" [shMenuData]="contextMenuService.formatMenuData([], -1)">
              <td [attr.colspan]="(defaultJob | filled) ? 7 : 6" class="text-center">
                {{ 'no_materials' | translate }}
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>

<fc-context-menu (isClicked)="doSomethingFromContextMenu($event)"></fc-context-menu>