import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type ClickEvent = Event;

@Component({
  selector: 'fieldmagic-primary-button',
  template: `
    <button
      class="btn {{ variant$ | async }}"
      [ngClass]="klass"
      [disabled]="isInProgress || disabled"
    >
      <fieldmagic-icon
        *ngIf="(icon | filled) || isInProgress; else content;"
        [icon]="icon || 'spinner'"
        [spin]="isInProgress"
      >
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </fieldmagic-icon>
      <ng-template #content>
        <ng-content *ngIf="label | blank; else asLabel"></ng-content>
      </ng-template>
      <ng-template #asLabel>
        <span>{{ label | translate }}</span>
      </ng-template>
    </button>
  `,
})
export class PrimaryButtonComponent {
  /// indicates that the button is work in progress
  @Input() isInProgress: boolean = false;

  /// indicates that the button should be disabled. Difference with the isInProgress, when disabled
  /// it doesn't show any progress indicator
  @Input() disabled: boolean = false;

  /// the icon to display at the left side of the text. This does not affect
  /// the loading indicator
  @Input() icon: string;

  /// a custom classes that will style the button
  @Input() klass: string | {
    [className: string]: boolean;
  };

  /// A simple string that labels the button. If you wish to have complex label
  /// you can use the template slot
  @Input() label: string;

  @Input() variant: 'error' | 'primary' | 'secondary' | 'success' | 'warning' = 'primary';

  readonly variant$ = new BehaviorSubject<string>('btn-primary');

  ngOnInit(): void {
    if (this.variant === 'error') {
      this.variant$.next('btn-danger');
    } else if (this.variant == 'secondary') {
      this.variant$.next('btn-secondary');
    } else if (this.variant == 'success') {
      this.variant$.next('btn-success');
    } else if (this.variant == 'warning') {
      this.variant$.next('btn-warning text-white');
    } else {
      this.variant$.next('btn-primary');
    }
  }
}
