import * as _ from 'lodash';
import * as _moment from 'moment';
import { Subject, of, concat, Observable, BehaviorSubject } from 'rxjs';
import { Component, Inject, OnInit, HostListener, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap, switchMap, map, finalize, filter } from 'rxjs/operators';
import { Select } from '../../../../objects/select';
import { Department } from '../../../../objects/department';
import { ViewService } from '../../../../services/view.service';
import { FormService } from '../../../../services/form.service';
import { RecordService } from '../../../../services/record.service';
import { ClientStoreService } from '../../../../services/client-store.service';
import { NotificationService } from '../../../../services/notification.service';
import { ArrService } from '../../../../services/helpers/arr.service';
import { StrService } from '../../../../services/helpers/str.service';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { InputSanitizerService } from '../../../../services/input-sanitizer.service';
import { PdfComponent } from './../../../../shared/components/view/pdf/pdf.component';
import { ClientsListStoreService } from '../../../../services/clients-list-store/clients-list-store.service';
import { SelectTemplate } from '../../../../objects/select-template';
import { get } from 'lodash';
import { toFormattedNumber } from '../../../../shared/utils/numbers';
import { LooseObject } from '../../../../objects/loose-object';
import { ReadableAddressPipe } from '../../../../pipes/readable-address.pipe';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HumanReadableDatePipe } from '../../../../pipes/human-readable-date/human-readable-date.pipe';
import { Router } from '@angular/router';
import { StockAllocationComponent } from '../../materials/stock-allocation/stock-allocation.component';
import { TranslateService } from '@ngx-translate/core';
import { blank, either, filled, isId } from '../../../../shared/utils/common';
import { spf } from '../../../../shared/utils/str';
import { sprintf } from 'sprintf-js';
import { MODULE_RELATE_TEXT_FIELDS } from '../../../form-templates/shared/constants';
import { SetUnsavedChangesData } from '../../../../objects/auto-save';
import { ContextMenuComponent } from '../../../../shared/components/context-menu/context-menu.component';
import { CustomerInvoiceLine } from '../../../../objects/line-items/customer-invoice-line';
import { ContextMenuService } from '../../../../services/context-menu.service';
import { UUID } from 'angular2-uuid';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'edit-invoice',
  templateUrl: './edit-invoice.component.html',
  styleUrls: ['./edit-invoice.component.scss']
})
export class EditInvoiceComponent implements OnInit {
  @ViewChild(ContextMenuComponent) contextMenuComponent: ContextMenuComponent;

  public strMyModule = 'customer_invoices';
  public jobInvoiceForm: any = [];
  public objRecordViewDetail: LooseObject = {};
  public strRecordId: any = (this.data.record_id != undefined && this.data.record_id != null && this.data.record_id != '') ? this.data.record_id : null;
  public strViewType: string = this.data.view_type;
  public strInvoicingType: string = '';
  public strJobInvoiceAmount: number = 0;
  public objJobData: {} = {};
  public customerInvoiceRecord: {} = {};
  public objCustomerData: {} = {};
  public objSiteData: {} = {};
  public objModuleConfig: {} = {};
  public strRecordModule: string = (this.data.module != undefined) ? this.data.module : '';
  public bSubmitted: boolean = false;
  public isTouched: boolean = false;
  public hasError: boolean = false;
  public bHasChanged: boolean = false;
  public bTimeEntryLoaded: boolean = false;
  public bMaterialLoaded: boolean = false;
  public bInvoiceLineLoaded: boolean = false;
  public arTaxCodeFilter: any = { is_sales: true };
  public arAccountCodeFilter: any = { is_sales: true };
  public arItemFilter: any = { labor: true, active: true };
  public arItemMaterialFilter: any = { labor: false, active: true };
  public arTimeEntryAttributes = [];
  public arMaterialEntryAttributes = [];
  public arInvoiceLineAttributes = [];
  public dateRangeField: any[] = new moment();
  public dateInvoiceField = new moment();
  public dateInvoiceDueField = new moment();
  public strContactId: any = '';
  public objSiteField: Select|string = null;
  public objCustomerField: Select|string = null;
  public arReadOnlyId: any = [];
  public arRelateEmptyId: any = [];
  public arRequiredField: any = [];

  public arRelateValues$: Observable<Select[]>;
  public arRelateValuesInput$ = new Subject<string>();

  public arRelateSiteValues$: Observable<Select[]>;
  public arRelateSiteValuesInput$ = new Subject<string>();

  public arRelateCustomerValues$: Observable<Select[]>;
  public arRelateCustomerValuesInput$ = new Subject<string>();

  public bRelateLoading: boolean = false;
  public bRelateLoadingSite: boolean = false;
  public bRelateLoadingCustomer: boolean = false;
  public arInitialContactRelate: any = [];

  public relateCustomerData: any = [];
  public relateSiteData: any = [];

  public relateTaxCodeData: any = [];
  public relateAccountCodeData: any = [];
  public relateItemData: any = [];
  public relateUserData: any = [];
  public relateDeparmentData: any = [];
  public relateMaterialData: any = [];
  public relateTimeEntryData: any = [];
  public relateTimeEntryItemData: any = [];
  public relateMaterialItemData: any = [];
  public arCustomField = ['line_items', 'contact_id', 'customer_id', 'site_id', 'date_invoice', 'date_due'];
  public arCustomColumn = ['show_total_price_only', 'show_labour_details', 'show_material_details'];
  public objDefaultTaxCode = {};
  public objDefaultAccountCode = {};
  public pdfPreview: boolean = false;
  public objRelateText: any = {};
  public objCacheData: {} = {};
  public objClientRecord: {} = {};
  public jobInvoiceFormDirty: boolean = false;
  public arDepartmentList: Array<{}> = [];
  public previewTemplate: Subject<SelectTemplate> = new Subject<SelectTemplate>();
  public isPreviewTemplate: boolean = false;
  public realTimePreviewTemplate: Subject<SelectTemplate> = new Subject<SelectTemplate>();

  /**
   * The last focused line for invoice.
   *
   * @var {number}
   */
  public numLastFocusedIndex: number = 0;

  public isAutoSave: boolean = false;
  public isFromUnsavedChanges: boolean = false;
  public autoSaveIntervalId;
  public relateChanges: LooseObject = {};
  public relateFields: Array<string> = [];
  public selectedLineItems: Array<LooseObject> = [];

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  /**
   * An event to trigger when something went wrong due to user failure
   *
   * @type {BehaviorSubject<boolean>}
   */
  public whenFailed$ = new BehaviorSubject<boolean>(false);

  /**
   * If department tracking is enabled.
   *
   * @var {boolean}
   */
  public bDepartmentTracking: boolean = false;

  //// total tax adjustment for this invoice
  totalTaxAdjustment: number = 0;

  whenInProgress$ = new BehaviorSubject<boolean>(false);

  get invoiceLineIncludedTax(): number {
    return this.computeInvoiceLineIncludedTax();
  }

  set invoiceLineIncludedTax(value: number) {
    const adjustment = toFormattedNumber(value, {
      currency: true,
    });

    const totalPrice = this.computeInvoiceLineExcludedTax();
    const totalTax = this.computeInvoiceLineTax();

    this.totalTaxAdjustment = toFormattedNumber(adjustment - (totalPrice + totalTax), {
      currency: true,
    });

    this.setAmountDue();
  }

  get includedTax(): number {
    return this.computeIncludedTax();
  }

  set includedTax(value: number) {
    const adjustment = toFormattedNumber(value, {
      currency: true,
    });

    const totalPrice = this.computeExcludedTax();
    const totalTax = this.computeTax();

    this.totalTaxAdjustment = toFormattedNumber(adjustment - (totalPrice + totalTax), {
      currency: true,
    });

    this.setAmountDue();
  }

  private _relatedSite: string = '';

  set relatedSite(value: string) {
    this._relatedSite = value;
  }

  get relatedSite(): string {
    return !_.isNil(this._relatedSite) ? this._relatedSite : '';
  }

  private _relatedJob: string = '';

  set relatedJob(value: string) {
    this._relatedJob = value;
  }

  get relatedJob(): string {
    return !_.isNil(this._relatedJob) ? this._relatedJob : '';
  }

  arGroupedTimeEntries: TimeEntryGroup[] = [];

  constructor(
    public dialogRef: MatDialogRef<EditInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formService: FormService,
    public recordService: RecordService,
    public viewService: ViewService,
    public arrService: ArrService,
    public strService: StrService,
    public notifService: NotificationService,
    public localStorageService: LocalStorageService,
    public clientStoreService: ClientStoreService,
    public clientsListStoreService: ClientsListStoreService,
    public contextMenuService: ContextMenuService,
    protected is: InputSanitizerService,
    private dialog: MatDialog,
    private readableAddressPipe: ReadableAddressPipe,
    private humanReadableDatePipe: HumanReadableDatePipe,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.objClientRecord = this.clientStoreService.getActiveClient();
    // Declare tax code and account code variable
    let defaultTaxCodeSale = [];
    let defaultAccountCodeSale = [];
    let dataChanges = this.formService.getUnsavedChangesData(_.get(this.data, 'customer_invoice_id', ''), 'customer_invoices', _.get(this.data, 'customer_invoice', ''), this.strRecordId);

    if (this.strViewType == 'edit') {
      this.recordService.getRecordAndParentRecord(
        'customer_invoices', this.data['customer_invoice_id'], !_.isEmpty(this.data.module) && !_.isEmpty(this.data.record_id)
      )
      .subscribe(([customerInvoice, moduleRecord]) => {
        this.objRecordViewDetail = moduleRecord;
        // Set Job Data if record view is job get the record in view service
        this.objJobData = (this.strRecordModule == 'jobs') ? moduleRecord['record_details'] : [];
        // Set Customer Data if record view is customer get the record in view service
        this.objCustomerData = (this.strRecordModule == 'customers') ? moduleRecord['record_details'] : [];
        // Set Site Data if record view is customer get the record in view service
        this.objSiteData = (this.strRecordModule == 'sites') ? moduleRecord['record_details'] : [];
        // Set Customer invoice record
        this.customerInvoiceRecord = customerInvoice;
        // Set Record details of invoice record
        this.data['invoice'] = customerInvoice['record_details'];
        // Set config of customer invoice
        this.objModuleConfig['record_details'] = this.customerInvoiceRecord['record_details'];
        this.objModuleConfig['record_view'] = this.customerInvoiceRecord['record_view'];
        this.objModuleConfig['used_fields'] = this.customerInvoiceRecord['used_fields'];
        // If customer invoice record has related data
        if (this.customerInvoiceRecord['related_data'] != undefined) {
          // Set all related data to objCacheData
          this.objCacheData = this.customerInvoiceRecord['related_data'];
          // Check if default tax code exist
          if (this.customerInvoiceRecord['related_data']['default_tax_code_sale'] != undefined) {
            // Set default tax code
            defaultTaxCodeSale = this.customerInvoiceRecord['related_data']['default_tax_code_sale'];
          }

          // Check if default account code exist
          if (this.customerInvoiceRecord['related_data']['default_account_code_sale'] != undefined) {
            // Set default account code
            defaultAccountCodeSale = this.customerInvoiceRecord['related_data']['default_account_code_sale'];
          }

          // If job data is empty. Check the related job data in customer record. What does this mean the dialog is not from widget.
          if (this.objJobData['id'] == undefined && this.customerInvoiceRecord['record_details']['job_id'] !== '' && this.customerInvoiceRecord['record_details']['job_id'] !== null) {
            let arRecordDetails = this.customerInvoiceRecord['record_details'];
            this.objJobData = {
              id: arRecordDetails['job_id'],
              text: arRecordDetails['job_text'],
              invoicing_type: arRecordDetails['invoicing_type'],
              po_number: arRecordDetails['job_po_number'],
              work_order_number: arRecordDetails['job_work_order_number'],
              job_summary: arRecordDetails['job_summary'],
              customer_id: arRecordDetails['job_customer_id'],
              customer_text: arRecordDetails['job_customer_text'],
              site_id: arRecordDetails['job_site_id'],
              site_text: arRecordDetails['job_site_text']
            }
          }
        }
        // If the record view is customers. Set customer record id
        if (this.strRecordModule == 'customers') {
          this.strRecordId = (this.objCustomerData['id'] != undefined) ? this.objCustomerData['id'] : '';
        // If the record view is jobs. Set job record id
        } else if (this.strRecordModule == 'jobs') {
          this.strRecordId = (this.objJobData['id'] != undefined) ? this.objJobData['id'] : '';
        }
        // Set Invoicing type from related job
        this.strInvoicingType = (this.objJobData['invoicing_type'] != undefined) ? this.objJobData['invoicing_type'] : '';
        // Set Invoice amount from related job
        this.strJobInvoiceAmount = (this.objJobData['amount_to_invoice'] != undefined) ? this.objJobData['amount_to_invoice'] : '';
        // Set default tax code
        this.objDefaultTaxCode = defaultTaxCodeSale;
        // Set default account code
        this.objDefaultAccountCode = defaultAccountCodeSale;

        /// set tax adjustment
        this.totalTaxAdjustment = toFormattedNumber(_.get(this.customerInvoiceRecord, 'record_details.tax_adjustment_amount'), {
          currency: true,
        });

        if (dataChanges.length > 0) {
          this.notifService.sendConfirmation('do_you_want_to_apply_unsaved_changes', 'unsaved_changes')
          .subscribe((confirmation) => {
            if (confirmation.answer) {
              dataChanges.forEach(data => {
                this.isFromUnsavedChanges = true;
                let path = data['path'];
                let value = data['value1'];
                let relatedTextField = MODULE_RELATE_TEXT_FIELDS[path];

                if (path.includes('line_items') && path.includes('customer_invoice')) {
                  path = path.replace('.customer_invoice', '');
                }
                // This is to set the previous related field change
                if (!_.isEmpty(relatedTextField)) {
                  let indexRelateTextField = dataChanges.findIndex(changes => changes['path'] == relatedTextField);
                  if (indexRelateTextField > -1) {
                    this.relateChanges[path] = dataChanges[indexRelateTextField]['value1'];
                  }
                }

                if (path == 'line_items' && value.length > 0) {
                  value = value.map(val => {
                    // Merging customer_invoice into the main object
                    const movedCustomerInvoice = {
                      ...val,
                      ...val.customer_invoice // Merging customer_invoice properties
                    };

                    return movedCustomerInvoice;
                  });

                  _.omit(value, 'customer_invoice');
                }

                if (path == 'tax_adjustment_amount') {
                  this.totalTaxAdjustment = toFormattedNumber(value, {
                    currency: true,
                  });
                }

                _.set(customerInvoice['record_details'], path, value);
              });

              this.initializeComponent();
            } else {
              this.initializeComponent();
              this.formService.removeUnsavedChangesDataToLocalStorage(_.get(this.data, 'customer_invoice_id', ''), 'customer_invoices', this.strRecordId);
            }
          });
        } else {
          this.initializeComponent()
        }
      });
    } else {
      this.data['invoice'] = {};
      this.recordService.getConfigAndParentRecord(
        'customer_invoices', !_.isEmpty(this.data.module) && !_.isEmpty(this.data.record_id)
      )
      .subscribe(([customerInvoice, moduleRecord]) => {
        this.objRecordViewDetail = moduleRecord;
        // Set Job Data if record view is job get the record in view service
        this.objJobData = (this.strRecordModule == 'jobs') ? moduleRecord['record_details'] : [];
        // Set Customer Data if record view is customer get the record in view service
        this.objCustomerData = (this.strRecordModule == 'customers') ? moduleRecord['record_details'] : [];
        // Set Site Data if record view is customer get the record in view service
        this.objSiteData = (this.strRecordModule == 'sites') ? moduleRecord['record_details'] : [];
        // Set config of customer invoice
        this.objModuleConfig = customerInvoice;
        // Check if has related data
        if (this.objModuleConfig['related_data'] != undefined) {
          // Set all related data to objCacheData
          this.objCacheData = this.objModuleConfig['related_data'];
          // Check if default tax code exist
          if (this.objModuleConfig['related_data']['default_tax_code_sale'] != undefined) {
            // Set Default tax code
            defaultTaxCodeSale = this.objModuleConfig['related_data']['default_tax_code_sale'];
          }

          // Check if default account code exist
          if (this.objModuleConfig['related_data']['default_account_code_sale'] != undefined) {
            // Set Default account code
            defaultAccountCodeSale = this.objModuleConfig['related_data']['default_account_code_sale'];
          }

          // If job data is empty. Check the related job data. What does this mean the dialog is not from widget.
          if (this.objJobData['id'] == undefined) {
            this.objJobData = (this.objModuleConfig['related_data']['jobs'] != undefined && this.objModuleConfig['related_data']['jobs'][0] != undefined) ? this.objModuleConfig['related_data']['jobs'][0] : [];
          }
        }
        // If the record view is customers. Set customer record id
        if (this.strRecordModule == 'customers') {
          this.strRecordId = (this.objCustomerData['id'] != undefined) ? this.objCustomerData['id'] : '';
        // If the record view is job. Set job record id
        } else if (this.strRecordModule == 'jobs') {
          this.strRecordId = (this.objJobData['id'] != undefined) ? this.objJobData['id'] : '';
        }
        // Set Invoicing Type from related job
        this.strInvoicingType = (this.objJobData['invoicing_type'] != undefined) ? this.objJobData['invoicing_type'] : '';
        // Set Invoice amount from related job
        this.strJobInvoiceAmount = (this.objJobData['amount_to_invoice'] != undefined) ? toFormattedNumber(this.objJobData['amount_to_invoice'], {
          currency: true,
        }) : 0;
        // Store department list
        this.arDepartmentList = (this.objModuleConfig['related_data']['departments']) ? this.objModuleConfig['related_data']['departments'] : [];
        // Set default tax code
        this.objDefaultTaxCode = defaultTaxCodeSale;
        // Set default account code
        this.objDefaultAccountCode = defaultAccountCodeSale;

        if (dataChanges.length > 0) {
          this.notifService.sendConfirmation('do_you_want_to_apply_unsaved_changes', 'unsaved_changes')
          .subscribe((confirmation) => {
            if (confirmation.answer) {
              this.isFromUnsavedChanges = true;

              dataChanges.forEach(data => {
                let path = data['path'];
                let value = data['value1'];
                let relatedTextField = MODULE_RELATE_TEXT_FIELDS[path];

                if (path.includes('line_items') && path.includes('customer_invoice')) {
                  path = path.replace('.customer_invoice', '');
                }
                // This is to set the previous related field change
                if (!_.isEmpty(relatedTextField)) {
                  let indexRelateTextField = dataChanges.findIndex(changes => changes['path'] == relatedTextField);
                  if (indexRelateTextField > -1) {
                    this.relateChanges[path] = dataChanges[indexRelateTextField]['value1'];
                  }
                }

                if (path == 'line_items' && value.length > 0) {
                  value = value.map(val => {
                    // Merging customer_invoice into the main object
                    const movedCustomerInvoice = {
                      ...val,
                      ...val.customer_invoice // Merging customer_invoice properties
                    };

                    return movedCustomerInvoice;
                  });

                  _.omit(value, 'customer_invoice');
                }

                if (path == 'tax_adjustment_amount') {
                  this.totalTaxAdjustment = toFormattedNumber(value, {
                    currency: true,
                  });
                }

                _.set(this.data['invoice'], path, value);
              });

              if (!_.isEmpty(get(this.data, ['invoice', 'line_items']))) {
                let compiledRelatedData: LooseObject = {};
                this.data['invoice']['line_items'].map(lineItem => {
                  lineItem['relate_data'] = {...lineItem, ...lineItem['relate_data']};

                  let relatedModule = lineItem['relate_module'];
                  if (compiledRelatedData[relatedModule] != undefined) {
                    compiledRelatedData[relatedModule].push(lineItem['relate_data']);
                  } else {
                    compiledRelatedData[relatedModule] = [];
                    compiledRelatedData[relatedModule].push(lineItem['relate_data']);
                  }

                  return lineItem;
                });

                this.data['related_data'] = compiledRelatedData;
                this.customerInvoiceRecord = this.data;
              }

              this.initializeComponent();
            } else {
              this.initializeComponent();
              this.formService.removeUnsavedChangesDataToLocalStorage(_.get(this.data, 'customer_invoice_id', ''), 'customer_invoices', this.strRecordId);
            }
          });
        } else {
          this.initializeComponent()
        }
      });
    }

    this.dialogRef.backdropClick().subscribe(_ => {
      this.cancelDialog();
    });
  }

  // This function is triggered inside the child component.
  doSomethingInParent(event) {
    // Check if field is job id
    if (event['field'] == 'job_id') {
      // Reset Attributes
      this.arTimeEntryAttributes = [];
      this.arMaterialEntryAttributes = [];
      this.arInvoiceLineAttributes = [];
      this.arInitialContactRelate = [];
      this.objJobData = [];
      this.strInvoicingType = '';
      this.strJobInvoiceAmount = 0;
      this.bTimeEntryLoaded = false;
      this.bMaterialLoaded = false;
      this.bInvoiceLineLoaded = false;

      // Set job id
      let strJobId = (!_.isEmpty(event['value']) && this.strService.fallsBackTo(event['value']['id']) !== null) ? event['value']['id'] : null;
      // Check if val has value and strRecordModule is job then assigned it to strRecordId(job_id)
      if (this.strRecordModule == 'jobs') {
        this.strRecordId = strJobId;
      }
      // If Job id is empty set default line items (Flexible invoicing)
      if (strJobId === null) {
        this.setLineItems();
      } else {
        // If has job id get the data
        this.recordService.getRecord('jobs', strJobId).subscribe( result => {
          // Set data
          this.objJobData = (result['record_details']) ? result['record_details'] : [];
          this.objRecordViewDetail = result;
          this.strInvoicingType = this.objJobData['invoicing_type'];
          this.strJobInvoiceAmount = this.objJobData['amount_to_invoice'];
          this.fieldPatchValue('po_number', (this.objJobData['po_number'] != undefined) ? this.objJobData['po_number'] : '');
          this.fieldPatchValue('work_order_number', (this.objJobData['work_order_number'] != undefined) ? this.objJobData['work_order_number'] : '');
          this.fieldPatchValue('invoice_summary', (this.objJobData['job_summary'] != undefined) ? this.objJobData['job_summary'] : '');
          // If record module is job. Set the customer by customer_id of job
          if (this.strRecordModule == 'jobs') {
            this.objCustomerField = !_.isEmpty(this.objJobData['customer_id']) ? new Select(this.objJobData['customer_id'], this.objJobData['customer_text']) : null;
          }
          this.objSiteField = !_.isEmpty(this.objJobData['site_id']) ? new Select(this.objJobData['site_id'], this.objJobData['site_text']) : null;
          this.setShowMaterialAndLabourDetails();
          this.setLineItems();
        });
      }
      this.relatedJob = event.value.text;
      this.setReferenceValue();
    }

    let numFormIndex = this.getFormFieldIndex(event['field']);
    let numFieldIndex = this.getFieldIndex(event['field'], this.jobInvoiceForm[numFormIndex]);

    if (numFormIndex > -1 && numFieldIndex > -1 && this.jobInvoiceForm[numFormIndex]['fields'][numFieldIndex]['controlType'] == 'relate') {
      this.relateChanges[event['field']] = get(event, 'value.text');
    }
  }

  initializeComponent() {
    if (this.strViewType == 'edit' || this.isFromUnsavedChanges) {
      // Set Default value and text
      let strDateInvoice = moment.utc(this.data.invoice.date_invoice).format('YYYY-MM-DD');
      let strDateDue = moment.utc(this.data.invoice.date_due).format('YYYY-MM-DD');
      this.dateInvoiceField = moment.utc(strDateInvoice).toDate();
      this.dateInvoiceDueField = moment.utc(strDateDue).toDate();

      this.initField();
      this.jobInvoiceForm.map( form => {
        let objField = Object.keys(form['groups']['controls']);
        objField.forEach( strField => {
          let indexField = this.getFieldIndex(strField, form);
          if (indexField > -1) {
            if (form['fields'][indexField]['controlType'] == 'relate') {
              let textField = strField.replace('_id', '_text');
              let strText = this.data.invoice[textField];
              form['fields'][indexField]['default_value'] = this.data.invoice[strField];
              form['fields'][indexField]['default_text'] = strText;
              this.relateChanges[strField] = strText;
            }
          }
          // If field exist. Set field value
          if (this.data.invoice[strField] != undefined) {
            this.fieldPatchValue(strField, this.data.invoice[strField]);
          }

        });

        form['fields'].forEach(fieldMetadata => {
          if (fieldMetadata['controlType'] == 'relate') {
            this.relateFields.push(fieldMetadata['key']);
          }
        });
      });
      // Get form index of accounting sync error and accounting sync detail
      let indexOfAccountingSyncError = this.getFormFieldIndex('accounting_sync_error');
      let indexOfAccountingSyncDetail = this.getFormFieldIndex('accounting_sync_detail');
      let indexindexOfAccountingSyncDetailField = this.getFieldIndex('accounting_sync_detail', this.jobInvoiceForm[indexOfAccountingSyncDetail]);
      // If field are exist
      if (indexOfAccountingSyncError > -1 && indexindexOfAccountingSyncDetailField > -1) {
        // Get the value of sync error
        let bAccountingSyncError = this.data.invoice['accounting_sync_error'];
        // If true. Show accounting sync detail field
        if (bAccountingSyncError) {
          this.jobInvoiceForm[indexOfAccountingSyncDetail]['fields'][indexindexOfAccountingSyncDetailField]['is_hidden'] = false;
        // If not. Hide accounting sync detail field
        } else {
          this.jobInvoiceForm[indexOfAccountingSyncDetail]['fields'][indexindexOfAccountingSyncDetailField]['is_hidden'] = true;
        }
      }
      // Set value for customer field
      this.objCustomerField = (this.data.invoice.customer_id != '' && this.data.invoice.customer_id != null) ? new Select(this.data.invoice.customer_id, this.data.invoice.customer_text) : null;
      // Set value for site field
      this.objSiteField = (this.data.invoice.site_id != '' && this.data.invoice.site_id != null) ? new Select(this.data.invoice.site_id, this.data.invoice.site_text) : null;
      // Set value for contact field
      this.strContactId = (this.data.invoice.contact_id != '' && this.data.invoice.contact_id != null) ? new Select(this.data.invoice.contact_id, this.data.invoice.contact_text) : '';
      this.setLineItems();
      this.triggerAutoSave();
    } else {

      this.dateInvoiceFieldChange('');
      this.initField();
      this.jobInvoiceForm.map(form => {
        form['fields'].forEach(fieldMetadata => {
          if (fieldMetadata['controlType'] == 'relate') {
            this.relateFields.push(fieldMetadata['key']);
            this.relateChanges[fieldMetadata['key']] = fieldMetadata['default_text'];
          }
        });
      });
      // Get form index of accounting sync error and accounting sync detail
      let indexOfAccountingSyncError = this.getFormFieldIndex('accounting_sync_error');
      let indexOfAccountingSyncDetail = this.getFormFieldIndex('accounting_sync_detail');
      let indexindexOfAccountingSyncDetailField = this.getFieldIndex('accounting_sync_detail', this.jobInvoiceForm[indexOfAccountingSyncDetail]);
      // If mode is create. Hide accounting sync detail
      if (indexOfAccountingSyncError > -1 && indexindexOfAccountingSyncDetailField > -1) {
        this.jobInvoiceForm[indexOfAccountingSyncDetail]['fields'][indexindexOfAccountingSyncDetailField]['is_hidden'] = true;
      }
      // Get index of this field
      let indexJobForm = this.getFormFieldIndex('job_id');
      let indexDeparmentForm = this.getFormFieldIndex('department_id');
      let indexCustomerForm = this.getFormFieldIndex('customer_id');
      let indexSiteForm = this.getFormFieldIndex('site_id');
      let indexJobField = this.getFieldIndex('job_id', this.jobInvoiceForm[indexJobForm]);
      let indexDepartmentField = this.getFieldIndex('department_id', this.jobInvoiceForm[indexDeparmentForm]);
      let indexCustomerField = this.getFieldIndex('customer_id', this.jobInvoiceForm[indexCustomerForm]);
      let indexSiteField = this.getFieldIndex('site_id', this.jobInvoiceForm[indexSiteForm]);

      if (this.data['customer_id'] && this.data['customer_text']) {
        this.objCustomerData['id'] = this.data['customer_id'];
        this.objCustomerData['customer_text'] = this.data['customer_text'];
      }

      // If has customer data
      if (this.objCustomerData['id'] != undefined) {
        if (indexCustomerForm > -1 && indexCustomerField > -1) {
          // Set default value for customer field and make it as readonly (due to the record view is customer).
          this.jobInvoiceForm[indexCustomerForm]['fields'][indexCustomerField]['default_value'] = this.objCustomerData['id'];
          this.jobInvoiceForm[indexCustomerForm]['fields'][indexCustomerField]['default_text'] = this.objCustomerData['customer_text'];
          this.jobInvoiceForm[indexCustomerForm]['fields'][indexCustomerField]['readonly'] = true;
          this.relateChanges['customer_id'] = this.objCustomerData['customer_text'];
          this.objCustomerField = (this.objCustomerData['id'] != '' && this.objCustomerData['id'] != null) ? new Select(this.objCustomerData['id'], this.objCustomerData['customer_text']) : '';
          this.fieldPatchValue('customer_id', (this.objCustomerData['id'] != undefined) ? this.objCustomerData['id'] : '');
        }
      }
      // If has site data
      if (this.objSiteData['id'] != undefined) {
        if (indexSiteForm > -1 && indexSiteField > -1) {
          this.relatedSite = this.objSiteData['text'];
          this.relatedJob = this.objJobData['job_number'];
          // Set default value for customer field and make it as readonly (due to the record view is customer).
          this.jobInvoiceForm[indexSiteForm]['fields'][indexSiteField]['default_value'] = this.objSiteData['id'];
          this.jobInvoiceForm[indexSiteForm]['fields'][indexSiteField]['default_text'] = this.objSiteData['site_text'];
          this.jobInvoiceForm[indexSiteForm]['fields'][indexSiteField]['readonly'] = true;
          this.relateChanges['site_id'] = this.objSiteData['site_text'];
          this.objSiteField = (this.objSiteData['id'] != '' && this.objSiteData['id'] != null) ? new Select(this.objSiteData['id'], this.objSiteData['site_text']) : null;
          this.fieldPatchValue('site_id', (this.objSiteData['id'] != undefined) ? this.objSiteData['id'] : '');
          this.fieldPatchValue('reference', this._computeReferenceValue());
        }
      }
      // If has job data
      if (this.objJobData['id'] != undefined) {
        this.relatedJob = this.objJobData['job_number'];
        this.relatedSite = this.objJobData['site_text'];
        if (indexJobForm > -1 && indexJobField > -1) {
           // Set default value for job field and make it as readonly.
          this.jobInvoiceForm[indexJobForm]['fields'][indexJobField]['default_value'] = this.objJobData['id'];
          this.jobInvoiceForm[indexJobForm]['fields'][indexJobField]['default_text'] = this.objJobData['text'];
          this.jobInvoiceForm[indexJobForm]['fields'][indexJobField]['readonly'] = true;
        }
        if (indexDeparmentForm > -1 && indexDepartmentField > -1) {
           // Set default value for department field.
          this.jobInvoiceForm[indexDeparmentForm]['fields'][indexDepartmentField]['default_value'] = this.objJobData['department_id'];
          this.jobInvoiceForm[indexDeparmentForm]['fields'][indexDepartmentField]['default_text'] = this.objJobData['department_text'];
        }
        this.relateChanges['site_id'] = get(this.objJobData, ['site_text']);
        this.relateChanges['customer_id'] = get(this.objJobData, ['customer_text']);
        this.relateChanges['job_id'] = get(this.objJobData, ['text']);
        // If job data has customer related. set that as default customer
        this.objCustomerField = (this.objJobData['customer_id'] != '' && this.objJobData['customer_id'] != null) ? new Select(this.objJobData['customer_id'], this.objJobData['customer_text']) : null;
        // If job data has site related. set that as default site
        this.objSiteField = (this.objJobData['site_id'] != '' && this.objJobData['site_id'] != null) ? new Select(this.objJobData['site_id'], this.objJobData['site_text']) : null;
        this.fieldPatchValue('job_id', (this.objJobData['id'] != undefined) ? this.objJobData['id'] : '');
        this.fieldPatchValue('customer_id', (this.objJobData['customer_id'] != undefined) ? this.objJobData['customer_id'] : '');
        this.fieldPatchValue('site_id', (this.objJobData['site_id'] != undefined) ? this.objJobData['site_id'] : '');
        this.fieldPatchValue('department_id', (this.objJobData['department_id'] != undefined) ? this.objJobData['department_id'] : '');
        this.fieldPatchValue('po_number', (this.objJobData['po_number'] != undefined) ? this.objJobData['po_number'] : '');
        this.fieldPatchValue('work_order_number', (this.objJobData['work_order_number'] != undefined) ? this.objJobData['work_order_number'] : '');
        this.fieldPatchValue('invoice_summary', (this.objJobData['job_summary'] != undefined) ? this.objJobData['job_summary'] : '');
        this.fieldPatchValue('reference', this._computeReferenceValue());
        this.setShowMaterialAndLabourDetails();
      }

      this.setLineItems();

      const numSyncToAccountingIndex: number = this.getFormFieldIndex('sync_to_accounting');

      if (numSyncToAccountingIndex > -1) {
        this.jobInvoiceForm[numSyncToAccountingIndex]['groups'].patchValue({
          // FC-4359: when creating customer invoices, the default value of sync_to_accounting flag will follow the value of 'sync_customer_invoice_on_creation'.
          sync_to_accounting: get(this.objClientRecord, 'sync_customer_invoice_on_creation', true)
        });
      }
    }

    this.triggerAutoSave();

    setTimeout( () => {
      this.onChanges();
    }, 2000);
  }

  /**
   * On changes of form
   */
  onChanges(): void {
    // Get index of this field
    let indexOfAmountPaid = this.getFormFieldIndex('amount_paid');
    let indexOfAmountDue = this.getFormFieldIndex('amount_due');
    let indexOfSyncToAccounting = this.getFormFieldIndex('sync_to_accounting');
    let indexOfAccountingSyncError = this.getFormFieldIndex('accounting_sync_error');
    let indexOfAccountingSyncDetail = this.getFormFieldIndex('accounting_sync_detail');
    let indexindexOfAccountingSyncDetailField = this.getFieldIndex('accounting_sync_detail', this.jobInvoiceForm[indexOfAccountingSyncDetail]);
    let indexOfGroupBy = this.getFormFieldIndex('enable_group_by');

    // If accounting sync error field exist
    if (indexOfAccountingSyncError > -1 && indexindexOfAccountingSyncDetailField > -1) {
      this.jobInvoiceForm[indexOfAccountingSyncError]['groups'].get('accounting_sync_error').valueChanges.subscribe(val => {
        // If accounting sync error is true. Show sync accounting details field else hide it
        if (val) {
          this.jobInvoiceForm[indexOfAccountingSyncDetail]['fields'][indexindexOfAccountingSyncDetailField]['is_hidden'] = false;
        } else {
          this.jobInvoiceForm[indexOfAccountingSyncDetail]['fields'][indexindexOfAccountingSyncDetailField]['is_hidden'] = true;
        }
      });
    }

    // If sync to accounting field exist
    if (indexOfSyncToAccounting > -1) {
      this.jobInvoiceForm[indexOfSyncToAccounting]['groups'].get('sync_to_accounting').valueChanges.subscribe(val => {
        // Clear accounting sync detail when sync to accounting is change
        this.jobInvoiceForm[indexOfSyncToAccounting]['groups'].patchValue({
          accounting_sync_detail: ''
        });
      });
    }

    // If amount paid field exist
    if (indexOfAmountPaid > -1) {
      this.jobInvoiceForm[indexOfAmountPaid]['groups'].get('amount_paid').valueChanges.subscribe(val => {
        let totalIncludedTax: number = (this.strInvoicingType == 'time_and_materials') ? this.computeIncludedTax() : this.computeInvoiceLineIncludedTax()
        // Clear accounting sync detail when sync to accounting is change
        let strAmountDue = totalIncludedTax - parseFloat(val);
        this.jobInvoiceForm[indexOfAmountDue]['groups'].patchValue({
          amount_due: !isNaN(strAmountDue) ? toFormattedNumber(strAmountDue, {
            currency: true,
          }) : 0,
        });
      });
    }

    if (indexOfGroupBy > -1) {
      this.jobInvoiceForm[indexOfGroupBy]['groups'].get('enable_group_by').valueChanges.subscribe(toggle => {
        if (toggle === true) {
          this.groupBy();
        }
      });
    }
  }
  /**
   * Initialize Main Field
   */
  initField() {
    // If invoicing type is not 'time_and_materials', disable the 'show_labour_details' and 'show_material_details'
    if (this.strInvoicingType != 'time_and_materials') {
      if (this.objModuleConfig['used_fields']['show_labour_details']) {
        this.objModuleConfig['used_fields']['show_labour_details'].readonly = true;
      }
      if (this.objModuleConfig['used_fields']['show_material_details']) {
        this.objModuleConfig['used_fields']['show_material_details'].readonly = true;
      }
      if (this.objModuleConfig['used_fields']['enable_group_by']) {
        this.objModuleConfig['used_fields']['enable_group_by'].readonly = true;
      }
    }

    // Add filter to customer relate
    if (this.objModuleConfig['used_fields']['customer_id'] !== undefined) {
      this.objModuleConfig['used_fields']['customer_id']['filter'] = {'is_customer': true};
    }

    // To set data of custom attributes as default value
    if (this.objModuleConfig['record_details']['custom_attributes'] != undefined && this.objModuleConfig['record_details']['custom_attributes'] != null && this.objModuleConfig['record_details']['custom_attributes'] != '') {
      let customAttributeKeys = Object.keys(this.objModuleConfig['record_details']['custom_attributes']);
      customAttributeKeys.forEach( strKey => {
        // Append each custom attributes to record details
        this.objModuleConfig['record_details'][strKey] = this.objModuleConfig['record_details']['custom_attributes'][strKey];
      });
    }
    let arFormData = this.formService.formData(this.objModuleConfig['record_view'], this.objModuleConfig['used_fields'], this.objModuleConfig['record_details']);
    this.jobInvoiceForm  = arFormData.map(
      formItems => {
        formItems['id'] = formItems['label'].toLowerCase().replace(/ /g,'_');
        formItems['groups'] = this.formService.toFormGroup(formItems['fields'])
        return formItems;
      }
    );
  }
  /**
   * Clear text field
   * @param attr - the object to be clear
   */
  clearField(attr) {
    // Find the index of the line item that was selected.
    let numLineItem = this.arTimeEntryAttributes.findIndex(line => (line.id_type == attr.id_type));
    // If the item is found.
    if (numLineItem > -1) {
      // Set the values of the line item.
      this.arTimeEntryAttributes[numLineItem]['work_date'] = [];
    }
  }

  /**
   * Removes the line item.
   * @param attr - the object to be removed.
   */
  removeAttribute(attr, strModule, bGrouped: boolean = false) {
    if (strModule == 'time_entry') {
      this.arTimeEntryAttributes.splice(this.arTimeEntryAttributes.indexOf(attr), 1);
      if (bGrouped) {
        this.groupBy();
      }
    } else if(strModule == 'material') {
      this.arMaterialEntryAttributes.splice(this.arMaterialEntryAttributes.indexOf(attr), 1);
    } else if (strModule == 'invoice_line') {
      this.arInvoiceLineAttributes.splice(this.arInvoiceLineAttributes.indexOf(attr), 1);
    }
    this.setAmountDue();
    this.markAsDirty();
  }

  /**
   * Adds a new input field.
   */
  addTimeEntryAttribute(objData: any = [], objCustomerInvoice: any = [], bRecompute = true, bFromUi: boolean = false, lineItemIndex: number = -1) {
    // Generate a unique name for the lineitem.
    let newId = 'nameField' + this.arTimeEntryAttributes.length + Math.floor((Math.random() * 10) + 1);
    let selectLineId = UUID.UUID();

    // If the generated name is indeed unique (no existing item of a similar name found.).
    if (this.arTimeEntryAttributes.findIndex(attr => (attr['id_type'] == newId)) < 0) {
      let arDefaultItemAccounting = this.arrService.keyFallsBackTo(this.customerInvoiceRecord['related_data'], 'default_item_accounting', []);
      let strDefaultItemAccountingId: string = (arDefaultItemAccounting) ? arDefaultItemAccounting['accounting_id'] : null;
      // If has existing data
      if (Object.keys(objData).length > 0) {
        let objDepartment = {};
        let strDepartmentId = '';
        let strDepartmentName = '';

        let strTaxCodeId = '';
        let strTaxCode = '';
        let strTaxCodeName = '';
        let strTaxRate: number = 0;
        let objTaxCode = {};

        let strAccountCodeId = '';
        let strAccountCode = '';
        let strAccountCodeName = '';
        let objAccountCode = {};
        let strUnitPrice: number = 0;
        // If view type is add - get the department record from time entry else get to the line items
        if (this.strViewType == 'add') {
          strUnitPrice = toFormattedNumber(either(objData, ['pricebook_unit_price', 'unit_price'], {
            default_value: 0,
          }), {
            currency: true,
            maxDecimalPlaces: 4,
          });
          objDepartment = (objData['department_id'] != undefined && objData['department_id'] != '' && objData['department_id'] != null) ? new Select(objData['department_id'], objData['department_name']) : {};
          strDepartmentId = (objData['department_id'] != undefined) ? objData['department_id'] : null;
          strDepartmentName = (objData['department_name'] != undefined) ? objData['department_name'] : null;

          if (objData['tax_code_id'] != undefined && objData['tax_code_id'] != null && objData['tax_code_id'] != '') {
            strTaxCodeId = objData['tax_code_id'];
            strTaxCode = objData['tax_code'];
            strTaxCodeName = objData['tax_code_name'];
            strTaxRate = parseFloat(objData['tax_rate']) * 0.01;
            objTaxCode = new Select(objData['tax_code_id'], objData['tax_code_name']);
          } else if (this.objDefaultTaxCode['id'] != undefined && this.objDefaultTaxCode['id'] != null && this.objDefaultTaxCode['id'] != '') {
            strTaxCodeId = this.objDefaultTaxCode['id'];
            strTaxCode = this.objDefaultTaxCode['code'];
            strTaxCodeName = this.objDefaultTaxCode['name'];
            strTaxRate = parseFloat(this.objDefaultTaxCode['rate']) * 0.01;
            objTaxCode = new Select(this.objDefaultTaxCode['id'], this.objDefaultTaxCode['text']);
          }

          if (objData['account_code_id'] != undefined && objData['account_code_id'] != null && objData['account_code_id'] != '') {
            strAccountCodeId = objData['account_code_id'];
            strAccountCode = objData['account_code'];
            strAccountCodeName = objData['account_code_name'];
            objAccountCode = { id: objData['account_code_id'], name: objData['account_code_name'] };
          } else if (this.objDefaultAccountCode['id'] != undefined && this.objDefaultAccountCode['id'] != null && this.objDefaultAccountCode['id'] != '') {
            strAccountCodeId = this.objDefaultAccountCode['id'];
            strAccountCode = this.objDefaultAccountCode['code'];
            strAccountCodeName = this.objDefaultAccountCode['name'];
            objAccountCode = { id: this.objDefaultAccountCode['id'], name: this.objDefaultAccountCode['name'] };
          }

          // If department still empty. We need to check if there's a department in related job
          let objDepartmentJob = new Department().getJobDepartment(this.objJobData);

          if (_.isEmpty(objDepartment) && !_.isEmpty(objDepartmentJob.department)) {
            objDepartment = objDepartmentJob.department;
            strDepartmentId = objDepartmentJob.department_id;
            strDepartmentName = objDepartmentJob.department_text;
          }
        } else {
          objDepartment = (objCustomerInvoice['department_id'] != undefined && objCustomerInvoice['department_id'] != '' && objCustomerInvoice['department_id'] != null) ? new Select(objCustomerInvoice['department_id'], objCustomerInvoice['department_name']) : {};
          strDepartmentId = (objCustomerInvoice['department_id'] != undefined) ? objCustomerInvoice['department_id'] : null;
          strDepartmentName = (objCustomerInvoice['department_name'] != undefined) ? objCustomerInvoice['department_name'] : null;

          if (objCustomerInvoice['tax_code_id'] != undefined && objCustomerInvoice['tax_code_id'] != null && objCustomerInvoice['tax_code_id'] != '') {
            strTaxCodeId = objCustomerInvoice['tax_code_id'];
            strTaxCode = objCustomerInvoice['tax_code'];
            strTaxCodeName = objCustomerInvoice['tax_code_name'];
            strTaxRate = parseFloat(objCustomerInvoice['tax_rate']) * 0.01;
            objTaxCode = new Select(objCustomerInvoice['tax_code_id'], objCustomerInvoice['tax_code_name']);
          } else if (this.objDefaultTaxCode['id'] != undefined && this.objDefaultTaxCode['id'] != null && this.objDefaultTaxCode['id'] != '') {
            strTaxCodeId = this.objDefaultTaxCode['id'];
            strTaxCode = this.objDefaultTaxCode['code'];
            strTaxCodeName = this.objDefaultTaxCode['name'];
            strTaxRate = parseFloat(this.objDefaultTaxCode['rate']) * 0.01;
            objTaxCode = new Select(this.objDefaultTaxCode['id'], this.objDefaultTaxCode['text']);
          }

          if (objCustomerInvoice['account_code_id'] != undefined && objCustomerInvoice['account_code_id'] != null && objCustomerInvoice['account_code_id'] != '') {
            strAccountCodeId = objCustomerInvoice['account_code_id'];
            strAccountCode = objCustomerInvoice['account_code'];
            strAccountCodeName = objCustomerInvoice['account_code_name'];
            objAccountCode = { id: objCustomerInvoice['account_code_id'], name: objCustomerInvoice['account_code_name'] };
          } else if (this.objDefaultAccountCode['id'] != undefined && this.objDefaultAccountCode['id'] != null && this.objDefaultAccountCode['id'] != '') {
            strAccountCodeId = this.objDefaultAccountCode['id'];
            strAccountCode = this.objDefaultAccountCode['code'];
            strAccountCodeName = this.objDefaultAccountCode['name'];
            objAccountCode = { id: this.objDefaultAccountCode['id'], name: this.objDefaultAccountCode['name'] };
          }

          strUnitPrice = (objCustomerInvoice['unit_price'] != undefined) ? toFormattedNumber(objCustomerInvoice['unit_price'], {
            currency: true,
            maxDecimalPlaces: 4,
          }) : toFormattedNumber(objData['unit_price'], {
            currency: true,
            maxDecimalPlaces: 4,
          });
        }

        let bExcludeFromInvoice = (objCustomerInvoice['exclude_from_invoice'] != undefined && objCustomerInvoice['exclude_from_invoice'] != '') ? objCustomerInvoice['exclude_from_invoice'] : false;
        let strItemAccountingId = '';

        if (this.arrService.keyFallsBackTo(objData, 'accounting_id', '')) {
          strItemAccountingId = objData['accounting_id'];
        } else if (this.arrService.keyFallsBackTo(objCustomerInvoice, 'item_accounting_id', '')) {
          strItemAccountingId = objCustomerInvoice['item_accounting_id'];
        } else {
          strItemAccountingId = strDefaultItemAccountingId;
        }

          let strItemId = objData['item_id'] || objData['id'];
          let strItemCode = objData['item_code'] || objData['code'];
          let strItemName = objData['item_name'] || objData['name'] || objData['text'];
          let hasTimeEntry = false;

          // Note: ObjData['billed_duration] just an identifier that the data is from time entry record
          if (!_.isEmpty(objCustomerInvoice['time_entry_id']) || objCustomerInvoice['created'] || objCustomerInvoice['for_update'] || (filled(objData['id']) && _.has(objData, 'billed_duration'))) {
            hasTimeEntry = true;
          }

          let lineItemsData = {
            id: "",
            time_entry: {
              actual_duration: _.toString(toFormattedNumber(objData['actual_duration'] || 0)),
              billed_duration: _.toString(toFormattedNumber(get(objData, 'billed_duration', get(objData, 'quantity', 0)))),
              description: objData['description'] || objData['name'],
              start_time: objData['start_time'] || null,
              end_time: objData['end_time'] || null,
              item_id: strItemId,
              user_id: objData['user_id'],
              user_text: objData['user_text'],
            },
            customer_invoice : {
              account_code: strAccountCode,
              tax_code: strTaxCode,
              item_id: strItemId,
              item_code: strItemCode,
              item_name: strItemName,
              time_entry_id: get(objCustomerInvoice, 'time_entry_id', hasTimeEntry ? objData['id'] : null),
              unit_price: _.toString(toFormattedNumber(strUnitPrice, {
                currency: true,
                maxDecimalPlaces: 4,
              })),
              tax_code_id: strTaxCodeId,
              tax_code_name: strTaxCodeName,
              tax_rate: strTaxRate * 100,
              account_code_name: strAccountCodeName,
              account_code_id: strAccountCodeId,
              exclude_from_invoice: bExcludeFromInvoice,
              department_id: strDepartmentId,
              department_name: strDepartmentName,
              item_accounting_id: strItemAccountingId,
              labor: !_.isUndefined(objData['labor']) ? objData['labor'] : objCustomerInvoice['labor'],
            },
            item: new Select(strItemId, strItemName),
            user: new Select(objData['user_id'], objData['user_text']),
            department: objDepartment,
            tax: objTaxCode,
            account_code: objAccountCode,
            id_type: newId,
            name: null,
            item_obv: new Observable<Select[]>(),
            item_typehead: new Subject<string>(),
            item_loader: false,
            taxcode_obv: new Observable<Select[]>(),
            taxcode_typehead: new Subject<string>(),
            taxcode_loader: false,
            account_code_obv: new Observable<Select[]>(),
            account_code_typehead: new Subject<string>(),
            account_code_loader: false,
            user_obv: new Observable<Select[]>(),
            user_typehead: new Subject<string>(),
            user_loader: false,
            department_obv: new Observable<Select[]>(),
            department_typehead: new Subject<string>(),
            department_loader: false,
            work_date: objData['start_time'] && objData['end_time'] ? [ moment.utc(objData['start_time']).toDate() , moment.utc(objData['end_time']).toDate() ] : null,
            amount: toFormattedNumber(parseFloat(get(objData, 'billed_duration', get(objData, 'quantity', 0))) * strUnitPrice, {
              currency: true,
            }),
            tax_rate: strTaxRate,
            has_time_entries: hasTimeEntry,
            time_entry_id: get(objCustomerInvoice, 'time_entry_id', hasTimeEntry ? objData['id'] : null),
            select_line_id: selectLineId,
            related_products: get(objData, 'related_products'),
            supplier_pricing: get(objData, 'supplier_pricing'),
          };

          if(lineItemIndex != -1) {
            this.arTimeEntryAttributes.splice(lineItemIndex, 0, lineItemsData);
          } else {
            this.arTimeEntryAttributes.push(lineItemsData);
          }

      } else {
        let arDefaultItemAccounting = this.arrService.keyFallsBackTo(this.objModuleConfig['related_data'], 'default_item_accounting', []);
        let strDefaultItemAccountingId: string = (arDefaultItemAccounting) ? arDefaultItemAccounting['accounting_id'] : '';
        // If department still empty. We need to check if there's a department in related job
        let objDepartmentJob = new Department().getJobDepartment(this.objJobData);

        //Add a new line item to the array of line items.
        this.arTimeEntryAttributes.push({
          id: "",
          time_entry: {
            actual_duration: '0.00',
            billed_duration: '0.00',
            description: null,
            start_time: null,
            end_time: null,
            item_id: null,
            user_id: null,
            user_text: null,
          },
          customer_invoice : {
            account_code: filled(this.objDefaultAccountCode['code']) ? this.objDefaultAccountCode['code'] : null,
            tax_code: filled(this.objDefaultTaxCode['code']) ? this.objDefaultTaxCode['code'] : null,
            item_id: null,
            item_code: null,
            item_name: null,
            time_entry_id: null,
            unit_price: '0.00',
            tax_code_id: filled(this.objDefaultTaxCode['id']) ? this.objDefaultTaxCode['id'] : null,
            tax_code_name: filled(this.objDefaultTaxCode['name']) ? this.objDefaultTaxCode['name'] : null,
            tax_rate: filled(this.objDefaultTaxCode['rate']) ? this.objDefaultTaxCode['rate'] : null,
            account_code_id: filled(this.objDefaultAccountCode['id']) ? this.objDefaultAccountCode['id'] : null,
            account_code_name: filled(this.objDefaultAccountCode['name']) ? this.objDefaultAccountCode['name'] : null,
            exclude_from_invoice: false,
            department_id: this.strService.fallsBackTo(objDepartmentJob.department_id),
            department_name: this.strService.fallsBackTo(objDepartmentJob.department_text),
            item_accounting_id: strDefaultItemAccountingId,
            labor: null,
          },
          item: {},
          user: {},
          tax: filled(this.objDefaultTaxCode['id']) ? new Select(this.objDefaultTaxCode['id'], this.objDefaultTaxCode['text']) : {},
          account_code: filled(this.objDefaultAccountCode['id']) ? { id: this.objDefaultAccountCode['id'], name: this.objDefaultAccountCode['name'] } : {},
          department: !_.isEmpty(objDepartmentJob.department) ? objDepartmentJob.department : {},
          id_type: newId,
          name: null,
          item_obv: new Observable<Select[]>(),
          item_typehead: new Subject<string>(),
          item_loader: false,
          taxcode_obv: new Observable<Select[]>(),
          taxcode_typehead: new Subject<string>(),
          taxcode_loader: false,
          account_code_obv: new Observable<Select[]>(),
          account_code_typehead: new Subject<string>(),
          account_code_loader: false,
          user_obv: new Observable<Select[]>(),
          user_typehead: new Subject<string>(),
          user_loader: false,
          department_obv: new Observable<Select[]>(),
          department_typehead: new Subject<string>(),
          department_loader: false,
          work_date: null,
          amount: '0.00',
          tax_rate: filled(this.objDefaultTaxCode['id']) ? parseFloat(this.objDefaultTaxCode['rate']) * 0.01 : 0,
          has_time_entries: false,
          time_entry_id: null,
          select_line_id: selectLineId,
          related_products: [],
          supplier_pricing: [],
        });
      }

      /**
       * Initialize observable fields
       * @param
       * strAttributeName - object attribute
       * strModule - module for relate
       * strObservableName - observable that is set
       * objMainFilter - Main filter for record (onChange of the fields)
       * arRelateInitialValue - initialRelateValue
       */
      // For Tax Code Relate
      this.initRelateRecords('arTimeEntryAttributes', 'tax_codes', 'taxcode', this.arTaxCodeFilter, this.relateTaxCodeData);
      // For Items Relate
      this.initRelateRecords('arTimeEntryAttributes', 'items', 'item', this.arItemFilter, this.relateTimeEntryItemData);
      // For User Relate
      this.initRelateRecords('arTimeEntryAttributes', 'users', 'user', false, this.relateUserData);
      // For Account Code Relate
      this.initRelateRecords('arTimeEntryAttributes', 'account_codes', 'account_code', this.arAccountCodeFilter, this.relateAccountCodeData);
      // For Department Relate
      this.initRelateRecords('arTimeEntryAttributes', 'departments', 'department', false, this.relateDeparmentData);

      if (bRecompute) {
        // Compute amount due when adding an attribute
        this.setAmountDue();
      }
    }

    this.groupBy()

    if (bFromUi) {
      this.copyActualHours();
      this.markAsDirty();
    }
  }

  /**
   * Adds a new input field.
   */
  addMaterialEntryAttribute(objData: any = [], objCustomerInvoice: any = [], bRecompute: boolean = true, bFromUi: boolean = false, lineItemIndex: number = -1) {
    // Generate a unique name for the lineitem.
    let newId = 'nameField' + this.arMaterialEntryAttributes.length + Math.floor((Math.random() * 10) + 1);
    let selectLineId = UUID.UUID();

    // If the generated name is indeed unique (no existing item of a similar name found.).
    if (this.arMaterialEntryAttributes.findIndex(attr => (attr['id_type'] == newId)) < 0) {
      let arDefaultItemAccounting = this.arrService.keyFallsBackTo(this.customerInvoiceRecord['related_data'], 'default_item_accounting', []);
      let strDefaultItemAccountingId: string = (arDefaultItemAccounting) ? arDefaultItemAccounting['accounting_id'] : '';

      // If has existing data
      if (Object.keys(objData).length > 0) {
        let objDepartment = {};
        let strDepartmentId = '';
        let strDepartmentName = '';

        let strTaxCodeId = '';
        let strTaxCode = '';
        let strTaxCodeName = '';
        let strTaxRate: number = 0;
        let objTaxCode = {};

        let strAccountCodeId = '';
        let strAccountCode = '';
        let strAccountCodeName = '';
        let objAccountCode = {};
        let strUnitPrice: number = 0;

        // If view type is add - get the department record from time entry else get to the line items
        if (this.strViewType == 'add') {
          objDepartment = (this.arrService.keyFallsBackTo(objData, 'department_id', '')) ? new Select(objData['department_id'], objData['department_name']) : {};
          strDepartmentId = (this.arrService.keyFallsBackTo(objData, 'department_id', '')) ? objData['department_id'] : null;
          strDepartmentName = (this.arrService.keyFallsBackTo(objData, 'department_name', '')) ? objData['department_name'] : null;

          if (this.arrService.keyFallsBackTo(objData, 'tax_code_id', '')) {
            strTaxCodeId = objData['tax_code_id'];
            strTaxCode = objData['tax_code'];
            strTaxCodeName = objData['tax_code_name'];
            strTaxRate = parseFloat(objData['tax_rate']) * 0.01;
            objTaxCode = new Select(objData['tax_code_id'], objData['tax_code_name']);
          } else if (this.arrService.keyFallsBackTo(this.objDefaultTaxCode, 'id', '')) {
            strTaxCodeId = this.objDefaultTaxCode['id'];
            strTaxCode = this.objDefaultTaxCode['code'];
            strTaxCodeName = this.objDefaultTaxCode['name'];
            strTaxRate = parseFloat(this.objDefaultTaxCode['rate']) * 0.01;
            objTaxCode = new Select(this.objDefaultTaxCode['id'], this.objDefaultTaxCode['text']);
          }

          if (this.arrService.keyFallsBackTo(objData, 'account_code_id', '')) {
            strAccountCodeId = objData['account_code_id'];
            strAccountCode = objData['account_code'];
            strAccountCodeName = objData['account_code_name'];
            objAccountCode = { id: objData['account_code_id'], name: objData['account_code_name'] };
          } else if (this.arrService.keyFallsBackTo(this.objDefaultAccountCode, 'id', '')) {
            strAccountCodeId = this.objDefaultAccountCode['id'];
            strAccountCode = this.objDefaultAccountCode['code'];
            strAccountCodeName = this.objDefaultAccountCode['name'];
            objAccountCode = { id: this.objDefaultAccountCode['id'], name: this.objDefaultAccountCode['name'] };
          }

          // If department still empty. We need to check if there's a department in related job
          let objDepartmentJob = new Department().getJobDepartment(this.objJobData);
          if (_.isEmpty(objDepartment) && !_.isEmpty(objDepartmentJob.department)) {
            objDepartment = objDepartmentJob.department;
            strDepartmentId = objDepartmentJob.department_id;
            strDepartmentName = objDepartmentJob.department_text;
          }
        } else {
          objDepartment = (this.arrService.keyFallsBackTo(objCustomerInvoice, 'department_id', '')) ? new Select(objCustomerInvoice['department_id'], objCustomerInvoice['department_name']) : {};
          strDepartmentId = (this.arrService.keyFallsBackTo(objCustomerInvoice, 'department_id', '')) ? objCustomerInvoice['department_id'] : null;
          strDepartmentName = (this.arrService.keyFallsBackTo(objCustomerInvoice, 'department_name', '')) ? objCustomerInvoice['department_name'] : null;

          if (this.arrService.keyFallsBackTo(objCustomerInvoice, 'tax_code_id', '')) {
            strTaxCodeId = objCustomerInvoice['tax_code_id'];
            strTaxCode = objCustomerInvoice['tax_code'];
            strTaxCodeName = objCustomerInvoice['tax_code_name'];
            strTaxRate = parseFloat(objCustomerInvoice['tax_rate']) * 0.01;
            objTaxCode = new Select(objCustomerInvoice['tax_code_id'], objCustomerInvoice['tax_code_name']);
          } else if (this.arrService.keyFallsBackTo(this.objDefaultTaxCode, 'id', '')) {
            strTaxCodeId = this.objDefaultTaxCode['id'];
            strTaxCode = this.objDefaultTaxCode['code'];
            strTaxCodeName = this.objDefaultTaxCode['name'];
            strTaxRate = parseFloat(this.objDefaultTaxCode['rate']) * 0.01;
            objTaxCode = new Select(this.objDefaultTaxCode['id'], this.objDefaultTaxCode['text']);
          }

          if (this.arrService.keyFallsBackTo(objCustomerInvoice, 'account_code_id', '')) {
            strAccountCodeId = objCustomerInvoice['account_code_id'];
            strAccountCode = objCustomerInvoice['account_code'];
            strAccountCodeName = objCustomerInvoice['account_code_name'];
            objAccountCode = { id: objCustomerInvoice['account_code_id'], name: objCustomerInvoice['account_code_name'] };
          } else if (this.arrService.keyFallsBackTo(this.objDefaultAccountCode, 'id', '')) {
            strAccountCodeId = this.objDefaultAccountCode['id'];
            strAccountCode = this.objDefaultAccountCode['code'];
            strAccountCodeName = this.objDefaultAccountCode['name'];
            objAccountCode = { id: this.objDefaultAccountCode['id'], name: this.objDefaultAccountCode['name'] };
          }
        }

          strUnitPrice = toFormattedNumber(either(objData, ['pricebook_unit_price', 'unit_price'], {
            default_value: 0,
          }), {
            currency: true,
            maxDecimalPlaces: 4,
          });

          let bExcludeFromInvoice = (objData['exclude_from_invoice'] != undefined && objData['exclude_from_invoice'] != '') ? objData['exclude_from_invoice'] : false;
          let strItemAccountingId = '';

          if (this.arrService.keyFallsBackTo(objData, 'accounting_id', '')) {
            strItemAccountingId = objData['accounting_id'];
          } else if (this.arrService.keyFallsBackTo(objCustomerInvoice, 'item_accounting_id', '')) {
            strItemAccountingId = objCustomerInvoice['item_accounting_id'];
          } else {
            strItemAccountingId = strDefaultItemAccountingId;
          }

          let strItemId = '';

          if (!_.isUndefined(objData['type']) && objData['type'] == 'product_catalog' || filled(objData['item_id'])) {
            strItemId = objData['item_id'];
          } else if (!_.isUndefined(objData['type']) && objData['type'] == 'once_off_purchase') {
            strItemId = '';
          } else {
            strItemId = objData['id'];
          }

          let strItemCode = objData['item_code'] || objData['code'];
          let strItemName = objData['item_name'] || objData['name'] || objData['text'];
          let strType = objData['type'] || (strItemId ? 'product_catalog' : 'once_off_purchase');

          if (strType == 'once_off_purchase') {
            objData['name'] = objData['description'];
          }

          let hasMaterial = false;

          if (!_.isEmpty(objCustomerInvoice['material_id']) || objCustomerInvoice['created'] || objCustomerInvoice['for_update'] || (filled(objData['id']) && _.has(objData, 'material_image'))) {
            hasMaterial = true;
          }

          let lineItemsData = {
            id: "",
            material_entry: {
              type: strType,
              item_id: strItemId,
              product: objData,
              exclude_from_invoice: bExcludeFromInvoice,
              markup: this.computeMarkup(objData['markup'], strUnitPrice, objData['unit_cost'] || 0),
              quantity: objData['quantity'] || 1,
              unit_cost: _.toString(toFormattedNumber(objData['unit_cost'] || 0, {
                currency: true,
                maxDecimalPlaces: 4,
              })),
              unit_price: _.toString(toFormattedNumber(strUnitPrice, {
                currency: true,
                maxDecimalPlaces: 4,
              })),
              total_cost: (bExcludeFromInvoice === false) ? toFormattedNumber(objData['total_cost'], {
                currency: true,
              }) : 0,
              total_price: (bExcludeFromInvoice === false) ? toFormattedNumber(objData['total_price'], {
                currency: true,
              }) : 0,
              notes: objData['notes'] || objData['description'] || objData['name'],
            },
            customer_invoice: {
              type: strType,
              quantity: objData['quantity'] || 1,
              tax_code: strTaxCode,
              account_code: strAccountCode,
              item_id: strItemId,
              item_code: strItemCode,
              item_name: strItemName,
              exclude_from_invoice: bExcludeFromInvoice,
              material_id: get(objCustomerInvoice, 'material_id', hasMaterial ? objData['id'] : null),
              tax_code_id: strTaxCodeId,
              account_code_id: strAccountCodeId,
              account_code_name: strAccountCodeName,
              department_id : strDepartmentId,
              department_name: strDepartmentName,
              item_accounting_id: strItemAccountingId,
              labor: !_.isUndefined(objData['labor']) ? objData['labor'] : get(objCustomerInvoice, 'labor', false),
            },
            item: new Select(strItemId, strItemName),
            tax: objTaxCode,
            account_code: objAccountCode,
            department: objDepartment,
            id_type: newId,
            name: null,
            item_obv: new Observable<Select[]>(),
            item_typehead: new Subject<string>(),
            item_loader: false,
            taxcode_obv: new Observable<Select[]>(),
            taxcode_typehead: new Subject<string>(),
            taxcode_loader: false,
            account_code_obv: new Observable<Select[]>(),
            account_code_typehead: new Subject<string>(),
            account_code_loader: false,
            department_obv: new Observable<Select[]>(),
            department_typehead: new Subject<string>(),
            department_loader: false,
            total_cost: (bExcludeFromInvoice === false) ? toFormattedNumber(parseFloat(objData['unit_cost']) * parseFloat(objData['quantity'] || 1), {
              currency: true,
            }) : 0,
            total_price: (bExcludeFromInvoice === false) ? toFormattedNumber(strUnitPrice * parseFloat(objData['quantity'] || 1), {
              currency: true,
            }) : 0,
            tax_rate: strTaxRate,
            has_material_entries: hasMaterial,
            material_id: get(objCustomerInvoice, 'material_id', hasMaterial ? objData['id'] : null),
            current_stock_level: objData['current_stock_level'] || 0,
            select_line_id: selectLineId,
            related_products: get(objData, 'related_products', get(objCustomerInvoice, 'related_products', [])),
            supplier_pricing: get(objData, 'supplier_pricing', get(objCustomerInvoice, 'supplier_pricing', [])),
          };

          if(lineItemIndex != -1) {
            this.arMaterialEntryAttributes.splice(lineItemIndex, 0, lineItemsData);
          } else {
            this.arMaterialEntryAttributes.push(lineItemsData);
          }

      } else {
        let arDefaultItemAccounting = this.arrService.keyFallsBackTo(this.objModuleConfig['related_data'], 'default_item_accounting', []);
        let strDefaultItemAccountingId: string = (arDefaultItemAccounting) ? arDefaultItemAccounting['accounting_id'] : '';
        let objDepartmentJob = new Department().getJobDepartment(this.objJobData);

        //Add a new line item to the array of line items.
        this.arMaterialEntryAttributes.push({
          id: "",
          material_entry: {
            type: 'product_catalog',
            item_id: null,
            product: null,
            exclude_from_invoice: false,
            markup: '0.00',
            quantity: 1,
            unit_cost: '0.00',
            unit_price: '0.00',
            total_cost: '0.00',
            total_price: '0.00',
            notes: null,
          },
          customer_invoice: {
            type: 'product_catalog',
            quantity: 1,
            account_code: filled(this.objDefaultAccountCode['code']) ? this.objDefaultAccountCode['code'] : null,
            tax_code: filled(this.objDefaultTaxCode['code']) ? this.objDefaultTaxCode['code'] : null,
            item_id: null,
            item_code: null,
            material_id: null,
            exclude_from_invoice: false,
            tax_code_id: filled(this.objDefaultTaxCode['id']) ? this.objDefaultTaxCode['id'] : null,
            tax_code_name: filled(this.objDefaultTaxCode['name']) ? this.objDefaultTaxCode['name'] : null,
            tax_rate: filled(this.objDefaultTaxCode['rate']) ? this.objDefaultTaxCode['rate'] : null,
            account_code_id: filled(this.objDefaultAccountCode['id']) ? this.objDefaultAccountCode['id'] : null,
            account_code_name: filled(this.objDefaultAccountCode['name']) ? this.objDefaultAccountCode['name'] : null,
            department_id: this.strService.fallsBackTo(objDepartmentJob.department_id),
            department_name: this.strService.fallsBackTo(objDepartmentJob.department_text),
            item_accounting_id: strDefaultItemAccountingId,
            labor: !_.isUndefined(objData['labor']) ? objData['labor'] : objCustomerInvoice['labor'],
          },
          item: {},
          tax: filled(this.objDefaultTaxCode['id']) ? new Select(this.objDefaultTaxCode['id'], this.objDefaultTaxCode['text']) : {},
          account_code: filled(this.objDefaultAccountCode['id']) ? { id: this.objDefaultAccountCode['id'], name: this.objDefaultAccountCode['name'] } : {},
          department: !_.isEmpty(objDepartmentJob.department) ? objDepartmentJob.department : {},
          id_type: newId,
          name: null,
          item_obv: new Observable<Select[]>(),
          item_typehead: new Subject<string>(),
          item_loader: false,
          taxcode_obv: new Observable<Select[]>(),
          taxcode_typehead: new Subject<string>(),
          taxcode_loader: false,
          account_code_obv: new Observable<Select[]>(),
          account_code_typehead: new Subject<string>(),
          account_code_loader: false,
          department_obv: new Observable<Select[]>(),
          department_typehead: new Subject<string>(),
          department_loader: false,
          total_cost: '0.00',
          total_price: '0.00',
          tax_rate: filled(this.objDefaultTaxCode['id']) ? parseFloat(this.objDefaultTaxCode['rate']) * 0.01 : 0,
          has_material_entries: false,
          material_id: null,
          select_line_id: selectLineId,
          related_products: [],
          supplier_pricing: [],
        });
      }

      /**
       * Initialize observable fields
       * @param
       * strAttributeName - object attribute
       * strModule - module for relate
       * strObservableName - observable that is set
       * objMainFilter - Main filter for record (onChange of the fields)
       * arRelateInitialValue - initialRelateValue
       */
      // For Items Relate
      this.initRelateRecords('arMaterialEntryAttributes', 'items', 'item', this.arItemMaterialFilter, this.relateMaterialItemData);
      // For Tax Code Relate
      this.initRelateRecords('arMaterialEntryAttributes', 'tax_codes', 'taxcode', this.arTaxCodeFilter, this.relateTaxCodeData);
      // For Account Code Relate
      this.initRelateRecords('arMaterialEntryAttributes', 'account_codes', 'account_code', this.arAccountCodeFilter, this.relateAccountCodeData);
      // For Department Relate
      this.initRelateRecords('arMaterialEntryAttributes', 'departments', 'department', false, this.relateDeparmentData);

      if (bRecompute) {
        // Compute amount due when adding an attribute
        this.setAmountDue();
      }
    }

    if (bFromUi) {
      this.markAsDirty();
    }
  }

  /**
   * Adds a new input field.
   */
  addInvoiceLineAttribute(objData: any = [], bRecompute: boolean = true, bFromUi: boolean = false, lineItemIndex: number = -1) {

    // Generate a unique name for the lineitem.
    let newId = 'nameField' + this.arInvoiceLineAttributes.length + Math.floor((Math.random() * 10) + 1);
    let selectLineId = UUID.UUID();

    // If the generated name is indeed unique (no existing item of a similar name found.).
    if (this.arInvoiceLineAttributes.findIndex(attr => (attr['id_type'] == newId)) < 0) {
      let arDefaultItemAccounting = this.arrService.keyFallsBackTo(this.customerInvoiceRecord['related_data'], 'default_item_accounting', []);
      let strDefaultItemAccountingId: string = (arDefaultItemAccounting) ? arDefaultItemAccounting['accounting_id'] : '';

      if (Object.keys(objData).length > 0) {
          let strTaxCodeId = '';
          let strTaxCode = '';
          let strTaxCodeName = '';
          let strTaxRate: number = 0;
          let objTaxCode = {};

          let strAccountCodeId = '';
          let strAccountCode = '';
          let strAccountCodeName = '';
          let objAccountCode = {};
          let strItemAccountingId;

          let strItemId = objData['id'] || objData['item_id'];
          let strItemName = objData['name'] || objData['item_name'];
          let strItemcode = objData['code'] || objData['item_code'];

          // FC-4428: if objData['id'] is not in uuid format, use objData['item_id']
          if (filled(strItemId) && !isId(strItemId) && isId(objData['item_id'])) {
            strItemId = objData['item_id'];
          }

          if (this.arrService.keyFallsBackTo(objData, 'tax_code_id', '')) {
            strTaxCodeId = objData['tax_code_id'];
            strTaxCode = objData['tax_code'];
            strTaxCodeName = objData['tax_code_name'];
            strTaxRate = parseFloat(objData['tax_rate']) * 0.01;
            objTaxCode = new Select(objData['tax_code_id'], objData['tax_code_name']);
          } else if (this.arrService.keyFallsBackTo(this.objDefaultTaxCode, 'id', '')) {
            strTaxCodeId = this.objDefaultTaxCode['id'];
            strTaxCode = this.objDefaultTaxCode['code'];
            strTaxCodeName = this.objDefaultTaxCode['name'];
            strTaxRate = parseFloat(this.objDefaultTaxCode['rate']) * 0.01;
            objTaxCode = new Select(this.objDefaultTaxCode['id'], this.objDefaultTaxCode['text']);
          }

          if (this.arrService.keyFallsBackTo(objData, 'account_code_id', '')) {
            strAccountCodeId = objData['account_code_id'];
            // FC-2684: If we don't have account codes, get the account codes in cached data
            strAccountCode = !objData['account_code'] ? this.objCacheData['account_codes'].find( account_codes => account_codes.id == objData['account_code_id']).code : objData['account_code'];
            strAccountCodeName = objData['account_code_name'];
            objAccountCode = { id: objData['account_code_id'], name: objData['account_code_name'] };
          } else if (this.arrService.keyFallsBackTo(this.objDefaultAccountCode, 'id', '')) {
            strAccountCodeId = this.objDefaultAccountCode['id'];
            strAccountCode = this.objDefaultAccountCode['code'];
            strAccountCodeName = this.objDefaultAccountCode['name'];
            objAccountCode = { id: this.objDefaultAccountCode['id'], name: this.objDefaultAccountCode['name'] };
          }

          strItemAccountingId = this.arrService.keyFallsBackTo(objData, 'item_accounting_id', strDefaultItemAccountingId);

          // FC-2020: Get the department name in department list, if we have department_id and empty department_name

          if (objData['department_id'] && !objData['department_name']) {
            let department = this.arDepartmentList.find(department => department['id'] == objData['department_id']);
            if (department['text']) {
              objData['department_name'] = department['text'];
            }
          }

          const unitPrice = toFormattedNumber(either(objData, ['pricebook_unit_price', 'unit_price'], {
            default_value: 0,
          }), {
            maxDecimalPlaces: 4,
            currency: true,
          });

          let lineItemsData = {
            id: "",
            invoice_line: {
              account_code: strAccountCode,
              tax_code: strTaxCode,
              department_id: objData['department_id'],
              department_name: objData['department_name'],
              quantity: objData['quantity'] || 1,
              unit_price: unitPrice,
              description: objData['description'] || objData['name'],
              tax_code_id: strTaxCodeId,
              tax_code_name: strTaxCodeName,
              tax_rate: strTaxRate * 100,
              account_code_name: strAccountCodeName,
              account_code_id: strAccountCodeId,
              item_accounting_id: strItemAccountingId,
              item_id: strItemId,
              item_code: strItemcode,
              labor: objData['labor'],
            },
            item: new Select(strItemId, strItemName),
            tax: objTaxCode,
            account_code: objAccountCode,
            department: new Select(objData['department_id'], objData['department_name']),
            id_type: newId,
            name: null,
            item_obv: new Observable<Select[]>(),
            item_typehead: new Subject<string>(),
            item_loader: false,
            department_obv: new Observable<Select[]>(),
            department_typehead: new Subject<string>(),
            department_loader: false,
            taxcode_obv: new Observable<Select[]>(),
            taxcode_typehead: new Subject<string>(),
            taxcode_loader: false,
            account_code_obv: new Observable<Select[]>(),
            account_code_typehead: new Subject<string>(),
            account_code_loader: false,
            amount: toFormattedNumber((objData['quantity'] || 1) * unitPrice, {
              currency: true,
            }),
            tax_rate: strTaxRate,
            current_stock_level: objData['current_stock_level'] || 0,
            select_line_id: selectLineId,
            related_products: get(objData, 'related_products'),
            supplier_pricing: get(objData, 'supplier_pricing'),
          };

          //Add a new line item to the array of line items.

          if(lineItemIndex != -1) {
            this.arInvoiceLineAttributes.splice(lineItemIndex, 0, lineItemsData);
          } else {
            this.arInvoiceLineAttributes.splice(this.numLastFocusedIndex, 0, lineItemsData);
          }

      } else {

        //Add a new line item to the array of line items.
        let strAmountToInvoice = (this.arrService.keyFallsBackTo(this.objJobData, 'amount_to_invoice', '')) ? parseFloat(this.objJobData['amount_to_invoice']) : 0;
        let arDefaultItemAccounting = this.arrService.keyFallsBackTo(this.objModuleConfig['related_data'], 'default_item_accounting', []);
        let strDefaultItemAccountingId: string = (arDefaultItemAccounting) ? arDefaultItemAccounting['accounting_id'] : '';
        let objDepartmentJob = new Department().getJobDepartment(this.objJobData);

        this.arInvoiceLineAttributes.splice(this.numLastFocusedIndex, 0, {
          id: "",
            invoice_line: {
              account_code: filled(this.objDefaultAccountCode['code']) ? this.objDefaultAccountCode['code'] : null,
              tax_code: filled(this.objDefaultTaxCode['code']) ? this.objDefaultTaxCode['code'] : null,
              department_id: this.strService.fallsBackTo(objDepartmentJob.department_id),
              department_name: this.strService.fallsBackTo(objDepartmentJob.department_text),
              quantity: 1,
              unit_price: (this.strInvoicingType == 'fixed_price_invoices') ? _.toString(toFormattedNumber(strAmountToInvoice, {
                currency: true,
                maxDecimalPlaces: 4,
              })) : '0.0000',
              description: (this.strInvoicingType == 'fixed_price_invoices') ? this.objJobData['job_summary'] : objData['name'],
              tax_code_id: filled(this.objDefaultTaxCode['id']) ? this.objDefaultTaxCode['id'] : null,
              tax_code_name: filled(this.objDefaultTaxCode['name']) ? this.objDefaultTaxCode['name'] : null,
              tax_rate: filled(this.objDefaultTaxCode['rate']) ? this.objDefaultTaxCode['rate'] : null,
              account_code_name: filled(this.objDefaultAccountCode['name']) ? this.objDefaultAccountCode['name'] : null,
              account_code_id: filled(this.objDefaultAccountCode['id']) ? this.objDefaultAccountCode['id'] : null,
              item_accounting_id: strDefaultItemAccountingId,
              item_id: null,
              item_code: null,
              labor: null,
            },
            item: {},
            department: !_.isEmpty(objDepartmentJob.department) ? objDepartmentJob.department : {},
            tax: filled(this.objDefaultTaxCode['id']) ? new Select(this.objDefaultTaxCode['id'], this.objDefaultTaxCode['text']) : {},
            account_code: filled(this.objDefaultAccountCode['id']) ? { id: this.objDefaultAccountCode['id'], name: this.objDefaultAccountCode['name'] } : {},
            id_type: newId,
            name: null,
            item_obv: new Observable<Select[]>(),
            item_typehead: new Subject<string>(),
            item_loader: false,
            department_obv: new Observable<Select[]>(),
            department_typehead: new Subject<string>(),
            department_loader: false,
            taxcode_obv: new Observable<Select[]>(),
            taxcode_typehead: new Subject<string>(),
            taxcode_loader: false,
            account_code_obv: new Observable<Select[]>(),
            account_code_typehead: new Subject<string>(),
            account_code_loader: false,
            amount: (this.strInvoicingType == 'fixed_price_invoices') ? toFormattedNumber(strAmountToInvoice, {
              currency: true,
            }) : 0,
            tax_rate: filled(this.objDefaultTaxCode['id']) ? parseFloat(this.objDefaultTaxCode['rate']) * 0.01 : 0,
            select_line_id: selectLineId,
            related_products: [],
            supplier_pricing: [],
        });
      }

      /**
       * Initialize observable fields
       * @param
       * strAttributeName - object attribute
       * strModule - module for relate
       * strObservableName - observable that is set
       * objMainFilter - Main filter for record (onChange of the fields)
       * arRelateInitialValue - initialRelateValue
       */
      // For Department Relate
      this.initRelateRecords('arInvoiceLineAttributes', 'departments', 'department', false, this.relateDeparmentData);
      // For Tax Code Relate
      this.initRelateRecords('arInvoiceLineAttributes', 'tax_codes', 'taxcode', this.arTaxCodeFilter, this.relateTaxCodeData);
      // For Account Code Relate
      this.initRelateRecords('arInvoiceLineAttributes', 'account_codes', 'account_code', this.arAccountCodeFilter, this.relateAccountCodeData);

      if (bRecompute) {
        // Compute amount due when adding an attribute
        this.setAmountDue();
      }
    }

    if (bFromUi) {
      this.markAsDirty();
    }
  }

  /**
   * Close the current dialog.
   */
  cancelDialog() {
    if (this.checkFormGroupDirty()) {
      // Pop-up modal for confirmation
      this.notifService.sendConfirmation('confirm_cancel')
        .filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
          this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Copy actual hours to billable hours per line item
   */
  copyActualHours() {
    this.arTimeEntryAttributes.forEach((data, index) => {
      if (this.arTimeEntryAttributes[index]['customer_invoice']['exclude_from_invoice']) {
        this.arTimeEntryAttributes[index]['time_entry']['billed_duration'] = '0.00';
        this.arTimeEntryAttributes[index]['amount'] = '0.00';
        this.setAmountDue();
      } else {
        let billed = _.get(data, 'time_entry.billed_duration');

        if (_.isNil(billed) || this.strViewType != 'edit') {
          billed = _.get(data, 'time_entry.actual_duration');
        }

        this.arTimeEntryAttributes[index]['time_entry']['billed_duration'] = _.toString(toFormattedNumber(billed));
        this.arTimeEntryAttributes[index]['amount'] = toFormattedNumber(parseFloat(this.arTimeEntryAttributes[index]['time_entry']['billed_duration']) * parseFloat(this.arTimeEntryAttributes[index]['customer_invoice']['unit_price']), {
          currency: true,
          maxDecimalPlaces: 2,
        });
        this.setAmountDue();
      }
    });
    this.markAsDirty();
  }

  /**
   * Set price and total to 0 if true
   * @param event
   * @param attr
   * @param module
   */
  excludeInvoiceChange(event, attr, strModule) {
    if (strModule == 'time_entry') {
      let numLineItem = this.arTimeEntryAttributes.findIndex(line => (line.id_type == attr.id_type));

      if (numLineItem > -1 ) {
        if (event.checked == true) {
          this.arTimeEntryAttributes[numLineItem]['amount'] = '0.00';
          this.arTimeEntryAttributes[numLineItem]['time_entry']['billed_duration'] = '0.00';
          this.setAmountDue();
        } else {
          this.arTimeEntryAttributes[numLineItem]['time_entry']['billed_duration'] = _.toString(toFormattedNumber(this.arTimeEntryAttributes[numLineItem]['time_entry']['actual_duration']));
          this.arTimeEntryAttributes[numLineItem]['amount'] = toFormattedNumber(
            parseFloat(this.arTimeEntryAttributes[numLineItem]['time_entry']['billed_duration']) * parseFloat(this.arTimeEntryAttributes[numLineItem]['customer_invoice']['unit_price']), {
              currency: true,
            }
          );
          this.setAmountDue();
        }
      }

    } else if (strModule == 'material') {
      let numLineItem = this.arMaterialEntryAttributes.findIndex(line => (line.id_type == attr.id_type));
      if (numLineItem > -1) {
        this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['exclude_from_invoice'] = event.checked;
        if (event.checked == true) {
          this.arMaterialEntryAttributes[numLineItem]['total_cost'] = '0.00';
          this.arMaterialEntryAttributes[numLineItem]['total_price'] = '0.00';
          this.arMaterialEntryAttributes[numLineItem]['material_entry']['total_cost'] = '0.00';
          this.arMaterialEntryAttributes[numLineItem]['material_entry']['total_price'] = '0.00';
          this.setAmountDue();
        } else {
          let unitCost = this.arMaterialEntryAttributes[numLineItem]['material_entry']['unit_cost'];
          let unitPrice = this.arMaterialEntryAttributes[numLineItem]['material_entry']['unit_price'];
          let quantity = this.arMaterialEntryAttributes[numLineItem]['material_entry']['quantity'];
          this.arMaterialEntryAttributes[numLineItem]['total_cost'] = toFormattedNumber(parseFloat(unitCost) * parseFloat(quantity), {
            currency: true,
          });
          this.arMaterialEntryAttributes[numLineItem]['total_price'] = toFormattedNumber(parseFloat(unitPrice) * parseFloat(quantity), {
            currency: true,
          });
          this.arMaterialEntryAttributes[numLineItem]['material_entry']['total_cost'] = toFormattedNumber(parseFloat(unitCost) * parseFloat(quantity), {
            currency: true,
          });
          this.arMaterialEntryAttributes[numLineItem]['material_entry']['total_price'] = toFormattedNumber(parseFloat(unitPrice) * parseFloat(quantity), {
            currency: true,
          });
          this.setAmountDue();
        }
      }
    }
    this.markAsDirty()
  }

  /**
   * Compute total when billable is change
   * @param event
   * @param attr
   */
  billableChange(event, attr) {
    // Find the index of the line item that was selected.
    let numLineItem = this.arTimeEntryAttributes.findIndex(line => (line.id_type == attr.id_type));
    if (numLineItem > -1) {
      let billed_duration = (event.target.value != '' && this.arTimeEntryAttributes[numLineItem]['customer_invoice']['exclude_from_invoice'] == false) ? event.target.value : 0;
      let totalAmount: number = (parseFloat(billed_duration) * this.arTimeEntryAttributes[numLineItem]['customer_invoice']['unit_price']);
      this.arTimeEntryAttributes[numLineItem]['amount'] = toFormattedNumber(totalAmount, {
        currency: true,
      });
      this.arTimeEntryAttributes[numLineItem]['time_entry']['billed_duration'] = _.toString(toFormattedNumber(billed_duration, {
        currency: true,
      }));
      this.setAmountDue();
    }
    this.markAsDirty();
  }

  /**
   * Compute data when unit price is change
   * @param event
   * @param attr
   * @param strModule
   */
  unitPriceChange(event, attr, strModule) {
    // Find the index of the line item that was selected.
    let numLineItem = -1;
    switch(strModule) {
      case 'time_entry':
        numLineItem = this.arTimeEntryAttributes.findIndex(line => (line.id_type == attr.id_type));
        if (numLineItem > -1) {
          let unitPrice = (event.target.value != '' && this.arTimeEntryAttributes[numLineItem]['customer_invoice']['exclude_from_invoice'] === false) ? event.target.value : '0.00';
          let totalAmount: number = (parseFloat(unitPrice) * this.arTimeEntryAttributes[numLineItem]['time_entry']['billed_duration']);
          this.arTimeEntryAttributes[numLineItem]['amount'] = toFormattedNumber(totalAmount, {
            currency: true,
          });
          this.arTimeEntryAttributes[numLineItem]['customer_invoice']['unit_price'] = _.toString(toFormattedNumber(unitPrice, {
            currency: true,
            maxDecimalPlaces: 4,
          }));
          this.setAmountDue();
        }
      break;
      case 'material':
        numLineItem = this.arMaterialEntryAttributes.findIndex(line => (line.id_type == attr.id_type));
        if (numLineItem > -1) {
          let unitPrice = (event.target.value != '') ? event.target.value : 0;
          let unitCost = parseFloat(this.arMaterialEntryAttributes[numLineItem]['material_entry']['unit_cost']);
          let markUp = (((parseFloat(unitPrice) - unitCost) / unitCost) * 100);
          markUp = (isNaN(markUp) || ! isFinite(markUp)) ? 0 : markUp;
          this.arMaterialEntryAttributes[numLineItem]['material_entry']['markup'] = _.toString(toFormattedNumber(markUp));
          this.arMaterialEntryAttributes[numLineItem]['material_entry']['unit_price'] = _.toString(toFormattedNumber(unitPrice, {
            currency: true,
            maxDecimalPlaces: 4,
          }));
          this.arMaterialEntryAttributes[numLineItem]['total_price'] = (this.arMaterialEntryAttributes[numLineItem]['material_entry']['exclude_from_invoice'] === false) ? toFormattedNumber(unitPrice * this.arMaterialEntryAttributes[numLineItem]['material_entry']['quantity'], {
            currency: true,
          }) : 0;
          this.arMaterialEntryAttributes[numLineItem]['material_entry']['total_price'] = (this.arMaterialEntryAttributes[numLineItem]['material_entry']['exclude_from_invoice'] === false) ? toFormattedNumber(unitPrice * this.arMaterialEntryAttributes[numLineItem]['material_entry']['quantity'], {
            currency: true,
          }) : 0;
          this.setAmountDue();
        }
      break;
      case 'invoice_line':
        numLineItem = this.arInvoiceLineAttributes.findIndex(line => (line.id_type == attr.id_type));
        if (numLineItem > -1) {
          let unitPrice = (event.target.value != '') ? event.target.value : 0;
          this.arInvoiceLineAttributes[numLineItem]['invoice_line']['unit_price'] = _.toString(toFormattedNumber(unitPrice, {
            currency: true,
            maxDecimalPlaces: 4,
          }));
          this.arInvoiceLineAttributes[numLineItem]['amount'] = toFormattedNumber(unitPrice * this.arInvoiceLineAttributes[numLineItem]['invoice_line']['quantity'], {
            currency: true,
          });
          this.setAmountDue();
        }
      break;
    }
    this.markAsDirty();
  }

  /**
   * Compute unit price and total price when markup is change (this is for materials)
   * @param event
   * @param attr
   */
  markupChange(event, attr) {
    // Find the index of the line item that was selected.
    let numLineItem = this.arMaterialEntryAttributes.findIndex(line => (line.id_type == attr.id_type));
    if (numLineItem > -1) {
      let markUp = (event.target.value != '') ? event.target.value : 0;
      let unitCost = parseFloat(this.arMaterialEntryAttributes[numLineItem]['material_entry']['unit_cost']);
      let unitPrice = (unitCost + ( (parseFloat(markUp) / 100) * unitCost ));
      let totalPrice: number = (unitPrice * this.arMaterialEntryAttributes[numLineItem]['material_entry']['quantity']);
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['unit_price'] = _.toString(toFormattedNumber(unitPrice, {
        currency: true,
        maxDecimalPlaces: 4,
      }));
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['markup'] = _.toString(toFormattedNumber(markUp));
      this.arMaterialEntryAttributes[numLineItem]['total_price'] = (this.arMaterialEntryAttributes[numLineItem]['material_entry']['exclude_from_invoice'] === false) ? toFormattedNumber(totalPrice, {
        currency: true,
      }) : 0;
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['total_price'] = (this.arMaterialEntryAttributes[numLineItem]['material_entry']['exclude_from_invoice'] === false) ? toFormattedNumber(totalPrice) : 0;
      this.setAmountDue();
    }
    this.markAsDirty();
  }

  /**
   * Compute unit price, total cost and total price when unit cost is change (this is for materials)
   * @param event
   * @param attr
   */
  unitCostChange(event, attr) {
    // Find the index of the line item that was selected.
    let numLineItem = this.arMaterialEntryAttributes.findIndex(line => (line.id_type == attr.id_type));
    if (numLineItem > -1) {
      let unitCost = (event.target.value != '') ? event.target.value : 0;
      let intMarkUp: number = parseFloat(this.arMaterialEntryAttributes[numLineItem]['material_entry']['markup']);
      let markUp = (intMarkUp == NaN || intMarkUp == Infinity) ? 0 : intMarkUp;
      let unitPrice = (parseFloat(unitCost) + ((markUp / 100) * parseFloat(unitCost)));
      let totalPrice: number = (unitPrice * this.arMaterialEntryAttributes[numLineItem]['material_entry']['quantity']);
      let totalCost: number = (parseFloat(unitCost) * this.arMaterialEntryAttributes[numLineItem]['material_entry']['quantity']);
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['unit_price'] = _.toString(toFormattedNumber(unitPrice, {
        currency: true,
        maxDecimalPlaces: 4,
      }));
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['unit_cost'] = _.toString(toFormattedNumber(unitCost, {
        currency: true,
        maxDecimalPlaces: 4,
      }));
      this.arMaterialEntryAttributes[numLineItem]['total_cost'] = (this.arMaterialEntryAttributes[numLineItem]['material_entry']['exclude_from_invoice'] === false) ? toFormattedNumber(totalCost, {
        currency: true,
      }) : 0;
      this.arMaterialEntryAttributes[numLineItem]['total_price'] = (this.arMaterialEntryAttributes[numLineItem]['material_entry']['exclude_from_invoice'] === false) ? toFormattedNumber(totalPrice, {
        currency: true,
      }) : 0;
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['total_cost'] = (this.arMaterialEntryAttributes[numLineItem]['material_entry']['exclude_from_invoice'] === false) ? toFormattedNumber(totalCost, {
        currency: true,
      }) : 0;
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['total_price'] = (this.arMaterialEntryAttributes[numLineItem]['material_entry']['exclude_from_invoice'] === false) ? toFormattedNumber(totalPrice, {
        currency: true,
      }) : 0;
      this.setAmountDue();
    }
    this.markAsDirty();
  }

  /**
   * Compute total cost, price and amount when quantity is change
   * @param event
   * @param attr
   * @param strModule
   */
  quantityChange(event, attr, strModule) {
    // Find the index of the line item that was selected.
    let numLineItem = -1;

    switch(strModule) {
      case 'material':
        numLineItem = this.arMaterialEntryAttributes.findIndex(line => (line.id_type == attr.id_type));
        if (numLineItem > -1) {
          let quantity = (event.target.value != '' && event.target.value > 0) ? event.target.value : 1;
          let totalCost: number = parseFloat(this.arMaterialEntryAttributes[numLineItem]['material_entry']['unit_cost']) * parseFloat(quantity);
          let totalPrice: number = parseFloat(this.arMaterialEntryAttributes[numLineItem]['material_entry']['unit_price']) * parseFloat(quantity);
          this.arMaterialEntryAttributes[numLineItem]['material_entry']['quantity'] = _.toString(toFormattedNumber(quantity));
          this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['quantity'] = _.toString(toFormattedNumber(quantity));
          this.arMaterialEntryAttributes[numLineItem]['total_cost'] = (this.arMaterialEntryAttributes[numLineItem]['material_entry']['exclude_from_invoice'] === false) ? toFormattedNumber(totalCost, {
            currency: true,
          }) : 0;
          this.arMaterialEntryAttributes[numLineItem]['total_price'] = (this.arMaterialEntryAttributes[numLineItem]['material_entry']['exclude_from_invoice'] === false) ? toFormattedNumber(totalPrice, {
            currency: true,
          }) : 0;
          this.arMaterialEntryAttributes[numLineItem]['material_entry']['total_cost'] = (this.arMaterialEntryAttributes[numLineItem]['material_entry']['exclude_from_invoice'] === false) ? toFormattedNumber(totalCost, {
            currency: true,
          }) : 0;
          this.arMaterialEntryAttributes[numLineItem]['material_entry']['total_price'] = (this.arMaterialEntryAttributes[numLineItem]['material_entry']['exclude_from_invoice'] === false) ? toFormattedNumber(totalPrice, {
            currency: true,
          }) : 0;
          this.setAmountDue();
        }
      break;
      case 'invoice_line':
        numLineItem = this.arInvoiceLineAttributes.findIndex(line => (line.id_type == attr.id_type));
        if (numLineItem > -1) {
          let quantity = (event.target.value != '') ? event.target.value : 0;
          let totalPrice: number = parseFloat(this.arInvoiceLineAttributes[numLineItem]['invoice_line']['unit_price']) * parseFloat(quantity);
          this.arInvoiceLineAttributes[numLineItem]['invoice_line']['quantity'] = _.toString(toFormattedNumber(quantity))
          this.arInvoiceLineAttributes[numLineItem]['amount'] = toFormattedNumber(totalPrice, {
            currency: true,
          });
          this.setAmountDue();
        }
      break;
    }
    this.markAsDirty();
  }

  /**
   * Set default date invoice due based on config
   * @param event
   */
  dateInvoiceFieldChange(event) {

    let currentClientId = this.clientStoreService.getActiveClient().client_id;
    let currentClientList = this.clientsListStoreService.getClientList();
    let strInvoiceDueDateConfig = currentClientList[currentClientId]['invoice_due_date'];
    let strInvoiceDueDate  = moment();

    switch (strInvoiceDueDateConfig) {
      case 'days_7':
        strInvoiceDueDate = moment(this.dateInvoiceField).add(7,'days').format('YYYY-MM-DD');
      break;
      case 'days_14':
        strInvoiceDueDate = moment(this.dateInvoiceField).add(14,'days').format('YYYY-MM-DD');
      break;
      case 'days_30':
        strInvoiceDueDate = moment(this.dateInvoiceField).add(30,'days').format('YYYY-MM-DD');
      break;
      case 'last_day_of_this_month':
        strInvoiceDueDate = moment(this.dateInvoiceField).endOf('month').format('YYYY-MM-DD');
      break;
      case 'first_day_of_next_month':
        strInvoiceDueDate = moment(this.dateInvoiceField).add(1,'months').startOf('month').format('YYYY-MM-DD');
      break;
      case 'last_day_of_next_month':
        strInvoiceDueDate = moment(this.dateInvoiceField).add(1,'months').endOf('month').format('YYYY-MM-DD');
      break;
    }

    this.dateInvoiceDueField = moment.utc(strInvoiceDueDate).toDate();
  }


  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectUser(event, attr) {
    // Find the index of the line item that was selected.
    let numLineItem = this.arTimeEntryAttributes.findIndex(line => (line.id_type == attr.id_type));
    // If the item is found.
    if (numLineItem > -1) {
      if (event == null || event == undefined || event == '') {
        // Set the values of the line item.
        this.arTimeEntryAttributes[numLineItem]['time_entry']['user_id'] = '';
      } else {
        // Set the values of the line item.
        this.arTimeEntryAttributes[numLineItem]['time_entry']['user_id'] = event['id'];
        this.arTimeEntryAttributes[numLineItem]['time_entry']['user_text'] = event['text'];
      }
    }
    this.markAsDirty();
    this.groupBy();
  }

  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectProductTimeEntry(event, attr) {
    // merge defaults
    event = Object.assign({
      unit_price: 0,
      unit_cost: 0,
      description: '',
    }, event);

    let strCustomerId = this.getCurrentCustomerId();

    if (strCustomerId !== null) {
      event['unit_price'] = either(event, ['pricebook_unit_price', 'unit_price'], {
        default_value: 0,
      });
    }

    let arTaxCode: [] | {} = [];
    let arAccountCode: [] | {} = [];
    let arDefaultItemAccounting;

    if (this.strViewType === 'edit') {
      arDefaultItemAccounting = this.arrService.keyFallsBackTo(this.customerInvoiceRecord['related_data'], 'default_item_accounting', []);
    } else {
      arDefaultItemAccounting = this.arrService.keyFallsBackTo(this.objModuleConfig['related_data'], 'default_item_accounting', []);
    }

    let strDefaultItemAccountingId: string = (arDefaultItemAccounting) ? arDefaultItemAccounting['accounting_id'] : '';


    event['description'] = (this.arrService.keyFallsBackTo(event, 'description', '')) ? event['description'] : "";

    if (this.arrService.keyFallsBackTo(event, 'default_tax_code_id', '')) {
      arTaxCode = {
        'id' : event['tax_code_id'],
        'rate' : event['tax_rate'],
        'code' : event['tax_code'],
        'name' : event['tax_code_name'],
        'text' : event['tax_code_name']
      }
    } else {
      if (this.strViewType == 'edit') {
        if (this.customerInvoiceRecord['related_data'] != undefined && this.customerInvoiceRecord['related_data']['default_tax_code_sale'] != undefined) {
          arTaxCode = this.customerInvoiceRecord['related_data']['default_tax_code_sale'];
        }
      } else {
        if (this.objModuleConfig['related_data'] != undefined && this.objModuleConfig['related_data']['default_tax_code_sale'] != undefined) {
          arTaxCode = this.objModuleConfig['related_data']['default_tax_code_sale'];
        }
      }
    }

    if (this.arrService.keyFallsBackTo(event, 'default_account_code_id', '')) {
      arAccountCode = {
        'id' : event['account_code_id'],
        'code' : event['account_code'],
        'name' : event['account_code_name']
      }
    } else {
      if (this.strViewType == 'edit') {
        if (this.customerInvoiceRecord['related_data'] != undefined && this.customerInvoiceRecord['related_data']['default_account_code_sale'] != undefined) {
          arAccountCode = this.customerInvoiceRecord['related_data']['default_account_code_sale'];
        }
      } else {
        if (this.objModuleConfig['related_data'] != undefined && this.objModuleConfig['related_data']['default_account_code_sale'] != undefined) {
          arAccountCode = this.objModuleConfig['related_data']['default_account_code_sale'];
        }
      }
    }

    // Find the index of the line item that was selected.
    let numLineItem = this.arTimeEntryAttributes.findIndex(line => (line.id_type == attr.id_type));
    // If the item is found.
    if (numLineItem > -1) {
      // Set the values of the line item.
      let totalAmount: number = (parseFloat(this.arTimeEntryAttributes[numLineItem]['time_entry']['billed_duration']) * parseFloat(event['unit_price']));
      this.arTimeEntryAttributes[numLineItem]['amount'] = (this.arTimeEntryAttributes[numLineItem]['customer_invoice']['exclude_from_invoice'] === false) ? toFormattedNumber(totalAmount, {
        currency: true,
      }) : 0;
      this.arTimeEntryAttributes[numLineItem]['time_entry']['item_id'] = event['id'];
      this.arTimeEntryAttributes[numLineItem]['time_entry']['description'] = event['description'];
      this.arTimeEntryAttributes[numLineItem]['customer_invoice']['item_id'] = event['id'];
      this.arTimeEntryAttributes[numLineItem]['customer_invoice']['item_code'] = event['code'];
      this.arTimeEntryAttributes[numLineItem]['customer_invoice']['item_name'] = event['text'];
      this.arTimeEntryAttributes[numLineItem]['customer_invoice']['item_accounting_id'] = (event['accounting_id'] !== null && event['accounting_id'] !== '') ? event['accounting_id'] : strDefaultItemAccountingId;


      // Tax code relate data
      let strTaxRate = (this.arrService.keyFallsBackTo(arTaxCode, 'rate', 0)) ? parseFloat(arTaxCode['rate']) * 0.01 : 0;
      this.arTimeEntryAttributes[numLineItem]['customer_invoice']['unit_price'] = _.toString(toFormattedNumber(event['unit_price'], {
        currency: true,
      }));
      this.arTimeEntryAttributes[numLineItem]['customer_invoice']['tax_code_id'] = (this.arrService.keyFallsBackTo(arTaxCode, 'id', '')) ? arTaxCode['id'] : '';
      this.arTimeEntryAttributes[numLineItem]['customer_invoice']['tax_code_name'] = (this.arrService.keyFallsBackTo(arTaxCode, 'name', '')) ? arTaxCode['name'] : '';
      this.arTimeEntryAttributes[numLineItem]['customer_invoice']['tax_code'] = (this.arrService.keyFallsBackTo(arTaxCode, 'code', '')) ? arTaxCode['code'] : '';
      this.arTimeEntryAttributes[numLineItem]['customer_invoice']['tax_rate'] = (this.arrService.keyFallsBackTo(arTaxCode, 'rate', '')) ? arTaxCode['rate'] : '';
      this.arTimeEntryAttributes[numLineItem]['tax'] = (this.arrService.keyFallsBackTo(arTaxCode, 'code', '')) ? arTaxCode : null;
      this.arTimeEntryAttributes[numLineItem]['tax_rate'] = strTaxRate;
      // Account code relate data
      this.arTimeEntryAttributes[numLineItem]['customer_invoice']['account_code_id'] = (this.arrService.keyFallsBackTo(arAccountCode, 'id', '')) ? arAccountCode['id'] : '';
      this.arTimeEntryAttributes[numLineItem]['customer_invoice']['account_code'] = (this.arrService.keyFallsBackTo(arAccountCode, 'code', '')) ? arAccountCode['code'] : '';
      this.arTimeEntryAttributes[numLineItem]['customer_invoice']['account_code_name'] = (this.arrService.keyFallsBackTo(arAccountCode, 'name', '')) ? arAccountCode['name'] : '';
      this.arTimeEntryAttributes[numLineItem]['account_code'] = (this.arrService.keyFallsBackTo(arAccountCode, 'id', '')) ? arAccountCode : null;
      this.setAmountDue();
    }
    this.markAsDirty();
  }

  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectProductMaterialEntry(event, attr) {
    // merge defaults
    event = Object.assign({
      unit_price: 0,
      unit_cost: 0,
      description: '',
    }, event);

    let arTaxCode = null;
    let arAccountCode = null;
    let arDefaultItemAccounting;

    if (this.strViewType === 'edit') {
      arDefaultItemAccounting = this.arrService.keyFallsBackTo(this.customerInvoiceRecord['related_data'], 'default_item_accounting', []);
    } else {
      arDefaultItemAccounting = this.arrService.keyFallsBackTo(this.objModuleConfig['related_data'], 'default_item_accounting', []);
    }

    let strDefaultItemAccountingId: string = (arDefaultItemAccounting) ? arDefaultItemAccounting['accounting_id'] : '';

    if (this.arrService.keyFallsBackTo(event, 'default_tax_code_id', '')) {
      arTaxCode = {
        'id' : event['tax_code_id'],
        'rate' : event['tax_rate'],
        'code' : event['tax_code'],
        'name' : event['tax_code_name'],
        'text' : event['tax_code_name']
      }
    } else {
      if (this.strViewType == 'edit') {
        if (this.customerInvoiceRecord['related_data'] != undefined && this.customerInvoiceRecord['related_data']['default_tax_code_sale'] != undefined) {
          arTaxCode = this.customerInvoiceRecord['related_data']['default_tax_code_sale'];
        }
      } else {
        if (this.objModuleConfig['related_data'] != undefined && this.objModuleConfig['related_data']['default_tax_code_sale'] != undefined) {
          arTaxCode = this.objModuleConfig['related_data']['default_tax_code_sale'];
        }
      }
    }

    if (this.arrService.keyFallsBackTo(event, 'default_account_code_id', '')) {
      arAccountCode = {
        'id' : event['account_code_id'],
        'code' : event['account_code'],
        'name' : event['account_code_name']
      }
    } else {
      if (this.strViewType == 'edit') {
        if (this.customerInvoiceRecord['related_data'] != undefined && this.customerInvoiceRecord['related_data']['default_account_code_sale'] != undefined) {
          arAccountCode = this.customerInvoiceRecord['related_data']['default_account_code_sale'];
        }
      } else {
        if (this.objModuleConfig['related_data'] != undefined && this.objModuleConfig['related_data']['default_account_code_sale'] != undefined) {
          arAccountCode = this.objModuleConfig['related_data']['default_account_code_sale'];
        }
      }
    }


    let strCustomerId = this.getCurrentCustomerId();

    if (strCustomerId !== null) {
      event['unit_price'] = either(event, ['pricebook_unit_price', 'unit_price'], {
        default_value: 0,
      });
    }

    let unitCost: number = parseFloat(event['unit_cost']);
    let unitPrice: number = parseFloat(event['unit_price']);
    let markUp: number = (((unitPrice - unitCost) / unitCost) * 100);
    markUp = (isNaN(markUp) || ! isFinite(markUp)) ? 0 : markUp;
    event['description'] = _.get(event, 'description', '');

    // Find the index of the line item that was selected.
    let numLineItem = this.arMaterialEntryAttributes.findIndex(line => (line.id_type == attr.id_type));

    // If the item is found.
    if (numLineItem > -1) {
      // Set the values of the line item.
      let total_price: number = (parseFloat(this.arMaterialEntryAttributes[numLineItem]['material_entry']['quantity']) * unitPrice);
      let total_cost: number = (parseFloat(this.arMaterialEntryAttributes[numLineItem]['material_entry']['quantity']) * unitCost);

      this.arMaterialEntryAttributes[numLineItem]['total_price'] = (this.arMaterialEntryAttributes[numLineItem]['material_entry']['exclude_from_invoice'] === false) ? toFormattedNumber(total_price, {
        currency: true,
      }) : 0;
      this.arMaterialEntryAttributes[numLineItem]['total_cost'] = (this.arMaterialEntryAttributes[numLineItem]['material_entry']['exclude_from_invoice'] === false) ? toFormattedNumber(total_cost, {
        currency: true,
      }) : 0 ;
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['total_price'] = (this.arMaterialEntryAttributes[numLineItem]['material_entry']['exclude_from_invoice'] === false) ? toFormattedNumber(total_price, {
        currency: true,
      }) : 0;
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['total_cost'] = (this.arMaterialEntryAttributes[numLineItem]['material_entry']['exclude_from_invoice'] === false) ? toFormattedNumber(total_cost, {
        currency: true,
      }) : 0;

      this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['item_id'] = event['id'];
      this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['item_code'] = event['code'];
      this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['item_name'] = event['text'];
      this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['item_accounting_id'] = (this.arrService.keyFallsBackTo(event, 'accounting_id', '')) ? event['accounting_id'] : strDefaultItemAccountingId;

      this.arMaterialEntryAttributes[numLineItem]['material_entry']['item_id'] = event['id'];
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['product'] = event['product'];
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['unit_price'] = _.toString(toFormattedNumber(unitPrice, {
        currency: true,
      }));
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['unit_cost'] = _.toString(toFormattedNumber(unitCost, {
        currency: true,
      }));
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['markup'] = _.toString(toFormattedNumber(markUp));
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['notes'] = event['description'];

      // Tax code relate data
      let strTaxRate = (this.arrService.keyFallsBackTo(arTaxCode, 'rate', 0)) ? parseFloat(arTaxCode['rate']) * 0.01 : 0;
      this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['tax_code_id'] = (this.arrService.keyFallsBackTo(arTaxCode, 'id', '')) ? arTaxCode['id'] : '';
      this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['tax_code'] = (this.arrService.keyFallsBackTo(arTaxCode, 'code', '')) ? arTaxCode['code'] : '';
      this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['tax_code_name'] = (this.arrService.keyFallsBackTo(arTaxCode, 'name', '')) ? arTaxCode['name'] : '';
      this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['tax_rate'] = (this.arrService.keyFallsBackTo(arTaxCode, 'rate', '')) ? arTaxCode['rate'] : '';

      this.arMaterialEntryAttributes[numLineItem]['tax'] = (this.arrService.keyFallsBackTo(arTaxCode, 'id', '')) ? arTaxCode : null;
      this.arMaterialEntryAttributes[numLineItem]['tax_rate'] = strTaxRate;
      // Account code relate data
      this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['account_code_id'] = (this.arrService.keyFallsBackTo(arAccountCode, 'id', '')) ? arAccountCode['id'] : '';
      this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['account_code'] = (this.arrService.keyFallsBackTo(arAccountCode, 'code', '')) ? arAccountCode['code'] : '';
      this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['account_code_name'] = (this.arrService.keyFallsBackTo(arAccountCode, 'name', '')) ? arAccountCode['name'] : '';
      this.arMaterialEntryAttributes[numLineItem]['account_code'] = (this.arrService.keyFallsBackTo(arAccountCode, 'id', '')) ? arAccountCode : null;
      this.setAmountDue();
    }
    this.markAsDirty();
  }

  /**
   * When selecting a product from the dropdown.
   * @param event - the product/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectProductInvoiceLineEntry(event, attr) {
    // If product is cleared. Dont proceed to logic
    if (event === undefined || event === null) {
      return false;
    }

    const strCustomerId = this.getCurrentCustomerId();

    if (strCustomerId !== null) {
      event['unit_price'] = either(event, ['pricebook_unit_price', 'unit_price'], {
        default_value: 0,
      });
    }

    let arTaxCode: [] | {} = [];
    let arAccountCode: [] | {} = [];
    let strDefaultProductForAccountingSync = this.objClientRecord['default_item_id'];

    event['description'] = (this.arrService.keyFallsBackTo(event, 'description', '')) ? event['description'] : "";

    if (this.arrService.keyFallsBackTo(event, 'default_tax_code_id', '')) {
      arTaxCode = {
        'id' : event['tax_code_id'],
        'rate' : event['tax_rate'],
        'code' : event['tax_code'],
        'name' : event['tax_code_name'],
        'text' : event['tax_code_name']
      }
    } else {
      if (this.strViewType == 'edit') {
        if (this.customerInvoiceRecord['related_data'] != undefined && this.customerInvoiceRecord['related_data']['default_tax_code_sale'] != undefined) {
          arTaxCode = this.customerInvoiceRecord['related_data']['default_tax_code_sale'];
        }
      } else {
        if (this.objModuleConfig['related_data'] != undefined && this.objModuleConfig['related_data']['default_tax_code_sale'] != undefined) {
          arTaxCode = this.objModuleConfig['related_data']['default_tax_code_sale'];
        }
      }
    }

    if (this.arrService.keyFallsBackTo(event, 'default_account_code_id', '')) {
      arAccountCode = {
        'id' : event['account_code_id'],
        'code' : event['account_code'],
        'name' : event['account_code_name']
      }
    } else {
      if (this.strViewType == 'edit') {
        if (this.customerInvoiceRecord['related_data'] != undefined && this.customerInvoiceRecord['related_data']['default_account_code_sale'] != undefined) {
          arAccountCode = this.customerInvoiceRecord['related_data']['default_account_code_sale'];
        }
      } else {
        if (this.objModuleConfig['related_data'] != undefined && this.objModuleConfig['related_data']['default_account_code_sale'] != undefined) {
          arAccountCode = this.objModuleConfig['related_data']['default_account_code_sale'];
        }
      }
    }

    // Find the index of the line item that was selected.
    let numLineItem = this.arInvoiceLineAttributes.findIndex(line => (line.id_type == attr.id_type));
    // If the item is found.
    if (numLineItem > -1) {
      // Set the values of the line item.
      let totalAmount: number = (parseFloat(this.arInvoiceLineAttributes[numLineItem]['invoice_line']['quantity']) * parseFloat(event['unit_price']));
      this.arInvoiceLineAttributes[numLineItem]['amount'] = toFormattedNumber(totalAmount, {
        currency: true,
      });
      this.arInvoiceLineAttributes[numLineItem]['invoice_line']['item_id'] = event['id'];
      this.arInvoiceLineAttributes[numLineItem]['invoice_line']['description'] = event['description'];
      this.arInvoiceLineAttributes[numLineItem]['invoice_line']['item_code'] = event['code'];
      this.arInvoiceLineAttributes[numLineItem]['invoice_line']['item_accounting_id'] = (event['accounting_id'] !== null && event['accounting_id'] !== '') ? event['accounting_id'] : strDefaultProductForAccountingSync;


      // Tax code relate data
      let strTaxRate = (this.arrService.keyFallsBackTo(arTaxCode, 'rate', 0)) ? parseFloat(arTaxCode['rate']) * 0.01 : 0;
      this.arInvoiceLineAttributes[numLineItem]['invoice_line']['unit_price'] = _.toString(toFormattedNumber(event['unit_price'], {
        currency: true,
      }));
      this.arInvoiceLineAttributes[numLineItem]['invoice_line']['tax_code_id'] = (this.arrService.keyFallsBackTo(arTaxCode, 'id', '')) ? arTaxCode['id'] : '';
      this.arInvoiceLineAttributes[numLineItem]['invoice_line']['tax_code'] = (this.arrService.keyFallsBackTo(arTaxCode, 'code', '')) ? arTaxCode['code'] : '';
      this.arInvoiceLineAttributes[numLineItem]['invoice_line']['tax_code_name'] = (this.arrService.keyFallsBackTo(arTaxCode, 'name', '')) ? arTaxCode['name'] : '';
      this.arInvoiceLineAttributes[numLineItem]['invoice_line']['tax_rate'] = (this.arrService.keyFallsBackTo(arTaxCode, 'rate', '')) ? arTaxCode['rate'] : '';
      this.arInvoiceLineAttributes[numLineItem]['tax'] = (this.arrService.keyFallsBackTo(arTaxCode, 'code', '')) ? arTaxCode : null;
      this.arInvoiceLineAttributes[numLineItem]['tax_rate'] = strTaxRate;
      // Account code relate data
      this.arInvoiceLineAttributes[numLineItem]['invoice_line']['account_code_id'] = (this.arrService.keyFallsBackTo(arAccountCode, 'id', '')) ? arAccountCode['id'] : '';
      this.arInvoiceLineAttributes[numLineItem]['invoice_line']['account_code'] = (this.arrService.keyFallsBackTo(arAccountCode, 'code', '')) ? arAccountCode['code'] : '';
      this.arInvoiceLineAttributes[numLineItem]['invoice_line']['account_code_name'] = (this.arrService.keyFallsBackTo(arAccountCode, 'name', '')) ? arAccountCode['name'] : '';
      this.arInvoiceLineAttributes[numLineItem]['account_code'] = (this.arrService.keyFallsBackTo(arAccountCode, 'id', '')) ? arAccountCode : null;
      this.setAmountDue();
    }

    this.markAsDirty();
  }

  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectWorkDate(event, attr) {

    let startDate = (attr['work_date'][0] != undefined && attr['work_date'][0] != null) ? attr['work_date'][0] : '';
    let endDate = (attr['work_date'][1] != undefined && attr['work_date'][1] != null) ? attr['work_date'][1] : '';
    let workDateDiff = this.calculateDateDiff(startDate, endDate);

    // Find the index of the line item that was selected.
    let numLineItem = this.arTimeEntryAttributes.findIndex(line => (line.id_type == attr.id_type));

    // If the item is found.
    if (numLineItem > -1 && workDateDiff != undefined) {
      let minutes = parseFloat(workDateDiff.minutes) * (1 / 60); // Formula: minutes * (hour / 60 minutes)
      let formattedHours = parseFloat(workDateDiff.hours) + minutes;
      let formattedDuration = _.toString(toFormattedNumber(formattedHours));

      // Set the values of the line item.
      this.arTimeEntryAttributes[numLineItem]['time_entry']['start_time'] = moment.utc(startDate).format('YYYY-MM-DD HH:mm:ss');
      this.arTimeEntryAttributes[numLineItem]['time_entry']['end_time'] = moment.utc(endDate).format('YYYY-MM-DD HH:mm:ss');
      this.arTimeEntryAttributes[numLineItem]['time_entry']['actual_duration'] = formattedDuration;
      this.arTimeEntryAttributes[numLineItem]['time_entry']['billed_duration'] = formattedDuration;
      this.arTimeEntryAttributes[numLineItem]['amount'] = (this.arTimeEntryAttributes[numLineItem]['customer_invoice']['exclude_from_invoice'] === false) ? toFormattedNumber(formattedHours * parseFloat(this.arTimeEntryAttributes[numLineItem]['customer_invoice']['unit_price']), {
        currency: true,
      }) : 0;
      this.setAmountDue();
    }

    this.markAsDirty();
  }

  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectATaxcode(event, attr, strModule) {
    let strTaxCodeId = (event == undefined || event == null) ? null : event['id'];
    let strTaxName = (event == undefined || event == null) ? null : event['name'];
    let strTaxCode = (event == undefined || event == null) ? null : event['code'];
    let strTaxRate = (event == undefined || event == null) ? 0 : parseFloat(event['rate']) * 0.01;

    if (strModule == 'time_entry') {
      // Find the index of the line item that was selected.
      let numLineItem = this.arTimeEntryAttributes.findIndex(line => (line.id_type == attr.id_type));

      // If the item is found.
      if (numLineItem > -1) {
          // Set the values of the line item.
          this.arTimeEntryAttributes[numLineItem]['customer_invoice']['tax_code_id'] = strTaxCodeId;
          this.arTimeEntryAttributes[numLineItem]['customer_invoice']['tax_code'] = strTaxCode;
          this.arTimeEntryAttributes[numLineItem]['customer_invoice']['tax_code_name'] = strTaxName;
          this.arTimeEntryAttributes[numLineItem]['customer_invoice']['tax_rate'] = parseFloat(event['rate']);
          this.arTimeEntryAttributes[numLineItem]['tax_rate'] = strTaxRate;
          this.setAmountDue();
      }
    } else if (strModule == 'materials') {
        // Find the index of the line item that was selected.
        let numLineItem = this.arMaterialEntryAttributes.findIndex(line => (line.id_type == attr.id_type));
        // If the item is found.
        if (numLineItem > -1) {
            // Set the values of the line item.
            this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['tax_code_id'] = strTaxCodeId;
            this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['tax_code'] = strTaxCode;
            this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['tax_code_name'] = strTaxName;
            this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['tax_rate'] = parseFloat(event['rate']);
            this.arMaterialEntryAttributes[numLineItem]['tax_rate'] = strTaxRate;
            this.setAmountDue();
        }
    } else if (strModule == 'invoice_line') {
      // Find the index of the line item that was selected.
      let numLineItem = this.arInvoiceLineAttributes.findIndex(line => (line.id_type == attr.id_type));
      // If the item is found.
      if (numLineItem > -1) {
          // Set the values of the line item.
          this.arInvoiceLineAttributes[numLineItem]['invoice_line']['tax_code_id'] = strTaxCodeId;
          this.arInvoiceLineAttributes[numLineItem]['invoice_line']['tax_code'] = strTaxCode;
          this.arInvoiceLineAttributes[numLineItem]['invoice_line']['tax_code_name'] = strTaxName;
          this.arInvoiceLineAttributes[numLineItem]['invoice_line']['tax_rate'] = parseFloat(event['rate']);
          this.arInvoiceLineAttributes[numLineItem]['tax_rate'] = strTaxRate;
          this.setAmountDue();
      }
    }
    this.markAsDirty();
  }
  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectAccountCode(event, attr, strModule) {
    let strAccountCodeId = (event == undefined || event == null) ? null : event['id'];
    let strAccountName = (event == undefined || event == null) ? null : event['name'];
    let strAccountCode = (event == undefined || event == null) ? null : event['code'];

    if (strModule == 'time_entry') {
      // Find the index of the line item that was selected.
      let numLineItem = this.arTimeEntryAttributes.findIndex(line => (line.id_type == attr.id_type));
      // If the item is found.
      if (numLineItem > -1) {
          this.arTimeEntryAttributes[numLineItem]['customer_invoice']['account_code_id'] = strAccountCodeId;
          this.arTimeEntryAttributes[numLineItem]['customer_invoice']['account_code'] = strAccountCode;
          this.arTimeEntryAttributes[numLineItem]['customer_invoice']['account_code_name'] = strAccountName;
      }
    } else if(strModule == 'materials') {
      // Find the index of the line item that was selected.
      let numLineItem = this.arMaterialEntryAttributes.findIndex(line => (line.id_type == attr.id_type));
      // If the item is found.
      if (numLineItem > -1) {
          this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['account_code_id'] = strAccountCodeId;
          this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['account_code'] = strAccountCode;
          this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['account_code_name'] = strAccountName;
      }
    } else if (strModule == 'invoice_line') {
       // Find the index of the line item that was selected.
       let numLineItem = this.arInvoiceLineAttributes.findIndex(line => (line.id_type == attr.id_type));
       // If the item is found.
       if (numLineItem > -1) {
           this.arInvoiceLineAttributes[numLineItem]['invoice_line']['account_code_id'] = strAccountCodeId;
           this.arInvoiceLineAttributes[numLineItem]['invoice_line']['account_code'] = strAccountCode;
           this.arInvoiceLineAttributes[numLineItem]['invoice_line']['account_code_name'] = strAccountName;
       }
    }
    this.markAsDirty();
  }

  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectDepartment(event, attr, strModule) {
    let strDepartmentCodeId = (event == undefined || event == null) ? null : event['id'];
    let strDepartmentName = (event == undefined || event == null) ? null : event['department_name'];
    let numLineItem = -1;
    if (strModule == 'time_entries') {
      // Find the index of the line item that was selected.
      numLineItem = this.arTimeEntryAttributes.findIndex(line => (line.id_type == attr.id_type));
    } else if (strModule == 'materials') {
      // Find the index of the line item that was selected.
      numLineItem = this.arMaterialEntryAttributes.findIndex(line => (line.id_type == attr.id_type));
    } else {
      // Find the index of the line item that was selected.
      numLineItem = this.arInvoiceLineAttributes.findIndex(line => (line.id_type == attr.id_type));
    }

    // If the item is found.
    if (numLineItem > -1) {
      if (strModule == 'time_entries') {
        // Set the values of the line item.
        this.arTimeEntryAttributes[numLineItem]['customer_invoice']['department_id'] = strDepartmentCodeId;
        this.arTimeEntryAttributes[numLineItem]['customer_invoice']['department_name'] = strDepartmentName;
      } else if (strModule == 'materials') {
        // Set the values of the line item.
        this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['department_id'] = strDepartmentCodeId;
        this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['department_name'] = strDepartmentName;
      } else {
        // Set the values of the line item.
        this.arInvoiceLineAttributes[numLineItem]['invoice_line']['department_id'] = strDepartmentCodeId;
        this.arInvoiceLineAttributes[numLineItem]['invoice_line']['department_name'] = strDepartmentName;
      }
    }
    this.markAsDirty();
  }

  /**
   * Compute Total ExcludedTax
   */
  computeExcludedTax() {
    let computedExcludedTax: number = 0;

    this.arTimeEntryAttributes.forEach( data => {
      if (data['amount'] != undefined && !isNaN(data['amount'])) {
        computedExcludedTax = computedExcludedTax + parseFloat(data['amount']);
      }
    });

    this.arMaterialEntryAttributes.forEach( data => {
      if (data['total_price'] != undefined && !isNaN(data['total_price'])) {
        computedExcludedTax = computedExcludedTax + parseFloat(data['total_price']);
      }
    });

    return toFormattedNumber(computedExcludedTax, {
      currency: true,
    });
  }
  /**
   * Compute Total Tax
   */
  computeTax() {
    let computedTax: number = 0;

    this.arTimeEntryAttributes.forEach( data => {
      computedTax += parseFloat(data.amount) * parseFloat(data.tax_rate);
    });

    this.arMaterialEntryAttributes.forEach( data => {
      computedTax += parseFloat(data.total_price) * parseFloat(data.tax_rate);
    });

    return toFormattedNumber(computedTax , {
      currency: true,
    });
  }

  /**
   * Compute Total Included Tax
   */
  computeIncludedTax() {
    let computedIncludedTax: number = this.computeExcludedTax() + this.computeTax();

    if (!isNaN(computedIncludedTax)){
      return toFormattedNumber(computedIncludedTax + _.toNumber(this.totalTaxAdjustment), {
        currency: true,
      });
    } else {
      return 0;
    }
  }

  /**
   * Compute Total Invoice Line Tax
   */
  computeInvoiceLineTax() {
    let computedTax: number = 0;

    this.arInvoiceLineAttributes.forEach( data => {
      computedTax += parseFloat(data.amount) * parseFloat(data.tax_rate);
    });

    return toFormattedNumber(computedTax, {
      currency: true,
    });
  }

  /**
   * Compute Total Excluded Tax (Invoice Line Items Layout)
   */
  computeInvoiceLineExcludedTax() {
    let computedExcludedTax: number = 0;

    this.arInvoiceLineAttributes.forEach( data => {
        computedExcludedTax += parseFloat(data.amount);
    });

    return toFormattedNumber(computedExcludedTax, {
      currency: true,
    });
  }

  /**
   * Compute Total Included Tax (Invoice Line Items Layout)
   */
  computeInvoiceLineIncludedTax() {
    let computedIncludedTax: number = this.computeInvoiceLineExcludedTax() + this.computeInvoiceLineTax();

    return toFormattedNumber(computedIncludedTax + _.toNumber(this.totalTaxAdjustment), {
      currency: true,
    });
  }

  /**
   * Save Invoice
   */
  saveInvoice() {
    this.hasError = false;

    // Get the raw value of the form.
    let objInvoice = this.compileSaveRecordData();

    if (objInvoice === false) {
      this.isPreviewTemplate = false;
      return;
    }

    this.isTouched = true;
    this.arReadOnlyId = [];
    this.arRelateEmptyId = [];
    this.arRequiredField = [];

    if (!this.hasError) {

      if (this.pdfPreview == true) {
        this.openPreview(objInvoice);
      } else {
        if (this.strViewType == 'edit' && this.data['invoice'] != undefined) {
          if(this.customerInvoiceRecord['related_data'] != undefined) {
            this.whenInProgress$.next(true);
            this.bSubmitted = true;

            this.recordService.saveRecord('customer_invoices', objInvoice, this.data['invoice']['id']).pipe(
              finalize(() => {
                this.bSubmitted = false;
                this.whenInProgress$.next(false);

                if (this.isPreviewTemplate) {
                  this.isPreviewTemplate = false;
                }
              })
            ).subscribe( res => {
              if (res.status == 200) {
                this.formService.removeUnsavedChangesDataToLocalStorage(_.get(this.data, 'customer_invoice_id', ''), 'customer_invoices', this.strRecordId);
                this.displayTemplate(this.data['invoice']['id']);
                if (this.strRecordModule == 'jobs') {
                  this.dialogRef.close({
                    action: 'save',
                    response: res.body,
                    invoice: objInvoice,
                  });
                } else {
                  this.notifyUnusedMaterial(objInvoice, res);
                }
              } else if (res.status == 202 && res.body.error !== undefined) {
                this.notifService.promptError(res.body.error);
              } else {
                  this.dialogRef.close({
                    action: 'fail',
                  });
              }
            });
          }
        } else {
          this.whenInProgress$.next(true);
          this.bSubmitted = true;

          // Status default to created
          objInvoice['status'] = 'created';

          // Save customer invoice
          this.recordService.saveRecord('customer_invoices', objInvoice, '').pipe(
            finalize(() => {
              this.bSubmitted = false;
              this.whenInProgress$.next(false);

              if (this.isPreviewTemplate) {
                this.isPreviewTemplate = false;
              }
            })
          ).subscribe( res => {
            if (res.status === 202) {
              if (res.body.error !== undefined) {
                this.notifService.promptError(res.body.error);
              } else {
                this.notifService.sendNotification('warning', 'invalid_next_customer_invoice_number', 'warning', 6000);
              }
            } else if (res.body.id != undefined) {
              this.formService.removeUnsavedChangesDataToLocalStorage(_.get(this.data, 'customer_invoice_id', ''), 'customer_invoices', this.strRecordId);
              this.displayTemplate(res.body.id);
              if (this.strRecordModule == 'jobs') {
                this.dialogRef.close({
                  action: 'save',
                  response: res.body,
                  invoice: objInvoice,
                });
              } else {
                this.notifyUnusedMaterial(objInvoice, res);
              }
            }
          });
        }
      }
    } else {
      this.notifService.sendNotification('not_allowed', 'required_notice', 'danger');
      this.bSubmitted = false;
      this.pdfPreview = false;
      this.isPreviewTemplate = false;
      this.whenInProgress$.next(false);
    }
  }
  /**
   * Compare the old data to the requested data
   * @param objInvoice - Requested data
   * @param relateData = Related Data
   */
  compareOldData(objInvoice, relateData = []) {

    if (relateData.length > 0) {
      relateData.forEach( strData => {
        let numLineItem = objInvoice['line_items'].findIndex(line => (line.relate_id == strData.id));
        if (numLineItem > -1) {
          let arLineItemField = Object.keys(objInvoice['line_items'][numLineItem]['relate_data']);
          arLineItemField.forEach( field => {
            let strOldValue = (strData[field] != undefined) ? strData[field] : '';
            let strNewValue = objInvoice['line_items'][numLineItem]['relate_data'][field];
            if (strOldValue != strNewValue || (strData['customer_invoice_id'] == '' || strData['customer_invoice_id'] == null)) {
              objInvoice['line_items'][numLineItem]['for_update'] = true;
              this.bHasChanged = true;
            }
          });
        }
      });
    } else {
      let objInvoiceFields = Object.keys(objInvoice);
      objInvoiceFields.forEach( strField => {
          if (strField != 'line_items') {
            let strOriginalDate = '';
            // Need to format the date just for comparing
            if (strField == 'date_invoice' || strField == 'date_due') {
              strOriginalDate = objInvoice[strField];
              this.data.invoice[strField] = this.formatDate(this.data.invoice[strField]);
              objInvoice[strField] = this.formatDate(objInvoice[strField]);
            }

            // Due to in objInvoice relate id set as null if its empty. We set the old data also as null
            if (this.arRelateEmptyId.indexOf(strField) > -1) {
              if (this.data.invoice[strField] == '') {
                this.data.invoice[strField] = null;
              }
            }

            if (strField == 'job_id' && this.data.invoice[strField] == '') {
              this.data.invoice[strField] = null;
            }

            if (this.data.invoice[strField] != objInvoice[strField]) {
              this.bHasChanged = true;
            }
            // Then after the checking of date set the old date format
            if (strField == 'date_invoice' || strField == 'date_due') {
              objInvoice[strField] = strOriginalDate;
            }
          } else {
            if (objInvoice[strField].length != this.data.invoice[strField].length) {
              this.bHasChanged = true;
            } else {
              objInvoice[strField].forEach( (data, index) => {
                let objLineItemsField = Object.keys(data['customer_invoice']);
                objLineItemsField.forEach( strLineItemField => {
                  if (this.data.invoice[strField][index] == undefined) {
                    this.bHasChanged = true;
                  } else {
                    if (this.data.invoice[strField][index][strLineItemField] != data['customer_invoice'][strLineItemField]) {
                      this.bHasChanged = true;
                    }
                  }
                });
              });
            }
          }


      });
    }
  }

  /**
   * Initialize relate fields
   * @param strAttributeName - object attribute
   * @param strModule - module for relate
   * @param strObservableName - observable that is set
   * @param objMainFilter - Main filter for record (onChange of the fields)
   * @param arRelateInitialValue - Initial relate value
   */
  initRelateRecords(strAttributeName, strModule, strObservableName, objMainFilter, arRelateInitialValue, curentIndex: number | string = '', objLineItem: LooseObject = undefined) {
    // Get the lastest index.
    let newIndex = (curentIndex === '') ? this[strAttributeName].length - 1 : curentIndex;
    let strObservable = strObservableName + '_obv';
    let strTypehead = strObservableName + '_typehead';
    let strLoader = strObservableName + '_loader';
    if (strModule === 'items') {
      const strCustomerId = this.getCurrentCustomerId();

      // Set a default dropdown value for this line item.
      this[strAttributeName][newIndex][strObservable] = concat(
        of(arRelateInitialValue),
        this[strAttributeName][newIndex][strTypehead].pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => this[strAttributeName][newIndex][strLoader] = true),
          switchMap(term => this.recordService.getProductRecordData(term, objMainFilter, strCustomerId).pipe(
            tap(() => {
              this[strAttributeName][newIndex][strLoader] = false;
            })
          ))
        )
      )
    } else if(['tax_codes', 'account_codes', 'departments'].includes(strModule)) {

      if (objLineItem != undefined) {
        objLineItem[strObservable] = concat(
          of(arRelateInitialValue),
          objLineItem[strTypehead].pipe(
            debounceTime(400),
            distinctUntilChanged(),
            tap(() => objLineItem[strLoader] = true),
            switchMap(term =>
              this.recordService.getFilterData(term, arRelateInitialValue).pipe(
                tap(() => {
                  objLineItem[strLoader] = false;
                })
              )
            )
          )
        );

      } else {
        // Set a default dropdown value for this line item.
        this[strAttributeName][newIndex][strObservable] = concat(
          of(arRelateInitialValue),
          this[strAttributeName][newIndex][strTypehead].pipe(
            debounceTime(400),
            distinctUntilChanged(),
            tap(() => this[strAttributeName][newIndex][strLoader] = true),
            switchMap(term =>
              this.recordService.getFilterData(term, arRelateInitialValue).pipe(
                tap(() => {
                  this[strAttributeName][newIndex][strLoader] = false;
                })
              )
            )
          )
        );
      }

    } else {
      // Set a default dropdown value for this line item.
      this[strAttributeName][newIndex][strObservable] = concat(
        of(arRelateInitialValue),
        this[strAttributeName][newIndex][strTypehead].pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => this[strAttributeName][newIndex][strLoader] = true),
          switchMap(term => this.recordService.getRecordRelate(strModule, term, '', false, objMainFilter).pipe(
            tap(() => {
              this[strAttributeName][newIndex][strLoader] = false;
            })
          ))
        )
      )
    }

  }

  /**
   * Open the relates' dropdown.
   *
   * @param {LooseObject} objAttribute
   * @param {string} strTypeheadName
   */
  openDropdown(objAttribute: LooseObject, strTypeheadName: string) {
    if (objAttribute[strTypeheadName]) {
      objAttribute[strTypeheadName].next('');
    }
  }
  /**
   * when product type is change and once_off_purchase is selected set unit cost and unit price to 0
   * @param event
   * @param attr
   */
  typeChange(event, attr) {
    // Find the index of the line item that was selected.
    let numLineItem = this.arMaterialEntryAttributes.findIndex(line => (line.id_type == attr.id_type));
    // If the item is found.
    if (numLineItem > -1) {
      // Set the values of the line item.
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['item_id'] = null;
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['type'] = event;
      this.arMaterialEntryAttributes[numLineItem]['customer_invoice']['type'] = event;
      this.arMaterialEntryAttributes[numLineItem]['item'] = {};
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['unit_cost'] = '0.00';
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['unit_price'] = '0.00';
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['markup'] = '0.00';
      this.arMaterialEntryAttributes[numLineItem]['total_price'] = '0.00';
      this.arMaterialEntryAttributes[numLineItem]['total_cost'] = '0.00';
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['total_price'] = '0.00';
      this.arMaterialEntryAttributes[numLineItem]['material_entry']['total_cost'] = '0.00';
    }
  }
  /**
   * Validate Request
   * @param objRequest
   */
  validateRequest(objRequest, bLineItems = true) {

    for (let key in objRequest) {
      if (this.arRequiredField.indexOf(key) > -1 && key != 'line_items') {
        if(_.isEmpty(objRequest[key])) {
          let indexOfFormField = this.getFormFieldIndex(key);
          if (indexOfFormField > -1) {
            this.jobInvoiceForm[indexOfFormField]['groups']['controls'][key].markAsTouched();
            this.jobInvoiceForm[indexOfFormField]['groups']['controls'][key].setErrors({'incorrect' : true});
          }
          this.hasError = true;
        }
      }

      switch(key) {
        case 'customer_id':
        case 'start_time':
        case 'end_time':
        case 'tax_code_id':
        case 'account_code_id':
        case 'notes':
          if (_.isEmpty(objRequest[key])) {
            this.hasError = true;
          }
        break;
        case 'product':
          if (_.isEmpty(objRequest['product']) && objRequest['type'] === 'once_off_purchase') {
            this.hasError = true;
          }
        break;
        case 'item_id':
          if (_.isEmpty(objRequest['item_id']) &&
              this.strInvoicingType ===  'time_and_materials' &&
              objRequest['type'] !== 'once_off_purchase') {
            this.hasError = true;
          }
        break;
        case 'department_id':
          if (this.bDepartmentTracking && _.isEmpty(objRequest[key]) && bLineItems) {
            this.hasError = true;
          }
        break;
        case 'user_id':
          if (_.isEmpty(objRequest[key]) && bLineItems) {
            this.hasError = true;
          }
        break;
      }
    }
  }
  /**
   * Validate Field
   * @param strValue
   */
  validateField(strValue) {
    if (strValue == '' || strValue == null || strValue == undefined) {
      return true;
    } else {
      return false;
    }
  }
  /**
   * Validate DateTime
   * @param arValue
   */
  validateDateTime(arValue) {

    if (arValue == null) {
      return true;
    }

    let startDate = (arValue[0] != undefined) ? arValue[0] : null;
    let endDate = (arValue[1] != undefined) ? arValue[1] : null;

    if (startDate == null || startDate == '' || endDate == null || endDate == '') {
      return true;
    } else {
      return false;
    }
  }
  /**
   * Check if invoicing type is fixed price
   */
  isFixedPriceType() {
    return (this.strInvoicingType == 'fixed_price_invoices') ? true : false;
  }

  /**
   * Initialize Contact Relate Data
   */
  initRelate() {
      //Set the values of the relate's dropdown.
      this.arRelateValues$ = concat(
        of(this.arInitialContactRelate), // We set the initial values to the ng-select.
        this.arRelateValuesInput$.pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => this.bRelateLoading = true),
          switchMap(term => this.recordService.getRecordRelate('contacts', term, '', false, false).pipe(
            tap((data) => {
              // this.item['options'] = data;
              this.bRelateLoading = false;
            }),
          ))
        ),
      );
  }
  /**
   * Initialize relate field
   * @param {string} strModule - module to get related data
   * @param strAttributeName  - attribute name i.e. arTimeEntryAttributes
   * @param strObservableName - Observable name i.e department
   * @param strIndex - Index of attribute
   * @param objFilter - Desired Filter
   */
  initRelateData(strModule, strAttributeName, strObservableName, strIndex, objFilter: any = false) {
      let strLoader = strObservableName + '_loader';
      if (strAttributeName != undefined && strAttributeName != '') this[strAttributeName][strIndex][strLoader] = true;
      switch (strModule) {
        case 'customers':
          this.bRelateLoadingCustomer = true;
        break;
        case 'sites':
          this.bRelateLoadingSite = true;
        break;
      }

      if (strModule == 'contacts') {

        let strModule = ['contacts'];
        let strFilter = {};
        this.bRelateLoading = true;
        let siteId = this.getCurrentSiteId();
        let customerId = this.getCurrentCustomerId();
        if (customerId || siteId) {
          strFilter['contact_roles'] = {};
          strModule.push('contact_roles');
          if (customerId) {
            strFilter['contact_roles']['customer_id'] = customerId;
          }
          if (siteId) {
            strFilter['contact_roles']['site_id'] = siteId;
          }
        }
        this.initContactRelateData(strModule.join('|'), strFilter);
      } else {
        if (strModule === 'items' && strAttributeName != undefined && strAttributeName != '') {
          let strCustomerId = this.getCurrentCustomerId();
          this.recordService.getProductRecordData('', objFilter, strCustomerId).subscribe(response => {
            this.initRelateRecords(strAttributeName, strModule, strObservableName, objFilter, response, strIndex);
            this[strAttributeName][strIndex][strLoader] = false;
          });
        } else {
          this.beforeGetRecordRelate(strModule, '', false, false, objFilter).subscribe( result => {
            if (strAttributeName != undefined && strAttributeName != '') {
              this.initRelateRecords(strAttributeName, strModule, strObservableName, objFilter, result, strIndex);
              this[strAttributeName][strIndex][strLoader] = false;
            } else {
              switch (strModule) {
                case 'customers':
                  this.relateCustomerData = result;
                  this.initRelateCustomer();
                  this.bRelateLoadingCustomer = false;
                break;
                case 'sites':
                  this.relateSiteData = result;
                  this.initRelateSite();
                  this.bRelateLoadingSite = false;
                break;
              }
            }
          });
        }
      }
  }

  /**
   * Initialize Site Relate Data
   */
  initRelateSite() {
    this.arRelateSiteValues$ = concat(
      of(this.relateSiteData), // We set the initial values to the ng-select.
      this.arRelateSiteValuesInput$.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => this.bRelateLoadingSite = true),
        switchMap(term => this.beforeGetRecordRelate('sites', term, '', false, {}).pipe(
          tap((data) => {
            // this.item['options'] = data;
            this.bRelateLoadingSite = false;
          }),
        ))
      ),
    );
  }

  /**
   * Here we can manipulate the relate filter before requesting it to api
   *
   * @param relate_module
   * @param term
   * @param id
   * @param email
   * @param filter
   *
   * @retuns Observable<Select[]>
   */
  beforeGetRecordRelate(relateModule, term, id, email, filter): Observable<Select[]> {

    filter = (filter) ? filter : {};
    let strCustomerId = this.getCurrentCustomerId();
    if (relateModule === 'sites' && (term === null || term === '' ) && strCustomerId != '') {
      filter['customer_id'] = strCustomerId;
    } else {
      delete filter['customer_id'];
    }

    return this.recordService.getRecordRelate(relateModule, term, id, email, filter)
  }

  /**
   * Initialize Customer Relate Data
   */
  initRelateCustomer() {
      this.arRelateCustomerValues$ = concat(
      of(this.relateCustomerData), // We set the initial values to the ng-select.
      this.arRelateCustomerValuesInput$.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => this.bRelateLoadingCustomer = true),
        switchMap(term => this.recordService.getRecordRelate('customers', term, '', false, false).pipe(
          tap((data) => {
            this.bRelateLoadingCustomer = false;
          }),
        ))
      ),
    );
  }

  /**
   * Get multiple relate record with joined
   * @param strModule - Multiple modules separated by |
   * @param strFilter - Filter by module
   */
  initContactRelateData(strModule, strFilter: any = []) {
    /**
     * Get multiple module relate record
     * @param strModule - request module to get config
     * @param strRecordIds - request record id of main table
     * @param arFilter - request filter
     * @param arOrderClause - request order clause   Format: ['id' => 'column', 'sort' => 'desc']
     * @param intLimit - request limit
     * @param arWhereIn - request to find in array
     * @param arWhereNotIn - request to find in array
     */

    this.recordService.getMultipleModuleRelateRecord(strModule, false, strFilter, false, 10).subscribe( res => {

      let mergeContactCustomerSite = [];

      // Get relate contact in contact_customers by customer_id in job
      if (res['contact_customers'] != undefined && res['contact_customers'] != null && res['contact_customers'] != '') {
        res['contact_customers'].forEach(data => {
          let objContactCustomerValue = {contact_id: data['contact_id'], text: data['contact_text'], groupname: 'customers', role: this.getPrimaryRole(data['role'])}
          mergeContactCustomerSite.push(objContactCustomerValue);
        });
      }
      // Get relate contact in contact_sites by site_id in job
      if (res['contact_sites'] != undefined && res['contact_sites'] != null && res['contact_sites'] != '') {
        res['contact_sites'].forEach(data => {
          let objContactSiteValue = {contact_id: data['contact_id'], text: data['contact_text'], groupname: 'sites', role: this.getPrimaryRole(data['role'])}
          mergeContactCustomerSite.push(objContactSiteValue);
        });
      }

      // If contact_customer and contact_site have record. Used that as initial data in contact field
      if (mergeContactCustomerSite.length > 0) {
        this.arInitialContactRelate = mergeContactCustomerSite;
        this.initRelate();
      } else {
        // Default contact record as initial data in contact field
        this.arInitialContactRelate = res['contacts'];
        this.initRelate();
      }
      this.bRelateLoading = false;
    });
  }
  /**
   * set line items
   */
  setLineItems() {
    if (this.strViewType == 'edit' || this.isFromUnsavedChanges) {
        // Check if invoice line items exist and not empty
        if (this.data.invoice.line_items != null && this.data.invoice.line_items != undefined && this.data.invoice.line_items != '') {
          this.data.invoice.line_items.forEach( (data, index) => {
            if (this.strInvoicingType === 'time_and_materials' && (!_.isEmpty(data['time_entry_id']) || data['relate_module'] == 'time_entries')) {
              let timeEntriesData = this.customerInvoiceRecord['related_data']['time_entries'];
              let timeEntriesIndex = timeEntriesData.findIndex( timeEntryData => timeEntryData['id'] == data['time_entry_id']);
              let arTimeEntries = [];
              if (timeEntriesIndex > -1) {
                arTimeEntries = timeEntriesData[timeEntriesIndex];
                let hasRelatedData = !_.isEmpty(data['relate_data']);
                this.addTimeEntryAttribute(hasRelatedData ? data['relate_data'] : arTimeEntries, data, false);
              } else {
                this.addTimeEntryAttribute(data['relate_data'], data, false);
              }
            } else if (this.strInvoicingType === 'time_and_materials' && (!_.isEmpty(data['material_id']) || data['relate_module'] == 'materials')) {
              let materialsData = this.customerInvoiceRecord['related_data']['materials'];
              let materialsIndex = materialsData.findIndex( materialData => materialData['id'] == data['material_id']);
              let arMaterials = [];
              if (materialsIndex > -1) {
                arMaterials = materialsData[materialsIndex];
                let hasRelatedData = !_.isEmpty(data['relate_data']);
                this.addMaterialEntryAttribute(hasRelatedData ? data['relate_data'] : arMaterials, data, false);
              } else {
                this.addMaterialEntryAttribute(data['relate_data'], data, false);
              }
            } else {
              this.addInvoiceLineAttribute(data, false);
            }
          });
          this.setAmountDue();
          this.bTimeEntryLoaded = true;
          this.bMaterialLoaded = true;
          this.bInvoiceLineLoaded = true;
        } else {
          this.bMaterialLoaded = true;
          this.bTimeEntryLoaded = true;
          this.bInvoiceLineLoaded = true;
        }
        this.groupBy();
    } else {
      // Initialize Attribute for Time Entry and Material
      if (this.strInvoicingType === 'time_and_materials') {
        // Initialize Attribute based from the time entries
          if (this.objRecordViewDetail['related_data'] != undefined && this.objRecordViewDetail['related_data']['time_entries'].length > 0) {
            let processCount = 0;
            this.objRecordViewDetail['related_data']['time_entries'].forEach( objTimeEntry => {
              this.addTimeEntryAttribute(objTimeEntry);
              processCount++;
              if (this.objRecordViewDetail['related_data']['time_entries'].length == processCount) {
                this.bTimeEntryLoaded = true;
              }
            });
          } else {
            this.bTimeEntryLoaded = true;
          }
        // Initialize Attribute based from the materials
          if (filled(get(this.objRecordViewDetail, 'related_data.materials', []))) {
            const materials = get(this.objRecordViewDetail, 'related_data.materials', []);

            for (const material of materials) {
              if (filled(get(material, 'customer_invoice_id'))) {
                continue;
              }

              this.addMaterialEntryAttribute(material);
            }

            this.bMaterialLoaded = true;
          } else {
            this.bMaterialLoaded = true;
          }
        // Initialize Attribute for Flexibile, Milestone and Fixed price
      } else if(this.strInvoicingType !== 'time_and_materials') {
        /**
         * FC-2020: In flexible_invoicing and fixed_price
         * Use the job work_order_items as invoice line items
         */
        if (this.objJobData['work_order_items']) {
          var arItemIds = _.cloneDeep(this.objJobData['work_order_items']).map(item => item['item_id']);
          // FC-2684: Get the item's default account code
          this.recordService.getRecordRelate('items', '', arItemIds)
          .pipe(
            map( (data) => {
              // Map the item response to ID: {DATA} so that we can retrieve it easily
              let items = [];
              data.forEach(item => items[item.id] = item );
              return items;
            }))
          .subscribe( response => {
            this.objJobData['work_order_items'].forEach( item => {
              var objItem = response[item.item_id];
              // FC-2684: Add the default account code in line items
              item['account_code_id'] = this.arrService.keyFallsBackTo(objItem, 'default_account_code_id', null);
              item['account_code_name'] = this.arrService.keyFallsBackTo(objItem, 'default_account_code_text', null);
              // FC-2020: In using work_order_items, use the discounted_price as unit_price
              item['unit_price'] = item['discounted_price'];
              // We need to delete this as this ID is from work order not item id
              delete item['id'];
              this.addInvoiceLineAttribute(item);
            });
          });
        }
        this.bInvoiceLineLoaded = true;
      }
    }
  }
  /**
   * Get index of form
   * @param strField
   */
  getFormFieldIndex(strField) {
    return this.jobInvoiceForm.findIndex(attr => (attr.groups.get(strField) != undefined && attr.groups.get(strField) != null));
  }
  /**
   * Get index of field
   * @param strField
   * @param form
   */
  getFieldIndex(strField, form) {
    if (_.get(form, 'fields', null)) {
      return form['fields'].findIndex( attr => (attr['key'] == strField));
    } else {
      return -1;
    }
  }
  /**
   * Set value of field
   * @param strField
   * @param strValue
   */
  fieldPatchValue(strField, strValue) {
    let indexOfFormField = this.getFormFieldIndex(strField);
    if (indexOfFormField > -1) {
      this.jobInvoiceForm[indexOfFormField]['groups'].patchValue({
        [strField] : strValue,
      }, {emitEvent: false, onlySelf: true});
    }
  }

  /**
   * Let's format the datetime value.
   * @param date
   */
  formatDate(strDate) {

    // Convert datetime to utc
    let utcTime = moment.utc(strDate).toDate();
    // Convert to local time zone and display
    return moment(utcTime).local().format('ll');
  }

  /**
   * Calculate Datetime difference
   * @param startdate
   * @param enddate
   */
  calculateDateDiff(startdate, enddate) {
    //define moments for the startdate and enddate
    var startdateMoment = moment(startdate);
    var enddateMoment = moment(enddate);

    if (startdateMoment.isValid() === true && enddateMoment.isValid() === true) {
      //getting the difference in years
      var years = enddateMoment.diff(startdateMoment, 'years');

      //to calculate the months, first get the previous year and then subtract it
      startdateMoment.add(years, 'years')
      var months = enddateMoment.diff(startdateMoment, 'months')

      //to calculate the days, first get the previous month and then subtract it
      startdateMoment.add(months, 'months');
      var days = enddateMoment.diff(startdateMoment, 'days')

      //Similar to days go the previous day
      // startdateMoment.add(days, 'days')
      var hours = enddateMoment.diff(startdateMoment, 'hours')

      //Getting minutes
      startdateMoment.add(hours, 'hours')
      var minutes = enddateMoment.diff(startdateMoment, 'minutes')

      //Similar to days go the previous day
      startdateMoment.add(minutes, 'minutes')
      var seconds = enddateMoment.diff(startdateMoment, 'seconds')

      return {
        years: years,
        months: months,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
      };
    }
    else {
      return undefined;
    }
  }

  /**
   * Get the default tax or account code from the local storage setting.
   * @param strKey tax | account
   */
  getDefaultCodes(strKey) {

    let objCOnfig = this.clientStoreService.getActiveClient();

    let strResult: string | boolean = false;

    if (objCOnfig['default_' + strKey + '_code_sale'] != null) {
      strResult = objCOnfig['default_' + strKey + '_code_sale'];
    }

    return strResult;
  }

  /**
   * Forms the ng-select object if the default tax/account code exists.
   * Also retrieves the name from the list of tax/account lists.
   * @param strKey tax | account
   * @param anyDefaultValue
   */
  getDefaultObject(strKey, anyDefaultValue = {}) {

    let objResult: any = anyDefaultValue;
    let strId = this.getDefaultCodes(strKey);
    let arRelate = [];

    if (strKey == 'tax') {
      arRelate = this.relateTaxCodeData;
    }

    if (strKey == 'account') {
      arRelate = this.relateAccountCodeData;
    }

    if (strId) {
      let numIndex = arRelate.findIndex(element => (element['id'] == strId));

      if (numIndex > -1) {
        objResult = {
          id: strId,
          name: arRelate[numIndex]['name']
        }
      }
    }

    return objResult;
  }

  /**
   * Trigger to openPreview
   *
   * @returns {void}
   */
  previewPDF(): void {
    this.pdfPreview = true;
    this.saveInvoice();
  }

  /**
   * Get and store the relate text on change
   *
   * @param {object} objField
   * @param {string} strValue
   *
   * @returns {void}
   */
  getRelateText(objField, strValue): void {
    const strRelateText = ((typeof strValue === 'string') ? strValue : _.get(strValue, 'text')) || null;

    if (objField.controlType == 'relate' && filled(strValue)) {
      this.jobInvoiceFormDirty = true;
      this.objRelateText[objField.key.replace('id', 'text')] = strRelateText;
    }

    if (this.strViewType == 'add' && objField['key'] == 'site_id' && strRelateText) {
      this.relatedSite = strRelateText;
      this.setReferenceValue();
    }

    this.relateChanges[objField['key']] = strRelateText;
  }

  /**
   * Open pdf preview
   * Request for pdf record data using the selected id.
   *
   * @param objModuleRecord
   *
   * @returns {void}
   */
  openPreview(objRecord): void {
    let arLineItems = _.cloneDeep(objRecord.line_items || []);
    objRecord = _.cloneDeep(_.merge(this.objRelateText, objRecord));
    let objInvoiceRecord = (this.data['invoice']) ? _.cloneDeep({
      ..._.merge(this.data['invoice'], objRecord),
      line_items: arLineItems,
    }) : objRecord;
    let objCompileLineItems: any = [];
    let bTimeAndMeterials = false;
    // FC-1818: Get the invoicing_type in parent record, use in Jobs when creating a customer_invoices
    let objParentRecord = this.viewService.getViewRecord();
    // FC-1987: Check if parent record exists
      if (objParentRecord && objParentRecord.invoicing_type) {
        // FC-2020: Fix realtime preview when creating invoice under job module
        bTimeAndMeterials = (objParentRecord.invoicing_type == 'time_and_materials') ? true : false;
      } else {
        bTimeAndMeterials = (objInvoiceRecord.invoicing_type == 'time_and_materials') ? true : false;
      }
    // Do we have time and materials invoicing type?
    if (bTimeAndMeterials) {
      objCompileLineItems = {
        table_materials: [],
        table_time_entries: []
      };
      // We need to seperate the each line items where it belong
      objInvoiceRecord.line_items.forEach( objLineItems => {
        if (objLineItems.relate_module == 'materials') {
          let objMaterial = _.merge(objLineItems['customer_invoice'], objLineItems['relate_data']);

          objCompileLineItems['table_materials'].push({
            ...objMaterial,
            item_name: objMaterial.product,
          });
        }
        if (objLineItems.relate_module == 'time_entries') {
          objCompileLineItems['table_time_entries'].push(_.merge(objLineItems['customer_invoice'], objLineItems['relate_data']));
        }
        // Merge the compiled line items in opportunity record
        _.merge(objInvoiceRecord, objCompileLineItems);
        // Delete the line_items
        delete objInvoiceRecord.line_items;
      });
    } else {
      // Format the line items
      objInvoiceRecord.line_items.forEach( objLineItems => {
        objCompileLineItems.push(objLineItems['customer_invoice']);
      });
      // Update the line items using new value
      objInvoiceRecord.line_items = objCompileLineItems;
    }

    // Do we have related_data in object record details?
    // We need to make sure that we have text in every relate record
    // This will apply in customer invoices that is in widget
    if (this.objRecordViewDetail && this.objRecordViewDetail.related_data) {
      if (objInvoiceRecord['customer_id'] && this.objRecordViewDetail.related_data['customers']) {

        let objCustomerRelateRecord = this.objRecordViewDetail.related_data['customers'].find(x => x.id == objInvoiceRecord['customer_id']);
        objInvoiceRecord['customer_text'] = objCustomerRelateRecord['customer_text'];
        objInvoiceRecord['customer_address_text'] = objCustomerRelateRecord['address_text'];
      }
      if (objInvoiceRecord['site_id'] && this.objRecordViewDetail.related_data['sites']) {

        objInvoiceRecord['site_text'] = this.objRecordViewDetail.related_data['sites'].find(x => x.id == objInvoiceRecord['site_id'])['site_text'];
      }
      if (objInvoiceRecord['contact_id'] && this.objRecordViewDetail.related_data['contacts']) {

        objInvoiceRecord['contact_text'] = this.objRecordViewDetail.related_data['contacts'].find(x => x.id == objInvoiceRecord['contact_id'])['contact_text'];
      }
    }

    objInvoiceRecord['amount_tax_ex'] = (bTimeAndMeterials) ? this.computeExcludedTax() : this.computeInvoiceLineExcludedTax();
    objInvoiceRecord['amount_tax'] = (bTimeAndMeterials) ? this.computeTax() : this.computeInvoiceLineTax();
    objInvoiceRecord['amount_tax_inc'] = (bTimeAndMeterials) ? this.computeIncludedTax() : this.computeInvoiceLineIncludedTax();

    let strLabel = (objInvoiceRecord.invoice_number && this.data['view_type'] == 'edit') ? objInvoiceRecord.customer_text+ ' - ' +objInvoiceRecord.invoice_number : 'preview_' +moment().format('YYYY_MM_DD_HHMMSS');
    let dialogConfig : {[k: string]: any} = {
      data: {
        module: 'customer_invoices',
        label: strLabel,
        response: {
          file_name: strLabel,
          module: 'tax_invoices',
          record: { ...this.viewService.getViewRecord(), ...objInvoiceRecord }
        }
      },
      disableClose:true
    };

    // If mobile
    if(window.innerWidth <= 800 && window.innerHeight <= 1024) {
      // Display the pop up in full screen (WHOLE PAGE)
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
      dialogConfig.maxHeight = '100vh';
      dialogConfig.maxWidth = '100vw';
    } else {
      // display as pop up if not mobile
      dialogConfig.width = '80%';
      dialogConfig.height = '80%';
    }

    this.pdfPreview = false;
    this.bSubmitted = false;
    this.dialog.open(PdfComponent, dialogConfig);
  }
  /**
   * set amount due with computation of included tax
   */
  setAmountDue() {
    let indexOfAmountDue = this.getFormFieldIndex('amount_due');
    let indexOfAmountPaid = this.getFormFieldIndex('amount_paid');
    let strAmountPaid: number = 0;
    let strAmountDue: number = 0;

    if (indexOfAmountPaid > -1) {
      let strAmountPaidValue = this.jobInvoiceForm[indexOfAmountDue]['groups'].controls.amount_paid.value;
      strAmountPaid = this.is.toFloat(strAmountPaidValue);
    }

    if (indexOfAmountDue > -1) {
      if (this.strInvoicingType == 'time_and_materials') {
        strAmountDue = this.computeIncludedTax() - strAmountPaid;
      } else {
        strAmountDue = this.computeInvoiceLineIncludedTax() - strAmountPaid;
      }

      this.jobInvoiceForm[indexOfAmountDue]['groups'].patchValue({
        amount_due: toFormattedNumber(strAmountDue, {
          currency: true,
        }),
      });
    }
  }

    /**
   * This is to increase the row
   * when textarea is focus
   */
    increaseRow(strModule: string, numIndex: number) {
      let currentElem = document.querySelector(`.${strModule}-desc-`+ numIndex);
      currentElem.setAttribute("style", "height: 100px");
    }

    /**
     * This is to decrease the row
     * when textarea is focus out
     */
    decreaseRow(strModule: string, numIndex: number) {
      let currentElem = document.querySelector(`.${strModule}-desc-`+ numIndex);
      currentElem.setAttribute("style", "height: 40px");
    }

  /**
   * This is for setting show material detail and show labour details
   * as readonly or not. And also to set as default value to true
   * depends on the invoicing type.
   *
   * Has related job with invoicing type of time and materials
   * Readonly - false, Default Value - true
   *
   * Has related job but the invoicing type is not time and materials
   * Readonly - false, Default Value - false
   *
   * No related job
   * Readonly - true, Default Value - false
   *
   * @return void
   */
  setShowMaterialAndLabourDetails(): void {

    if (this.objJobData['id'] != undefined) {

      let indexShowLabourDetailsForm = this.getFormFieldIndex('show_labour_details');
      let indexShowMaterialDetailsForm = this.getFormFieldIndex('show_material_details');
      let indexEnableGroupByForm = this.getFormFieldIndex('enable_group_by');
      let indexShowLabourDetailsField = this.getFieldIndex('show_labour_details', this.jobInvoiceForm[indexShowLabourDetailsForm]);
      let indexShowMaterialDetailsField = this.getFieldIndex('show_material_details', this.jobInvoiceForm[indexShowMaterialDetailsForm]);
      let indexEnableGroupByField = this.getFieldIndex('enable_group_by', this.jobInvoiceForm[indexEnableGroupByForm]);

      // If invoicing type is TIME AND MATERIALS, default value should be TRUE, read only should be FALSE and showing of labor details should be TRUE
      let isInvoicingTypeTimeAndMaterials = this.strInvoicingType === 'time_and_materials';

      if (this.jobInvoiceForm[indexShowLabourDetailsForm] !== undefined) {
        this.jobInvoiceForm[indexShowLabourDetailsForm]['fields'][indexShowLabourDetailsField]['default_value'] = isInvoicingTypeTimeAndMaterials;
        this.jobInvoiceForm[indexShowLabourDetailsForm]['fields'][indexShowLabourDetailsField]['readonly'] = ! isInvoicingTypeTimeAndMaterials;
        this.fieldPatchValue('show_labour_details', isInvoicingTypeTimeAndMaterials);
      }

      if (this.jobInvoiceForm[indexShowMaterialDetailsForm] !== undefined) {
        this.jobInvoiceForm[indexShowMaterialDetailsForm]['fields'][indexShowMaterialDetailsField]['default_value'] = isInvoicingTypeTimeAndMaterials;
        this.jobInvoiceForm[indexShowMaterialDetailsForm]['fields'][indexShowMaterialDetailsField]['readonly'] = ! isInvoicingTypeTimeAndMaterials;
        this.fieldPatchValue('show_material_details', isInvoicingTypeTimeAndMaterials);
      }

      if (this.jobInvoiceForm[indexEnableGroupByForm] !== undefined) {
        this.jobInvoiceForm[indexEnableGroupByForm]['fields'][indexEnableGroupByField]['readonly'] = ! isInvoicingTypeTimeAndMaterials;
      }
    }
  }

  /**
   * Check if the form group is changed
   *
   * @returns {boolean}
   */
  checkFormGroupDirty(): boolean {
    let bDirty = false;
    this.jobInvoiceForm.forEach( objForm => {
      var formGroup = objForm.groups;
      if (formGroup.dirty === true) {
        bDirty = true;
      }
    });
    return bDirty || this.jobInvoiceFormDirty;
  }

  /**
   * Mark as dirty
   *
   * @returns {void}
   */
  markAsDirty(): void {
    this.jobInvoiceFormDirty = true;
  }

  /**
   * This will get the current id of the customer
   */
  protected getCurrentCustomerId(): string|null {
    return (typeof this.objCustomerField === 'string') ? this.objCustomerField : _.get(this.objCustomerField, 'id', null);
  }

  /**
   * This will get the current id of the site
   */
  protected getCurrentSiteId(): string|null {
    let siteId = (this.objSiteField && this.objSiteField['id']) ? this.objSiteField['id'] : this.objSiteField;
    return (typeof siteId == 'string') ? siteId : null;
  }

  /**
   * triggers the save and preview
   */
  onSubmitAndPreview(): void {
    this.isPreviewTemplate = true;

    if (!this.bSubmitted) {
      this.saveInvoice();
    }
  }

  /**
   * trigger event to disaplay template
   *
   * @param id
   */
  displayTemplate(id: string): void {
    if (this.isPreviewTemplate && id) {
      this.previewTemplate.next({
        id: id,
        module: 'customer_invoices',
        document_type: 'customer_invoice_report'
      });
    }

    this.isPreviewTemplate = false;
  }

  /**
   * Method to call when error has occured in the application
   *
   * @return {void}
   */
  onError(): void {
    this.whenFailed$.next(true);
  }

  /**
   * get the primary contact role to display in relate option
   *
   * @param roles
   */
  protected getPrimaryRole(roles): string | null {
    if (roles.length) {
        let primaryRole = roles.find( data => data.primary );
        return primaryRole ? primaryRole['id'] : roles[0]['id'];
    }
    return null;
  }

  onLineTotalChange(input: HTMLInputElement, opts: {
    attr: any,
    index: number,
    module: string,
  }): void {
    const adjustment = toFormattedNumber(input.value, {
      currency: true,
    });

    if (opts.module == 'invoice_line' && this.arInvoiceLineAttributes.length > opts.index) {
      const quantity = toFormattedNumber(_.get(opts.attr, 'invoice_line.quantity'));
      const unitPrice = toFormattedNumber(adjustment / quantity, {
        currency: true,
        maxDecimalPlaces: 4,
      });

      this.arInvoiceLineAttributes[opts.index]['invoice_line']['unit_price'] = unitPrice;
    }

    if (opts.module == 'material' && this.arMaterialEntryAttributes.length > opts.index) {
      const quantity = toFormattedNumber(_.get(opts.attr, 'material_entry.quantity'));
      const unitPrice = toFormattedNumber(adjustment / quantity, {
        currency: true,
        maxDecimalPlaces: 4,
      });
      const unitCost = toFormattedNumber(_.get(opts.attr, 'material_entry.unit_cost'), {
        currency: true,
        maxDecimalPlaces: 4,
      });

      const markup = toFormattedNumber((unitPrice - unitCost) / unitCost);

      this.arMaterialEntryAttributes[opts.index]['material_entry']['unit_price'] = unitPrice;
      this.arMaterialEntryAttributes[opts.index]['material_entry']['markup'] = markup;
    }

    if (opts.module == 'time_entry' && this.arTimeEntryAttributes.length > opts.index) {
      const quantity = toFormattedNumber(_.get(opts.attr, 'time_entry.billed_duration'));
      const unitPrice = toFormattedNumber(adjustment / quantity, {
        currency: true,
        maxDecimalPlaces: 4,
      });

      this.arTimeEntryAttributes[opts.index]['customer_invoice']['unit_price'] = unitPrice;
    }
  }

  onLineTotalCostChange(input: HTMLInputElement, opts: {
    attr: any,
    index: number,
    module: string,
  }): void {
    const adjustment = toFormattedNumber(input.value, {
      currency: true,
    });

    if (opts.module == 'material' && this.arMaterialEntryAttributes.length > opts.index) {
      const quantity = toFormattedNumber(_.get(opts.attr, 'material_entry.quantity'));
      const unitCost  = toFormattedNumber(adjustment / quantity, {
        currency: true,
      });
      const unitPrice = toFormattedNumber(_.get(opts.attr, 'material_entry.unit_price'), {
        currency: true,
      });

      const markup = toFormattedNumber((unitPrice - unitCost) / unitCost);

      this.arMaterialEntryAttributes[opts.index]['material_entry']['unit_cost'] = unitCost;
      this.arMaterialEntryAttributes[opts.index]['material_entry']['markup'] = markup;
    }
  }

  onTaxAdjustment(): void {
    this.markAsDirty();
    this.setAmountDue();
  }

  /**
   * compile the save data
   *
   * @returns
   */
  compileSaveRecordData(isPreview: boolean = false, isFromAutoSave: boolean = false): LooseObject|false {
    // Get the raw value of the form.
    let objInvoice = [];

    if (! isPreview) {
      this.bSubmitted = true;
      this.isTouched = true;
    }

    this.hasError = false;
    this.arReadOnlyId = [];
    this.arRelateEmptyId = [];
    this.arRequiredField = [];
    this.jobInvoiceForm.map(
      form => {
        Object.keys(form['groups'].value).forEach(
          item => {
            let strFieldMetadata = form['fields'].find( x => x.key == item);
            if (strFieldMetadata != undefined) {
              if (strFieldMetadata.readonly) this.arReadOnlyId.push(item);
              if (strFieldMetadata.controlType == 'relate') this.arRelateEmptyId.push(item);
              if (strFieldMetadata.required) this.arRequiredField.push(item);
            }
        })
    });

    //Loops the values.
    this.jobInvoiceForm.forEach(
      item => {
        //Merge all form group values into a single object.
        objInvoice = {...objInvoice, ...item['groups'].getRawValue()};
    });

    // Remove readonly fields
    this.arReadOnlyId.forEach( strId => {
      if (strId != 'accounting_sync_detail') {
        delete objInvoice[strId];
      }
    });

    let objLines: any = [];
    let objTimeEntryLines: any = [];
    let objMaterialLines: any = [];

    if (this.strRecordModule == 'jobs') {
      if(this.strRecordId != null && this.strRecordId != undefined && this.strRecordId != '') {
        objInvoice['job_id'] = this.strRecordId;
      } else {
        objInvoice['job_id'] = null;
      }
    }

    objInvoice['contact_id'] = '';

    if (this.arrService.keyFallsBackTo(this.strContactId, 'contact_id', '')) {
      objInvoice['contact_id'] = this.strContactId['contact_id'];
    } else if (this.arrService.keyFallsBackTo(this.strContactId, 'id', '')) {
      objInvoice['contact_id'] = this.strContactId['id'];
    }

    objInvoice['customer_id'] = this.getCurrentCustomerId();
    objInvoice['site_id'] = this.getCurrentSiteId();
    objInvoice['date_invoice'] = moment(this.dateInvoiceField).format('YYYY-MM-DD');
    objInvoice['date_due'] = moment(this.dateInvoiceDueField).format('YYYY-MM-DD');
    objInvoice['tax_adjustment_amount'] = this.totalTaxAdjustment;

    if (objInvoice['site_id'] == '' || objInvoice['site_id'] == null) {
      objInvoice['site_id'] = null;
    }

    if (objInvoice['contact_id'] == '' || objInvoice['contact_id'] == null) {
      objInvoice['contact_id'] = null;
    }

    this.validateRequest(objInvoice, false);

    // Validate each line itmes of time entry
    this.arTimeEntryAttributes.forEach( data => {
      // Set all number to string
      data.customer_invoice.quantity = data.time_entry.billed_duration;
      data.customer_invoice.total_price = data.amount;
      data.customer_invoice.unit_price = data.customer_invoice.unit_price;
      data.customer_invoice.description = data.time_entry.description;
      data.customer_invoice.item_name = data.item.text;

      objTimeEntryLines.push({'customer_invoice' : data.customer_invoice, 'relate_data': data.time_entry, 'relate_module': 'time_entries', 'created': data.has_time_entries, 'relate_id': data.time_entry_id, 'for_update': (data.has_time_entries) ? data.has_time_entries : false});
      this.validateRequest(data.time_entry);
      this.validateRequest(data.customer_invoice);
    });
    // Validate each line itmes of mtaerials
    this.arMaterialEntryAttributes.forEach( data => {
      data.customer_invoice.quantity = data.material_entry.quantity;
      data.customer_invoice.unit_price = data.material_entry.unit_price;
      data.customer_invoice.total_price = data.total_price;
      data.customer_invoice.description = data.material_entry.notes;

      if (data.material_entry.type == 'once_off_purchase') {
        data.material_entry.product = data.material_entry.notes;
      } else {
        data.material_entry.product = data.material_entry.product ? data.material_entry.product['name'] : (data.item.text || data.material_entry.notes);
        data.customer_invoice.item_name = data.item.text;
      }

      objMaterialLines.push({'customer_invoice' : data.customer_invoice, 'relate_data': data.material_entry, 'relate_module': 'materials', 'created': data.has_material_entries, 'relate_id': data.material_id, 'for_update': (data.has_material_entries) ? data.has_material_entries : false});
      this.validateRequest(data.material_entry);
      this.validateRequest(data.customer_invoice);
    });
    // Validate each line itmes of flexible and milestone invoicing
    this.arInvoiceLineAttributes.forEach( data => {
      data.invoice_line.total_price = data.amount;
      data.invoice_line.quantity = data.invoice_line.quantity;
      this.validateRequest(data.invoice_line);
      objLines.push({'customer_invoice' : {
        ...data.invoice_line,
        item_name: data.item ? data.item.text : null,
      }});
    });

    objInvoice['line_items'] = [];

    if (this.strInvoicingType == 'time_and_materials') {
      if (objTimeEntryLines.length > 0 || objMaterialLines.length > 0) {
        objInvoice['line_items'].push(...objTimeEntryLines);
        objInvoice['line_items'].push(...objMaterialLines);
      } else {
        if (!isPreview) {
          this.bSubmitted = false;
          this.pdfPreview = false;
          if (!isFromAutoSave) {
            this.notifService.sendNotification('not_allowed', 'line_items_required', 'warning');
            return false;
          }
        }
      }
    } else if(this.strInvoicingType != 'time_and_materials') {
      if (objLines.length > 0) {
        objInvoice['line_items'] = objLines;
      } else {
        if (!isPreview) {
          this.bSubmitted = false;
          this.pdfPreview = false;
          if (!isFromAutoSave) {
            this.notifService.sendNotification('not_allowed', 'invoice_line_items_required', 'warning');
            return false;
          }
        }
      }
    }

    this.arRelateEmptyId.forEach( strField => {
      if(objInvoice[strField] == '' || objInvoice[strField] == null) {
        if(objInvoice[strField] != undefined) delete objInvoice[strField];
      };
    });

    return objInvoice;
  }

  /**
   * for viewing the current record
   *
   * @returns void
   */
  onPreview(): void {
    let customerInvoiceRecord = this.compileSaveRecordData(true);

    if (customerInvoiceRecord !== false) {
      this.whenInProgress$.next(true);

      let objRelateField = this.getRelateFieldModuleIds(customerInvoiceRecord);

      this.recordService.getMultipleModuleRelateRecord(objRelateField.module.join('|'), false, objRelateField.filter).subscribe( response => {
        let arMaterials = [];
        let arTimeEntries = [];

        objRelateField.relate_field.forEach( relateField => {
          if (response[relateField.module] && response[relateField.module][0]) {
            customerInvoiceRecord[relateField.key] = response[relateField.module][0];
            if (customerInvoiceRecord[relateField.key]['address']) {
              customerInvoiceRecord[relateField.key]['address'] =
                this.readableAddressPipe.transform(customerInvoiceRecord[relateField.key]['address'])
            }
          } else {
            customerInvoiceRecord[relateField.key] = {}
          }
        });

        customerInvoiceRecord['line_items'] = customerInvoiceRecord['line_items'].map( lineItems => {
          lineItems['customer_invoice']['item'] = (lineItems['customer_invoice']['item_name'])
            ? lineItems['customer_invoice']['item_name']
            : lineItems['customer_invoice']['description']
          return { ...lineItems['customer_invoice'], ...lineItems['relate_data']};
        });

        customerInvoiceRecord['line_items'].forEach(objLineItem => {
          if (objLineItem['time_entry_id']) {
            objLineItem['start_date'] = this.humanReadableDatePipe.transform(objLineItem['start_time']);
            objLineItem['end_date'] = this.humanReadableDatePipe.transform(objLineItem['end_time']);
            arTimeEntries.push(objLineItem);
          } else {
            arMaterials.push(objLineItem);
          }
        });

        customerInvoiceRecord['materials'] = [];
        customerInvoiceRecord['time_entries'] = [];
        customerInvoiceRecord['amount_tax_ex'] = this.computeInvoiceLineExcludedTax();
        customerInvoiceRecord['amount_tax'] = this.computeInvoiceLineTax();
        customerInvoiceRecord['amount_tax_inc'] = this.invoiceLineIncludedTax;

        if (this.strInvoicingType === 'time_and_materials') {
          customerInvoiceRecord['materials'] = arMaterials;
          customerInvoiceRecord['time_entries'] = arTimeEntries;
          customerInvoiceRecord['amount_tax_ex'] = this.computeExcludedTax();
          customerInvoiceRecord['amount_tax'] = this.computeTax();
          customerInvoiceRecord['amount_tax_inc'] = this.includedTax;
        }

        if (this.enableGroup) {
          customerInvoiceRecord['grouped_time_entries'] = this.arGroupedTimeEntries.map(group => {
            return {
              user_id: group.user_id,
              user_text: group.user_text == 'unassigned' ? this.translate.instant('unassigned') : group.user_text,
              summary: group.summary,
              total: group.time_entries
              .map(entries => { return entries['amount']})
              .reduce((sum, current) => sum + current, 0)
            };
          });
        }

        this.realTimePreviewTemplate.next({
          id: this.data['invoice'] ? this.data['invoice']['id'] : null,
          module: 'customer_invoices',
          document_type: 'customer_invoice_report',
          data: customerInvoiceRecord as LooseObject
        });
      })
    }
  }

  /*
   * When a invoice line item is dragged then dropped, we simply move the posiiton.
   *
   * @returns {void}
   */
  dropInvoice(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.arInvoiceLineAttributes, event.previousIndex, event.currentIndex);
  }

  /**
   * When a material line item is dragged then dropped, we simply move the posiiton.
   *
   * @returns {void}
   */
  dropMaterial(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.arMaterialEntryAttributes, event.previousIndex, event.currentIndex);
  }

  /**
   * get relate fields module and ids
   *
   * @param objRecord
   * @returns
   */
  getRelateFieldModuleIds(objRecord: LooseObject) {

    let arModule = [];
    let objFilter = {};
    let arRelateFields = [
      { id: 'customer_id', module: 'customers', key: 'customer' },
      { id: 'site_id', module: 'sites', key: 'site' },
      { id: 'user_id', module: 'users', key: 'assigned_user' },
    ];
    arRelateFields.forEach( relateField => {
      if (objRecord[relateField.id]) {
        arModule.push(relateField.module);
        objFilter[relateField.module] = { id: objRecord[relateField.id] };
      }
    });

    return {
      module: arModule,
      filter: objFilter,
      relate_field: arRelateFields
    }

  }

  /**
   * Check if group is enabled.
   *
   * @returns {boolean}
   */
  get enableGroup() {
    if (this.jobInvoiceForm) {
      let numJobIndex = this.jobInvoiceForm.findIndex(tabs => {
        if (tabs['groups'].get('enable_group_by')) {
          return true;
        } else {
          return false;
        }
      })
      if (numJobIndex > -1) {
        return this.jobInvoiceForm[numJobIndex].groups.get('enable_group_by').value;
      }
    }
    return false;
  }

  /**
   * Group by the edit. This allows us maintain the instance of the line items
   * even if we move it to a different list.
   *
   * @returns {void}
   */
  groupBy() {
    this.arGroupedTimeEntries = [];
    this.arTimeEntryAttributes.forEach(item => {
      let numIndex = this.arGroupedTimeEntries.findIndex(item1 => {
        if (item['user'] && item['user']['id'] && item['user']['id'] == item1.user_id) {
          return true;
        }
        if ((item['user'] == null || (item['user'] && _.isEmpty(item['user']['id']))) && item1.user_id == null) {
          return true;
        }
        return false;
      });
      if (numIndex > -1) {
        this.arGroupedTimeEntries[numIndex].time_entries.push(item);
      } else {
        if (item['user'] && item['user']['id']) {
          this.arGroupedTimeEntries.push(
            new TimeEntryGroup(item['user']['id'], item['user']['text'], [item])
          )
        } else {
          this.arGroupedTimeEntries.push(
            new TimeEntryGroup(null, 'unassigned', [item])
          )
        }
      }
    });
  }

  /**
   * Computes the mark up depending on the cost and price.
   *
   * @return {string}
   */
  public computeMarkup(numExistingMarkup: number = 0, numSell: number = 0, numBuy: number = 0): string {

    let intTotalComputation = 100 * (numSell - numBuy) / numBuy;

    if (numExistingMarkup > 0) {
      return _.toString(toFormattedNumber(numExistingMarkup));
    } else {
      return _.toString(toFormattedNumber((intTotalComputation !== Infinity ? intTotalComputation : 0)));
    }

  }

  /**
   * Notify the user if there is still unused material
   */
  notifyUnusedMaterial(customerInvoice, saveResponse) {
    let materialIds = customerInvoice['line_items'].map( lineItem => {
      return lineItem.relate_id
    });
    this.recordService.getRecordRelateJoined('materials', false, { 'items.is_inventoried': true }, false, false, {
      'materials.id': materialIds,
      'materials.status': ['pending', 'allocated'],
    }).subscribe( response => {
      if (response.length) {
        this.notifService.sendConfirmation('do_you_want_to_redirect_in_materials_to_allocate')
          .pipe(
            filter(confirmation => confirmation.answer == true),
            finalize(() => this.dialogRef.close({
              action: 'save',
              response: saveResponse.body
            }))
          )
          .subscribe(() => {
            let currentUrl = this.recordService.getParentData();
            if (currentUrl.module === 'jobs') {
              this.dialog.open(StockAllocationComponent, {
                width: '80%',
                height: 'auto',
                data: {
                  job_data :  this.objJobData,
                  record_id : this.objJobData['id'],
                  material_ids: customerInvoice['line_items'].map( lineItem => {
                    return lineItem.relate_id
                  })
                },
                panelClass: 'custom-dialog'
              });
            } else {
              this.router.navigate([`/jobs/${this.data['invoice']['job_id']}`], {
                queryParams: {
                  allocate_material: 'open',
                  customer_invoice_id: this.data['invoice']['id'] || '',
                },
                queryParamsHandling: 'merge'
              });
            }
        });
      } else {
        this.dialogRef.close({
          action: 'save',
          response: saveResponse.body
        });
      }
    });
  }

  setReferenceValue(): void {
    let indexReference = this.getFormFieldIndex('reference');
    if (indexReference > -1) {
      this.jobInvoiceForm[indexReference]['groups'].patchValue({
        reference: this._computeReferenceValue(),
      });
    }
  }

  doSomethingFromContextMenu(event) {
    let copiedLineItemFromLocalStorage = this.contextMenuService.getCopiedLineItem();

    if (event.action == 'paste' && filled(copiedLineItemFromLocalStorage)) {
      let currentInvoiceType = get(event, ['data', 'additional_data', 'invoice_type']);

      if (copiedLineItemFromLocalStorage['invoice_type'] != currentInvoiceType && currentInvoiceType != 'invoice_line') {
        copiedLineItemFromLocalStorage['item_id'] = null;
        copiedLineItemFromLocalStorage['item_name'] = null;
        copiedLineItemFromLocalStorage['item_code'] = null;
      }

      switch(currentInvoiceType) {
        case 'time_entry':
          let indexCounterTimeEntry = get(event, ['data', 'index']) + 1;
          copiedLineItemFromLocalStorage.forEach(lineItem => {
            if (lineItem['labor'] == true) {
              this.addTimeEntryAttribute(lineItem, [], false ,false, indexCounterTimeEntry);
              indexCounterTimeEntry++;
            }
          });
        break;

        case 'material':
          let indexCounterMaterial = get(event, ['data', 'index']) + 1;
          copiedLineItemFromLocalStorage.forEach(lineItem => {
            if (!lineItem['labor']) {
              this.addMaterialEntryAttribute(lineItem, [], false, false, indexCounterMaterial);
              indexCounterMaterial++;
            }
          });
        break;

        case 'invoice_line':
          let indexCounter = get(event, ['data', 'index']) + 1;
          copiedLineItemFromLocalStorage.forEach(lineItem => {
            this.addInvoiceLineAttribute(lineItem, false, false, indexCounter);
            indexCounter++;
          });
        break;

        case 'grouped_time_entry':
          let indexCounterGroupTimeEntry = get(event, ['data', 'index']) + 1;
          copiedLineItemFromLocalStorage.forEach(lineItem => {
              this.addTimeEntryAttribute(lineItem, [], false ,false, indexCounterGroupTimeEntry);
              indexCounterGroupTimeEntry++;
            });
          this.groupBy();
        break;
      }

      this.setAmountDue();
    } else if (event.action == 'copy') {
      let copiedLineItems = [];
      let eventDataLineItem = _.get(event, ['data', 'line_item'], []);

      if (this.selectedLineItems.length > 0) {
        copiedLineItems = this.selectedLineItems.map(lineItem => {
          let newLineItem = this.formatCopiedLineItem(lineItem);

          return new CustomerInvoiceLine(newLineItem);
        });
      } else if (eventDataLineItem) {
        let newLineItem = this.formatCopiedLineItem(eventDataLineItem);

        copiedLineItems = [new CustomerInvoiceLine(newLineItem)];
      }

      if (filled(copiedLineItems)) {
        this.contextMenuService.setCopiedLineItem(copiedLineItems);
      }
    }
  }

  formatCopiedLineItem(lineItem: LooseObject) {
    let currentLineItem = _.cloneDeep(lineItem);
    let lineItemKeyData = this.strInvoicingType == 'time_and_materials' ? 'customer_invoice' : 'invoice_line';
    currentLineItem['tax_code_name'] = _.get(currentLineItem, ['tax', 'text']);
    currentLineItem['account_code_name'] = _.get(currentLineItem, [lineItemKeyData, 'account_code_name']);
    currentLineItem['account_code'] = _.get(currentLineItem, [lineItemKeyData, 'account_code']);
    currentLineItem['item_name'] = _.get(currentLineItem, ['item', 'text']);
    currentLineItem['invoice_type'] = _.get(event, ['data', 'additional_data', 'invoice_type']);
    currentLineItem['tax_rate'] = parseFloat(currentLineItem['tax_rate']) > 0 ? parseFloat(currentLineItem['tax_rate']) * 100 : 0;

    let newLineItem = {..._.get(currentLineItem, [lineItemKeyData], []), ...currentLineItem};

    if (filled(_.get(newLineItem, ['material_entry']))) {
      newLineItem = {...newLineItem, ..._.get(newLineItem, ['material_entry'])};
    } else if (filled(_.get(newLineItem, ['time_entry']))) {
      newLineItem = {...newLineItem, ..._.get(newLineItem, ['time_entry'])};
    } else if (filled(_.get(newLineItem, ['invoice_line']))) {
      newLineItem = {...newLineItem, ..._.get(newLineItem, ['invoice_line'])};
    }

    return newLineItem;
  }

  onClickedLineItem($event, attr) {
    this.selectedLineItems = this.contextMenuService.selectLineItem($event, attr, this.selectedLineItems);
  }

  _computeReferenceValue(): string|undefined {
    const parts = [];

      if (filled(this.relatedJob)) {
        parts.push(spf('Job Number %s', {
          args: [this.relatedJob],
        }));
      }

      if (filled(this.relatedSite)) {
        parts.push(_.trim(this.relatedSite.replace(/^\[.*\]\s/, '')));
      }

    if (blank(parts)) {
      return;
    }

    return _.trim(_.join(parts, ' '));
  }

  /**
   * Set index to insert to new products
   *
   * @param numIndex
   */
  public setLastIndex(numIndex: number) {
    this.numLastFocusedIndex = numIndex + 1;
  }

  triggerAutoSave() {
    if (blank(this.autoSaveIntervalId)) {
      this.autoSaveIntervalId = setInterval(() => {
        if (this.checkFormGroupDirty()) {
          this.saveCustomerInvoiceViaAutoSave();
        }
      }, 5000);
    }
  }

  saveCustomerInvoiceViaAutoSave() {
    this.arReadOnlyId = [];
    this.arRelateEmptyId = [];
    this.arRequiredField = [];
    let objCustomerInvoice = this.compileSaveRecordData(false, true);
    this.bSubmitted = false;

    if (!_.isEmpty(objCustomerInvoice)) {
      objCustomerInvoice['id'] = this.data['customer_invoice_id'];

      let unsavedChangesData: SetUnsavedChangesData = {
        record_id: _.get(this.data, 'customer_invoice_id', ''),
        module: 'customer_invoices',
        data_to_save: objCustomerInvoice,
        parent_record_id: this.strRecordId,
        related_fields: this.relateFields,
        related_changes: this.relateChanges,
      };

      this.formService.setUnsavedChangesDataToLocalStorage(unsavedChangesData);
    }
  }

  ngOnDestroy(): void {
    this.formService.removeAutoSaveInterval(this.autoSaveIntervalId);
  }
}

export class TimeEntryGroup {

  user_id: string;
  user_text: string;
  time_entries: LooseObject[];
  collapsed?: boolean = true;

  get summary() {
    return this.time_entries.map(item => {

      if (item['time_entry'] && item['time_entry']['description']) {
        return item['time_entry']['description'];
      }

      if (item['relate_data'] && item['relate_data']['time_entries']) {
        return item['relate_data']['time_entries']['description'];
      }

      return '';
    }).join(' ').trim();
  }


  constructor(
    strUserId: string,
    strUserText: string,
    arTimeEntries: LooseObject[]
  ) {
    this.user_id = strUserId;
    this.user_text = strUserText;
    this.time_entries = arTimeEntries;
  }

}
