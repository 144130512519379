import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TagConfig } from '../../objects/tag-config';
import { TagRecordsComponent } from '../../dialogs/tag-records/tag-records.component';
import { ManageTagsComponent } from '../../dialogs/manage-tags/manage-tags.component';

@Component({
  selector: 'tag-options',
  templateUrl: './tag-options.component.html',
  styleUrls: ['./tag-options.component.scss']
})
export class TagOptionsComponent {

  /**
   * What module this record is.
   *
   * @var {string}
   */
  @Input('module') module: string;

  /**
   * List of records to be tagged.
   */
  @Input('records') records: any[];

  /**
   * When tagging is success, emit the event.
   *
   * @vat {EventEmitter}
   */
  @Output() onTaggedSuccess = new EventEmitter<boolean>();

  /**
   * The config of the tags, like which modules should this display.
   *
   * @var {TagConfig}
   */
  private objTagConfig = new TagConfig;

  /**
   * Simple getter to show hide this option.
   *
   * @return {boolean}
   */
  get showTagButtonInModule(): boolean {
    return this.objTagConfig.show(this.module)
  }

  constructor(private dialog: MatDialog) { }

  /**
   * Check if there are records selected, if yes, use
   * the tag record dialog. If not, open the manage record.
   *
   * @returns {void}
   */
  checkRecords() {
    if (this.records && this.records.length > 0) {
      this.openTagRecord();
    } else {
      this.openManageTags();
    }
  }

  /**
   * Open the tag record dialog.
   *
   * @returns {void}
   */
  openTagRecord() {
    let objTagRecord = this.dialog.open(TagRecordsComponent, {
      width: '75%',
      height: 'auto',
      data: {
        module: this.module,
        records: this.records
      }
    });

    objTagRecord.afterClosed().subscribe((response) => {
      if (response && response.tagged === true) {
        this.onTaggedSuccess.next(true);
      }
    })
  }

  /**
   * Open the manage tags dialog.
   *
   * @returns {void}
   */
  openManageTags() {
     this.dialog.open(ManageTagsComponent, {
      width: '75%',
      height: 'auto',
      disableClose: true
    });
  }

}
