<div class="row">
  <div class="col-sm-7">
    <span class="title" translate>
      <fa-icon [icon]="['fas', 'envelope']" ></fa-icon>
      &nbsp; {{ 'archived_mails'| translate }}:&nbsp;<span class="text-wrap">{{ thread.subject | truncate:100 }}</span>
    </span>
  </div>

  <div class="col-sm-5">
    <span class="buttons">
      <button type="button" (click)="openMailDialog()" class="btn ml-1 btn-primary">
        <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
        <span class="pl-1">{{ 'new_email' | translate }}</span>
      </button>
      <button type="buttin" (click)="openParticipantsList()" class="btn ml-1 btn-primary">
        <fa-icon [icon]="['fas', 'users']"></fa-icon>
        <span class="pl-1">{{ 'participants' | translate }}</span>
      </button>
      <button type="button" (click)="cancelDialog()" class="btn ml-1 btn-danger">
        <fa-icon [icon]="['fas', 'times']"></fa-icon>
        <span class="pl-1">{{ 'close' | translate }}</span>
      </button>
    </span>
  </div>
</div>

<hr class="separator"/>

<div class="row">
  <div class="col-12">
    <ng-container *ngIf="isLoading" >
      <div class="col-sm-12" align="center">
        <h4><fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon></h4>
      </div>
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <div class="no-messages-container" *ngIf="thread.messages.length < 1">
        <h4 class="text-muted text-center">{{ 'no_archived_mails' | translate }}</h4>
      </div>

      <div #messagesContainer class="messages-container" *ngIf="thread.messages.length > 0">
        <!-- thread-message -->
        <div class="message-row" *ngFor="let message of thread.messages; trackBy: trackMessage; let isLast = last;">
          <div class="mail-header" (click)="renderMail(message)">
            <!-- from -->
            <div class="row">
              <div class="col-xs-9 col-sm-9">
                <span class="font-weight-bold small">
                  {{ message.from }}
                  <!-- message size -->
                  {{ message.mailSizeText }}&nbsp;
                  <!-- loader -->
                  <ng-container *ngIf="message.isLoading">&nbsp;<fa-icon [icon]="['fas', 'spinner']" [spin]="true" ng></fa-icon></ng-container>
                </span>
              </div>
              <div class="col-xs-3 col-sm-3">
                <!--icons -->
                <small class="text-muted float-right">
                  <!-- attachment icon -->
                  <ng-container *ngIf="message.attachmentsCount > 0">
                    {{ message.attachmentsCount }}&nbsp;<fa-icon [icon]="['fas', 'paperclip']" aria-hidden="true"></fa-icon>&nbsp;
                  </ng-container>

                  <!-- created at -->
                  <!-- https://momentjs.com/docs/#/displaying/ Localized formats reference -->
                  <fa-icon [icon]="['fas', 'clock']" aria-hidden="true"></fa-icon>&nbsp;{{ message.createdAt.format('lll') }}
                </small>
              </div>
            </div>

          </div>

          <!-- content -->
          <div class="row" *ngIf="message.mail && message.shouldCollapse">
            <!-- recipients -->
            <small class="text-muted col-sm-12 mail-to">{{ 'to' | translate }}: {{ message.mail.to.join(', ') }}</small>
            <!-- cc -->
            <small class="text-muted col-sm-12 mail-cc" *ngIf="message.mail.cc.length > 0">{{ 'Cc' | translate }}: {{ message.mail.cc.join(', ') }}</small>
            <!-- bcc -->
            <small class="text-muted col-sm-12 mail-bcc" *ngIf="message.mail.bcc.length > 0">{{ 'Bcc' | translate }}: {{ message.mail.bcc.join(', ') }}</small>

            <!-- body -->
            <div class="col-sm-12 mail-body" [innerHtml]="message.mail.content"></div>

            <!-- attachments -->
            <div *ngIf="message.mail.attachments.length > 0 && !message.mail.email_infected_tag" class="col-sm-12 mail-attachments">
              <hr class="separator"/>
              <span class="text-capitalize ">{{ 'attachments' | translate }}:&nbsp;</span>
              <ng-container *ngFor="let attachment of message.mail.attachments;">
                <fa-icon [icon]="['fas', 'paperclip']" aria-hidden="true"></fa-icon>&nbsp;{{ attachment.filename }}
                  <a (click)="downloadAttachment(attachment)" href="#">
                  <fa-icon [icon]="['fas', 'download']" class="file-icon-download" matTooltip="{{ 'download_file' | translate}}"></fa-icon>
                </a>&nbsp;
            </ng-container>
            </div>
            <div *ngIf="message.mail.attachments.length > 0 && message.mail.email_infected_tag" class="col-sm-12 mail-attachments">
              <hr class="separator"/>
              <span class="text-capitalize ">{{ 'attachments' | translate }}:&nbsp;</span>
              <span class="text-danger">{{ 'email_infected_files' | translate}}&nbsp;</span>
              <fa-icon [icon]="['fas', 'exclamation-circle']" class="text-danger" matTooltip="{{ 'email_infected_files' | translate}}"></fa-icon>
            </div>
          </div>

          <!-- seperator -->
          <hr class="separator"/>
        </div>

        <!-- paginator -->
        <div class="more-seperator" *ngIf="thread.nextToken" >
            <button class="btn btn-outline-primary col-sm-12 btn-small" (click)="retrievedPreviousMessages(thread.nextToken)" [disabled]="isPaginating">
              <fa-icon *ngIf="isPaginating" [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
              <span *ngIf="! isPaginating">{{ 'more' | translate }}</span>
            </button>
          </div>
      </div>
    </ng-container>
  </div>
</div>
