import { Component, OnInit, Input } from '@angular/core';
import { Common } from '../../../../objects/common';
import { ListingIcons,
          PrimaryFields,
          NoIconFields,
          StatusFields,
          ObjectFields,
          SecondaryFields,
          StatusColors,
          CustomIcon,
          ListingTypes,
          AssetStatusColors,
          JobChecklistStatusColors,
          DisplayLabel
        } from '../../../../lists/listing-fields';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomTranslateService } from '../../../../services/custom-translate.service';
import { TranslateService } from '@ngx-translate/core';
import { Phone } from '../../../../objects/phone';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { CustomCurrencyPipe } from '../../../../pipes/custom-currency-pipe';
import { get, isEmpty, isArray } from 'lodash';
import { fallback, filled } from '../../../utils/common';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [CustomTranslateService]
})
export class ListComponent implements OnInit {

  @Input() strRecordUrl: string = '';
  @Input() rowData: any;
  @Input() rowType: any;
  @Input() rowToDisplay: any;
  @Input() currentModule: any;

  public arIcon: any;
  public arModuleData;
  public arDataDisplay = [];
  public strCurrentModule;
  public strFieldName;
  public arRowDisplay;
  public strId;
  public objStatusColors = new StatusColors;
  public objAssetStatusColors = new AssetStatusColors;
  public objJobChecklistStatusColors = new JobChecklistStatusColors;
  public arListingTypes = new ListingTypes;

  public hasDateColumn = false;
  public hasWordBreakableColumn = false;
  public isWordStillBreakable = false;
  public totalProfitValue;
  public totalProfitText;

  constructor(
    private sanitizer: DomSanitizer,
    public customTranslate: CustomTranslateService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    // Get current module
    this.strCurrentModule = this.currentModule;
    // row of data from api listing
    this.arModuleData = this.rowData;
    // Fields that are to display in listing (eg. first_name, company, status, phone)
    this.arRowDisplay = this.rowToDisplay;
    // Gets the id of the current row
    this.strId = this.arModuleData['id']

    // Loop through all row data
    for (var intRowCount = 0; intRowCount < this.arRowDisplay.length ; intRowCount++) {

      // Get the current field name
      this.strFieldName = this.arRowDisplay[intRowCount];

      // Get the current field data to be displayed
      var fieldData = this.arModuleData[this.strFieldName];

      /**
       * TODO: This isn't really how I think we should do this, we should
       * have an object ex. Lead, and inside the Lead we have a phone property
       * that we expect to be an array of Phones but for now, let's manually map.
       */
      if (Array.isArray(fieldData) && this.strFieldName === 'phone') {
        // Let's check if we can cast the first item as a phone.
        if (fieldData.length != 0 && fieldData[0] as Phone) {
          // If yes then let's create an array of phones.
          fieldData = fieldData.map(element => { return new Phone(element)});
        }
      }

      // Check if field name is first name and always concatinate the last name
     // Check if field name is first name and always concatinate the last name
     let strAdditonalElement = this.sanitizer.bypassSecurityTrustHtml('<div kat-' +this.strFieldName+ '="' +fieldData+ '"><div>');
     if (this.strFieldName == 'first_name') {
      // Check get first name and last name
      let strFirstName = (this.arModuleData['first_name']) ? this.arModuleData['first_name'] : '';
      let strLastName = (this.arModuleData['last_name']) ? this.arModuleData['last_name'] : '';
      let strCompleteName = strFirstName+ ' ' +strLastName;
      this.arDataDisplay.push([this.strFieldName, (strCompleteName.trim()) ? strCompleteName.trim() : '--', strAdditonalElement]);
      } else if (this.strFieldName == 'department_id'){
        let arDepartmentDisplay = fieldData.map(({ text }) => text);
        this.arDataDisplay.push([this.strFieldName, arDepartmentDisplay, strAdditonalElement]);
      } else {
        this.arDataDisplay.push([this.strFieldName, fieldData, strAdditonalElement]);
      }
    }
    // Check if column has date field
    this.hasDateColumn = this.isDate(this.arRowDisplay, true)
    // Check if column expects long data (eg. Address)
    this.hasWordBreakableColumn = this.isWordBreakable(this.arRowDisplay);
    // Long data that will break after the first set of breakable words
    this.isWordStillBreakable = this.isWordBreakable(this.arRowDisplay, true);

    this.rowType['created_at'] = { type: 'datetime' };
    this.rowType['updated_at'] = { type: 'datetime' };

  }

  // Is field data is a primary field in the current module?
  // Check to change color of primary field
  isAPrimaryField(fieldData) {

    var arPrimaryFields = PrimaryFields[this.strCurrentModule];

    if (arPrimaryFields == fieldData) {
      return true;
    }

    return false;
  }

  // Is field data is a Secondary field in the current module?
  // Check to change color of Secondary field
  isASecondaryField(fieldData) {

    var arSecondaryFields = SecondaryFields[this.strCurrentModule].split(',');

    for (var i = 0; i < arSecondaryFields.length; i++) {
      if (arSecondaryFields[i] == fieldData) {
        return true;
      }
    }

    return false;
  }

  // Checks if fields ought to have icon (based on the design)
  hasIcon(fieldData) {

    var arNoIconFields = NoIconFields[this.strCurrentModule].split(',');

    for (var i = 0; i < arNoIconFields.length; i++) {
      if (arNoIconFields[i] == fieldData) {
        return false;
      }
    }

    return true;
  }



  // Changes color if circle icon based on status value
  // of the current module
  statusRepresentation(strStatus) {
    // Check if color status is set, if not return empty
    return (typeof (this.objStatusColors[this.strCurrentModule][strStatus]) ? this.objStatusColors[this.strCurrentModule][strStatus] : '')
  }

  // Changes the css text color based on the Asset Status in job module
  AssetStatusRepresentation(strStatus) {
    return (typeof (this.objAssetStatusColors['status'][strStatus]) ? this.objAssetStatusColors['status'][strStatus] : '')
  }


  /**
   * Gets the color of the job checklist status based on its value
   *
   * @param strStatus
   * @returns string
   */
  JobChecklistStatusRepresentation(strStatus): string {
    return (typeof (this.objJobChecklistStatusColors['status'][strStatus]) ? this.objJobChecklistStatusColors['status'][strStatus] : null)
  }


  // Is field data is an object type?
  isObject(fieldData) {
    if (typeof(fieldData) == 'object') {
      return true;
    }
    return false;
  }

  // Is field data is a string type?
  isString(fieldData) {
    if (typeof(fieldData) == 'string') {
      return true;
    }
    return false;
  }

  /*
  * Checks if field is an object in that module
  * eg. [{home: "835-650-416"}, {office: "032-395-734"}, {mobile: "035-910-320"}]
  * returns boolean
  */
  isInObjectFields(strFields) {
    var arObjectFields = ObjectFields[this.strCurrentModule].split(',');
    for (var i = 0; i < arObjectFields.length; i++) {
      if (arObjectFields[i] == strFields) {
        return true;
      }
    }
    return false;
  }

  /*
  * Formats the object of arrays passed
  * Sample passed data : [{home: "835-650-416"}, {office: "032-395-734"}, {mobile: "035-910-320"}]
  * Sample of return :  (eg. home : 700-564-007 office : 032-395-734 mobile : 035-910-320)
  */
  formatObject(fieldDisplay, fieldData) {
    if (! Array.isArray(fieldDisplay) && typeof fieldDisplay !== 'object') {
      return fieldData;
    }

    let strKey = Object.keys(fieldDisplay)[0];
    let strValue = Object.values(fieldDisplay)[0];

    switch (fieldData) {
      case 'phone':
        let arPhoneValue = [];

        switch (strKey) {
          case 'home':
            return arPhoneValue = ['home', strValue];
            break;
          case 'work':
            return arPhoneValue = ['phone-office', strValue];
            break;
          case 'others':
            return arPhoneValue = ['phone-plus', strValue];
            break;
        }

        return arPhoneValue = ['mobile-android-alt', strValue];
      break;
      case 'email_address':
        return strValue;
      break;
      case 'attributes':
        if (this.strCurrentModule === 'asset_types') {
          return (fieldDisplay['type'] && fieldDisplay['label']) ? this.translateService.instant(fieldDisplay['type']) + " : " +this.translateService.instant(fieldDisplay['label']) : '' ;
        } else {
          return strKey + " : " + strValue;
        }
      break;
      case 'tax_type':
        return strValue;
      break;
      case 'department_id':
        return fieldDisplay['text'];
      break;
      case 'total_profit':
        let profit = new CustomCurrencyPipe(this.localStorageService).transform(Number(fieldDisplay['total_profit']));
        let profitPercentage = fieldDisplay['profit_percentage'].toFixed(2);

        this.totalProfitText = profit + "<br />" + "(" + profitPercentage + "%)";
        this.totalProfitValue = fieldDisplay['total_profit'];
        return  profit + " (" + profitPercentage + "%)";
      break;
      case 'skills':
        return fieldDisplay['text'];
      case 'items':
        return fieldDisplay['name'];
      default:
        return strKey + " : " + strValue;
      break;
    }
  }

  // Check if field passed is a date field
  isDate(fieldData, isArray = false) {
    // Initialize all date fields in modules
    var arDates = [
      'date_follow_up',
      'created_at',
      'required_by',
      'po_date',
      'date_due',
      'date_invoice',
      'forecast_close_date',
      'updated_at',
      'invoice_due',
      'invoice_date',
      'due_date',
      'next_invoice_date',
      'last_invoice_date',
      'start_time',
      'next_job_date',
      'start_datetime',
      'end_datetime',
    ];

    if (isArray) {
      for (var i = 0; i < fieldData.length; i++) {
        if (arDates.includes(fieldData[i])) {
          return true;
        }
      }
      return false;
    }

    if (arDates.includes(fieldData)) {
      return true;
    }

    return false;
  }

  // Checks if fields expect long data (Eg. Addressed, Expected Close Date etc.)
  isWordBreakable(fieldData, second = false) {


    // Initialize all Breakable Words in fields in modules
    var arBreakableWords = [
      'address_summary',
      'site_text',
      'location',
      'dispatch_location',
      'created_at',
      'updated_at',
      'invoice_due',
      'invoice_date',
      'description'
    ];

      // Initialize all Breakable Words that wil
      // break after the 1st set (Address & some long dates)
    if (second) {
      arBreakableWords = [
        'company',
        'forecast_close_date',
        'po_date'
      ];
    }


    if (typeof fieldData == 'object') {
      for (var i = 0; i < fieldData.length; i++) {
        if (arBreakableWords.includes(fieldData[i])) {
          return true;
        }
      }
      return false;
    }

    if (arBreakableWords.includes(fieldData)) {
      return true;
    }

    return false;

  }

  // Formats date fields
  // Returns only date with MM/DD/YYYY format if no time is indicated (eg. 07/06/2018)
  // Returns date with time otherwise (eg. 07/06/2018 @ 7:53 am)
  formatDatetime(fieldData, fieldType) {
    if (! fieldData) {
      return '--';
    }

    let convertedDate = moment(fieldData);

    if (! convertedDate.isValid()) {
      return '--';
    }

    if (this.rowType[fieldType]['type'] == 'date') {
      return convertedDate.format('ll')
    }

    let convertedUTCDateTime = moment.utc(fieldData).toDate();
    return moment(convertedUTCDateTime).format('lll');
  }

  // Gets the Status field of the current module
  moduleStatus() {
    return StatusFields[this.strCurrentModule];
  }

  // Gets icon of the field
  getIcon(fieldData, fieldValue = '') {

      if (ListingIcons[fieldData]) {
        if (ListingIcons[fieldData] == 'custom-icon') {

          if (typeof (fieldValue) != 'string') {
            return CustomIcon[Object.keys(fieldValue)[0]];
          } else {
            if (CustomIcon[fieldValue]) {
              return CustomIcon[fieldValue];
            }
          }
        } else {

          return ListingIcons[fieldData]
        }
      }

      return null;
  }

  // Checks if status field is boolean
  // Especial cases for users and customers
  // That has boolean values on their statuses
  isStatusBoolean(strDataDisplay) {

    switch (this.strCurrentModule) {
      case 'customers':
        return (strDataDisplay == true) ? 'on_hold' : 'active';
      case 'sites':
        return (strDataDisplay == true) ? 'disabled_maintenance' : 'enabled_maintenance';
      case 'workflows':
      case 'items':
      case 'recurring_jobs':
      case 'recurring_invoices':
        return (strDataDisplay == true) ? 'active' : 'inactive';
      case 'roles':
        return (strDataDisplay == true) ? 'active' : 'inactive';
      case 'activity_log_types':
        return (strDataDisplay == true) ? 'productive' : 'unproductive';
      default:
        return strDataDisplay.toString();
    }
  }


  // Checks special cases in fields (if there's any) and display it
  defaultDisplay(field, value) {

    switch(field) {
      case 'is_checklist_enabled' :
        return value === true ? 'checklist_enabled' : 'checklist_disabled';
      case 'type' :
        if (this.strCurrentModule === 'checklists') {
          switch(value) {
            case 'job':
              return 'job_checklist';
            case 'opportunity':
              return 'quote_checklist';
            case 'asset':
              return 'assets_checklist';
          }
        } else {
          return value;
        }
      case 'document_size' :
        if (!isNaN(value)) {
          // Check if file (in kb) is less than 1000
          if (value > 1000) {
            // Convert kb to MB
            let floatFileSize = (value / 1024);
            // Round off with 2 decimal places only
            return Math.round(floatFileSize * 100) / 100 + ' mb';
          }

          // If size given is less than 1000 kb Return as is
          return Math.round(value * 100) / 100 + ' kb';

        } else {
          return '';
        }
      case 'opportunity_number' :
      case 'job_number' :
      case 'walk_order' :
      case 'invoice_number' :
      case 'job_text' :
      case 'po_number' :
      case 'purchase_order_text' :
        if (value.length <= 6) {
          while (value.length < 6) {
            value = "0" + value;
          }
        }
        return value;
      case 'activity_name':
        if (this.strCurrentModule === 'activities') {
          switch(value) {
            case 'phone':
              return 'events.phone';
            case 'in_person':
              return 'events.in_person';
            case 'web':
              return 'events.web';
            default:
              return value;
          }
        }
      case 'modules':
        if (isArray(value)) {
          return value.map(
            objModule => objModule.text ? this.translateService.instant(objModule.text) : ''
          ).filter(
            strModel => strModel
           ).join(', ');
        }
      case 'address_text':
        return (value === 'AL') ? '--' : value;
      case 'share_template_text':
        const strField: string = this.translateService.instant('share_template');
        const strYesNo: string = value === 'true' ? 'yes' : 'no';
        const strYesNoTranslated: string = this.translateService.instant(strYesNo);

        return `${strField}: ${strYesNoTranslated}`;
      default :
        if (this.arListingTypes.currencies.includes(field)) {
          return new CustomCurrencyPipe(this.localStorageService).transform(Number(value));
        } else {
          return (value != undefined && value != null) ? value.toString().trim() : value;
        }
    }
  }

  /**
   * Check if text is a number
   * @param strText
   */
  isNumber(strText) {
    return isNaN(strText);
  }

  /**
   * This is for changing the field name to translate correctly
   *
   * @param strFieldName
   */
  changeFieldName(strFieldName) {
    switch (strFieldName) {
      case 'first_name':
        return 'name';
      default:
        return strFieldName;
    }
  }

  /**
   * Change the displayed label to translate correctly
   *
   * @param strFieldName
   */
  changeRegularFieldName(strFieldName): string {
    switch (strFieldName) {
      case 'share_template_text':
        return null;
      case 'customer_type':
        if (this.arModuleData['is_customer'] && this.arModuleData['is_supplier']) {
          return 'customer_supplier_number';
        } else if (this.arModuleData['is_customer']) {
          return 'customer_number';
        } else if (this.arModuleData['is_supplier']) {
          return 'supplier_number';
        } else {
          return null;
        }
      default:
        return strFieldName;
    }
  }

  /*
  * Checks if label should be displayed alongside the field value
  * returns boolean
  */
  shouldDisplayLabel(strFields, strValue): boolean {
    return ! isEmpty(DisplayLabel[this.strCurrentModule]) && ! isEmpty(strValue) && DisplayLabel[this.strCurrentModule].split(',').includes(strFields);
  }

  /**
   * Displays the hover tooltip values for regular fields
   *
   * @param {any[]} arData
   *
   * @returns {string|null}
   */
  getRegularFieldHoverTooltip(arData: any[]): string|null {
    if (arData.length < 3) {
      return null;
    }

    if (this.strCurrentModule === 'items' && arData[0] === 'unit_price') {
        const strField: string = get(this.arModuleData, 'labor') === true ? 'hourly_cost' : 'unit_cost';
        const strTranslatedField: string = this.translateService.instant(strField);
        const strCostValue: string = fallback(get(this.arModuleData, strField, '0'), {
          fallback: () => '0'
        });

        return `${strTranslatedField}: ${strCostValue}`;
    } else {
      const strFieldName: string = this.changeRegularFieldName(arData[0]);
      const strDisplayValue: string = this.defaultDisplay(arData[0], arData[1]);

      if (filled(strFieldName) && filled(strDisplayValue)) {
        const strTranslatedFieldName: string = this.translateService.instant(strFieldName);
        const strTranslatedDisplayValue: string = this.translateService.instant(strDisplayValue);

        return `${strTranslatedFieldName}: ${strTranslatedDisplayValue}`;
      } else {
        return null;
      }
    }
  }
}
