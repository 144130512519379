import { Observable, Subject, concat, OperatorFunction, of, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap, filter } from "rxjs/operators";
import { blank, filled } from '../shared/utils/common';

/**
 * How to use:
 *
 * <ng-select
 *    [items]="this.source | async"
 *    [typeahead]="this.typehead"
 *    [loading]="this.loader"
 *    [(ngModel)]="this.value or this.model">
 * </ng-select>
 *
 * In Relate<L, V>, L stands for the "List" type.
 * V is for the "Value" type of the items in the List.
 *
 * Note: Use "value" in the ngModel if you do not require
 * a previous value, but if you need to get the previous
 * value, use "model" instead.
 *
 */
export class Relate<V> {

  /**
   * For adding data on the fly in the source observable.
   *
   * @var {Subject<V[]>}
   */
  $source = new Subject<V[]>();

  /**
   * The observable that will handle the result
   * of the http calls when typing.
   *
   * @var {Observable}
   */
  public source: Observable<V[]>;

  /**
   * The observable that awaits the data
   * the user will input.
   *
   * @var {Subject}
   */
  public typehead: Subject<string>;

  /**
   * Simple boolean to identify
   * if the data has been loaded.
   *
   * @var {boolean}
   */
  public loader: boolean;

  /**
   * Holds the value of the ng-select.
   *
   * @var {V | null}
   */
  public value: V = null;

  /**
   * Holds the previous value of the ng-select.
   *
   * @var {V | null}
   */
   public previous_value: V | null = null;

  /**
   * Get the value for this related.
   *
   * @return {V | null}
   */
  get model(): V | null {
    return this.value;
  }

  /**
   * Set the value for the relate while
   * also saving previous value for
   * whatever purpose it may serve.
   *
   * @var {V | null}
   */
  set model(value: V | null) {
    this.previous_value = this.value;
    this.value = value;
  }

  constructor() {
    this.source = new Observable<V[]>();
    this.typehead = new Subject<string>();
    this.loader = false;

    return this;
  }

  /**
   * Build all the necessary properties
   * to create an ng select that is a relate
   * field.
   *
   * @param {OperatorFunction<any, any>} objSwitchMap
   *  - The switch map which will contain the observable.
   * @param {Observable<L> | L} arInitialValue
   *  - Could either be a static value or an observable.
   *  Note: If observable, only triggers once then closes the observable.
   *
   * @returns {Relate<T>}
   */
  buildRelates(
    objSwitchMap: OperatorFunction<any, any>,
    arInitialRelateContent: Observable<V[]> | V[] = null,
    bSetInitialValue: boolean = false
  ): Relate<V> {

    let obvTypehead = this.typehead.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => this.loader = true),
      objSwitchMap,
      tap(() => this.loader = false)
    );

    if (arInitialRelateContent) {
      if (arInitialRelateContent instanceof Observable) {
        this.source = merge(arInitialRelateContent.take(1), obvTypehead, this.$source.asObservable());
      } else {
        this.source = merge(of(arInitialRelateContent || []), obvTypehead, this.$source.asObservable());
      }
    } else {
      this.source = merge(obvTypehead, this.$source.asObservable());
    }

    if (bSetInitialValue === true && blank(this.value) && filled(arInitialRelateContent)) {
      this.value = arInitialRelateContent[0];
    }

    return this;
  }

  /**
   * Destroys the typehead. As for the source,
   * there is no need to destroy it
   * as we are not creating a subscription on it.
   *
   * @return {void}
   */
  destroyTypehead(): void {
    this.typehead.unsubscribe();
  }

  /**
   * If you want the option to load data only on click,
   * simply use this on (open) in ngSelect.
   *
   * @return {void}
   */
  loadDataOnClick(strType: string | null = null) {
    this.typehead.next(strType);
  }
}

