
    <div class="w-100 d-flex flex-column d-flex-gap">
      <textarea
        class="form-control font-size-11 {{ expandableKey }}"
        [ngClass]="{'input-height-40' : expandable}"
        [disabled]="isDisabled$ | async"
        (change)="onChange($event.target.value)"
        (keyup)="onKeyUp($event.target.value)"
        (blur)="onTouched()"
        [attr.maxLength]="max"
        [attr.rows]="rows"
        [value]="value"
        (focus)="(expandable) ? onFocusIn() : ''"
        (focusout)="(expandable) ? onFocusOut() : ''"
      ></textarea>
      <div
        *ngIf="max != undefined"
        class="d-flex w-100 justify-content-end"
      >
        <small class="text-help">{{ remaining$ | async }}/{{max}}</small>
      </div>
    </div>
  