import { tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ViewService } from '../../../services/view.service';
import { RecordService } from '../../../services/record.service';
import { ListingService } from '../../../services/listing.service';
import { NotificationService } from '../../../services/notification.service';
import { InvoicesComponent } from '../../jobs/customer-invoices/invoices.component';
import { MaterialsService } from '../../../services/materials.service';

@Component({
  selector: 'customer-invoices-from-recurring-invoices',
  templateUrl: './customer-invoices-from-recurring-invoices.component.html',
  styleUrls: ['./customer-invoices-from-recurring-invoices.component.scss']
})
export class CustomerInvoicesFromRecurringInvoicesComponent extends InvoicesComponent implements OnInit {

  // For checking current recordId
  strCurrentId: string;

  // Widget Customer Invoice Status Filter Items
  arWidgetCustomerInvoiceStatusFilterItems = [
    {id: 'created', name: 'created'},
    {id: 'sent_to_client', name: 'sent_to_client'},
    {id: 'paid', name: 'paid'},
    {id: 'voided', name: 'voided'}
  ];

  constructor(
    dialog: MatDialog,
    listService: ListingService,
    notifService: NotificationService,
    viewService: ViewService,
    recordService: RecordService,
    protected route: ActivatedRoute,
    protected materialsService: MaterialsService,
  ) {
    super(dialog, listService, notifService, viewService, recordService, materialsService);
  }

  ngOnInit() {
    this
      .route
      .params
      .pipe(
        tap(({id}) => this.strRecordId = id),
      )
      .subscribe();

    super.ngOnInit();
  }

  /**
   * @inheritDoc
   */
  fetchList(strPage, objFilter: {} = {}) {
    const objPagination = this.listService.beforeFetching(strPage);

    objFilter['recurring_invoice_id'] = this.strRecordId;

    this.bPageLoaded = false;

    this
      .listService
      .fetchDataAdvanceSearch
      (objPagination['objPage'],
        'customer_invoices',
        objFilter,
        { 'id': 'created_at', 'sort': 'desc' },
      )
      .pipe(
        tap(objResponse => this.arInvoices = objResponse['data']),
        tap(objResponse => this.listService.afterFetching(objResponse, strPage)),
        tap(() => this.bPageLoaded = true),
      )
      .subscribe();
  }

  //Check if strRecordId changes
  ngDoCheck(): void {
    if(this.strCurrentId !== this.strRecordId){
      this.ngOnChanges();
      this.strCurrentId = this.strRecordId;
    }
  }

  ngOnChanges(): void {
    this.onRefresh();
  }
}
