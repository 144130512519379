import { Component, Input, ContentChild, TemplateRef, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filled } from '../../../../shared/utils/common';
import { get } from 'lodash';

type ErrorMessageFunction = (params?: any) => string;

@Component({
  selector: 'app-validation-messages',
  templateUrl: './validation-messages.component.html',
  styleUrls: ['./validation-messages.component.scss']
})
export class ValidationMessagesComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl;
  @Input() messages: { [key: string]: ErrorMessageFunction } = {};

  @ContentChild(TemplateRef) customTemplate: TemplateRef<any>;

  private defaultMessages: { [key: string]: ErrorMessageFunction } = {
    required: (params?: any) => {
      return this.translate.instant('validation_error_messages.required');
    },
    minlength: (params: { requiredLength: number }) => {
      return this.translate.instant('validation_error_messages.minlength', { requiredLength: params.requiredLength });
    },
    maxlength: (params: { requiredLength: number }) => {
      return this.translate.instant('validation_error_messages.maxlength', { requiredLength: params.requiredLength });
    },
    email: (params?: any) => {
      return this.translate.instant('validation_error_messages.email');
    },
    pattern: (params?: any) => {
      return this.translate.instant('validation_error_messages.pattern');
    },
    min: (params: { min: number }) => {
      return this.translate.instant('validation_error_messages.min', { min: params.min });
    },
    max: (params: { max: number }) => {
      return this.translate.instant('validation_error_messages.max', { max: params.max });
    },
  };;

  private langChangeSub: Subscription;
  private valueChangeSub: Subscription;

  errorMessage: string;

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.errorMessage = this.getErrorMessage();
    this.valueChangeSub = this.control.valueChanges.subscribe(() => {
      this.errorMessage = this.getErrorMessage();
    });

    // Retranslate the values if the language change.
    this.langChangeSub = this.translate.onLangChange.subscribe(() => {
      this.errorMessage = this.getErrorMessage();
    });
  }

  ngOnDestroy() {
    if (this.langChangeSub) {
      this.langChangeSub.unsubscribe();
    }
    if (this.valueChangeSub) {
      this.valueChangeSub.unsubscribe();
    }
  }

  getErrorMessage() {
    let errorMessage = null;
    if (filled(get(this, 'control.errors')) === false) {
      return errorMessage;
    }

    const errors = this.control.errors;
    const firstErrorKey = Object.keys(errors)[0];

    if (!filled(firstErrorKey)) {
      return errorMessage;
    }

    const errorParams = errors[firstErrorKey];
    if (this.messages[firstErrorKey] !== undefined) {
      return this.messages[firstErrorKey](errorParams);
    } else if (this.defaultMessages[firstErrorKey] !== undefined) {
      return this.defaultMessages[firstErrorKey](errorParams);
    } else {
      return this.translate.instant('validation_error_messages.default')
    }
  }
}
