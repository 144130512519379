<header>
  <div class="d-flex dialog-header">
    <h3 class="task-title">
      <fa-icon [icon]="['fas', 'clone']" class="mr-1"></fa-icon>
      <span>{{ 'duplicate' | translate }} {{ 'task' | translate }} - {{ taskMetadata.activity_name | truncate }}</span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <fieldmagic-primary-button
            id="btnSave"
            (click)="saveTask()"
            [disabled]="isLoading"
            [isInProgress]="isLoading"
            icon="save"
            label="save"
          >
          ></fieldmagic-primary-button>
        </li>
        <li class="list-inline-item">
          <fieldmagic-primary-button
            variant="error"
            id="btnClose"
            (click)="closeDialog()"
            [disabled]="isLoading"
            icon="times"
            label="close"
          ></fieldmagic-primary-button>
        </li>
      </ul>
    </div>
  </div>
</header>
<hr class="separator"/>
<mat-dialog-content>
  <form [formGroup]="taskDuplicateForm">
    <div class="form-group">
      <label>{{ duplicateTaskMetadata['number_of_copies'].label | translate }} <span class="text-danger">*</span></label>
      <ng-select
        formControlName="number_of_copies"
        bindLabel="text"
        bindValue="id"
        [items]="duplicateTaskMetadata['number_of_copies'].options"
        appendTo="body"
        [clearable]="false">
      </ng-select>
    </div>
    <div class="form-group">
      <label> {{ duplicateTaskMetadata['new_task_on'].label | translate }}: <span class="text-danger">*</span></label>
      <ng-select
        formControlName="new_task_on"
        bindLabel="text"
        bindValue="id"
        [items]="duplicateTaskMetadata['new_task_on'].options"
        appendTo="body"
        [clearable]="false">
          <ng-template ng-option-tmp let-item="item">{{ item.text | translate }}</ng-template>
          <ng-template ng-label-tmp let-item="item">{{ item.text | translate }}</ng-template>
      </ng-select>
    </div>
    <div class="form-group">
      <label>{{ duplicateTaskMetadata['task_duration'].label | translate }}: <span class="text-danger">*</span></label>
      <input
        formControlName="task_duration"
        type="number"
        class="form-control"
        minlength="0"
        (change) = "formatTaskDuration($event)"/>
    </div>
    <div class="form-group">
      <label> {{ objRelateConfig.label  | translate }} <span class="text-danger">*</span></label>
      <ng-select
        [items]="objRelateConfig.relate.source | async"
        [typeahead]="objRelateConfig.relate.typehead"
        [loading]="objRelateConfig.relate.loader"
        [clearable]="true"
        [ngModelOptions]="{standalone: true}"
        [ngClass]="{'is-invalid': !isTechnicianValid}"
        bindLabel="text"
        [bindValue]
        (change)="checkValidTechnician($event)"
        [(ngModel)]="objUserData"
        (open)="objRelateConfig.relate.loadDataOnClick()"
        [id]="objRelateConfig.label"
        appendTo="body"
        [name]="objRelateConfig.label">
      </ng-select>
    </div>
  </form>
</mat-dialog-content>
