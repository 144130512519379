// List of corresponding icons of fields
export enum ListingIcons {
    'first_name' = 'user',
    'user_id' = 'user',
    'user_text' = 'user',
    'contact_text' = 'user',

    'company' = 'building',
    'name' = 'building',
    'customer_text' = 'building',
    'supplier_text' = 'building',
    'address_text' = 'map-marker-alt',
    'site_text' = 'map-marker-alt',
    'location' = 'map-marker-alt',
    'dispatch_location' = 'map-marker-alt',

    'department_name' = 'sitemap',
    'department_text' = 'sitemap',
    'department_id' = 'sitemap',

    'code' = 'hashtag',
    'purchase_order_text' = 'hashtag',
    'invoice_number' = 'hashtag',
    'opportunity_number' = 'hashtag',
    'customer_number' = 'hashtag',
    'supplier_number' = 'hashtag',
    'site_number' = 'hashtag',
    'job_number' = 'hashtag',
    'walk_order' = 'hashtag',
    'serial_number' = 'hashtag',
    'job_text' = 'hashtag',
    'po_number' = 'hashtag',

    'invoicing_type' = 'clipboard-list',
    'asset_type_text' = 'clipboard-list',

    'description' = 'book',
    'invoice_summary' = 'align-justify',
    'job_summary' = 'align-justify',
    'job_title' = 'address-card',
    'attributes' = 'toolbox',
    'substatus' = 'project-diagram',
    'phone' = 'mobile-alt',
    'email_address' = 'envelope',
    'current_stock_level' = 'chart-line',
    'rate' = 'percentage',
    'default_discount' = 'percentage',
    'level' = 'sitemap',
    'special_notes' = 'sticky-note',
    'project_name' = 'tasks',
    'activity_type' = 'custom-icon',
    'tax_type' = 'custom-icon',
    'module' = 'tasks',
    'created_by' = 'user',
    'actual_duration' = 'clock',
    'category' = 'grip-horizontal',
    'unit_cost' = 'dollar-sign',
    'attachment' = 'paperclip',
    'product_name' = 'box-open',
    'period' = 'calendar-alt',
    'priority' = 'exclamation',
    'next_job_date' = 'calendar-alt',
    'period_text' = 'clock',
    'created_by_name' = 'user',
    'quantity' = 'hashtag',
    'minimum_quantity' = 'hashtag',
    'reorder_quantity' = 'cart-plus',
    'roles' = 'user-cog',
    'warehouse_name' = 'warehouse-alt',
    'warehouse_stock_level' = 'chart-line',
    'share_template_text' = 'share-alt',
    rows_successfully_imported_text= 'check',
    rows_with_errors_text= 'times',
    total_rows_text = 'equals',
    skills = 'toolbox',
    items = 'box-open',
};

export class ListingTypes {
  currencies = [
    'amount_due',
    'amount_to_invoice',
    'amount_actually_invoiced',
    'amount',
    'unit_price',
    'unit_cost',
    'price',
    'amount_tax_ex',
    'amount_tax_excluded',
    'amount_tax_included',
    'amount_tax',
    'amount_tax_inc',
  ]
}

// Primary Fields have different text size and is blue in color
export enum PrimaryFields {
  'leads' = 'first_name',
  'customers' = 'name',
  'sites' = 'customer_text',
  'teams' = 'name',
  'contacts' = 'first_name',
  'opportunities' = 'opportunity_number',
  'jobs' = 'job_number',
  'assets' = 'serial_number',
  'customer_invoices' = 'invoice_number',
  'purchase_orders' = 'po_number',
  'supplier_invoices' = 'invoice_number',
  'users' = 'user_text',
  'resources' = 'name',
  'departments' = 'department_name',
  'items' = 'name',
  'workflows' = 'name',
  'pricebooks' = 'name',
  'tax_codes' = 'name',
  'document_library' = 'document_name',
  'asset_types' = 'name',
  'project_templates' = 'project_name',
  'account_codes' = 'name',
  'job_skills' = 'name',
  'checklists' = 'name',
  'activities' = 'activity_name',
  'materials' = '',
  'time_entries' = '',
  'email_templates' = 'name',
  'sms_templates' = 'name',
  imports = 'module',
  recurring_jobs = 'customer_text',
  recurring_invoices = 'customer_text',
  warehouses = 'name',
  stock_levels = 'product_name',
  roles = 'name',
  activity_logs = '',
  activity_log_types = 'name',
  subcontractors = 'name',
  stocktakes = '',
  credit_notes = '',
};

// Secondary fields have different font weight and is grey in color
export enum SecondaryFields {
  'leads' = 'company',
  'customers' = 'address_text',
  'sites' = 'address_summary',
  'contacts' = 'job_title,customer_text',
  'opportunities' = 'opportunity_name,stage,site_text',
  'jobs' = 'customer_text,site_text',
  'assets' = 'site_text',
  'customer_invoices' = 'customer_text,site_text',
  'purchase_orders' = 'customer_text,contact_text',
  'supplier_invoices' = 'supplier_text,contact_text',
  'users' = 'level',
  'resources' = '',
  'departments' = '',
  'workflows' = '',
  'items' = 'code,unit_price,unit_cost',
  'pricebooks' = 'default_price',
  'tax_codes' = 'code,tax',
  'account_codes' = 'code,tax',
  'project_templates' = '',
  'document_library' = '',
  'asset_types' = 'primary',
  'job_skills' = '',
  'checklists' = '',
  'activities' = '',
  'materials' = '',
  'time_entries' = '',
  'email_templates' = 'module',
  'sms_templates' = 'module',
  'job_safety_sub_task_templates' = 'sub_task',
  imports = 'progress',
  'contact_roles' = '',
  'recurring_invoices' = '',
  'recurring_jobs' = '',
  'exports' = '',
  'warehouses' = 'address_text',
  'stock_levels' = 'product_code,quantity',
  roles = '',
  job_templates = '',
  activity_logs = '',
  activity_log_types = '',
  subcontractors = '',
  stocktakes = '',
  credit_notes = '',
  'advance_reports' = '',
};

// List of fields that have no icons (Per module)
export enum NoIconFields {
  'leads' = 'first_name,company',
  'customers' = 'name,address_text,status,on_hold,customer_type',
  'sites' = 'address_text,tenant,customer_text,location_name,unit,level,lot_number,address_summary',
  'contacts' = 'first_name,job_title,customer_text',
  'opportunities' = 'stage,customer_text,site_text,opportunity_name,opportunity_checklist_status',
  'jobs' = 'customer_text,site_text,asset_status,job_checklist_status',
  'assets' = 'site_text',
  'customer_invoices' = 'customer_text,site_text',
  'purchase_orders' = 'customer_text,contact_text,job_stage',
  'supplier_invoices' = 'supplier_text,contact_text',
  'users' = 'user_text,level,status',
  'resources' = 'name',
  'departments' = 'department_name',
  'items' = 'name,code',
  'workflows' ='name',
  'pricebooks' = 'name',
  'tax_codes' = 'name',
  'teams' = 'name',
  'document_library' = 'document_name,upload_name,document_size',
  'account_codes' = 'name',
  'asset_types' = 'name',
  'project_templates' = 'project_name',
  'job_skills' = 'name',
  'checklists' = 'name,type,asset_type_text,is_checklist_enabled',
  'activities' = 'activity_name',
  'materials' = 'type,product,quantity',
  'time_entries' = '',
  'email_templates' = 'name,subject,module',
  'sms_templates' = 'name,module,message',
  'job_safety_sub_task_templates' = 'name',
  'contact_roles' = 'role,text',
  recurring_invoices = '',
  recurring_jobs = '',
  exports = 'module,modules',
  warehouses = 'name,item_id,status,type,qr_code,address',
  stock_levels = 'product_name,product_code,unit_price,unit',
  imports = 'detailed_progress_text,rows_successfully_imported_text,rows_with_errors_text,total_rows_text',
  roles = 'name',
  job_templates = '',
  activity_logs = '',
  activity_log_types = 'name',
  subcontractors = 'name,skills',
  stocktakes = 'warehouse_text',
  credit_notes = 'type,status,invoice_type',
  'advance_reports' = '',
};

// Corresponding statuses per module
export enum StatusFields {
  'leads' = 'status',
  'customers' = 'on_hold',
  'sites' = 'disable_maintenance',
  'contacts' = '',
  'opportunities' = 'status',
  'jobs' = 'status',
  'assets' = 'status',
  'customer_invoices' = 'status',
  'purchase_orders' = 'status',
  'supplier_invoices' = 'status',
  'users' = 'status',
  'resources' = '',
  'departments' = '',
  'items' = 'active',
  'workflows' = 'is_active',
  'pricebooks' = '',
  'tax_codes' = '',
  'project_templates' = '',
  'job_skills' = '',
  'document_library' = '',
  'account_codes' = '',
  'asset_types' = '',
  'checklists' = '',
  'activities' = '',
  'materials' = '',
  'time_entries' = '',
  'sms_templates' = '',
  'warehouses' = 'status',
  'stock_levels' = '',
  imports = '',
  recurring_jobs = 'status',
  recurring_invoices = 'is_active',
  exports = 'status',
  roles = '',
  activity_logs = '',
  activity_log_types = 'is_productive',
  credit_notes = '',
  'advance_reports' = '',
}

// List of fields that are in Object format (per module)
export enum ObjectFields {
  'leads' = 'phone,email_address',
  'customers' = 'phone,email_address,user_id',
  'sites' = 'email_address,phone',
  'teams' = '',
  'contacts' = 'phone,email_address',
  'opportunities' = 'total_profit',
  'jobs' = '',
  'assets' = 'attributes',
  'customer_invoices' = '',
  'purchase_orders' = '',
  'supplier_invoices' = '',
  'users' = 'department_id,phone',
  'resources' = 'attributes',
  'departments' = '',
  'items' = '',
  'workflows' = '',
  'pricebooks' = 'lines',
  'tax_codes' = 'tax_type',
  'project_templates' = '',
  'job_skills' = '',
  'document_library' = '',
  'account_codes' = '',
  'asset_types' = 'attributes',
  'checklists' = 'questions',
  'activities' = '',
  'materials' = '',
  'time_entries' = '',
  'email_templates' = 'attachment',
  'sms_templates' = '',
  'job_safety_sub_task_templates' = '',
  imports = '',
  'contact_roles' = '',
  recurring_jobs = '',
  recurring_invoices = '',
  exports = '',
  warehouses = '',
  stock_levels = '',
  roles = '',
  job_templates = '',
  activity_logs = '',
  activity_log_types = '',
  subcontractors = 'skills',
  stocktakes = 'items',
  credit_notes = '',
  'advance_reports' = '',
}

export class AssetStatusColors {
  status = {
      'no_assets': 'text-no-display',
      'pending_testing': 'text-no-display',
      'asset_failures': 'text-danger',
      'asset_quote_sent': 'text-primary',
      'no_failures': 'text-success'
  }
}

export class JobChecklistStatusColors {
  status = {
      'no_checklists': 'text-no-display',
      'pending_completion': 'text-no-display',
      'fail': 'text-danger',
      'review_complete': 'text-primary',
      'pass': 'text-success'
  }
}

// List of statuses and their corresponding colors (Per Module)
export class StatusColors {

  jobs = {
    'awaiting_scheduling' : 'text-secondary' ,
    'scheduled' : 'text-primary',
    'pending' : 'text-warning',
    'awaiting_invoicing' : 'text-success',
    'completed' : 'text-invoiced',
    'cancelled' : 'text-danger',
  };

  assets = {
    'fail' : 'text-danger',
    'not_tested' : 'text-warning',
    'pass' : 'text-success'
  };

  users = {
    'active' : 'text-success',
    'inactive' : 'text-danger'
  };

  workflows = {
    'true' : 'text-success',
    'false' : 'text-danger'
  }

  sites = {
    'true' : 'text-danger',
    'false' : 'text-success'
  };

  customers = {
    'true' : 'text-danger',
    'false' : 'text-success'
  };

  leads = {
    'new' : 'text-success',
    'assigned' : 'text-warning',
    'converted' : 'text-secondary',
    'disqualified' : 'text-danger',
  };

  opportunities = {
    'open' : 'text-success',
    'closed_won' : 'text-primary',
    'closed_lost' : 'text-danger'
  };

  purchase_orders = {
    'draft' : 'text-secondary',
    'sent' : 'text-success',
    'partially_delivered' : 'text-warning',
    'delivered': 'text-info'
  };

  customer_invoices = {
    'created' : 'text-secondary',
    'sent_to_client' : 'text-warning',
    'paid' : 'text-success',
    'voided': 'text-danger'
  };

  supplier_invoices = {
    'created' : 'text-secondary',
    'sent_to_client' : 'text-warning',
    'paid' : 'text-success',
    'voided': 'text-danger'
  };

  items = {
    'true' : 'text-success',
    'false' : 'text-danger'
  }

  checklist_response = {
    'awaiting_completion' : 'text-warning',
    'fail' : 'text-danger',
    'pass' : 'text-success'
  }

  warehouses = {
    'active' : 'text-success',
    'inactive' : 'text-warning'
  }

  recurring_jobs = {
    true: 'text-success',
    false: 'text-danger',
  }

  recurring_invoices = {
    true: 'text-success',
    false: 'text-danger',
  }

  exports = {
    'success' : 'text-success',
    'in_progress' : 'text-warning',
    'failed' : 'text-danger',
  };

  activity_log_types = {
    true: 'text-success',
    false: 'text-danger',
  };
}

// List of Icons used in Prompt Types (CHECKLIST)
export enum PromptTypeIcons {
  'image' = 'file-image',
  'text' = 'file-alt',
  'pass/fail' = 'check-double',
  'signature' = 'signature',
  'dropdown' = 'caret-circle-down',
  'field_entry' = 'marker',
  'instructions' = 'list-ul',
  'date' = 'calendar-alt',
  'number' = 'hashtag'
}

// List of dynamic values from listing icon
// ex. activitiy_type it has different values and we need different icon for them
export enum CustomIcon {
  'event' = 'calendar',
  'note' = 'sticky-note',
  'task' = 'tasks',
  'email' = 'envelope',
  'message' = 'sms',
  'sales' = 'dollar-sign',
  'purchase' = 'shopping-bag'
}

export enum DisplayLabel {
  'sites' = 'unit,level,lot_number,tenant,location_name',
  'imports' = 'rows_successfully_imported_text,rows_with_errors_text,total_rows_text'
};
export class ModuleStatusValueTranslations {
  recurring_jobs = {
    true: 'active',
    false: 'inactive',
  };

  recurring_invoices = {
    true: 'active',
    false: 'inactive',
  };

  activity_log_types = {
    true: 'productive',
    false: 'unproductive',
  };
}