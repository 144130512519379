import { Component, OnInit, Inject } from '@angular/core';
import { isFunction } from 'lodash';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { ViewService } from '../../../../../../../services/view.service';
import { RecordService } from '../../../../../../../services/record.service';
import { NotificationService } from '../../../../../../../services/notification.service';
import { Select } from "./../../../../../../../objects/select";
import { DialogButtonConfig } from "./../../../../../../../objects/dialog-button-config";
import { BehaviorSubject } from 'rxjs';
import { map, filter, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-annual-asset-report',
  templateUrl: './annual-asset-report.component.html',
  styleUrls: ['./annual-asset-report.component.scss']
})
export class AnnualAssetReportComponent implements OnInit {

  public bFailedAssetsOnly: boolean = false;
  public bCustomField: boolean = false;
  public arSelectedIds: Array<string> = [];
  public bSelectAll: boolean = false;

  /**
   * @type {Select[]}
   */
  public options: Select[] = [];

  /**
   * @type {BehaviorSubject<boolean>}
   */
  readonly loading$ = new BehaviorSubject(true);

  /**
   * @type {DialogButtonConfig[]}
   */
  public arButtons: DialogButtonConfig[] = [
    {
      icon: (this.data.has_custom_field) ? null : ['fas', 'file-pdf'],
      text: (this.data.has_custom_field) ? 'continue' : 'preview',
      callback: 'onSubmit',
      disabled: this.loading$,
    },
    {
      icon: ['fas', 'times'],
      text: 'close',
      callback: 'closeDialog'
    },
  ]

  constructor(
    private viewService: ViewService,
    private recordService: RecordService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<AnnualAssetReportComponent>
  ) { }

  ngOnInit() {
    this.getAssetType(this.data.id)
  }

  /**
   * get asset type data
   *
   * @param siteId
   *
   * @returns {void}
   */
  getAssetType(siteId: string): void {
    this.recordService.getAssetUniqueAssetTypes('assets', {
      site_id: siteId
    }).pipe(
      finalize(() => this.loading$.next(false)),
      map((objData) => objData.body.map(objAsset => {
        return new Select(objAsset['id'], objAsset['text'], '', null, false);
      })),
      filter((arOptions) => arOptions.length)
    ).subscribe(arOptions => {
      this.options = arOptions;
    });
  }

  /**
   * select option and store in filter
   *
   * @param event MatCheckboxChange
   * @param {Select} record set of dropdown option
   * @param {boolean} selectAll determine we need to select all data
   */
  markAsSelected(event, record: Select, markAll: boolean = false ): void {
    if (event.checked) {
      if (markAll) {
        this.options.forEach( option => {
          option.selected = true;
          this.arSelectedIds.push(option.id);
        });
      } else {
        this.arSelectedIds.push(record.id);
      }
    } else {
      this.bSelectAll = false;
      if (markAll) {
        this.options.forEach( option => {
          option.selected = false;
        });
        this.arSelectedIds = [];
      } else {
        this.arSelectedIds = this.arSelectedIds.filter( id => id != record.id);
      }
    }
  }

  /**
   * close the dialog
   *
   * @returns {void}
   */
  closeDialog(data: object = null): void {
    this.dialogRef.close(data);
  }

  /**
   * submit dialog
   *
   * @returns {void}
   */
  onSubmit(): void {
    if (this.arSelectedIds.length) {
      this.closeDialog({
        asset_type_id: this.arSelectedIds,
        customer_id: this.viewService.arRecord.customer_id || null,
        is_failed: this.bFailedAssetsOnly
      });
    } else {
      this.notificationService.notifyWarning('please_select_asset_type');
    }
  }

  /**
   * Wait until the child component emits the data
   *
   * @param event
   *
   * @returns {void}
   */
  ngOnHeaderChange(event): void {
    if (isFunction(this[event.callback])) {
        this[event.callback]();
    } else {
        this.closeDialog();
    }
  }
}
