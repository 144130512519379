
  <header>
    <div class="d-flex">
      <h3 class="title">
          <fa-icon [icon]="['fas', 'history']" ></fa-icon>&nbsp;
          <span>{{ 'recurring_job_history'| translate }} </span>
      </h3>
      <div class="ml-auto d-flex">
        <ul class="list-inline pull-right">
          <li class="list-inline-item">
            <fieldmagic-primary-button
              (click)="cancelDialog()"
              icon="times"
              variant="error"
              label="close"
            ></fieldmagic-primary-button>
          </li>
        </ul>
      </div>
    </div>
</header>

<hr class="separator"/>

<div class="mt-3">
  <ng-container *ngIf="bIsLoaded">
    <div class="">
      <table class="table table-bordered table-striped" width="100%">
        <thead>
            <tr align="center">
              <th width="15%"> {{ 'job_number' | translate }}</th>
              <th width="15%"> {{ 'status' | translate }}</th>
              <th width="20%"> {{ 'amount_actually_invoiced' | translate }}</th>
              <th width="20%"> {{ 'date_completed' | translate }}</th>
              <th width="30%"> {{ 'job_summary' | translate }}</th>
            </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="arJobs.length > 0">
            <tr align="center" *ngFor="let job of arJobs">
              <td width="15%" class="truncate">
                <div class="row" matTooltip="{{ 'job_number' | translate }}: {{ pad(job.job_number, 6) }}">
                  <div class="col-12">
                      <a [routerLink]="['/jobs/'+job.id]">
                        <span class="span_truncate" *ngIf="job.job_number != '' && job.job_number != null"> {{ pad(job.job_number, 6) }} </span>
                      </a>
                      <span *ngIf="job.job_number == '' || job.job_number == null"> -- </span>
                  </div>
                </div>
              </td>
              <td width="15%" class="truncate">
                <div class="row" matTooltip="{{ 'status' | translate }}: {{ job.status | translate }}">
                  <div class="col-12">
                      <span class="span_truncate" *ngIf="job.status != '' && job.status != null"> {{ job.status | translate }} </span>
                      <span *ngIf="job.status == '' || job.status == null"> -- </span>
                  </div>
                </div>
              </td>
              <td width="20%" class="truncate">
                <div class="row" matTooltip="{{ 'amount_actually_invoiced' | translate }}: {{ (job.amount_actually_invoiced == '' || job.amount_actually_invoiced == null) ? '--' : (job.amount_actually_invoiced | currency) }}">
                  <div class="col-12">
                      <span class="span_truncate" *ngIf="job.amount_actually_invoiced != '' && job.amount_actually_invoiced != null"> {{ job.amount_actually_invoiced | currency }} </span>
                      <span *ngIf="job.amount_actually_invoiced == '' || job.amount_actually_invoiced == null"> -- </span>
                  </div>
                </div>
              </td>
              <td width="20%" class="truncate">
                <div class="row" matTooltip="{{ 'date_completed' | translate }}: {{ (job.date_completed == '' || job.date_completed == null) ? '--' : formatDate(job.date_completed) }}">
                  <div class="col-12">
                      <span class="span_truncate" *ngIf="job.date_completed != '' && job.date_completed != null"> {{ formatDate(job.date_completed) }} </span>
                      <span *ngIf="job.date_completed == '' || job.date_completed == null"> -- </span>
                  </div>
                </div>
              </td>
              <td width="30%" class="truncate">
                  <div class="row" matTooltip="{{ 'job_summary' | translate }}: {{ job.job_summary }}">
                    <div class="col-12">
                        <span class="span_truncate" *ngIf="job.job_summary != '' && job.job_summary != null"> {{ job.job_summary }} </span>
                        <span *ngIf="job.job_summary == '' || job.job_summary == null"> -- </span>
                    </div>
                  </div>
                </td>
            </tr>
          </ng-container>

          <ng-container *ngIf="arJobs.length < 1">
            <tr>
              <td colspan="5">
                <div align="center" class="row">
                  <div class="col-12">
                      <span> {{ 'no_recurring_job_history' | translate }}</span>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

  </ng-container>
</div>
<div align="center" *ngIf="!bIsLoaded">
  <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="loading-spinner" aria-hidden="true"></fa-icon>
</div>

