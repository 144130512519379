// libraries
import { Component, Inject, OnInit, HostListener, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NgForm } from '@angular/forms';

// moment
import { Moment } from 'moment';
import * as _moment from 'moment';

// reactivity
import { Observable, concat, of, Subject, BehaviorSubject } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged, switchMap, map, finalize, take, filter } from 'rxjs/operators';

// application custom services
import { RecordService } from '../../../../../services/record.service';
import { NotificationService } from '../../../../../services/notification.service';
import { CustomTranslateService } from '../../../../../services/custom-translate.service';
import { ViewService } from '../../../../../services/view.service';
import { LocalStorageService } from '../../../../../services/local-storage.service';
import { NumberService } from '../../../../../services/helpers/number.service';

// application custom class
import { Select } from '../../../../../objects/select';
import { StatusCode } from '../../../../../lists/status-code';

// application contracts
import { IDialogData, ICustomerOption, ISiteOption, IContactOption,
  IDepartmentOption, ITaxCodeOption, IAccountCodeOption, IPeriodOption,
  IDatePeriodOption, ILineItem, IRecurringInvoice, IAssignedUserOption, IItemOption, IRelatedModuleData
} from '../../../../../contracts/recurring-invoice';

import { isEmpty, get, toNumber, toString, find, first, cloneDeep } from 'lodash';
import { ClientStoreService } from '../../../../../services/client-store.service';
import { LooseObject } from '../../../../../objects/loose-object';
import { toFormattedNumber } from '../../../../utils/numbers';
import { blank, filled } from '../../../../utils/common';
import { ContextMenuComponent } from '../../../context-menu/context-menu.component';
import { ContextMenuService } from '../../../../../services/context-menu.service';
import { UUID } from 'angular2-uuid';
import { RecurringInvoiceLine } from '../../../../../objects/line-items/recurring-invoice-line';

// create moment instance
const moment = (_moment as any).default || _moment;

@Component({
  selector: 'app-edit-recurring-invoice-form',
  templateUrl: './edit-recurring_invoice-form.component.html',
  styleUrls: ['./edit-recurring_invoice-form.component.scss'],
})
export class EditRecurringInvoiceFormComponent implements OnInit {
  @ViewChild(ContextMenuComponent) contextMenuComponent: ContextMenuComponent;
  /**
   * sets the current recurring invoice identifier
   *
   * @var {string}
   */
  strCurrentRecordID: string;

  /**
   * contains the information regarding the current recurring invoice date
   *
   * @var {IRecurringInvoice}
   */
  objRecurringInvoice: IRecurringInvoice = {
    strId: null,
    isAutomatic: false,
    isActive: true,
    emailToClient: false,
    objPeriod: null,
    objNextInvoiceDate: new moment(),
    objCustomer: null,
    objContact: null,
    objSite: null,
    objExpiryDate: null,
    strPurchaseOrderNumber: null,
    objInvoiceDate: null,
    objDueDate: null,
    strInvoiceSummary: null,
    arLineItems: [],
    objAssignedUser: null,
    strReference:  null
  };

  /**
   * tells if the current form is submitting
   *
   * @var {boolean}
   */
  isSubmitting: boolean = false;

  /**
   * tells if the current related field list is fetching to
   * the api and updating
   *
   * @var {object}
   */
  isLoadingRelated = {
    customers: false,
    sites: false,
    users: false,
  };

  /**
   * contains the list of related customers as obversable
   * property for select input field which is using the
   * ng-select
   *
   * @var {Observable<Select[]>}
   */
  arSelectableRelatedCustomers$: Observable<Select[]>;

  /**
   * Contains the search subject for customer field
   *
   * @type {Subject<string>}
   */
  objSearchCustomerInput$ = new Subject<string>();

  /**
   * contains the list of selectable related sites as observable
   * property for select input field which is using the
   * ng-selectngModelGroup="arLineItems"
   *
   * @var {Observable<Select[]>}
   */
  arSelectableRelatedSites$: Observable<Select[]>;

  /**
   * contains the list of selectable related sites as observable
   * property for select input field which is using the
   * ng-selectngModelGroup="arLineItems"
   *
   * @var {Subject<string>}
   */
  arSelectableRelatedSitesInput$ = new Subject<string>();

  /**
   * tells if the current related sites lists if fetching from the api
   * and updating its content
   *
   * @var {boolean}
   */
  isLoadingRelatedSites: boolean = false;

  /**
   * contains the list of selected related contacts as observable
   * property which the combination of the customer contacts
   * and site contacts. If data is missing from both ends it will be the default
   * contacts for the current client
   *
   * @var {Observable<Select[]>}
   */
  arSelectableRelatedContacts$: Observable<Select[]>;

  /**
   * observable property which will contain the string of search
   * from the typeahead function of the select field
   *
   * @var {Subject<string>}
   */
  objRelatedContactInput$: Subject<string> = new Subject<string>();

  /**
   * tells if the current related contacts list is fetching from the api
   * and updating its content
   *
   * @var {boolean}
   */
  isLoadingRelatedContacts: boolean = false;

  /**
   * contains the list of selectable related users as observable property
   * for select input field which is using the ng-selectngModelGroup="arLineItems"
   *
   * @var {Observable<Select[]>}
   */
  arSelectableAssignedUser$: Observable<Select[]>;

  /**
   * observable property which will contain the string of search
   * from the typeahead function of the select field
   *
   * @var {Subject<string>}
   */
  objRelatedAssignedUserInput$: Subject<string> = new Subject<string>();

  /**
   * tells if the current related assigned users is being fetched from the api and updating
   * its content
   *
   * @var {boolean}
   */
  isLoadingRelatedAssignedUsers: boolean = false;

  /**
   * contains the selectable periods for the select field period
   *
   * @var {Array<Select>}
   */
  arSelectablePeriods$: Observable<IPeriodOption[]> = of([
    { id: 'months_1', text: 'monthly' },
    { id: 'months_3', text: 'quarterly' },
    { id: 'months_6', text: 'bi_annual' },
    { id: 'years_1', text: 'annual' },
    { id: 'years_2', text: 'every_2_years' },
    { id: 'years_3', text: 'every_3_years' },
    { id: 'years_4', text: 'every_4_years' },
    { id: 'years_5', text: 'every_5_years' }
  ]);

  /**
   * contains the selectable date periods for invoice date and due date
   * field
   *
   * @var {Array<Select>}
   */
  arSelectableDatePeriods$: Observable<IDatePeriodOption[]> = of([
    { id: 'today', text: 'today' },
    { id: 'days_7', text: 'seven_days' },
    { id: 'days_14', text: 'fourteen_days' },
    { id: 'days_30', text: 'thirty_days' },
    { id: 'last_day_of_this_month', text: 'last_day_of_this_month' },
    { id: 'first_day_of_next_month', text: 'first_day_of_next_month' },
  ]);

  /**
   * tells if the current line items data are successfully loaded
   *
   * @var {boolean}
   */
  isLineItemsLoaded: boolean = false;

  /**
   * contains the total exempted tax
   *
   * @var {number}
   */
  fltTotalExcludedTaxAmount: number = 0;

  /**
   * contains the total taxed amount
   *
   * @var {number}
   */
  fltTotalTaxAmount: number = 0;

  /**
   * contains the total included tax amount
   *
   * @var {number}
   */
  fltTotalIncludedTaxAmount: number = 0;

  /// this contains the tax adjustment for the whole invoice
  totalTaxAdjustment: number = 0;

  /**
   * Current form reference
   *
   * @type {NgForm}
   */
  @ViewChild('recurringInvoiceForm') recurringInvoiceForm: NgForm;

  /**
   * Listen to escape press of the user
   *
   * @returns {void}
   */
  @HostListener('window:keyup.esc')
  onKeyUp(): void {
    this.closeDialog();
  }

  /**
   * Flag used to check if the current component has a reference of the form
   *
   * @type {boolean}
   */
  get hasFormReference(): boolean {
    return !! this.recurringInvoiceForm;
  }

  /**
   * Observable to emit when disabling the submit button
   *
   * @type {BehaviorSubject<boolean>}
   */
  disabledSaveButton$ = new BehaviorSubject(false);

  /**
   * Configured default account code (only present with accounting connected clients)
   *
   * @type {IAccountCodeOption}
   */
  objDefaultAccountCode: IAccountCodeOption;

  /**
   * Configured default tax code (only present with accounting connected clients)
   *
   * @type {ITaxCodeOption}
   */
  objDefaultTaxCode: ITaxCodeOption;

  /**
   * @type {IDepartmentOption[]}
   */
  protected arCachedDepartments: IDepartmentOption[] = [];

  /**
   * @type {ITaxCodeOption[]}
   */
  protected arCachedTaxCodes: ITaxCodeOption[] = [];

  /**
   * @type {IAccountCodeOption[]}
   */
  protected arCachedAccountCodes: IAccountCodeOption[] = [];

  /**
   * If department tracking is enabled.
   *
   * @var {boolean}
   */
  public bDepartmentTracking: boolean = false;
  public selectedLineItems: Array<LooseObject> = [];

  /**
   * class constructor
   *
   * @param {MatDialogRef<EditRecurringInvoiceFormComponent>} currentDialog
   * @param {RecordService}                                   recordService
   * @param {NotificationService}                             notificationService
   * @param {CustomTranslateService}                          translationService
   * @param {ViewService}                                     viewService
   * @param {MAT_DIALOG_DATA: IDialogData}                    objData
   */
  constructor(
    public contextMenuService: ContextMenuService,
    protected currentDialog: MatDialogRef<EditRecurringInvoiceFormComponent>,
    protected recordService: RecordService,
    protected notificationService: NotificationService,
    protected translationService: CustomTranslateService,
    protected viewService: ViewService,
    protected localStorage: LocalStorageService,
    @Inject(MAT_DIALOG_DATA) protected objData: IDialogData,
    protected numberService: NumberService,
    protected clientService: ClientStoreService
  ) {}

  /**
   * {@inheritdoc}
   *
   * angular lifecycle callback called when template was initialized
   */
  ngOnInit(): void {
    this.bDepartmentTracking = this.clientService.isDepartmentTracking();
    this.recordService.getRecordBasedOnParent(this.getData('moduleName') === 'sites' || this.getData('moduleName') === 'customers')
    .subscribe( response => {
      if (this.getData('moduleName') === 'sites') {
        this.initSiteModuleRecurringInvoice(response);
      } else if (this.getData('moduleName') === 'customers') {
        this.initCustomerModuleRecurringInvoice(response);
      } else {
        if (this.isCreateForm()) {
          this.setCurrentSite(null);

          if (this.objData['customer_id'] && this.objData['customer_text']) {
            this.setCurrentCustomer({
              id: this.objData['customer_id'],
              text: this.objData['customer_text']
            });
          } else {
            this.setCurrentCustomer(null);
          }

          this.recordService.getRecordConfig('recurring_invoices', {}, true).subscribe((config) => {
            this.setRelatedDefaults(config['related_data']);

            this.initSelectableFieldsValues();

            this.setSelectableDepartments(config['related_data']['departments'] || []);

            this.setSelectableTaxCodes(config['related_data']['tax_codes'] || []);

            this.setSelectableAccountCodes(config['related_data']['account_codes'] || []);

            const details = get(config, 'record_details', {});
            const user = Object.assign({}, {
                ... (toString(get(details, 'user_id')).trim().length > 0 && {
                  id: details['user_id'],
                }),
                ... (toString(get(details, 'user_text')).trim().length > 0 && {
                  text: details['user_text'],
                }),
              });

            if (! isEmpty(user)) {
              this.objRecurringInvoice.objAssignedUser = user;
            }
          });
        } else {
          this.setCurrentRecordID(this.getData('recordID'));
          this.initEditRecurringInvoiceForm();
        }
      }
    })

    // as per elle translations should be initialized
    this.getTranslationService().initializeTransalateables([ 'confirm_discard', 'confirm_discard_header' ]);

    this.currentDialog.backdropClick().subscribe(_ => {
      this.closeDialog();
    });
  }

  /**
   * pushes a new stack of line item to the list
   *
   * @returns {void}
   */
  addLineItem(): void {
    this.addEmptyLineItem();
  }

  /**
   * called by the close/cancel button for closing the current instance
   * of the dialog
   */
  closeDialog(): void {
    // if current form was changed
    if (this.hasFormReference && this.recurringInvoiceForm.dirty) {
      // show notification
      this.getNotificationService().sendConfirmation(
        'confirm_discard',
        'confirm_discard_header'
      ).subscribe(confirmation => {
        if (confirmation.answer) {
          this.currentDialog.close();
        }
      });
    } else {
      this.currentDialog.close();
    }
  }

  /**
   * calculates the line item amount
   *
   * @param   {any} objLineItem
   *
   * @returns {void}
   */
  calculateAmount(objLineItem: ILineItem): void {
    let intQuantity: any = objLineItem.quantity || 0;
    let fltUnitPrice: any = objLineItem.unitPrice || 0;

    objLineItem.unitPrice = toFormattedNumber(parseFloat(fltUnitPrice.toString()), {
      currency: true,
      maxDecimalPlaces: 4,
    });
    objLineItem.amount = toFormattedNumber(parseFloat((intQuantity * fltUnitPrice).toString()), {
      currency: true,
    });

    // recalculate totals
    this.calculateLineItemsTotal();
  }

  /**
   * calculates the total line items amount which includes the total excluded tax amount,
   * total taxable amount and total amount including the tax
   *
   * @returns {void}
   */
  calculateLineItemsTotal(): void {
    // reset calculations
    let fltTotalTaxAmount: number = 0;
    let fltTotalExcludedTaxAmount: number = 0;

    // loop each line items
    this.objRecurringInvoice.arLineItems.forEach((objLineItem) => {
      // calculate excluded tax
      fltTotalExcludedTaxAmount += (toNumber(objLineItem.unitPrice) * objLineItem.quantity);
      // calculate total taxable amount
      if (objLineItem.objTaxCode) {
        const taxRate = toNumber(objLineItem.objTaxCode.rate) / 100;
        const tax = toNumber(objLineItem.amount) * taxRate;

        fltTotalTaxAmount += tax;
      }
    });

    // sets the property values
    this.fltTotalExcludedTaxAmount = toFormattedNumber(fltTotalExcludedTaxAmount, {
      currency: true,
    });

    this.fltTotalTaxAmount = toFormattedNumber(fltTotalTaxAmount, {
      currency: true,
    });

    // calculate total inc tax amount
    this.fltTotalIncludedTaxAmount = this.fltTotalExcludedTaxAmount + (this.fltTotalTaxAmount + toNumber(this.totalTaxAdjustment));
  }

  /**
   * checks if the current dialog is a create form or edit form
   *
   * @returns {boolean}
   */
  isCreateForm(): boolean {
    return (this.getData('isNew') === true);
  }

  /**
   * removes a line item from the line items list based on given
   * index and recalculates the total
   *
   * @param   {number} intIndex
   *
   * @returns {void}
   */
  removeLineItem(intIndex: number): void {
    // do nothing
    if (intIndex < 0 || this.objRecurringInvoice.arLineItems.length <= 1) {
      return;
    }

    // verify we dont exceed to the array bounds of line items
    if (this.objRecurringInvoice.arLineItems.length > intIndex) {
      // destroy selected row
      this.objRecurringInvoice.arLineItems.splice(intIndex, 1);
      // call recalculate totals
      this.calculateLineItemsTotal();
    }
  }

  /**
   * save the current changes to the recurring invoice
   *
   * @returns {void}
   */
  save(objForm: NgForm): void {
    let hasEmptyAccountAndTaxCode = false

    if (! objForm.valid) {
      this.getNotificationService().notifyNotAllowed('required_notice');
    } else if (objForm.valid) {

      if (!isEmpty(objForm.value.arLineItems)) {
        // check each line items if ObjAccountCode and ObjTaxCode is Null
        Object.values(objForm.value.arLineItems).forEach(element => {
          if (isEmpty(element['objAccountCode']) && isEmpty(element['objTaxCode'])) {
            hasEmptyAccountAndTaxCode = true;
          }
        });
      }

      if ( (objForm.value.emailToClient && isEmpty(objForm.value.objContact))) {
        this.getNotificationService().notifyNotAllowed('required_notice');
      } else if (hasEmptyAccountAndTaxCode) {
        this.getNotificationService().notifyNotAllowed('error_empty_account_and_tax_code');
      } else {
        // send our data to the api if valid and changed
        this.sendToAPI();
      }
    }
  }


  /**
   * clears the value of the expiration date
   *
   * @param {NgForm} objForm
   */
  clearExpirationDate(objForm: NgForm): void {
    objForm.form.patchValue({ objExpiryDate: null });
    objForm.form.markAsDirty();
  }

  /**
   * Handles event when item has been selected
   *
   * @param {ILineItem} objLineItem
   *
   * @returns {void}
   */
  onItemChange(objLineItem: ILineItem): void {
    let objSelected: IItemOption = objLineItem.objItem;

    if (isEmpty(objSelected)) return;

    objLineItem.unitPrice = this.getPricebookPrice(objSelected);

    // if we have a tax code
    if (! isEmpty(objSelected.tax_code_id)) {
      objLineItem.objTaxCode = {
        id: objSelected.tax_code_id,
        text: objSelected.tax_code_name,
        code: objSelected.tax_code,
        rate: objSelected.tax_rate,
        name: get(objSelected, 'tax_code_name', get(objSelected, 'name'))
      };
    } else {
      objLineItem.objTaxCode = this.objDefaultTaxCode;
    }

    // if we have account code
    if (! isEmpty(objSelected.account_code_id)) {
      objLineItem.objAccountCode = {
        id: objSelected.account_code_id,
        text: objSelected.account_code_name,
        code: objSelected.account_code
      };
    } else {
      objLineItem.objAccountCode = this.objDefaultAccountCode;
    }

    objLineItem.description = objSelected.description || null;

    this.calculateAmount(objLineItem);
  }

  /**
   * Handles event when user open selections for items
   *
   * @param {objLineItem} objLineItem
   *
   * @returns {void}
   */
  onItemSelectOpen(objLineItem: ILineItem): void {
    objLineItem._bIsLoadingItems = true;

    this.recordService.getProductRecordData(undefined, undefined, this.getCurrentCustomerId())
      .pipe(
        finalize(() => objLineItem._bIsLoadingItems = false)
      )
      .subscribe((arItems: IItemOption[]) => {
        objLineItem._arSelectableItems$ = concat(
          of(arItems),
          objLineItem._arSelectableItems$
        );
      })
  }

  /**
   * send the data to the API
   *
   * @returns {void}
   */
  protected sendToAPI(): void {
    // generate a data payload
    let objPayloadData = this.generatePayloadData();

    this.isSubmitting = true;

    // send to the api
    this.getRecordService().saveRecord('recurring_invoices', objPayloadData, this.getData('recordID')).pipe(
      take(1),
      tap((objResponse) => {
        if (objResponse.status === StatusCode.kResponseAccepted) {
          this.notificationService.promptError(objResponse.body.error);
        }
      }),
      finalize(() => this.isSubmitting = false),
      filter((objResponse) => objResponse.status === 200 || objResponse.status === 201)
    ).subscribe((objResponse) => {
      // if have successfully created the data and close the dialog
      // if creation of new date was made shows a success add message
      // rather if it is just an update show update message
      let strMessage = (objResponse.status === 201) ? 'header_notification.success_added' : 'header_notification.success_update';

      // show notification
      this.getNotificationService().notifySuccess(strMessage)

      this.currentDialog.close({message: 'save', response: objResponse.body});
    })
  }

  /**
   * generate the payload for the main data including the line items
   * before sending to the api
   *
   * @returns {object}
   */
  protected generatePayloadData(): object {
    return {
      user_id: this.objRecurringInvoice.objAssignedUser.id,
      customer_id: this.objRecurringInvoice.objCustomer.id,
      contact_id: (this.objRecurringInvoice.objContact !== null) ? this.objRecurringInvoice.objContact.id : null,
      site_id: (this.objRecurringInvoice.objSite !== null) ? this.objRecurringInvoice.objSite.id : null,
      period: this.objRecurringInvoice.objPeriod.id,
      invoice_summary: this.objRecurringInvoice.strInvoiceSummary,
      invoice_date: this.objRecurringInvoice.objInvoiceDate.id,
      due_date: this.objRecurringInvoice.objDueDate.id,
      po_number: this.objRecurringInvoice.strPurchaseOrderNumber,
      is_automatic: this.objRecurringInvoice.isAutomatic,
      is_active: this.objRecurringInvoice.isActive,
      email_to_client: this.objRecurringInvoice.emailToClient,
      next_invoice_date: this.objRecurringInvoice.objNextInvoiceDate.format('YYYY-MM-DD'),
      expiry_date: (this.objRecurringInvoice.objExpiryDate !== null) ? this.objRecurringInvoice.objExpiryDate.format('YYYY-MM-DD') : null,
      line_items: this.generateLineItemsPayload(),
      reference: this.objRecurringInvoice.strReference,
      tax_adjustment_amount: this.totalTaxAdjustment,
    }
  }

  /**
   * generates the current payload for line items before sending it
   * to the api and formats it
   *
   * @returns Array<any>
   */
  protected generateLineItemsPayload(): Array<any> {
    let arLineItems = [];

    // loop each line items data and push to the payload
    this.objRecurringInvoice.arLineItems.forEach((objLineItem: ILineItem) => {
      const objItem: IItemOption = objLineItem.objItem;

      arLineItems.push({
        item_id: get(objItem, 'id', null),
        item_code: get(objItem, 'code', null),
        item_name: get(objItem, 'text', null),
        labor: get(objItem, 'labor'),
        quantity: objLineItem.quantity,
        unit_price: objLineItem.unitPrice,
        description: objLineItem.description,
        tax_code_id: (objLineItem.objTaxCode !== null) ? objLineItem.objTaxCode.id : null,
        total_price: objLineItem.amount,
        account_code_id: (objLineItem.objAccountCode !== null) ? objLineItem.objAccountCode.id : null,
        department_id: this.bDepartmentTracking ? objLineItem.objDepartment.id : null,
        account_code: (objLineItem.objAccountCode !== null) ? objLineItem.objAccountCode.code : null,
        tax_code: (objLineItem.objTaxCode !== null) ? objLineItem.objTaxCode.code : null,
      });
    });

    return arLineItems;
  }

  /**
   * retrieves our record service for fetching api data
   *
   * @returns {RecordService}
   */
  protected getRecordService(): RecordService {
    return this.recordService;
  }

  /**
   * retrieves the injected translation service
   *
   * @returns {CustomTranslateService}
   */
  protected getTranslationService(): CustomTranslateService {
    return this.translationService;
  }

  /**
   * retrieves our notification service instance
   *
   * @returns {NotificationService}
   */
  protected getNotificationService(): NotificationService {
    return this.notificationService;
  }

  /**
   * retrieves view service instance
   *
   * @returns {ViewService}
   */
  protected getViewService(): ViewService {
    return this.viewService;
  }

  /**
   * get the current data value or the current data object it self
   *
   * @param {string} strKey
   */
  protected getData(strKey: string): any {
    return (strKey === '*') ? this.objData : this.objData[strKey];
  }

  /**
   * retrieves the current record id of the recurring invoice
   *
   * @returns {string}
   */
  getCurrentRecordID(): string {
    return this.strCurrentRecordID;
  }

  /**
   * This will get the current id of the customer
   */
  protected getCurrentCustomerId(): string|null {
    return (this.getCurrentCustomer() && this.getCurrentCustomer().id) ? this.getCurrentCustomer().id : null;
  }

  /**
   * get the current customer object from the recurring invoice
   * data
   *
   * @return {ICustomerOption}
   */
  protected getCurrentCustomer(): ICustomerOption {
    return this.objRecurringInvoice.objCustomer;
  }

  /**
   * get the current site object from the recurring invoice data
   *
   * @return {ISiteOption}
   */
  protected getCurrentSite(): ISiteOption {
    return this.objRecurringInvoice.objSite;
  }

  /**
   * This will get the current id of the site
   */
  protected getCurrentSiteId(): string|null {
    return (this.getCurrentSite() && this.getCurrentSite().id) ? this.getCurrentSite().id : null;
  }

  /**
   * sets the current record of the recurring invoice
   *
   * @param   {string|null} strRecordID
   *
   * @returns {void}
   */
  setCurrentRecordID(strRecordID?: string): void {
    if (! this.strCurrentRecordID) {
      this.strCurrentRecordID = strRecordID;
    }
  }

  /**
   * sets the current customer ID propery value
   *
   * @param {string|ICustomerOption} objCustomer
   */
  protected setCurrentCustomer(objCustomer: string|ICustomerOption): void {
    this.objRecurringInvoice.objCustomer = (typeof objCustomer === 'object')
      ? objCustomer
      : { id: objCustomer, text: null };
  }

  /**
   * sets the current value of property site ID
   *
   * @param {string|null|ISiteOption} objSite
   */
  protected setCurrentSite(objSite: string|null|ISiteOption): void {
    if (typeof objSite === 'object') {
      this.objRecurringInvoice.objSite = objSite;
      if (this.isCreateForm() && ! isEmpty(objSite)) {
        this.objRecurringInvoice.strReference = objSite.text.replace(/ *\[[^)]*\] */g, "");
      }
    } else {
      this.objRecurringInvoice.objSite = { id: objSite, text: null };
    }
  }

  /**
   * sets the current selectable customers list
   *
   * @param {Array<ICustomerOption>} arCustomers
   */
  protected setSelectableCustomers(arCustomers: Array<ICustomerOption>): void {
    // check if we have a selected customer
    let objCurrentCustomer = this.getCurrentCustomer();

    if (objCurrentCustomer !== null) {
      // look for the array of customers if one is matching set at as the found
      // rather get the first one instead
      this.setCurrentCustomer(arCustomers.find((objCustomer) => ( objCustomer.id === objCurrentCustomer.id )) || arCustomers[0]);
    }

    this.arSelectableRelatedCustomers$ = concat(
      of(arCustomers),
      this.objSearchCustomerInput$.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => this.isLoadingRelated.customers = true),
        switchMap((strTerm: string) => {
          return this.recordService.getRecordRelate('customers', strTerm, '', false, false)
            .pipe(
              tap(() => this.isLoadingRelated.customers = false)
            );
        })
      )
    );
  }

  /**
   * sets the current selectable sites list
   *
   * @param {Array<ISiteOption>} arSites
   */
  protected setSelectableSites(arSites: Array<ISiteOption>): void {
    // check if we have a selected site
    let objCurrentSite = this.getCurrentSite();

    if (objCurrentSite !== null) {
      // look for the array of customers if one is matching set at as the found
      this.setCurrentSite(arSites.find((objSite) => ( objSite.id === objCurrentSite.id )));
    }

    this.arSelectableRelatedSites$ = of(arSites);
  }

  /**
   * sets the current selectable contacts list
   *
   * @param {Array<IContactOption>} arContacts
   */
  protected setSelectableContacts(objResponse: any): void {
    let arContacts: Array<IContactOption> = [];

    // related contacts(customers)
    let arCustomersContact = objResponse['contact_customers'] || [];
    if (arCustomersContact.length > 0) {
      arCustomersContact.forEach((objContact) => {
        arContacts.push({
          id: objContact['contact_id'],
          text: objContact['contact_text'] || objContact['text'],
          groupname: 'contact_customers',
          role: this._computeDisplayedRole(objContact['role']),
        });
      });
    }

    // related contacts(sites)
    let arSitesContact = objResponse['contact_sites'] || [];
    if (arSitesContact.length > 0) {
      arSitesContact.forEach((objContact) => {
        arContacts.push({
          id: objContact['contact_id'],
          text: objContact['contact_text'] || objContact['text'],
          groupname: 'contact_sites',
          role: this._computeDisplayedRole(objContact['role']),
        });
      });
    }

    // if contact are both empty from related contacts lets merged the general contacts
    let arDefaultContacts = objResponse['contacts'] || [];

    if (arContacts.length == 0) {
      arDefaultContacts.forEach((objContact) => {
        arContacts.push({
          id: objContact['id'],
          text: objContact['customer_text'] || objContact['text'],
        });
      });
    }

    // check if we have a current contact from the current recurring invoice data
    let objCurrentContact = this.objRecurringInvoice.objContact;

    if (objCurrentContact !== null) {
      // we override the current object contact we the data from the
      // contacts list if none found then we revert it back to as it was before or rather null?
      objCurrentContact = arContacts.find((objContact) => (objContact.id === objCurrentContact.id)) || null;

      // backward compatibility for selected that was previously selected from the general contacts
      // if the current selected contact was not found in the contacts lets try finding it in the default contacts
      if (objCurrentContact == null) {
        let objPreviouslySelected = this.objRecurringInvoice.objContact;
        objCurrentContact = arDefaultContacts.find((objContact) => (objContact.id === objPreviouslySelected.id)) || null;
      }

      this.objRecurringInvoice.objContact = objCurrentContact;
    }

    this.arSelectableRelatedContacts$ = concat(
      of(arContacts),
      this.objRelatedContactInput$.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => this.isLoadingRelatedContacts = true),
        switchMap((strTerm: string) => {
          return this.recordService.getRecordRelate('contacts', strTerm, '', false, false)
            .pipe(tap(() => this.isLoadingRelatedContacts = false));
        })
      )
    )
  }

  /**
   * sets the current list of selectable departments
   *
   * @param {any} arDepartments
   */
  protected setSelectableDepartments(arDepartments: Array<IDepartmentOption>): void {
    // check if we have pre selected department for each line items
    this.objRecurringInvoice.arLineItems.map((objLineItem: ILineItem) => {
      if (objLineItem.objDepartment) {
        objLineItem.objDepartment = arDepartments.find((objDepartment) => (objDepartment.id === objLineItem.objDepartment.id)) || objLineItem.objDepartment;
      }
    });

    this.arCachedDepartments = arDepartments;
  }

  /**
   * sets the current list of selectable tax codes
   *
   * @param {any} arTaxCodes
   */
  protected setSelectableTaxCodes(arTaxCodes: Array<ITaxCodeOption>): void {
    // check if we have pre selected tax code for each line items
    this.objRecurringInvoice.arLineItems.map((objLineItem: ILineItem) => {
      if (objLineItem.objTaxCode) {
        objLineItem.objTaxCode = arTaxCodes.find((objTaxCode) => (objTaxCode.id === objLineItem.objTaxCode.id)) || objLineItem.objTaxCode;
      }
    });

    this.arCachedTaxCodes = arTaxCodes;
  }

  /**
   * sets the current list of selectable account codes
   *
   * @param {any} arAccountCodes
   */
  protected setSelectableAccountCodes(arAccountCodes: Array<IAccountCodeOption>): void {
    // check if we have pre selected department for each line items
    this.objRecurringInvoice.arLineItems.map((objLineItem: ILineItem) => {
      if (objLineItem.objAccountCode) {
        objLineItem.objAccountCode = arAccountCodes.find((objAccountCode) => (objAccountCode.id === objLineItem.objAccountCode.id)) || objLineItem.objAccountCode;
      }
    });

    this.arCachedAccountCodes = arAccountCodes;
  }

  /**
   * sets the current selectable assigned users list
   *
   * @param {IAssignedUserOption[]} arAssignedUsers
   */
  protected setSelectableAssignedUsers(arAssignedUsers: IAssignedUserOption[]): void {
    let objCurrentAssignedUser = this.objRecurringInvoice.objAssignedUser;
    let objCurrentSelectedAssignedUser = null;

    // if we have already selected assigned user
    if (objCurrentAssignedUser) {
      objCurrentSelectedAssignedUser = arAssignedUsers.find(objAssignedUser => objCurrentAssignedUser.id === objAssignedUser.id) || null;
    }

    // if we still don't have the selected user we should set the default to the current authenticated user
    // is this the right way of having the current authenticated user id
    if (! objCurrentSelectedAssignedUser) {
      let strCurrentAuthenticatedUserId = this.localStorage.getItem('user_id');
      objCurrentSelectedAssignedUser = arAssignedUsers.find(objAssignedUser => objAssignedUser.id === strCurrentAuthenticatedUserId) || null;

      // if we still don't have the current authenticated user lets push it to the options rather
      if (! objCurrentSelectedAssignedUser) {
        let strCurrentAuthenticatedUserName = this.localStorage.getItem('user_name');
        objCurrentSelectedAssignedUser = { id: strCurrentAuthenticatedUserId, text: strCurrentAuthenticatedUserName};
        arAssignedUsers.push(objCurrentSelectedAssignedUser);
      }
    }

    // overrides the current value with the proper value from the list
    this.objRecurringInvoice.objAssignedUser = objCurrentSelectedAssignedUser;

    this.arSelectableAssignedUser$ = concat(
      of(arAssignedUsers),
      this.objRelatedAssignedUserInput$.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => this.isLoadingRelated['users'] = true),
        switchMap((strTerm: string) => {
          return this.recordService.getRecordRelate('users', strTerm, '', false, false)
            .pipe(tap(() => this.isLoadingRelated['users'] = false));
        })
      )
    );
  }

  /**
   * sets the current selected period if any from the recurring data
   *
   * @param   {IPeriodOption|null} objCurrentPeriod
   *
   * @returns {void}
   */
  protected setSelectedPeriod(objCurrentPeriod?: IPeriodOption): void {
    // if set then look from the list
    if (objCurrentPeriod) {
      this.arSelectablePeriods$.subscribe((arPeriods) => {
        this.objRecurringInvoice.objPeriod = arPeriods.find((objPeriod) => (objPeriod.id === objCurrentPeriod.id)) || null;
      });
    }
  }

  /**
   * sets the current selected invoice date of the recurring invoice data
   *
   * @param   {IDatePeriodOption|null} objCurrentInvoiceDate
   *
   * @returns {void}
   */
  protected setSelectedInvoiceDate(objCurrentInvoiceDate?: IDatePeriodOption): void {
    if (objCurrentInvoiceDate) {
      this.arSelectableDatePeriods$.subscribe((arDatePeriods) => {
        this.objRecurringInvoice.objInvoiceDate = arDatePeriods.find((objDatePeriod) => (objDatePeriod.id === objCurrentInvoiceDate.id)) || null;
      });
    }
  }

  /**
   * sets the current selected invoice due date of the recurring invoice data if given
   *
   * @param   {IDatePeriodOption|null} objCurrentDueDate
   *
   * @returns {void}
   */
  protected setSelectedDueDate(objCurrentDueDate?: IDatePeriodOption): void {
    if (objCurrentDueDate) {
      this.arSelectableDatePeriods$.subscribe((arDatePeriods) => {
        this.objRecurringInvoice.objDueDate = arDatePeriods.find((objDatePeriod) => (objDatePeriod.id === objCurrentDueDate.id)) || null;
      });
    }
  }

  /**
   * initializes all the selectable fields values
   *
   * @returns {void}
   */
  protected initSelectableFieldsValues(): void {

    // sets the current selected period
    this.setSelectedPeriod(this.objRecurringInvoice.objPeriod);
    this.setSelectedInvoiceDate(this.objRecurringInvoice.objInvoiceDate);
    this.setSelectedDueDate(this.objRecurringInvoice.objDueDate);

    this.initLineItems();
  }

  /**
   * initializes related modules select field values
   *
   * @param {string} strModule
   * @returns {void}
   */
  public initRelatedSelectableFieldValues(strModule: string): void
  {
    if (strModule === 'contacts') {
      let objFiltersPayload = {};
      this.isLoadingRelatedContacts = true;

      if (this.getData('moduleName') === 'customers') {
        objFiltersPayload = Object.assign(objFiltersPayload, {
          customer_id: this.getCurrentCustomerId(),
        });
      } else if (this.getData('moduleName') === 'sites') {
        objFiltersPayload = Object.assign(objFiltersPayload, {
          site_id: this.getCurrentSiteId(),
          customer_id: this.getCurrentCustomerId(),
        });
      }

      this.getRecordService().getMultipleModuleRelateRecord('contact_roles', false, objFiltersPayload)
      .subscribe((objResponse) => {
        // related contacts
        this.setSelectableContacts(objResponse);
        this.isLoadingRelatedContacts = false;
      });
    } else if (strModule == 'sites') {
      this.getRelateRecord('sites').subscribe((objResponse) => {
        this.siteRelateRecord(objResponse);
      });
    } else {
      this.isLoadingRelated[strModule] = true;

      this.recordService.getRecordRelate(strModule, '', [], false)
      .subscribe((objResponse) => {
        switch(strModule) {
          case 'customers':
            // related customers
            let arCustomers = objResponse as ICustomerOption[];
            this.setSelectableCustomers(arCustomers);
            this.isLoadingRelated[strModule] = false;
          break;
          case 'sites':
            // related sites
            let arSites = objResponse as ISiteOption[];
            this.setSelectableSites(arSites);
            this.isLoadingRelated[strModule] = false;
          break;
          case 'users':
            // assigned users
            let arAssignedUsers = objResponse as IAssignedUserOption[];
            this.setSelectableAssignedUsers(arAssignedUsers);
            this.isLoadingRelated[strModule] = false;
          break;
        }
      });
    }
  }

  /**
   * Updates the value of the field to Observable select
   *
   * @param arRelateInitialValue
   * @param arFilter
   *
   * @returns void
   */
  public siteRelateRecord(arRelateInitialValue = [], arFilter = []): void {

    this.arSelectableRelatedSites$ = concat(
      of(arRelateInitialValue), // We set the initial values to the ng-select.
      this.arSelectableRelatedSitesInput$.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => this.isLoadingRelated['sites'] = true),
        switchMap(term => this.getRelateRecord('sites', term, {}).pipe(
          tap((data) => {
            this.isLoadingRelated['sites'] = false;
          }),
        ))
      ),
    );
  }

  /**
   * Here we can manipulate the relate filter before requesting it to api
   *
   * @param relateModule
   * @param terms
   * @param filter
   *
   * @retuns Observable<Select[]>
   */
  public getRelateRecord(relateModule, terms = null, filter = {}): Observable<Select[]> {

    filter = (filter) ? filter : {};
    let strCustomerId = (this.objRecurringInvoice.objCustomer) ? this.objRecurringInvoice.objCustomer['id'] : null || null ;
    if (relateModule === 'sites' && (terms === null || terms === '' ) && strCustomerId != null) {
      filter['customer_id'] = strCustomerId;
    }

    return this.recordService.getRecordRelate(relateModule, terms, false, false, filter);
  }

  /**
   * initialize the line items
   *
   * @returns {void}
   */
  protected initLineItems(): void {
    if (this.isCreateForm()) {
      // append empty line item if we only have a create form
      this.addEmptyLineItem();
    } else {
      this.objRecurringInvoice.arLineItems.map((objLineItem) => {
        // calculate each items amount
        this.calculateAmount(objLineItem);

        return objLineItem;
      });
    }

    // calulate totals
    this.calculateLineItemsTotal();

    setTimeout(() => {
      this.isLineItemsLoaded = true;
    }, 500);
  }

  /**
   * initializes data for the editing of recurring invoice data
   *
   * @returns {void}
   */
  protected initEditRecurringInvoiceForm(): void {
    this.getRecordService().getRecord(
      'recurring_invoices', this.getCurrentRecordID(), true, {}, 'edit_form'
    ).subscribe((objResponse) => {
      // it seems it returns a HttpResponse object when failure occurs
      // so we would not continue to process the response since it determines failure on request
      // might contain 404 Not found status
      if (objResponse.status) {
        return;
      }

      let objRecord = objResponse['record_details'];

      // create the recurring invoice data by merging with default
      this.objRecurringInvoice = Object.assign(this.objRecurringInvoice, {
        isActive: objRecord['is_active'],
        isAutomatic: objRecord['is_automatic'],
        emailToClient: objRecord['email_to_client'] || false,
        objPeriod: { id: objRecord['period'] },
        objNextInvoiceDate: this.createMomentFromRawDateString(objRecord['next_invoice_date']),
        objCustomer: { id: objRecord['customer_id'] },
        objContact: (objRecord['contact_id']) ? { id: objRecord['contact_id'] } : null,
        objSite: (objRecord['site_id']) ? { id: objRecord['site_id'] } : null,
        objExpiryDate: (objRecord['expiry_date']) ? this.createMomentFromRawDateString(objRecord['expiry_date']) : null,
        strPurchaseOrderNumber: objRecord['po_number'],
        objInvoiceDate: { id: objRecord['invoice_date'] },
        objDueDate: { id: objRecord['due_date'] },
        strInvoiceSummary: objRecord['invoice_summary'],
        strReference: objRecord['reference'],
        arLineItems: objRecord['line_items'].map((objLineItem) => {
          let _objLineItem = {
            objItem: (! isEmpty(objLineItem['item_id'])) ? { id: objLineItem['item_id'], text: objLineItem['item_name'], code: objLineItem['item_code'], labor: objLineItem['labor'] } : null,
            quantity: objLineItem['quantity'],
            description: objLineItem['description'],
            objDepartment: {
              id: objLineItem['department_id'],
              text: objLineItem['department_name'],
            },
            objTaxCode: (! isEmpty(objLineItem['tax_code_id'])) ? {
              id: objLineItem['tax_code_id'],
              text: objLineItem['tax_code_name'],
              code: objLineItem['tax_code'],
              rate: objLineItem['tax_rate'],
              name: get(objLineItem, 'tax_code_name', get(objLineItem, 'name')),
            } : null,
            objAccountCode: (! isEmpty(objLineItem['account_code_id'])) ? {
              id: objLineItem['account_code_id'],
              text: objLineItem['account_code_name'],
              code: objLineItem['account_code'],
            } : null,
            unitPrice: objLineItem['unit_price'],
            amount: objLineItem['total_price'],
            select_line_id: UUID.UUID(),
            related_products: get(objLineItem, 'related_products', []),
            supplier_pricing: get(objLineItem, 'supplier_pricing', []),
          } as ILineItem;

          _objLineItem = this.appendItemSearch(_objLineItem);

          return _objLineItem;
        }),
        objAssignedUser: {
          id: objRecord['user_id'],
          text: objRecord['user_text'],
        }
      });

      this.totalTaxAdjustment = toFormattedNumber(get(objRecord, 'tax_adjustment_amount'), {
        currency: true,
      });

      // departments
      let arDepartments = objResponse['related_data']['departments'] || [];
      this.setSelectableDepartments(arDepartments);

      // tax codes
      let arTaxCodes = objResponse['related_data']['tax_codes'] || [];
      this.setSelectableTaxCodes(arTaxCodes);

      // account codes
      let arAccountCodes = objResponse['related_data']['account_codes'] || [];
      this.setSelectableAccountCodes(arAccountCodes);

      let objCustomer = {id: objRecord['customer_id'], text: objRecord['customer_text']};
      let objSite = {id: objRecord['site_id'], text: objRecord['site_text']};
      let objUser = {id: objRecord['user_id'], text: objRecord['user_text']};
      let objContactSelect = [{id: objRecord['contact_id'], text: objRecord['contact_text']}];
      let objContact = {contacts : objContactSelect}
      // sets customer selected
      this.setCurrentCustomer(objCustomer);
      // sets site selected
      this.setCurrentSite(objSite);
      // sets user selected
      this.setSelectableAssignedUsers([objUser]);
      // sets site selected
      this.setSelectableContacts(objContact);

      // defaults
      this.setRelatedDefaults(objResponse['related_data']);

      // initialize fields
      this.initSelectableFieldsValues();
    });
  }

  /**
   * initializes edit/create form for customer module
   *
   * @returns {void}
   */
  protected initCustomerModuleRecurringInvoice(objParentViewRecord): void {
    // if we dont have a parent view record dont process
    if (! objParentViewRecord) {
      return;
    }

    // we only need the details
    let objRecordDetails = objParentViewRecord['record_details'];
    let objCustomer = {id: objRecordDetails['id'], text: objRecordDetails['text']};

    // sets current customer
    this.setCurrentCustomer(objCustomer);
    this.setSelectableAssignedUsers([]);

    // if we have a create form
    if (this.isCreateForm()) {
      // Get config of customer invoice
      this.recordService.getRecordConfig('recurring_invoices', null, true).subscribe((objConfig) => {
        // defaults
        this.setRelatedDefaults(objConfig['related_data']);

        // set values for the selected fields
        this.initSelectableFieldsValues();

        // departments
        let arDepartments = objConfig['related_data']['departments'] || [];
        this.setSelectableDepartments(arDepartments);

        // tax codes
        let arTaxCodes = objConfig['related_data']['tax_codes'] || [];
        this.setSelectableTaxCodes(arTaxCodes);

        // account codes
        let arAccountCodes = objConfig['related_data']['account_codes'] || [];
        this.setSelectableAccountCodes(arAccountCodes);

        // defaults
        this.setRelatedDefaults(objConfig['related_data']);

        // set values for the selected fields
        this.initSelectableFieldsValues();
      });
    } else {
      this.setCurrentRecordID(this.getData('recordID'));
      this.initEditRecurringInvoiceForm();
    }
  }

  /**
   * initializes edit/create form for customer module
   *
   * @returns {void}
   */
  protected initSiteModuleRecurringInvoice(objParentViewRecord = undefined): void {
    // if we dont have a parent view record dont process
    if (! objParentViewRecord) {
      return;
    }

    // we only need the details
    let objRecordDetails = objParentViewRecord['record_details'];
    let objCustomer = {id: objRecordDetails['customer_id'], text: objRecordDetails['customer_text']};
    let objSite = {id: objRecordDetails['id'], text: objRecordDetails['text']};

    // sets current customer
    this.setCurrentCustomer(objCustomer);
    this.setCurrentSite(objSite);
    this.setSelectableAssignedUsers([]);

    // if we have a create form
    if (this.isCreateForm()) {
      // Get config of customer invoice
      this.recordService.getRecordConfig('recurring_invoices', null, true).first().subscribe( objConfig => {
        // departments
        let arDepartments = objConfig['related_data']['departments'] || [];
        this.setSelectableDepartments(arDepartments);

        // tax codes
        let arTaxCodes = objConfig['related_data']['tax_codes'] || [];
        this.setSelectableTaxCodes(arTaxCodes);

        // account codes
        let arAccountCodes = objConfig['related_data']['account_codes'] || [];
        this.setSelectableAccountCodes(arAccountCodes);

        // defaults
        this.setRelatedDefaults(objConfig['related_data']);

        // set values for the selected fields
        this.initSelectableFieldsValues();
      });
    } else {
      this.setCurrentRecordID(this.getData('recordID'));
      this.initEditRecurringInvoiceForm();
    }
  }

  /**
   * pushes an empty line item object to the line items list
   *
   * @returns {void}
   */
  public addEmptyLineItem(objData: LooseObject = null): void {
    if (objData) {
      let taxCode: ITaxCodeOption|null = this.objDefaultTaxCode;
      let accountCode: IAccountCodeOption|null = this.objDefaultAccountCode;

      if (filled(get(objData, 'account_code_id'))) {
        accountCode = {
          id: get(objData, 'account_code_id'),
          code: get(objData, 'account_code'),
          text: get(objData, 'account_code_name'),
        };
      }

      if (filled(get(objData, 'tax_code_id'))) {
        taxCode = {
          id: get(objData, 'tax_code_id'),
          code: get(objData, 'tax_code'),
          text: get(objData, 'tax_code_name'),
          rate: toFormattedNumber(get(objData, 'tax_rate')),
          name: get(objData, 'tax_code_name', get(objData, 'name'))
        };
      }

      let objLineItem = {
        quantity: get(objData, 'quantity', 1),
        description: objData['description'],
        objDepartment: null,
        objTaxCode: taxCode,
        unitPrice: objData['unit_price'] || 0,
        objAccountCode: accountCode,
        amount: objData['unit_price'] || 0,
        isInvalidDepartment: false,
        isInvalidTaxCode: false,
        isInvalidAccountCode: false,
        stock: objData['current_stock_level'] || 0,
        select_line_id: UUID.UUID(),
        related_products: get(objData, 'related_products'),
        supplier_pricing: get(objData, 'supplier_pricing'),
      } as ILineItem;

      objLineItem = this.appendItemSearch(objLineItem);
      objLineItem.objItem = {
        "id": get(objData, 'item_id', get(objData, 'id')),
        "code": get(objData, 'item_code', get(objData, 'code')),
        "text": get(objData, 'item_name', get(objData, 'name')),
        "labor": get(objData, 'labor'),
      } as any;

      objLineItem.objDepartment = {
        "id": get(objData, 'department_id'),
        "department_name": get(objData, 'department_name'),
        "text": get(objData, 'department_name'),
      } as any;

      this.objRecurringInvoice.arLineItems.push(objLineItem);
    }

    this.calculateLineItemsTotal();
  }

  /**
   * creates an instance of the Moment from a given raw date string
   * usually came from the API which removes the UTC and recreates it based
   * on a local/client utc
   *
   * @param {string} strRawDate
   */
  protected createMomentFromRawDateString(strRawDate: string): Moment {
    return moment(
      moment.utc(strRawDate).toDate()
    ).local();
  }

  /**
   * Build object for item search per line item
   *
   * @param {ILineItem} intIdx
   *
   * @returns {ILineItem}
   */
  protected appendItemSearch(objLineItem: ILineItem): ILineItem {

    objLineItem._bIsLoadingItems = false;
    objLineItem._objItemTypeahead$ = new Subject<string>();
    objLineItem._arSelectableItems$ = objLineItem._objItemTypeahead$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => objLineItem._bIsLoadingItems = true),
      switchMap((strTerm) => this.recordService.getProductRecordData(strTerm, undefined, this.getCurrentCustomerId()).pipe(
        tap(() => objLineItem._bIsLoadingItems = false),
        map((arOptions) => arOptions.map((objOption) => ({
          id: objOption.id,
          text: objOption.text,
          code: objOption['code'],
          unit_price: objOption['unit_price'],
          pricebook_unit_price: objOption['pricebook_unit_price'],
          tax_code_id: objOption['tax_code_id'],
          tax_code_name: objOption['tax_code_name'],
          tax_code: objOption['tax_code'],
          tax_rate: objOption['tax_rate'],
          account_code_id: objOption['account_code_id'],
          account_code_name: objOption['account_code_name'],
          account_code: objOption['account_code'],
          description: objOption['description'],
          labor: objOption['labor'],
        })))
      ))
    );

    objLineItem._bIsLoadingDepartments = false;
    objLineItem._objDepartmentTypeahead$ = new Subject<string>();

    objLineItem._arSelectableDepartments$ = objLineItem._objDepartmentTypeahead$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => objLineItem._bIsLoadingDepartments = true),
      switchMap((strTerm) => this.getRelateRecord('departments', strTerm).pipe(
        tap(() => objLineItem._bIsLoadingDepartments = false),
        map((arOptions) => arOptions.map((objOption) => ({
          id: objOption.id,
          text: objOption.text,
        })))
      ))
    );

    objLineItem._bIsLoadingAccountCodes = false;
    objLineItem._objAccountCodeTypeahead$ = new Subject<string>();
    objLineItem._arSelectableAccountCodes$ = objLineItem._objAccountCodeTypeahead$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => objLineItem._bIsLoadingAccountCodes = true),
      switchMap((strTerm) => this.getRelateRecord('account_codes', strTerm).pipe(
        tap(() => objLineItem._bIsLoadingAccountCodes = false),
        map((arOptions) => arOptions.map((objOption) => ({
          id: objOption.id,
          text: objOption.text,
          code: objOption['code'],
        })))
      ))
    );

    objLineItem._bIsLoadingTaxCodes = false;
    objLineItem._objTaxCodeTypeahead$ = new Subject<string>();
    objLineItem._arSelectableTaxCodes$ = objLineItem._objTaxCodeTypeahead$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => objLineItem._bIsLoadingTaxCodes = true),
      switchMap((strTerm) => this.getRelateRecord('tax_codes', strTerm).pipe(
        tap(() => objLineItem._bIsLoadingTaxCodes = false),
        map((arOptions) => arOptions.map((objOption) => ({
          id: objOption.id,
          text: objOption.text,
          code: objOption['code'],
          rate: objOption['rate'],
          name: objOption['name'],
        })))
      ))
    );

    return objLineItem;
  }

  /**
   * Set default related modules
   *
   * @param {IRelatedModuleData} objRelated
   *
   * @returns {void}
   */
  protected setRelatedDefaults(objRelated: IRelatedModuleData): void {
    this.objDefaultAccountCode = objRelated.default_account_code_sale;
    this.objDefaultTaxCode = objRelated.default_tax_code_sale;
  }

  /**
   * get the pricebook unit price in item
   *
   * @param objItem
   *
   * @returns {number}
   */
  protected getPricebookPrice(objItem: IItemOption): number {
    if (this.getCurrentCustomerId() && objItem.pricebook_unit_price) {
      return objItem.pricebook_unit_price
    } else {
      return objItem.unit_price;
    }
  }

  /**
   * Handles open selection event for line item department field
   *
   * @param {ILineItem} objLineItem
   *
   * @returns {void}
   */
  onOpenDepartmentField(objLineItem: ILineItem): void {
    objLineItem._arSelectableDepartments$ = concat(of(this.arCachedDepartments), objLineItem._arSelectableDepartments$);
  }

  /**
   * Handles open selection event for line item account code field
   *
   * @param {ILineItem} objLineItem
   *
   * @returns {void}
   */
  onOpenAccountCodeField(objLineItem: ILineItem): void {
    objLineItem._arSelectableAccountCodes$ = concat(of(this.arCachedAccountCodes), objLineItem._arSelectableAccountCodes$);
  }

  /**
   * Handles open selection event for line item tax code field
   *
   * @param {ILineItem} objLineItem
   *
   * @returns {void}
   */
  onOpenTaxCodeField(objLineItem: ILineItem): void {
    objLineItem._arSelectableTaxCodes$ = concat(of(this.arCachedTaxCodes), objLineItem._arSelectableTaxCodes$);
  }

  /**
   * Add site text to reference when
   * a site is selected.
   *
   * @param {any} objRecord
   *
   * @returns {void}
   */
  onSiteChanged(objRecord: any): void {
    if (this.isCreateForm() && objRecord && objRecord.site_text) {
      this.objRecurringInvoice.strReference = objRecord.site_text;
    }
  }

  /**
   * Triggers the form reference submit if present
   *
   * @returns {void}
   */
  triggerFormSubmit(): void {
    this.hasFormReference && this.recurringInvoiceForm.ngSubmit.emit();
  }

  /**
   * Listens to form error
   *
   * @returns {void}
   */
  onFormError(): void {
    this.disabledSaveButton$.next(true);
  }

  onLineTotalChange(input: HTMLInputElement, opts: {
    line: ILineItem,
  }): void {
    const adjustment = toFormattedNumber(input.value, {
      currency: true,
    });

    const quantity = toFormattedNumber(opts.line.quantity);
    const pricing = adjustment / quantity;

    opts.line.unitPrice = toFormattedNumber(pricing, {
      currency: true,
      maxDecimalPlaces: 4,
    });

    this.calculateLineItemsTotal();
  }

  onTaxAdjustment(input: HTMLInputElement): void {
    this.totalTaxAdjustment = toFormattedNumber(input.value, {
      currency: true,
    });

    this.calculateLineItemsTotal();
  }

  onGrandTotalChange(input: HTMLInputElement): void {
    const adjustment = toFormattedNumber(input.value, {
      currency: true,
    });

    const totalPrice = this.fltTotalExcludedTaxAmount;
    const totalTax = this.fltTotalTaxAmount;

    this.totalTaxAdjustment = toFormattedNumber((adjustment - (totalPrice + totalTax)), {
      currency: true,
    });
  }

  doSomethingFromContextMenu(event) {
    let copiedLineItemFromLocalStorage = this.contextMenuService.getCopiedLineItem();

    if (event.action == 'paste' && filled(copiedLineItemFromLocalStorage)) {
      let indexCounter = get(event, ['data', 'index']) + 1;
      copiedLineItemFromLocalStorage.forEach(lineItem => {
        this.addEmptyLineItem(lineItem);
        this.calculateAmount(this.objRecurringInvoice.arLineItems[indexCounter]);
        indexCounter++;
      });
    } else if (event.action == 'copy') {
      let copiedLineItems = [];
      let eventDataLineItem = get(event, ['data', 'line_item'], []);

      if (this.selectedLineItems.length > 0) {
        copiedLineItems = this.selectedLineItems.map(lineItem => {
          let newLineItem = this.formatCopiedLineItem(lineItem);

          return new RecurringInvoiceLine(newLineItem);
        });
      } else if (eventDataLineItem) {
        let newLineItem = this.formatCopiedLineItem(eventDataLineItem);

        copiedLineItems = [new RecurringInvoiceLine(newLineItem)];
      }

      if (filled(copiedLineItems)) {
        this.contextMenuService.setCopiedLineItem(copiedLineItems);
      }
    }
  }

  formatCopiedLineItem(lineItem: LooseObject) {
    let currentLineItem = cloneDeep(lineItem);
    currentLineItem['tax_code'] = get(currentLineItem, ['objTaxCode', 'code']);
    currentLineItem['tax_code_id'] = get(currentLineItem, ['objTaxCode', 'id']);
    currentLineItem['tax_code_name'] = get(currentLineItem, ['objTaxCode', 'name']);
    currentLineItem['tax_rate'] = get(currentLineItem, ['objTaxCode', 'rate']);
    currentLineItem['account_code'] = get(currentLineItem, ['objAccountCode', 'code']);
    currentLineItem['account_code_id'] = get(currentLineItem, ['objAccountCode', 'id']);
    currentLineItem['account_code_name'] = get(currentLineItem, ['objAccountCode', 'text']);
    currentLineItem['item_id'] = get(currentLineItem, ['objItem', 'id']);
    currentLineItem['item_code'] = get(currentLineItem, ['objItem', 'code']);
    currentLineItem['item_name'] = get(currentLineItem, ['objItem', 'text']);
    currentLineItem['labor'] = get(currentLineItem, ['objItem', 'labor']);
    currentLineItem['department_id'] = get(currentLineItem, ['objDepartment', 'id']);
    currentLineItem['department_name'] = get(currentLineItem, ['objDepartment', 'text']);

    return currentLineItem;
  }

  onClickedLineItem($event, attr) {
    this.selectedLineItems = this.contextMenuService.selectLineItem($event, attr, this.selectedLineItems);
  }

  /**
   * get the primary contact role to display in relate option
   *
   * @param roles
   */
  protected getPrimaryRole(roles): string | null {
    if (roles.length) {
        let primaryRole = roles.find( data => data.primary );
        return primaryRole ? primaryRole['id'] : roles[0]['id'];
    }
    return null;
  }

  private _computeDisplayedRole(roles: Record<string, any>[]): string {
    if (blank(roles)) {
      return 'no_role';
    }

    const primary = find(roles, (role) => get(role, 'primary', false)) || first(roles);

    return get(primary, 'text', 'no_role');
  }
}
