<header>
  <div class="d-flex dialog-header">
    <h3 class="dialog-title" translate>
      <fa-icon [icon]="['fas', 'link']" transform="shrink-3" class="dialog-title"></fa-icon>
      <span class="ml-2">{{ 'choose_inspection_period' | translate }}</span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <fieldmagic-primary-button
            (click)="submit()"
            icon="link"
            label="link"
          >
          </fieldmagic-primary-button>
        </li>
        <li class="list-inline-item">
          <fieldmagic-primary-button
            (click)="dialogClose()"
            icon="times"
            label="close"
          ></fieldmagic-primary-button>
        </li>
      </ul>
    </div>
  </div>
</header>

<hr />

<mat-dialog-content>
  <form [formGroup]="form">
    <div class="row">
      <div class="col mb-3">
        <div>
          <label class="labels" for="checklist_name" translate>
            {{ 'checklist_name' | translate }}
          </label>
        </div>
        <input class="form-control font-size-11 input-height-40" formControlName="checklist_name" [readonly]="true" value="dialogData.text">
      </div>
    </div>
    <div class="row">
      <div class="col mb-3">
        <div>
          <label class="labels" for="checklist_name" translate>
            {{ 'choose_period' | translate }}
            <required-tag [hasSpace]="false"></required-tag>
          </label>
        </div>
        <ng-select
          ngDefaultControl
          [items]="dialogData.extras['available_periods']"
          formControlName="choose_period"
          [clearable]="true"
          [multiple]= true
          appendTo="body"
          [ngClass]="{'is-invalid': form.controls['choose_period'].invalid && form.controls['choose_period'].touched && form.dirty}">

          <ng-template ng-option-tmp let-item="item" class="value-text">
              {{ item | translate }}
          </ng-template>

          <ng-template ng-label-tmp let-item="item" let-clear="clear" class="value-text">
              {{ item | translate }}
          </ng-template>

        </ng-select>
      </div>
    </div>
  </form>
</mat-dialog-content>