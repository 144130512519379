<header>
  <div class="d-flex header-container header-label">
    <span class="title">
      <fa-icon [icon]="['fas', 'receipt']" class="pr-2"></fa-icon>
      <span>{{ 'work_order_items' | translate }}</span>
    </span>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button class="btn btn-primary" [disabled]="selectedLineItems.length < 1" (click)="addWorkOrderItems()">
              <fa-icon [icon]="['fas', 'save']"></fa-icon>
              <span class="pl-1">{{ 'add_work_order_items' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <fieldmagic-primary-button
            (click)="cancelDialog()"
            variant="error"
            label="close"
            icon="times"
          ></fieldmagic-primary-button>
        </li>
      </ul>
    </div>
  </div>
  <hr />
</header>

<mat-dialog-content>
  <table class="table table-bordered">
    <thead>
      <tr class="text-center font-weight-bold">
        <td width="2%">
          <input type="checkbox" [(ngModel)]="isSelectAll" (change)="onChangeCheckbox($event, [], true)">
        </td>
        <td width="22%">{{ 'product' | translate }}</td>
        <td width="30%">{{ 'description' | translate }}</td>
        <td width="4%">{{ 'quantity' | translate }}</td>
        <td width="10%">{{ 'tax_code' | translate }}</td>
        <td width="8%">{{ 'unit_cost' | translate }}</td>
        <td width="8%">{{ 'unit_price' | translate }}</td>
        <td width="8%">{{ 'discounted_price' | translate }}</td>
        <td width="8%">{{ 'line_total' | translate }}</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="lineItems.length < 1" class="text-center">
        <td colspan="9">{{ 'no_items' | translate }}</td>
      </tr>
      <ng-container *ngFor="let lineItem of lineItems; index as i">
        <tr [ngClass]="{'disabled-row': isSelectedFromParentLineItems(lineItem)}" [matTooltip]="(isSelectedFromParentLineItems(lineItem) ? 'already_selected' : '') | translate">
          <td class="text-center">
            <input *ngIf="isSelectedFromParentLineItems(lineItem)" type="checkbox" [disabled]="isSelectedFromParentLineItems(lineItem)">
            <input *ngIf="!isSelectedFromParentLineItems(lineItem)" [checked]="isLineItemSelected(lineItem)" type="checkbox" (change)="onChangeCheckbox($event, lineItem, false)">
          </td>
          <td class="text-center">
            <span *ngIf="lineItem.item_id">[{{ lineItem.item_code }}] {{ lineItem.item_name }}</span>
            <span *ngIf="!lineItem.item_id">--</span>
          </td>
          <td class="text-center">
            <span *ngIf="lineItem.description" matTooltip="{{ lineItem.description }}">
              {{ lineItem.description | slice:0:75 }}
              <span *ngIf="lineItem.description.length > 75">...</span>
            </span>
            <span *ngIf="!lineItem.description">--</span>
          </td>
          <td class="text-center">
            <span>{{ formatNumber(lineItem.quantity) }}</span>
          </td>
          <td class="text-center"><span>{{ lineItem.tax_code_name }}</span></td>
          <td class="text-center"><span>{{ lineItem.unit_cost | currency }}</span></td>
          <td class="text-center"><span>{{ lineItem.unit_price | currency }}</span></td>
          <td class="text-center"><span>{{ lineItem.discounted_price | currency }}</span></td>
          <td class="text-center"><span>{{ lineItem.lineTotal | currency }}</span></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</mat-dialog-content>