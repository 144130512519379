import { Injectable } from '@angular/core';
import { EventApi, EventInput } from '@fullcalendar/common';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, combineLatest, Subject } from 'rxjs';
import { GraphQLFilter } from '../../../shared/components/graphql-filter/graphql-filter.component';
import * as APIService from '../../../API.service';
import { JsonService } from '../../../services/helpers/json/json.service';
import { ViewService } from '../../../services/view.service';
import { ClientStoreService } from '../../../services/client-store.service';
import { get, isEmpty } from 'lodash';
import { sprintf } from 'sprintf-js';
import { ReadableAddressPipe } from '../../../pipes/readable-address.pipe';
import { TaskMetadataC } from '../full-calendar/classes/metadata/task-metadata';
import { JobMetadata, JobMetadataC } from '../full-calendar/classes/metadata/job-metadata';
import { OpportunityMetadata, OpportunityMetadataC } from '../full-calendar/classes/metadata/opportunity-metadata';
import { ActivityLogTypeMetadata } from '../full-calendar/classes/metadata/activity-log-type-metadata';
import { LooseObject } from '../../../objects/loose-object';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { CalendarApiService } from './calendar-api.service';
import { NotificationService } from '../../../services/notification.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { blank, when } from '../../../shared/utils/common';

@Injectable()
export class CalendarService {

  /**
   * Filtration object that will be passed to our GetCalendarSchedulableTasks
   * GrahpQL query which affects the list of schedulable tasks.
   *
   * @type {GraphQLFilter}
   */
  schedulableTasksFilter: GraphQLFilter | null = null;

  /**
   * Are the users list still being fetched?
   *
   * @type {BehaviorSubject<boolean>}
   */
  resourcesAreLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Are the tasks that will be displayed for the current date
   * (displayed in the calendar) still being fetched?
   *
   * @type {BehaviorSubject<boolean>}
   */
  eventsAreLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Is the list of tasks that are available for scheduling, still being fetched?
   *
   * @type {BehaviorSubject<boolean>}
   */
  schedulableTasksAreLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Behavior subject for the calendar module that determines its loading state.
   *
   * @type {Observable<boolean[]>}
   */
  isCalendarDataLoading$: Observable<boolean[]> = combineLatest(
    this.resourcesAreLoading,
    this.eventsAreLoading,
    // this.schedulableTasksAreLoading
  );

  /**
   * Behavior subject for checking the list of tasks.
   *
   * @type {BehaviorSubject<{id: string, value: string}[] | boolean>}
   */
  reloadSchedulableTasks = new BehaviorSubject<object>({});

  /**
   * Subject for triggering update of calendar when
   * scheduling, unscheduling, duplicating, or editing
   * task in dialog.
   *
   * @type {Subject<object>}
   */
  updateCalendar: Subject<object> = new Subject<object>();

  /**
   * Observable for updateCalendar.
   *
   * @type {Subject<object>}
   */
  calendarUpdate$: Observable<object> = this.updateCalendar.asObservable();

  /**
   * Subject for triggering update of tasks/jobs list for scheduling when
   * scheduling, unscheduling, or editing
   * task in dialog.
   *
   * @type {Subject<object>}
   */
  updateForSchedulingList: Subject<object> = new Subject<object>();

  /**
   * Observable for updateForSchedulingList.
   *
   * @type {Subject<object>}
   */
  forSchedulingListUpdate$: Observable<object> = this.updateForSchedulingList.asObservable();

  strRcordMetadataType: string = null;

  /**
   * Adjust the total count after scheduling/unscheduling without
   * having to refetch the tasks/jobs for scheduling list.
   *
   * @type {number}
   */
  private totalChangeOffset: number = 0;

  /**
   * Adjust the 'showing to' count after scheduling/unscheduling without
   * having to refetch the tasks/jobs for scheduling list.
   *
   * @type {number}
   */
  private showingToChangeOffset: number = 0;

  /**
   * Returns the totalChangeOffset value to display the latest
   * number of entries in the tasks/jobs for scheduling list.
   *
   * @returns {number}
   */
  get totalChange(): number {
    return this.totalChangeOffset;
  }

  /**
   * Returns the showingToChangeOffset value to display the latest
   * number remaining items in the current page of the
   * tasks/jobs for scheduling list.
   *
   * @returns {number}
   */
  get showingToChange(): number {
    return this.showingToChangeOffset;
  }

  constructor(
    protected translateService: TranslateService,
    protected jsonService: JsonService,
    protected readableAddressPipe: ReadableAddressPipe,
    protected clientStoreService: ClientStoreService,
    protected viewService: ViewService,
    protected router: Router,
    protected _location: Location,
    protected _api: CalendarApiService,
    protected _notifications: NotificationService
  ) { }

  /**
   * Returns task metadata from the given FullCalendar
   * event object.
   *
   * FullCalendar's external event objects returns custom
   * attributes in the following manner: `object.event.extendedProps`
   * and since our own task metadata is not returned in this
   * way, we end up having two (2) ways for accessing a task's
   * metadata from within a FullCalendar event object. This
   * method will help in retrieving said metadata.
   *
   * @param event
   *
   * @returns {{string}}
   */
  getTaskMetadata(event: EventApi | EventInput | any): any {
    if (event.taskMetadata !== undefined) {
      return event.taskMetadata;
    } else if (event.extendedProps !== undefined) {
      return event.extendedProps.taskMetadata;
    } else if (event.event !== undefined) {
      return event.event.extendedProps.taskMetadata
    }
  }

  /**
   * Returns task metadata from the given FullCalendar
   * event object.
   *
   * FullCalendar's external event objects returns custom
   * attributes in the following manner: `object.event.extendedProps`
   * and since our own task metadata is not returned in this
   * way, we end up having two (2) ways for accessing a task's
   * metadata from within a FullCalendar event object. This
   * method will help in retrieving said metadata.
   *
   * @param event
   *
   * @returns {{string}}
   */
  getRecordMetadata(event: EventApi | EventInput | any): TaskMetadataC | JobMetadataC | ActivityLogTypeMetadata  {
    if (event.recordMetadata !== undefined) {
      this.strRcordMetadataType = event.recordMetadata.metadata_type || null;
      return event.recordMetadata;
    } else if (event.extendedProps !== undefined) {
      this.strRcordMetadataType = event.extendedProps.recordMetadata.metadata_type || null;
      return event.extendedProps.recordMetadata;
    } else if (event.event !== undefined) {
      this.strRcordMetadataType = event.event.extendedProps.recordMetadata.metadata_type || null;
      return event.event.extendedProps.recordMetadata;
    }
  }

  getRecordMetadataType(): string | null {
    return this.strRcordMetadataType || null
  }

  getEventData(objEvent: any): TaskMetadataC | JobMetadataC | OpportunityMetadataC | ActivityLogTypeMetadata {
    let objEventData = objEvent.recordMetadata
      || get(objEvent, ['extendedProps', 'recordMetadata'])
      || get(objEvent, ['event', 'extendedProps', 'recordMetadata']);

    if (objEventData.metadata_type == 'task') {
      return new TaskMetadataC(objEventData);
    } else if (objEventData.metadata_type == 'job') {
      return new JobMetadataC(objEventData);
    } else if (objEventData.metadata_type === 'opportunity') {
      return new OpportunityMetadataC(objEventData);
    }

    return objEventData;
  }

  /**
   * Formats the taskmetadata so that it can be properly displayed
   * by the dialog box.
   *
   * @param {Object} taskMetadata
   *
   * @returns {Object}
   */
  formatTaskForDialogDisplay(taskMetadata): object {
    let humanReadableDateFormat = 'lll';
    let taskMetadataClone = JSON.parse(JSON.stringify(taskMetadata));

    taskMetadataClone['due_date'] = taskMetadataClone['due_date'] !== null ? moment(taskMetadataClone['due_date']).format(humanReadableDateFormat) : null;
    taskMetadataClone['date_completed'] = taskMetadataClone['date_completed'] !== null ? moment(taskMetadataClone['date_completed']).format(humanReadableDateFormat) : null;

    /*if (taskMetadataClone['job_task_eta'] !== null) {
      let jobTaskEta = moment(taskMetadataClone['job_task_eta']);
      if (jobTaskEta.isSame(moment(), 'day')) {
        // When formating the estimated time of arrival, let's make sure not to
        // display the current date if the date is today. Only show the time
        taskMetadataClone['job_task_eta'] = `${this.translateService.instant('today')}, ${jobTaskEta.format('HH:mm A')}`;
      } else {
        taskMetadataClone['job_task_eta'] = jobTaskEta.format(humanReadableDateFormat);
      }

      // Only show the ETA if the technician is still in his way.
      taskMetadataClone['showTaskEta'] = taskMetadata.task_progress === 'in_transit';
    }*/

    /*if (taskMetadataClone['site_email_address']) {
      taskMetadataClone['site_email_address'] = JSON.parse(taskMetadataClone['site_email_address']);
    }*/

    switch (taskMetadataClone['priority']) {
      case '1':
        taskMetadataClone['priority'] = 'high';
        break;
      case '2':
        taskMetadataClone['priority'] = 'medium';
        break;
      case '3':
        taskMetadataClone['priority'] = 'low';
        break;
    }

    return taskMetadataClone;
  }

  /**
   * Formats the given list of tasks so that it can be properly displayed
   * in the list of schedulable tasks.
   *
   * @param tasks
   *
   * @returns {APIService.UnscheduledTaskSubscription[] | APIService.GetCalendarSchedulableTasksQuery[]}
   */
  formatTaskForListDisplay(tasks): APIService.UnscheduledTaskSubscription[] | APIService.GetCalendarSchedulableTasksQuery[] {

    // FC-1378: Cannot read property 'map'
    tasks = tasks || [];
    return JSON.parse(JSON.stringify(tasks)).map((task) => {
      // As of writing, we only modify tasks to return a user's full name.
      // We do this just so that we don't concatenate the first and last name
      // repeatedly everywhere.
      let first_name = (task.user_first_name !== '' && task.user_first_name !== null) ? task.user_first_name : '';
      let last_name = (task.user_last_name !== '' && task.user_last_name !== null) ? task.user_last_name : '';
      task.user_full_name = `${first_name} ${last_name}`;

      return task;
    });
  }

  formatTooltip(strMetadataType, objMetadata): string {
    if (strMetadataType === 'activity_log_type') {
      return 'test';
    } else {
      return this.formatTaskTooltip(objMetadata, objMetadata.departments)
    }
  }

  /**
   * Gets task data and formats to be
   * displayed on task's tooltip
   *
   * @todo: Add design to task's tooltip
   * @returns {string|undefined}
   */
  formatTaskTooltip(taskMetadata, taskDepartments) : string|undefined {

    if (get(taskMetadata, ['metadata_type']) === 'task') {
      const strDefaultEmptyValue = '--';
      const strTaskModule: 'job' | 'opportunity' = get(taskMetadata, 'job') ? 'job' : 'opportunity';
      const strDeletedMessage: string = strTaskModule === 'job' ? 'calendar_job_deleted' : 'calendar_quote_deleted';

      let strTaskPriority = strDefaultEmptyValue;
      let strDepartmentName = get(taskMetadata, ['department', 'name'], strDefaultEmptyValue);
      let strTaskDescription = taskMetadata['description'] || strDefaultEmptyValue;
      let address = get(taskMetadata, [strTaskModule, 'full_address'], strDefaultEmptyValue);

      if (taskMetadata['priority'] && taskMetadata['priority'] !== null) {
        strTaskPriority = this.translateService.instant(taskMetadata['priority']);
      }

      let strCustomerName = get(taskMetadata, [strTaskModule, 'customer', 'name'], strDefaultEmptyValue);
      let strToolTipHeader = (!taskMetadata[strTaskModule][`${strTaskModule}_number`]) ? this.translateService.instant(strDeletedMessage)+ `\n` : "";

      return sprintf(
        "%s%s: %s\n%s: %s\n%s: %s\n%s: %s\n%s: %s\n%s: %s",
        strToolTipHeader,
        this.translateService.instant('name'), taskMetadata['name'],
        this.translateService.instant('customer_name'), strCustomerName,
        this.translateService.instant('department'), strDepartmentName,
        this.translateService.instant('priority'), strTaskPriority,
        this.translateService.instant('task_description'), strTaskDescription,
        this.translateService.instant('address'), address || '--',
      );
    }
  }

  /**
   * Decrements the 'Showing x to y of z entries' values
   * in the tasks/jobs for scheduling list.
   *
   * @returns {void}
   */
  decrementListingOffsets(): void {
    this.totalChangeOffset -= 1;
    this.showingToChangeOffset -= 1;
  }

  /**
   * Increments the 'Showing x to y of z entries' values
   * in the tasks/jobs for scheduling list.
   *
   * @param {number} numListLength
   *
   * @returns {void}
   */
  incrementListingOffsets(numListLength: number): void {
    if (numListLength < 10) {
      this.showingToChangeOffset += 1;
    }

    this.totalChangeOffset += 1;
  }

  /**
   * Resets the 'Showing x to y of z entries' values
   * in the tasks/jobs for scheduling list.
   *
   * @returns {void}
   */
  resetListingOffsets(): void {
    this.totalChangeOffset = 0;
    this.showingToChangeOffset = 0;
  }

  /**
   * Create a query params for related customer module
   *
   * @param {bIsBehalfOfChildClient} boolean
   *
   * @returns {Record<string, string>}
   */
  protected makeRelateCustomerQueryParams(bIsBehalfOfChildClient: boolean): Record<string, string> {
    const onBehalfOfClientId = get(this.viewService.getRecord().record_details, 'customer_owner_client_id');
    const currentClientId = this.clientStoreService.getActiveClient().client_id;

    if (onBehalfOfClientId !== currentClientId && !bIsBehalfOfChildClient) {
      return {
        on_behalf_of: onBehalfOfClientId,
      };
    }

    return {};
  }

  /**
   * Creates the URL for links to other modules
   *
   * @param {string} moduleName
   * @param {string|undefined} moduleId
   * @param {bIsBehalfOfChildClient} boolean
   *
   * @returns {string}
   */
  createUrl(moduleName: string, moduleId: string | undefined = undefined, bIsBehalfOfChildClient: boolean): string {
    let navigateCommand = [`/${moduleName}/${moduleId}`];
    let navigationExtras = {
      queryParams: {
        ... (moduleName === 'customers' && this.makeRelateCustomerQueryParams(bIsBehalfOfChildClient))
      }
    }
    let url = this.router.serializeUrl(this.router.createUrlTree(navigateCommand, navigationExtras));

    return this._location.prepareExternalUrl(url);
  }

  /**
   * Gets the opportunity details of the task.
   *
   * @returns {OpportunityMetadata}
   */
  getTaskOpportunityDetails(objTaskMetadata: LooseObject, objRecordDetails: LooseObject, arTasks = []): OpportunityMetadata {
    return {
      id: objTaskMetadata['opportunity_id'],
      opportunity_number: objRecordDetails['opportunity_number'],
      forecast_close_date: objRecordDetails['forecast_close_date'],
      full_address: objRecordDetails['address_text'],
      customer: {
        id: objRecordDetails['customer_id'],
        name: objRecordDetails['customer_text'],
      },
      tasks: arTasks
        ? arTasks.filter(task => task.id !== objTaskMetadata['id'])
        : null,
      assigned_user: !isEmpty(objRecordDetails['user_id'])
        ? { id: objRecordDetails['user_id'], name: objRecordDetails['user_text'] }
        : null,
      department: !isEmpty(objRecordDetails['department_id'])
        ? { id: objRecordDetails['department_id'], name: objRecordDetails['department_text'] }
        : null
    }
  }

  /**
   * Gets the job details of the task.
   *
   * @returns {JobMetadata}
   */
  getTaskJobDetails(objTaskMetadata: LooseObject, objRecordDetails: LooseObject, arTasks = []): JobMetadata {
    return {
      id: objTaskMetadata['job_id'],
      job_number: objRecordDetails['job_number'],
      due_date: objRecordDetails['due_date'],
      full_address: objRecordDetails['address_text'],
      customer: {
        id: objRecordDetails['customer_id'],
        name: objRecordDetails['customer_text'],
      },
      tasks: arTasks
        ? arTasks.filter(task => task.id !== objTaskMetadata['id'])
        : null,
      assigned_user: !isEmpty(objRecordDetails['user_id'])
        ? { id: objRecordDetails['user_id'], name: objRecordDetails['user_text'] }
        : null,
      department: !isEmpty(objRecordDetails['department_id'])
        ? { id: objRecordDetails['department_id'], name: objRecordDetails['department_text'] }
        : null
    }
  }

  unscheduleTask(opts: {
    task: Record<string, any>,
    record_details: Record<string, any>,
    module: string,
    tasks: any, /// no idea wtf is this
    onComplete: (task?: UnscheduleTaskResponse) => void,
    on_behalf_of_child_client?: boolean,
    child_client_id?: string,
  }): void {
    if (blank(get(opts, 'task.id'))) {
      return;
    }

    this._notifications.sendConfirmation('Are you sure you want to unschedule this task?')
      .pipe(
        filter(({answer}) => answer),
        switchMap(() => this._api.unscheduleTask(get(opts, 'task.id'), {
          ... (opts.on_behalf_of_child_client && {
            for_child_client_id: opts.child_client_id,
          })
        })),
        tap({
          next: () => this._notifications.notifySuccess('task_schedule_cancelled'),
          error: () => {
            this._notifications.notifyError('task_schedule_cancel_error');
            opts.onComplete();
          },
        }),
      )
      .subscribe((task) => {
        // we would use the task data from the api response
        // when a parent client cancels its own task in the child client
        // system. This is necessary so we could append the task
        // to the list of task to be schedule just like the normal
        // unscheduling of task
        const data: TaskMetadataC = (task['was_replaced']) ? task : {
          id: get(opts.task, 'id'),
          name: get(opts.task, 'activity_name'),
          status: 'awaiting_scheduling',
          due_date: null,
          priority: get(opts.task, 'priority'),
          estimated_duration: 0,
          description: get(opts.task, 'notes'),
          job: when(opts.module === 'jobs', {
            then: () => this.getTaskJobDetails(opts.task, opts.record_details, opts.tasks),
          }),
          opportunity: when(opts.module === 'opportunities', {
            then: () => this.getTaskOpportunityDetails(opts.task, opts.record_details, opts.tasks),
          }),
          department: {
            id: get(opts.task, 'department_id'),
            name: get(opts.task, 'department_name'),
          },
          assigned_user: null,
          viewable: false,
          parent_id: get(opts.task, 'parent_id'),
          metadata_type: 'task',
          activity_date: null,
        };

        opts.onComplete({
          action: 'unschedule',
          data,
        });
      });
  }
}

type UnscheduleTaskResponse = {
  action: string;
  data: TaskMetadataC;
}