import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TagDisplay } from '../objects/tag-display';
import { clone } from 'lodash';
import { LooseObject } from '../../../objects/loose-object';

@Component({
  selector: 'fm-dynamic-chip',
  template: `<mat-chip
                #fmTagPopover="ngbPopover"
                [ngbPopover]="popContent"
                [autoClose]="'outside'"
                container="body"
                [style.background]="tag.color"
                [removable]="removable"
                (removed)="remove(tag)">
                {{ tag.name }}
                <fa-icon *ngIf="removable"
                  [style.margin-top]="'3px'"
                  [icon]="['fas', 'times-circle']"
                  matChipRemove
                  (click)="$event.stopPropagation()">
                </fa-icon>
             </mat-chip>
             <ng-template #popContent>
              <input [(ngModel)]="objTag.name" class="form-control mb-1" [ngClass]="{'is-invalid': bMarkAsRequired}"/>
              <small *ngIf="bMarkAsRequired" class="text-danger">{{ 'required' | translate }}</small>
              <span
                [style.background]="tag.color"
                [cpToggle]="true"
                [cpDialogDisplay]="'inline'"
                [(colorPicker)]="objTag.color">
              </span>
              <button class="btn btn-primary btn-block mt-2" (click)="select(fmTagPopover)">{{ 'save' | translate }}</button>
             </ng-template>
             `
})
export class FmDynamicChip implements OnInit {

  /**
   * The tag data to display.
   *
   * @var {TagDisplay}
   */
  @Input('tag') tag: TagDisplay;

  /**
   * If the tag is removable, hides the x icon
   * if false.
   *
   * @var {boolean}
   */
  @Input('removable') removable: boolean;

  /**
   * Informs parent component whenever a tag is removed.
   *
   * @var {EventEmitter<TagDisplay>}
   */
  @Output() removeEvent = new EventEmitter<TagDisplay>();

  /**
   * Informs parent component whenever a tag is removed.
   *
   * @var {EventEmitter<TagDisplay>}
   */
  @Output() saveEvent = new EventEmitter<TagDisplay>();

  /**
   * Indicator to show the field is requited.
   *
   * @var {boolean}
   */
  bMarkAsRequired: boolean = false;

  /**
   * The tag data in the popup.
   *
   * @var {TagDisplay}
   */
  objTag: TagDisplay;

  ngOnInit(): void {
    this.objTag = clone(this.tag);
  }

  /**
   * Method that triggers the removeEvent
   *
   * @param {TagDisplay} tag
   */
  remove(tag: TagDisplay) {
    this.removeEvent.next(tag);
  }

  /**
   * Method that triggers the saveEvent
   *
   * @param {LooseObject} objEvent
   */
  select(objEvent: LooseObject) {
    if (this.tag.name.length > 0) {
      this.bMarkAsRequired = false;
      this.tag = this.objTag;
      this.saveEvent.next(this.tag);
      objEvent.close();
    } else {
      this.bMarkAsRequired = true;
    }
  }

}
