import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ListingService } from '../../../../services/listing.service';
import { NotificationService } from '../../../../services/notification.service';
import { RecordService } from '../../../../services/record.service';
import { ViewService } from '../../../../services/view.service';
import { Subject, of, concat, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, first, filter } from 'rxjs/operators';
import { Select } from '../../../../objects/select';

import { EditRecurringJobsComponent } from '../recurring-jobs/edit-recurring-jobs/edit-recurring-jobs.component';
import { RecurringJobsHistoryComponent } from '../recurring-jobs/recurring-jobs-history/recurring-jobs-history.component';
import { EditRecurringInvoiceFormComponent } from '../recurring_invoices/form/edit-recurring_invoice-form.component';
import { RelateIds } from '../../../../lists/relate-ids';
import * as moment from 'moment';
import { filled } from '../../../utils/common';

@Component({
  selector: 'app-recurring-jobs',
  templateUrl: './recurring-jobs.component.html',
  styleUrls: ['./recurring-jobs.component.scss'],
  providers: [ListingService]
})
export class RecurringJobsComponent implements OnInit {
  @Input() strRecordId : string;
  @Input() strModule : string;
  @Output() refreshRelatePanel = new EventEmitter<any>();
  public arRecurringJobs = [];
  public arPreviousPages = {};
  public bPageLoaded: boolean = false;
  public objJobData: any;
  public objModuleConfig: any = {};
  public bDialogLoaded: boolean = true;
  public periodDropdownOption = [];

  public arRelateAssetTypeValues$: Observable<Select[]>;
  public arRelateAssetTypeValuesInput$ = new Subject<string>();
  public bRelateLoadingAssetType: boolean = false;
  public relateAssetTypeData: any = [];
  public strAssetTypeId: string;
  public strStatus: string;
  public strBillable: string;


  public arStatusFilter: string[] = [
    'active', 'inactive'
  ];
  public arBillableFilter: Array<{
    id: string,
    text: string
  }> = [
    { id: 'active', text: 'yes' },
    { id: 'inactive', text: 'no' },
  ];

  constructor(
    private dialog: MatDialog,
    public listService: ListingService,
    private notifService: NotificationService,
    public viewService: ViewService,
    private recordService: RecordService
  ) { }

  ngOnInit() {
    // Get the list on page initialization.
    this.fetchList('default');
  }

  /**
  * Open the create purchase order dialog.
  */
  createRecurringJob() {
    // Create the object to be passed inside the dialog.
    let objData = {
    maxWidth: '100%',
    width: '80%',
    height: 'auto',
    padding: '1%',
    disableClose: true,
    // The id of the jobs, the purchase order's "parent".
    data: {
        period_options: this.periodDropdownOption,
        record_id : this.strRecordId,
        module: this.strModule,
        view_type : 'add'
      }
    };

    // Initialize the dialog.
    let tabDialogRef = this.dialog.open(EditRecurringJobsComponent, objData);

    // When the dialog closes, reload the list.
    tabDialogRef.afterClosed().pipe(
      first(),
      filter((item) => filled(item) && item !== false),
    ).subscribe(item => {
      if (item != undefined) {
        let strModuleId = RelateIds[this.strModule];
        let arFilter = { ["recurring_invoices." + strModuleId]: this.strRecordId };
        this.recordService.getRecordRelate('recurring_invoices', '', false, false, arFilter).subscribe( response => {
          if (response.length < 1) {
            this.notifService.sendConfirmation('no_recurring_invoice_create').subscribe(confirmation => {
              if (confirmation.answer) {
                // open the dialog
                this.dialog.open(EditRecurringInvoiceFormComponent, {
                width: '80%',
                height: 'auto',
                data: {
                  isNew: true,
                  moduleID: this.strRecordId,
                  moduleName: this.strModule,
                  recordID: '',
                },
                disableClose: true
                }).afterClosed().first().subscribe((strStatus) => {
                  // if current dialog was closed and needs refresh via save status from the
                  // dialog callback
                  this.refreshRelatePanel.emit({ widget: 'recurring_invoices' });
                  setTimeout(() => {
                    this.fetchList('default');
                  }, 500);
                });
              } else {
                // Show notification that activity has been created.
                this.notifService.notifySuccess('header_notification.success_added');
                setTimeout(() => {
                  this.fetchList('default');
                }, 500);
              }
            });
          } else {
            this.notifService.notifySuccess('header_notification.success_added');
            setTimeout(() => {
              this.fetchList('default');
            }, 500);
          }
        });
      }
    });
  }

  /**
   * Opens the selected RecurringJob from the list.
   * @param objRecurringJob - the RecurringJob data.
   */
  openRecurringJob(objRecurringJob) {
    let objUpdatedData: any = [];
      // Object to be passed in the dialog.
      let objData = {
        maxWidth: '100%',
        width: '80%',
        height: 'auto',
        padding: '1%',
        disableClose: true,
        // Data to be passed on
        data: {
          // Put the job id and invoice data to the dialog.
          record_id : this.strRecordId,
          period_options: this.periodDropdownOption,
          recurring_job: objUpdatedData,
          recurring_job_id: objRecurringJob['id'],
          module: this.strModule,
          view_type : 'edit'
        }
      };

      // Instantiate dialog.
      let tabDialogRef = this.dialog.open(EditRecurringJobsComponent, objData);
      // Reload invoice list once the dialog is closed.
      tabDialogRef.afterClosed().first().subscribe(item => {
        // Only when the user hits save will we reload the list.
        if (item != undefined && item == 'record_update_success') {
          // Show notification that activity has been created.
          this.notifService.notifySuccess('header_notification.success_update');
          setTimeout(() => {
            this.fetchList('default');
          }, 3500);
        }
      });
  }

  /**
   * Fetch the list of purchase order.
   * @param strPage - what page is currently to be viewed.
   */
  fetchList(strPage) {

    let strModuleId = RelateIds[this.strModule];
    let objFilter = { [strModuleId]: this.strRecordId };
      if (this.strAssetTypeId != null && this.strAssetTypeId != '') { objFilter['asset_type_id'] = this.strAssetTypeId; }

    if (this.strStatus == 'active') {
      objFilter['status'] = true;
    } else if (this.strStatus == 'inactive') {
      objFilter['status'] = false;
    }

    if (this.strBillable == 'active') {
      objFilter['billable'] = true;
    } else if (this.strBillable == 'inactive') {
      objFilter['billable'] = false;
    }

    let objPagination = this.listService.beforeFetching(strPage);
    this.bPageLoaded = false;
    // Get the list from API.
    this.listService.fetchDataAdvanceSearch(
      objPagination['objPage'],
      'recurring_jobs',
      objFilter,
      { 'id': 'created_at', 'sort': 'desc' },
    ).subscribe(response => {
      this.setRecurringJobList(response['data'], response['option']);
      this.listService.afterFetching(response, strPage);
      this.bPageLoaded = true;
    });
  }

  /**
   *
   * @param strId - record id
   */
  deleteRecurringJob(strId) {
    this.notifService.sendConfirmation().subscribe(confirmation => {
      if (confirmation.answer) {
        this.recordService.deleteRecord('recurring_jobs', strId).first().subscribe(
          data => {
            if (data) {
              this.fetchList('default');
              this.notifService.notifySuccess('record_delete_success');
            } else {
              this.notifService.notifyError('record_delete_failed');
            }
          });
      }
    })

  }

  /**
   * Initialize asset type related data
   */
  initRelateAssetTypeField() {
    this.bRelateLoadingAssetType = true;
    this.recordService.getRecordRelate('asset_types', '', false).subscribe( result => {
      this.relateAssetTypeData = (result) ? result : [];
      this.initRelateAssetType();
      this.bRelateLoadingAssetType = false;
    });
  }

  /**
   * Initialize asset type relate field
   */
  initRelateAssetType() {
    this.arRelateAssetTypeValues$ = concat(
      of(this.relateAssetTypeData), // We set the initial values to the ng-select.
      this.arRelateAssetTypeValuesInput$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => this.bRelateLoadingAssetType = true),
      switchMap(term => this.recordService.getRecordRelate('asset_types', term, '', false, false).pipe(
        tap((data) => {
        // this.item['options'] = data;
        this.bRelateLoadingAssetType = false;
        }),
      ))
      ),
    );
  }

  /**
   * Open dialog for recurring job history
   * @param strRecurringJobId
   */
  getRecurringJobHistory(strRecurringJobId) {

    // Create the object to be passed inside the dialog.
    let objData = {
    maxWidth: '70%',
    width: '70%',
    height: '70%',
    padding: '1%',
    disableClose: true,
    // The id of the jobs, the purchase order's "parent".
    data: {
        recurring_job_id : strRecurringJobId
      }
    };

    // Initialize the dialog.
    let tabDialogRef = this.dialog.open(RecurringJobsHistoryComponent, objData);

    // When the dialog closes, reload the list.
    tabDialogRef.afterClosed().first().subscribe(item => {
    });
  }

  /**
   * Let's format the datetime value.
   * @param date
   */
  formatDate(strDate) {

    // Convert datetime to utc
    let utcTime = moment.utc(strDate).toDate();
    // Convert to local time zone and display
    return moment(utcTime).local().format('lll');
  }

  /**
   * Set the list of recurring invoices
   *
   * @param {Array<any>} arRecurringJobData
   * @param {Array<any>} arRecurringJobOption
   */
  setRecurringJobList(arRecurringJobData: Array<any>, arRecurringJobOption: Array<any>): void {
    this.arRecurringJobs = arRecurringJobData.map((objRecurringJobRecord) => {
      return Object.assign(objRecurringJobRecord, {
        amount_tax_included: parseFloat(objRecurringJobRecord['amount_tax_included']).toFixed(2),
        amount_tax_excluded: parseFloat(objRecurringJobRecord['amount_tax_excluded']).toFixed(2),
        period: this.getDropdownText('period', objRecurringJobRecord['period'], arRecurringJobOption)
      });
    });
  }

  /**
   * Get the dropdown text based on options
   *
   * @param {string} strField
   * @param {string} strDropdownId
   * @param {Array<any>} arOption
   *
   * @returns {string}
   */
  getDropdownText(strField: string, strDropdownId: string, arOption: Array<any>): string {
    var strDropdownText = strDropdownId;
    if (arOption[strField] && arOption[strField]['config']) {
      var arMatchOption = arOption[strField]['config'].find(option => option.id === strDropdownId);
      if (arMatchOption) {
        return arMatchOption.text;
      }
    }
    return strDropdownText;
  }

  /**
   * Handles refresh list event
   *
   * @returns {void}
   */
  onRefresh(): void {
    this.arRecurringJobs = []; // clear list
    this.fetchList('default');
  }

  ngOnChanges(): void {
    this.onRefresh();
  }
}
