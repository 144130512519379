<header>
  <div class="d-flex header-container header-label">
    <span class="title">
      <fa-icon [icon]="['fas', 'edit']" class="mr-1"></fa-icon>{{ 'new_import_queue' | translate }}
    </span>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li *ngIf="moduleSource" class="list-inline-item" >
          <button class="btn btn-primary" (click)="goToImportListing()">
            <fa-icon [icon]="['fas', 'file-pdf']"></fa-icon>
            <span class="pl-1">{{ 'go_to_import' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item" *ngIf="strCurrentStep === 'choose_file'">
          <button type="submit" class="btn btn-primary btn-lg submit-button" [disabled]="(! importQueueForm.valid && moduleSource === null) || canQueue === false || (importQueueForm.get('moduleName').value === 'assets' && this.importQueueForm.get('assetType').value === '')" (click)="submitImportQueueForm()">
            <button-icon-loader [defaultIcon]="['far', 'file-import']" [isLoading]="bStepsLoading"></button-icon-loader>
            {{ 'upload' | translate }}
          </button>
        </li>
        <li class="list-inline-item">
          <fieldmagic-primary-button
            (click)="closeDialog()"
            variant="error"
            icon="times"
            label="close"
          >
          </fieldmagic-primary-button>
        </li>
      </ul>
    </div>
  </div>
  <hr class="m-2"/>
</header>
<mat-dialog-content>
  <div class="row mb-3">
    <div class="col-md-12" align="center">
      <div class="progress-nav btn align-middle w33p cursor-unset " [ngClass]="{'active': objSteps.choose_file}">
        <span>{{ 'choose_file' | translate }}</span>
      </div>
      <div class="progress-nav btn align-middle w33p cursor-unset" [ngClass]="{'active': objSteps.field_mapping}">
        <span>{{ 'field_mapping' | translate }}</span><span *ngIf="strModule" class="pl-1">({{ strModule | translate }})</span>
      </div>
      <div class="progress-nav btn align-middle w33p cursor-unset" [ngClass]="{'active': objSteps.importing}">
        <span>{{ 'importing' | translate }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="strCurrentStep == 'choose_file' && objSteps.choose_file" class="choose_file">
    <form [formGroup]="importQueueForm" (ngSubmit)="submitImportQueueForm()">
      <div class="row mt-4">
        <div class="col-md-6 module-wrapper">
          <label *ngIf="moduleSource === null" class="jc-l mt-2 select-labels">{{ 'select_target_module' | translate }}:</label>
          <label *ngIf="moduleSource !== null" class="jc-l mt-2 select-labels"> Import {{ moduleSource | translate }}</label>
          <ng-select
            *ngIf="moduleSource === null"
            appendTo="body"
            [items]="importable_modules"
            bindValue="label"
            bindLabel="model"
            class="value-text"
            formControlName="moduleName"
            [searchable]="false"
            [clearable]="false">

            <ng-template ng-option-tmp let-item="item" class="value-text">
              {{item.label | translate}}
            </ng-template>

            <ng-template ng-label-tmp let-item="item" class="value-text">
                {{item.label | translate }}
            </ng-template>
          </ng-select>
        </div>
        <div *ngIf="hasAdditionalTemplateFilter" class="col-md-6 asset-wrapper">
          <label class="jc-l mt-2 select-labels">{{ 'select_asset_type' | translate }}:</label>
          <ng-select
              formControlName="assetType"
              id="assetTypeField"
              [items]="objAssetTypeRelate['source'] | async"
              [typeahead]="objAssetTypeRelate['typehead']"
              [loading]="objAssetTypeRelate['loader']"
              bindLabel="name"
              bindValue="id"
              [clearable]="false"
              (open)="objAssetTypeRelate['typehead'].next('')"
              appendTo="body">
          </ng-select>
        </div>
        <div class="col-md-12 mt-3 upload-wrapper">
          <drag-and-drop-file-upload-input [accept]="'.csv'" [multiple]="false" formControlName="importFile"></drag-and-drop-file-upload-input>
        </div>
        <div class="col-md-12 template-wrapper">
          <small *ngIf="moduleSource === null">
            <span class="text-muted" *ngIf="importQueueForm.get('moduleName').valid">
              {{ 'download_template_for' | translate }} {{ importQueueForm.get('moduleName').value | translate }}
              <b><a href="javascript:void(0)" (click)="downloadTemplate(importQueueForm.get('moduleName').value)">{{ 'here' | translate }}</a></b>
              <fa-icon [icon]="['fas', 'info-circle']" class="info text-primary" matTooltip="{{ 'import_templates_instruction' | translate }}"></fa-icon>
            </span>
          </small>

          <small *ngIf="moduleSource !== null">
            <span class="text-muted">
              {{ 'download_template_for' | translate }} {{ moduleSource | translate }}
              <b><a href="javascript:void(0)" (click)="downloadTemplate(moduleSource)">{{ 'here' | translate }}</a></b>
              <fa-icon [icon]="['fas', 'info-circle']" class="info text-primary" matTooltip="{{ 'import_templates_instruction' | translate }}"></fa-icon>
            </span>
          </small>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="strCurrentStep == 'field_mapping' && objSteps.field_mapping" class="field_mapping">
    <field-mappings
      [bLoading]="bStepsLoading"
      [strModule]="strFieldMappingModule"
      [objUploadUrl]="objFieldMappingUploadUrl"
      [strRelatedRecordId]="importQueueForm.get('assetType').value"
      (objEventSubmit)="onSubmitFieldMapping($event)"
      [additionalMappings]="data.additional_mappings"
    ></field-mappings>
  </div>
  <div *ngIf="strCurrentStep == 'importing' && objSteps.importing" class="importing d-flex justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
</mat-dialog-content>
