<header>
  <div class="d-flex dialog-header">
    <h3 class="title">
      <span>
        <fa-icon [icon]="['fas', 'plus-circle']" class="mr-1 text-success"></fa-icon>{{ 'Add Products to ' + data['name'] }}
      </span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <fieldmagic-primary-button
            (click)="addProducts()"
            icon="plus-circle"
            label="add"
          >
          </fieldmagic-primary-button>
        </li>
        <li class="list-inline-item">
          <fieldmagic-primary-button
            variant="error"
            (click)="dialogRef.close()"
            icon="times"
            label="close"
          ></fieldmagic-primary-button>
        </li>
      </ul>
    </div>
  </div>
</header>
<hr>
<div>
  <mat-dialog-content>
    <div style="width: 600px;">
      <ng-select [items]="objProducts.source | async"
        multiple="true"
        bindLabel="name"
        bindValue="id"
        appendTo="body"
        (open)="objProducts.loadDataOnClick()"
        [typeahead]="objProducts.typehead"
        [loading]="objProducts.loader"
        [(ngModel)]="objProducts.value">
      </ng-select>
    </div>
  </mat-dialog-content>
</div>
