import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalRecord } from '../objects/global-record';
import { AdvancedSearchboxResponse, RemoteRecord } from '../objects/advanced-searchbox-domains';
import { AdvanceSearchboxTemplate } from '../objects/advance-searchbox';
import { LooseObject } from '../objects/loose-object';
import { ActionsForChildClientOptions } from '../features/data-sharing/objects/services/sharing-data';
import { filter, map } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { filled, isId } from '../shared/utils/common';

const kBaseSearchUrl: string = environment.search_url;

@Injectable()

export class SearchService {

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Send a request to our lambda function,
   * that is connected to our Elasticsearch.
   *
   * @param {string} strQuery - The term to search.
   * @param {string} strModule - Bounds the search by a specific module.
   * @param {SearchOptions} options - Search related options
   *
   * @returns {Observable<GlobalRecord[]>}
   */
  global(strQuery: string, strModule: string|string[] = null, options: SearchOptions = {}): Observable<GlobalRecord[]> {

    let body = new URLSearchParams();
    body.append('term', (strQuery != null) ? strQuery.trim() : strQuery);

    // If module is set to all (global search default), do not include
    // it in the body in order to search without module constraint
    if (strModule && strModule !== 'all') {
      body.append('module', strModule as any);
    }

    if (filled(options.for_child_client_id) && isId(options.for_child_client_id)) {
      body.append('other_client_id', options.for_child_client_id);
    }

    return this.http.post<string>(kBaseSearchUrl, body.toString())
    .pipe(
      // This will filter which record we will fetch either from aggregations or just on hits
      map(results => {
        if (strModule && strModule !== 'all' &&
          results && results['hits'] &&
          results['hits']['hits']
        ) {
            return results['hits'];
         } else if (results && results['aggregations'] &&
          results['aggregations']['by_index'] &&
          results['aggregations']['by_index']['buckets']
        ) {
            return results['aggregations']['by_index']['buckets'];
         } else {
            return [];
         }
      }),
      map(results => {
        if (strModule && strModule !== 'all') {
          return results['hits'].map(hits => {
              let records = new GlobalRecord(
                hits['_source'],
                hits['_score'],
                results['max_score'],
              );

              return records;
            }
          );
        } else {
          let reOrderedResult = this.reOrderGlobalSearchResultByIndices(results, strQuery);
          let reOrderedRecords = reOrderedResult.map(bucket => {
            return bucket['top_docs']['hits']['hits'].map(hits => {
              let reOrderedRecords = new GlobalRecord(
                  hits['_source'],
                  hits['_score'],
                  bucket['top_docs']['hits']['max_score'],
              );

              return reOrderedRecords;
            });
          });

          return reOrderedRecords.flat();
        }
      })
    );
  }

  isNumeric(str: string): boolean {
    return /^\d+$/.test(str);
  }

  /**
   * Get Remote Data
   *
   * @param   {AdvanceSearchboxTemplate}                asTemplate  The Template to be used as the basis of data source
   * @param   {string<AdvancedSearchboxDomains>[]}      term        The term used to search on the module
   *
   * @return  {Observable<AdvancedSearchboxResponse>}
   */
  getRemoteData(asTemplate: AdvanceSearchboxTemplate, term: string): Observable<AdvancedSearchboxResponse> {
    let remoteUrl = `${kBaseSearchUrl}/remote/related/${asTemplate.remoteModule}`;

    let body = new URLSearchParams();
    body.append('term', term);

    // Add the bindings to the body
    body.append('label', asTemplate.bindLabel === undefined ? 'name' : asTemplate.bindLabel);
    body.append('value', asTemplate.bindValue === undefined ? 'id' : asTemplate.bindValue);
    if (asTemplate.moduleOrigin) {
      body.append('moduleOrigin', asTemplate.moduleOrigin);
    }

    return this.http.post<RemoteRecord[]>(remoteUrl, body.toString())
      .catch(error => {
        return Observable.throw(error);
      })
      .map((response: RemoteRecord[]) => {
        const asResponse: AdvancedSearchboxResponse = {
          response: response,
          term: term,
        };
        return asResponse;
      });
  }

  /**
   * Reordering of result based on priority indeces.
   * @param responseFromGlobalSearch
   * @returns
   */
  reOrderGlobalSearchResultByIndices(responseFromGlobalSearch, term) {
    const isTermNumber = this.isNumeric(term);

    const priorityList = isTermNumber ? [
      'jobs',
      'opportunities',
      'customer_invoices',
      'purchase_orders', 'supplier_invoices',
      'customers',
      'sites',
      'contacts',
    ] :
    [
      'customers',
      'sites',
      'contacts',
    ];

    // Function to sort the buckets based on the priority list
    const orderedBuckets = responseFromGlobalSearch.sort((a, b) => {
      const indexA = priorityList.indexOf(a.key);
      const indexB = priorityList.indexOf(b.key);

      // If the index is not found in the priority list, assign it a lower priority
      const priorityA = indexA === -1 ? priorityList.length : indexA;
      const priorityB = indexB === -1 ? priorityList.length : indexB;

      return priorityA - priorityB;
    });

    return orderedBuckets;
  }
}

type SearchOptions = ActionsForChildClientOptions;