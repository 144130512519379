import { Component, OnInit, Inject, HostListener, ElementRef } from '@angular/core';

import { cloneDeep, get, isEmpty, isNil } from 'lodash';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject, of, concat, Observable, Subscription, from, iif } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, map, filter, catchError } from 'rxjs/operators';

import { FormService } from '../../../../../services/form.service';
import { WidgetService } from '../../../../../services/widget.service';
import { RecordService } from '../../../../../services/record.service';
import { NotificationService } from '../../../../../services/notification.service';

import { Select } from '../../../../../objects/select';
import { RelateIds } from  '../../../../../lists/relate-ids';
import { ModuleLogo } from  '../../../../../lists/module-logo';
import { Notification } from '../../../../../objects/notification';
import { EditformComponent } from '../../../editform/editform.component';
import { RoleEditHeaderLabel, RoleModuleOptionId } from  '../../../../../lists/role';
import { isId } from '../../../../utils/common';

@Component({
  selector: 'app-role-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  public strModule: string = '';
  public strModuleId: string = '';
  public strRelateModule: string = '';
  public strHeaderLabel: string = '';
  public strViewType: string = '';
  public arRecord: Array<object> = [];
  public arRoleForm: Array<{
      id: string,
      form: FormGroup,
      primary_contact_role: boolean,
      metadata: object
  }> = []
  public bSubmit: boolean = false;
  public bLoading: boolean = true;
  public bSubmitted: boolean = false;
  public objRoleOption: object = {};
  public objFormMetadata: object = {};
  public strPrimaryContactRole: string = '';
  public bPrimaryHasChanged: boolean = false;
  public strPrimaryContactId: string = null;
  public strPreviousPrimaryContactId: string = null;
  public strMatDialogContentClass: string = 'overflow-visible';

  public objContactRelate = {};
  public arRelateValues$: Observable<Select[]> ;
  public arRelateValuesInput$ = new Subject<string>();

  private subscriptions: Subscription[] = [];

  get moduleClass(): string {
    let strDefaultClass = 'col-sm-4';
    if (this.strModule === 'opportunities') {
      strDefaultClass = 'col-sm-5';
    } else if (this.strRelateModule === 'customers') {
      strDefaultClass = 'col-sm-3';
    }
    return strDefaultClass;
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  constructor(
    public formService: FormService,
    public objElementRef: ElementRef,
    public widgetService: WidgetService,
    public recordService: RecordService,
    public notificationService: NotificationService,
    public dialogRef: MatDialogRef<EditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
  ) {
    this.strModule = data.module;
    this.strModuleId = data.module_id
    this.strViewType = data.view_type;
    this.strRelateModule = data.relate_module;
    this.strHeaderLabel = RoleEditHeaderLabel[data.relate_module];

    if (this.strViewType == 'manage') {
      this.getRoleList();
    } else {
      this.arRecord.push(data.record);
      this.initRoleForm();
    }

    this.dialogRef.backdropClick().subscribe(_ => {
      this.cancelDialog();
    });
  }

  ngOnInit() {}

  getRoleList(): void {
    var objFilter = {};
    if (this.strModule == 'contacts') {
      objFilter['contact_id'] = this.strModuleId;
    }
    this.widgetService.getAllContactRoles(
      (this.strModule == 'contacts') ? this.strRelateModule : this.strModule,
      (this.strModule == 'contacts') ? null : this.strModuleId,
      JSON.stringify(objFilter)).subscribe(data => {
      this.arRecord = data['data'];
      this.initRoleForm();
    });
  }

  /**
   * cancel dialog
   */
  cancelDialog() {
    if (this.checkFormGroupDirty()) {
      this.notificationService.sendConfirmation('confirm_cancel')
        .filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
          this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Check if the form group is changed
   *
   * @returns {boolean}
   */
  checkFormGroupDirty(): boolean {
    let bDirty = false;
    this.arRoleForm.forEach(objForm => {
      if (objForm['form'].dirty === true) {
        bDirty = true;
      }
    });
    return bDirty;
  }

  /**
   * initialize role form
   * if there are not records to render, create a 3 form as default
   */
  initRoleForm() {
    this.recordService.getDropdownRecord('contacts').subscribe(response => {
      var strDropdownModule = (this.strModule == 'contacts') ? this.strRelateModule : this.strModule;
      if (response.body[RoleModuleOptionId[strDropdownModule]]) {
        this.objRoleOption = response.body[RoleModuleOptionId[strDropdownModule]]['config']
        if (this.strViewType == 'manage') {
          if (this.arRecord.length) {
            this.arRecord.forEach(objRecord => {
              if (objRecord['primary_contact_id']) {
                this.strPrimaryContactId = objRecord['primary_contact_id'];
                this.strPreviousPrimaryContactId = objRecord['primary_contact_id'];
              }
              this.createRoleForm(objRecord);
            });
            this.createRoleForm();
            this.bLoading = false;
            this.updateMatDialogClass();
          } else {
            for (let intDataCounter = 1; intDataCounter <= 3; intDataCounter++) {
              this.createRoleForm();
            }
            this.bLoading = false;
          }
        } else {
          if (this.arRecord[0]) {
            if (this.arRecord[0]['primary_contact_id']) {
              this.strPrimaryContactId = this.arRecord[0]['primary_contact_id'];
              this.strPreviousPrimaryContactId = this.arRecord[0]['primary_contact_id'];
            }
            this.createRoleForm(this.arRecord[0]);
          }
          this.bLoading = false;
        }
      }
    });
  }

  /**
   * create role form config
   *
   * @param record
   */
  createRoleForm(record: object = {}): void {
    let id = (record['id']) ? record['id'] : this.generateRandomId() + this.generateRandomId();
    let roleForm = {
      id: id,
      form: this.createFormGroup(record),
      primary_contact_role: (record['contact_id']) ? record['contact_id'] == this.strPrimaryContactId : false,
      metadata: cloneDeep(this.createFormMetadata(record)),
      editable: get(record, 'editable', true),
    };
    if (record['role']) {
      let primaryRole = record['role'].findIndex(role => role.primary == true);
      if (primaryRole > -1) {
        this.setPrimaryRole(record['role'][primaryRole]['id'], roleForm);
      }
    }
    this.arRoleForm.push(roleForm);
    this.objContactRelate[id] = {
      loading: false,
      item: cloneDeep(this.arRelateValues$),
      typeahead: cloneDeep(this.arRelateValuesInput$)
    };
    // this will apply the metadata option to typeahead
    this.initContactRelate(roleForm.id, roleForm.metadata['module'].options);
  }

  /**
   * create's role form group
   *
   * @param module_field_value
   * @param role_value
   */
  createFormGroup(record): FormGroup {
    var strRelateId = (this.strRelateModule == 'contacts') ? 'contact_id' : 'module_id';
    const shouldDisabledField = ! get(record, 'editable', true);

    var arRole = (record['role'])
      ? cloneDeep(record['role'].map(data => data['id'])).filter( element =>
          element != null || element != undefined
      ) : null;
    let objFormGroup = new FormGroup({
        module: new FormControl({
          value: (record[strRelateId]) ? record[strRelateId] : null,
          disabled: shouldDisabledField,
        }, [
          Validators.required,
        ]),
        role: new FormControl({
          value: arRole,
          disabled: shouldDisabledField,
        }, [
          Validators.required,
          Validators.maxLength(64),
        ]),
        notify_task_transit: new FormControl({
          value: (record['notify_task_transit']) ? record['notify_task_transit'] : false,
          disabled: shouldDisabledField,
        }),
        notify_before_schedule: new FormControl({
          value: (record['notify_before_schedule']) ? record['notify_before_schedule'] : false,
          disabled: shouldDisabledField,
        }),
        notify_job_complete: new FormControl({
          value: (record['notify_job_complete']) ? record['notify_job_complete'] : false,
          disabled: shouldDisabledField,
        }),
        display_all_sites: new FormControl((record['display_all_sites']) ? record['display_all_sites'] : false),
        set_contact_quotes: new FormControl({
          value: (record['set_contact_quotes']) ? record['set_contact_quotes'] : false,
          disabled: shouldDisabledField,
        }),
        set_contact_po: new FormControl({
          value: (record['set_contact_po']) ? record['set_contact_po'] : false,
          disabled: shouldDisabledField,
        }),
        set_contact_job_report: new FormControl({
          value: (record['set_contact_job_report']) ? record['set_contact_job_report'] : false,
          disabled: shouldDisabledField,
        }),
    });

    this.subscriptions.push(objFormGroup.valueChanges.subscribe(value => {
      let formIndex = this.arRoleForm.findIndex( data => data.form.controls['module'].value == value.module);
      if (this.arRecord.length === 0 && this.strPrimaryContactId === null && value.module && value.role) {
        this.onChangePrimaryRole(formIndex);
      } else {
        let options = this.arRoleForm[formIndex].metadata['module'].options;
        if (options) {
          let selectedOption = options.filter( option => option.id === value.module);
          // to display a single option in dropdown after the selecting a record
          this.initContactRelate(this.arRoleForm[formIndex].id, selectedOption);
        }
      }
    }));

    return objFormGroup;
  }

  /**
   * create's metadata for each form
   *
   * @param record
   */
  createFormMetadata(record: object = {}) {
    let strRelateId = (this.strRelateModule == 'contacts') ? 'contact_id' : 'module_id';
    let strTextField = (this.strRelateModule == 'contacts') ? 'contact_text' : 'module_text';
    let strRelateText = (this.strRelateModule == 'opportunities') ? this.formatOpportunityNumber(record[strTextField]) : record[strTextField];
    return {
      module: this.formService.tranformFieldObject({
        key: 'module',
        type: 'relate',
        module: this.strRelateModule,
        label: '',
        required: true,
        options: (record[strRelateId]) ? [ new Select(record[strRelateId], strRelateText) ] : [],
        default_value: record[strRelateId],
      }, {
        module_text: strRelateText
      }),
      role: this.formService.tranformFieldObject({
        key: 'role',
        type: 'multiselect',
        has_primary: true,
        label: '',
        required: true,
        options: this.objRoleOption,
        default_value: record['role'],

      })
    }
  }

  /**
   * updates the contact primary role
   *
   * @param index
   * @param updateTo
   */
  onChangePrimaryRole(index: number, updateTo = false): void {
    if (this.arRoleForm[index] && this.arRoleForm[index].form.valid) {
      if (this.strModule != 'contacts' && updateTo == false) {
        this.arRoleForm.map(objForm => objForm.primary_contact_role = false);
        this.arRoleForm[index].primary_contact_role = !updateTo;
        this.strPrimaryContactId = this.arRoleForm[index].form.controls['module'].value;
        this.arRoleForm[index].form.markAsDirty();
      } else if (this.strModule == 'contacts') {
        this.arRoleForm[index].primary_contact_role = !updateTo;
        this.strPrimaryContactId = this.arRoleForm[index].form.controls['module'].value;
        this.arRoleForm[index].form.markAsDirty();
      }
    } else {
      this.arRoleForm[index].primary_contact_role = null;
    }
  }

  /**
   * creates a new role form
   *
   * @return @void
   */
  addRole(): void {
    this.createRoleForm();
    this.bSubmit = false;
  }

  /**
   * save the current changes
   */
  onSubmit(): void {
    // FC-3892: fix issue in the Manage Contact Roles dialog where the invalid form control indicator doesn't appear if form is invalid
    this.arRoleForm.forEach(item => {
      // if both module and role values are empty, don't display the red indicator
      if (!item.form.valid && !(isNil(item.form.value.module) && isNil(item.form.value.role))) {
        item.form.controls.role.markAsTouched();
        item.form.controls.module.markAsTouched();
      }
    });

    this.bSubmitted = true;
    let objPayload = this.createPayload();

    if (!isEmpty(objPayload) && this.isValidForm()) {
      this.recordService.saveMultipleRecord('contact_roles', objPayload).pipe(
        map( objResponse => objResponse.body ),
        switchMap( () => {
          var arPrimaryRolePayload = this.getContactPrimaryRolePayload();
          // this will update the selected contact role as primary contact of the current record
          return iif(() =>this.strModule == 'contacts' && arPrimaryRolePayload.length != 0,
            this.recordService.saveMultipleRecord(this.strRelateModule, arPrimaryRolePayload).pipe( tap() ),
            of({})
          )
        }),
        switchMap( () => {
          let bNewPrimaryRole = this.strPrimaryContactId != this.strPreviousPrimaryContactId;
          // this will change the primary role of current record
          return iif( () => this.strModule != 'contacts' && bNewPrimaryRole,
            this.recordService.saveRecord(
              this.strModule,
              { primary_contact_id: this.strPrimaryContactId },
              this.strModuleId
            ).pipe( tap() ),
            of({})
          )
        }),
        switchMap( () => {
          this.onSubmitNotification(200);
          this.dialogRef.close('success');
          return of({});
        }),
        catchError( () => {
          this.bSubmit = true;
          this.bSubmitted = false;
          return of({});
        }),
      ).subscribe();
    } else {
      this.bSubmit = true;
      this.bSubmitted = false;
      this.onSubmitNotification();
    }
  }

  /**
    * send's notification after clicking save
    *
    * @param status
    */
  onSubmitNotification(status: number = null): void {
    if (status === 200) {
      var message = (this.strViewType == 'manage') ? 'header_notification.success_added' : 'header_notification.success_update';
      this.notificationService.notifySuccess(message);
    } else {
      this.notificationService.notifyError('required_notice');
    }
  }

  /**
   * check if every form is valid
   *
   * @return boolean
   */
  isValidForm(): boolean {
    return this.arRoleForm.filter(objForm => {
      if ((objForm.form.controls['module'].value || objForm.form.controls['role'].value) &&
        objForm.form.valid === false) {
        return objForm;
      }
    }).length == 0;
  }

  /**
   * create's a payload to save the data
   * removes all the form obj that has no related data and role
   */
  createPayload(): Array<object> {
    return cloneDeep(this.arRoleForm).map(objForm => {
      var objFormData = objForm.form.getRawValue();
      let arRoles = [];
      // FC-2922: compile all roles
      let arRoleOption = objForm.metadata['role'].options;
      if (objFormData.role && arRoleOption) {
        arRoles = objFormData.role.map(selectedRole => arRoleOption.find(data => data.id == selectedRole));
      }
      if (objFormData.module && arRoles) {
        return {
          id: this.isValidUUid(objForm.id) ? objForm.id : null,
          role: arRoles,
          module: (this.strModule == 'contacts') ? this.strRelateModule : this.strModule,
          module_id: (this.strModule == 'contacts') ? objFormData.module : this.strModuleId,
          contact_id: (this.strModule == 'contacts') ? this.strModuleId : objFormData.module,
          notify_task_transit: objFormData.notify_task_transit,
          notify_before_schedule: objFormData.notify_before_schedule,
          notify_job_complete: objFormData.notify_job_complete,
          display_all_sites: objFormData.display_all_sites,
          set_contact_quotes: objFormData.set_contact_quotes,
          set_contact_job_report: objFormData.set_contact_job_report,
          set_contact_po: objFormData.set_contact_po,
        }
      }
    }).filter(objForms => objForms !== undefined);
  }

  /**
   * return all the record that is marked as primary role
   */
  getContactPrimaryRolePayload(): Array<object> {
    var arPrimaryRole = this.arRoleForm.filter(objForm => objForm.form.dirty == true && objForm.primary_contact_role);
    if (arPrimaryRole.length) {
      return arPrimaryRole.map(objForm => ({
        id: objForm.form.controls['module'].value,
        primary_contact_id: this.strModuleId
      }))
    }
    return []
  }

  /**
   * add leading zeroes to opportunity number
   * should always be a 6 digit
   */
  formatOpportunityNumber(opportunity_number): string {
    if (opportunity_number) {
      let numCharCount = opportunity_number.toString().length;
      let numAdditionalZeroes = (6 - numCharCount);
      let strFinalName = '';
      for (let index = 0; index < numAdditionalZeroes; index++) {
        strFinalName = strFinalName + '0';
      }
      return strFinalName + opportunity_number;
    }
    return '';
  }

  /**
   * update the mat-dialog-content class
   */
  updateMatDialogClass(): void {
    setTimeout(() => {
      if (this.objElementRef.nativeElement.querySelector('.mat-dialog-content').offsetHeight > 750) {
        this.strMatDialogContentClass = 'overflow-overlay';
      }
    }, 2)
  }

  /**
   * ubsubscribe to all subscriptions made
   *
   * @return @void
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Initialize Contact Relate Data
   */
  initContactRelate(id, defaultOption = []): void {

    let arNewOptions = [];

    defaultOption.forEach(objContactOptions => {
      let arFind = arNewOptions.find(objFindOption => { return objContactOptions['id'] == objFindOption['id']})
      if (!arFind) {
        arNewOptions.push(objContactOptions);
      }
    });

    let selectedIds = this.getSelectedIds();
    let relatedModule = (this.strModule == 'contacts') ? this.strRelateModule : 'contacts';
    let index = this.arRoleForm.findIndex(data => data.id === id);
    this.arRoleForm[index].metadata['module'].options = arNewOptions;
    this.objContactRelate[id].item = concat(
      of(arNewOptions), // Set the initial values to the ng-select.
      this.objContactRelate[id].typeahead.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => this.objContactRelate[id].loading = true),
        switchMap(term => this.recordService.getRecordRelate(relatedModule, term, '', false, { where_not_in: { id: selectedIds } }).pipe(
          tap((data) => {
            this.arRoleForm[index].metadata['module'].options = this.convertDataToOption(data, this.arRoleForm[index].form.controls['module'].value);
            this.objContactRelate[id].loading = false;
          }),
          map(data => this.convertDataToOption(data, this.arRoleForm[index].form.controls['module'].value))
        ))
      )
    );
  }

  /**
   * get contact relate
   */
  onClickRelate(roleForm): void {
    let selectedIds = this.getSelectedIds();
    let relatedModule = (this.strModule == 'contacts') ? this.strRelateModule : 'contacts';
    let id = roleForm.form.controls['module'].value || null;
    if (isEmpty(id)) {
      this.recordService.getRecordRelate(relatedModule, '', '', false, { where_not_in: { id: selectedIds } }).subscribe(data => {
        let options = this.convertDataToOption(data, id);
        this.initContactRelate(roleForm.id, options);
      });
    }
  }

  /**
   * Convert the data to select option format
   *
   * @param arData Array<object>
   *
   * @returns Select[]
   */
  convertDataToOption(arData: Array <object>, id = null): Select[] {
    let arSelectedIds = this.getSelectedIds();
    let arNewOption: Select[] = [];
    arData.forEach( arOption => {
      if (!isEmpty(arOption['id']) && !isEmpty(arOption['text'])) {
        if (arSelectedIds.indexOf(arOption['id']) == -1 && arOption['id'] || arOption['id'] == id) {
          arNewOption.push(new Select(arOption['id'], arOption['text']));
        }
      }
    });
    // Add option that allow to create option if relate data is empty
    if (arData.length < 1 && this.strRelateModule == 'contacts') {
      arNewOption.push(new Select('create', 'create_new_contacts'));
    }
    return arNewOption;
  }

  /**
   * get all selected id
   */
  getSelectedIds(): Array <string> {
    return cloneDeep(this.arRoleForm).map(objForm => {
      return this.isValidUUid(objForm.form.controls['module'].value) ? objForm.form.controls['module'].value : null;
    }).filter(objForms => !isEmpty(objForms));
  }

  /**
   * generates random id
   */
  generateRandomId(): string {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }

  /**
   * mark the option as primary
   *
   * @param id
   * @param form
   */
  setPrimaryRole(id, form): void {
    if (form.metadata.role) {
      form.metadata.role.options.map( option => {
        option.primary = (option.id == id) ? true : false;
        return option;
      });
    }
  }

  /**
   * validate's uuid
   *
   * @param id
   */
  isValidUUid(id: string): boolean {
    let regExp = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/, 'i');
    return regExp.test(id);
  }

  /**
   * check the triggered event when changing the relate value
   *
   * @param event
   * @param roleForm
   */
  ngOnChangeRelate(event, roleForm): void {
    if (get(event, 'id') == 'create') {
      // remove the selected dropdown value
      roleForm.form.controls['module'].setValue(null)
      // open create dialog
      this.openCreateDialog(roleForm);
    }
  }

  /**
   * open dialog
   */
  openCreateDialog(roleForm): void {
    let dialogConfig = this.getCreateDialogConfig();
    let recordDialog = this.dialog.open(EditformComponent, dialogConfig);
    recordDialog.afterClosed().first().subscribe( response => {
      if (response.status == 'save' && isId(get(response, 'data.id')) ) {
        // set the created record id to form
        let record = response.data;
        roleForm.form.controls['module'].setValue(record.id);
        this.initContactRelate(roleForm.id, [new Select(record.id, record.text)])
      }
    });
  }

  /**
   * get create dialog config
   */
  getCreateDialogConfig(): {[k: string]: any} {
    let relatedModule = (this.strModule == 'contacts') ? this.strRelateModule : 'contacts';
    let dialogConfig : {[k: string]: any} = {
      data: {
        strModule: relatedModule,
        strMode: 'add',
        bIsRoleForm: true
      },
      disableClose: true
    };

    if(window.innerWidth <= 800 && window.innerHeight <= 1024) {
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
      dialogConfig.maxHeight = '100vh';
      dialogConfig.maxWidth = '100vw';
    } else {
      dialogConfig.width = '80%';
      dialogConfig.height = 'auto';
    }

    return dialogConfig;
  }
}
