<header>
  <div class="d-flex header-container header-label">
    <span class="header-title">
      <h4 class="page-title">
        <fa-icon [icon]="['fas', 'exchange-alt']" class="pr-2"></fa-icon>
        <span>{{ 'stock-transfers' | translate }}</span>
      </h4>
    </span>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <fieldmagic-primary-button
            (click)="cancelDialog()"
            icon="times"
            variant="error"
            label="close"
          ></fieldmagic-primary-button>
        </li>
      </ul>
    </div>
  </div>
  <hr />
</header>

<mat-dialog-content class="overflow-visible mat-dialog-content">
  <div *ngIf="!loading" class="col-md-12">
    <div class="row record-container mb-16 pr-0 pl-0">
      <div class="col-md-6">
        <label class="labels w-100">{{ 'status' | translate }}</label>
        <span class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts long-text">{{ objStockTransferData.status | translate }}</span>
      </div>
    </div>
    <div class="row record-container mb-16 pr-0 pl-0">
      <div class="col-md-6">
        <label class="labels w-100">{{ 'warehouse_source' | translate }}</label>
        <span class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts long-text">{{ objStockTransferData.warehouse_source_text | translate }}</span>
      </div>
      <div class="col-md-6">
        <label class="labels w-100">{{ 'warehouse_destination' | translate }}</label>
        <span class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts long-text">{{ objStockTransferData.warehouse_destination_text | translate }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="!loading" class="col-md-12 mt-2">
    <div class="float-right">
      <nav class="pagination-section">
        <ul class="pagination">
          <li [ngClass]="previousButton" class="page-item">
            <a class="page-link pointer" aria-label="first" (click)="getStockTransferQuantities('prev', filter)">
              <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
            </a>
          </li>
          <li [ngClass]="nextButton" class="page-item">
            <a class="page-link pointer" aria-label="first" (click)="getStockTransferQuantities('next', filter)">
              <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <table class="table table-striped table-sm w100">
      <thead>
        <tr>
          <th class="w40p">{{ 'product' | translate }}</th>
          <th class="w20p">{{ 'quantity' | translate }}</th>
        </tr>
      </thead>
    </table>
    <div *ngIf="!stockTransferQuantityLoading; else isLoading">
      <table class="table table-striped table-sm w100">
        <tbody>
          <tr *ngFor="let data of stockTransferQuantityData; let i = index;">
            <td class="w40p">{{ data.item_text }} ({{ data.item_code }})</td>
            <td class="w20p">{{ data.quantity }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <ng-template #isLoading>
      <div class="col-sm-12 text-center">
        <inline-view-loader [isLoading]="true" [hasText]="false"></inline-view-loader>
      </div>
    </ng-template>
  </div>
</mat-dialog-content>