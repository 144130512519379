import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationService } from '../../../../../../services/notification.service';
import { EmailAttachment, EmailService } from '../../../../../../services/email.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'save-email-template',
  templateUrl: './save-template.component.html',
  styleUrls: [
    './save-template.component.scss',
  ]
})
export class SaveEmailTemplateDialogComponent {
  /**
   * @type {FormGroup}
   */
  readonly form = new FormGroup({
    name: new FormControl(null, [
      Validators.required,
      Validators.maxLength(128),
    ])
  });

  /**
   * @type {boolean}
   */
  inProgress: boolean = false;

  /**
   * Create instance of the component
   *
   * @param {MatDialogRef<SaveEmailTemplateDialogComponent>} dialog
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) protected readonly props: SaveEmailTemplateDialogComponentProps,
    protected readonly dialog: MatDialogRef<SaveEmailTemplateDialogComponent>,
    protected readonly notifications: NotificationService,
    protected readonly emails: EmailService
  ) {}

  /**
   * Handles user on cancel action
   *
   * @returns {void}
   */
  onCancel(): void {
    this.dialog.close(SaveEmailTemplateDialogComponentState.CANCELLED);
  }

  /**
   * Handle user on save action
   *
   * @returns {void}
   */
  onSave(): void {
    if (this.form.invalid) {
      this.notifications.notifyError('please_complete_the_form');
    } else {
      this.inProgress = true;

      this.emails.createEmailTemplate$({
        name: this.form.value.name,
        subject: this.props.subject,
        body: this.props.body,
        attachments: this.props.attachments,
        share_template: this.props.share_template
      }).pipe(
        finalize(() => {
          this.inProgress = false;
        })
      ).subscribe(() => {
        this.notifications.notifySuccess('email_template_saved');
        this.dialog.close(SaveEmailTemplateDialogComponentState.SUCCESSFUL);
      });
    }
  }
}

export interface SaveEmailTemplateDialogComponentProps {
  subject?: string;
  body?: string;
  attachments?: EmailAttachment[];
  share_template?: boolean;
}

export enum SaveEmailTemplateDialogComponentState {
  CANCELLED = 'CANCELLED',
  SUCCESSFUL = 'SUCCESSFUL',
}

export type SaveEmailTemplateDialogComponentStates = keyof typeof SaveEmailTemplateDialogComponentState;