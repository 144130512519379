<div>

  <div class="input-group">
    <input
      type="text"
      class="form-control date-input"
      (dateTimeChange)="updateDateTime($event)"
      [(ngModel)]="model"
      [id]="objItem.key"
      [selectMode]="objDate.select_mode"
      [owlDateTime]="owlDateComponent"
      [ngModelOptions]="{standalone: true}"
      [required]="required || false"
      [readonly]="readonly || false"
      [min]="dateRangeMin"
      [max]="dateRangeMax"
      [placeholder]="placeholder"
      [ngClass]="{'is-invalid': !valid  && touched && !readonly}">

    <span *ngIf="!required && readonly == false">
      <fa-icon *ngIf="control.value"
        [icon]="['fas', 'times']"
        class="date-clear"
        (click)="clearField()">
      </fa-icon>
    </span>

    <button
      class="calendar-button"
      (click)="readonly == false && calendarToggle()"
      [owlDateTimeTrigger]="(!readonly) ? owlDateComponent : ''"
      [disabled]="readonly">
      <fa-icon
        [icon]="['fal', 'calendar']"
        class="text-white">
      </fa-icon>
    </button>
  </div>

  <div [ngClass]="{ 'calendar-hidden' : !bIsOwlComponentShown,'calendar-shown': bIsOwlComponentShown }">
    <owl-date-time #owlDateComponent [pickerType]="objDate.picker_type"></owl-date-time>
  </div>

</div>