<header>
  <div class="d-flex">
    <h3 class="title">
        <fa-icon [icon]="['fas', 'users']" ></fa-icon>&nbsp;
        <span *ngIf="strViewType == 'edit'" >{{ 'edit_event'| translate }} </span>
        <span *ngIf="strViewType == 'add'" >{{ 'create_event'| translate }} </span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <fieldmagic-primary-button
            (click)="onSubmit()"
            icon="save"
            label="save"
            [isInProgress]="bShowLoader"
          >
          </fieldmagic-primary-button>
        </li>
        <li class="list-inline-item">
          <fieldmagic-primary-button
            variant="error"
            (click)="cancelDialog()"
            icon="times"
            label="close"
          >
          </fieldmagic-primary-button>
        </li>
      </ul>
    </div>
  </div>
</header>
<hr class="separator"/>
<div class="row">
  <div *ngIf="objEventName && objEventField && eventForm && bPageLoaded" class="col-sm-12">
    <form [formGroup]="eventForm">
      <div *ngFor="let strName of objEventName" class="form-group">
          <app-edit-field [item]="objEventField[strName]" [ngClass]="{'is-invalid': !eventForm.valid && bSubmitted}" [form]="eventForm" strMode="add" [bSubmitted]="bSubmitted" [strCustomClass]="objEventField[strName]['custom_class']"></app-edit-field>
      </div>
    </form>
  </div>
  <ng-container *ngIf="!bPageLoaded">
    <div class="col-sm-12" align="center">
      <h4><fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon></h4>
    </div>
  </ng-container>
</div>