
<header>
  <div class="d-flex">
    <fieldmagic-dialog-title
      icon="plus-circle"
      class="text-primary"
      label="{{ strViewType == 'edit' ? 'edit' : 'add' }}"
    ></fieldmagic-dialog-title>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button (click)="preSaveRecurringJob()" class="btn ml-1 btn-primary" [disabled]="bSubmitted || (disabledSaveButton$ | async)">
            <fa-icon *ngIf="!bSubmitted" [icon]="['fas', 'save']"></fa-icon>
            <fa-icon *ngIf="bSubmitted" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
            <span class="pl-1">{{ 'save' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <fieldmagic-primary-button
            (click)="cancelDialog()"
            icon="times"
            variant="error"
            label="close"
            [disabled]="bSubmitted"
          ></fieldmagic-primary-button>
        </li>
      </ul>
    </div>
  </div>
</header>

<hr class="separator"/>

<mat-dialog-content *ngIf="recurringJobForm" class="mobile-view-dialog">
<app-error-handler onlyFor="edit_form" [isAction]="true" alignment="center" (onError)="onFormError()">
<div *ngIf="recurringJobForm.length != 0" class="mt-3">
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li *ngFor="let item of recurringJobForm" class="nav-item text-size">
      <a class="nav-link" [ngClass]="{'active': recurringJobForm[0]['label'] === item.label}" id="{{item.label}}-tab" data-toggle="tab" href="#{{item.id}}" role="tab" aria-selected="true" translate>{{ item.label }}</a>
    </li>
  </ul>
  <div class="tab-content">
    <div *ngFor="let item of recurringJobForm" [ngClass]="{'active': recurringJobForm[0]['label'] === item.label, 'show': recurringJobForm[0]['label'] === item.label}" class="tab-pane row p-4" [id]="item.id" role="tabpanel">
      <form [formGroup]="item.groups">
        <div class="row">
          <ng-container *ngFor="let data of item.fields">
            <div [ngClass]="
            {'col-2': arCustomColumn.indexOf(data.label) > -1,
            'col-6': arCustomColumn.indexOf(data.label) < 0 && data.space === 6,
            'col-12': arCustomColumn.indexOf(data.label) < 0 && data.space === 12
            }"
            class="mb-3">
              <ng-container>
                <app-edit-field [item]="data" [form]="item.groups" [bSubmitted]="bSubmitted" [strMode]="strViewType" strModule="recurring_jobs" [strRecordId]=""></app-edit-field>
                <ng-template *ngIf="data.key === 'line_items' then lineItems"></ng-template>
                <ng-template *ngIf="data.key === 'work_order_items' then workOrderItems"></ng-template>
                <ng-template *ngIf="data.key === 'material_line_items' then materialLineItems"></ng-template>

              </ng-container>
            </div>
            <ng-template #lineItems>
              <ng-container>
                <div class="row content_padding">
                  <table class="table table-bordered table-striped ">
                    <thead>
                      <tr>
                        <th scope="col" width="15%" class="font-12">{{ 'asset_type' | translate }}</th>
                        <th scope="col" width="14%" class="font-12">{{ 'asset_group' | translate }}</th>
                        <th scope="col" width="20%" class="font-12">{{ 'checklist' | translate }}</th>
                        <th *ngIf="bDepartmentTracking" scope="col" width="15%" class="font-12">{{ 'department' | translate }}</th>
                        <th scope="col" width="15%" class="font-12">{{ 'technician' | translate }}</th>
                        <th scope="col" width="20%" class="font-12">{{ 'task_description' | translate }}</th>
                        <th scope="col" width="1%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="arLineAttributes.length < 1">
                        <td colspan="7" class="text-center">
                          <span>{{ 'no_available_items' | translate }}</span>
                        </td>
                      </tr>
                      <ng-container *ngFor="let attr of arLineAttributes; index as i">
                        <tr>
                            <td scope="row">
                              <div class="p-2">
                                <ng-select
                                  [items]="attr['asset_type_obv'] | async"
                                  [typeahead]="attr['asset_type_typehead']"
                                  [loading]="attr['asset_type_loader']"
                                  [clearable]="true"
                                  [ngModelOptions]="{standalone: true}"
                                  bindLabel="text"
                                  bindValue="id"
                                  [(ngModel)]="attr['data']['asset_type_id']"
                                  [id]="attr['id'] + 'asset_type'"
                                  [name]="attr['id'] + 'asset_type'"
                                  (change)="assetTypeChange($event, i)"
                                  (open)="getRelatedData('asset_types', 'asset_type', i)">
                                </ng-select>
                              </div>
                            </td>
                            <td>
                              <div class="p-2">
                                <ng-select
                                  [items]="attr['asset_group_obv'] | async"
                                  [typeahead]="attr['asset_group_typehead']"
                                  [loading]="attr['asset_group_loader']"
                                  [clearable]="true"
                                  [disabled]="!attr['data']['asset_type_id']"
                                  bindLabel="text"
                                  bindValue="id"
                                  [ngModelOptions]="{standalone: true}"
                                  [(ngModel)]="attr['data']['asset_group_id']"
                                  (open)="getRelatedData('asset_groups', 'asset_group', i)">
                                </ng-select>
                              </div>
                            </td>
                            <td scope="row">
                              <div>
                                <ng-select
                                  [items]="attr['checklist_obv'] | async"
                                  [typeahead]="attr['checklist_typehead']"
                                  [loading]="attr['checklist_loader']"
                                  [clearable]="false"
                                  [closeOnSelect]="false"
                                  bindLabel="text"
                                  [bindValue]
                                  [ngModelOptions]="{standalone: true}"
                                  groupBy="asset_type_text"
                                  [(ngModel)]="attr['checklist']"
                                  [id]="attr['id'] + 'checklist'"
                                  [name]="attr['id'] + 'checklist'"
                                  [maxSelectedItems]="10"
                                  [multiple]="true"
                                  [ngClass]="{'is-invalid': attr['has_checklist_error']}"
                                  (change)="checklistChange($event, i)"
                                  (open)="getRelatedData('checklists', 'checklist', i, attr['checklist_filter'])">

                                  <ng-template ng-optgroup-tmp let-item="item">
                                    {{ (item.asset_type_text | translate) || ('job_checklist' | translate) }}
                                  </ng-template>
                                </ng-select>
                              </div>
                            </td>
                            <td *ngIf="bDepartmentTracking">
                              <div class="p-2">
                                <ng-select
                                  [items]="attr['department_obv'] | async"
                                  [typeahead]="attr['department_typehead']"
                                  [loading]="attr['department_loader']"
                                  [clearable]="true"
                                  [ngModelOptions]="{standalone: true}"
                                  bindLabel="text"
                                  bindValue="id"
                                  [(ngModel)]="attr['data']['department_id']"
                                  [id]="attr['id'] + 'department'"
                                  [name]="attr['id'] + 'department'"
                                  (open)="getRelatedData('departments', 'department', i)">
                                </ng-select>
                              </div>
                            </td>
                            <td>
                              <div class="p-2">
                                <ng-select
                                  [items]="attr['user_obv'] | async"
                                  [typeahead]="attr['user_typehead']"
                                  [loading]="attr['user_loader']"
                                  [clearable]="true"
                                  [ngModelOptions]="{standalone: true}"
                                  bindLabel="text"
                                  bindValue="id"
                                  [(ngModel)]="attr['data']['user_id']"
                                  [id]="attr['id'] + 'user'"
                                  [name]="attr['id'] + 'user'"
                                  (open)="getRelatedData('users', 'user', i)">
                                </ng-select>
                              </div>
                            </td>
                            <td>
                              <div class="p-2">
                                <textarea
                                class="form-control font-12 task-desc-{{i}}"
                                [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="attr['data']['task_description']"
                                (focus)="increaseRow(i)"
                                (focusout)="decreaseRow(i)"
                                #taskDesc>
                                </textarea>
                              </div>
                            </td>
                            <td>
                              <div class="p-2 text-center">
                                <fa-icon [ngClass]="{'text-secondary': arLineAttributes.length < 2, 'text-danger': arLineAttributes.length > 1}" class="cursor-pointer font-12" (click)="(arLineAttributes.length > 1) ? removeAttribute(i) : ''" [icon]="['fal', 'minus-circle']"></fa-icon>
                                <fa-icon class="text-success cursor-pointer font-12" (click)="addLineAttribute()" [icon]="['fas', 'plus-circle']"></fa-icon>
                              </div>
                            </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </ng-container>
            </ng-template>

            <ng-template #workOrderItems>
              <ng-container>
                <app-jobs-work-order-items
                  class="row"
                  [asChild]="true"
                  [bDepartmentTracking]="bDepartmentTracking"
                  [initialValue]="initialWorkOrder$ | async"
                  (updated)="onWorkOrderUpdate($event)"
                  [parentWasValidated]="wasValidated$ | async"
                  [defaultTaxCode]="defaultTaxCode$ | async"
                  [defaultPricebook]="defaultPricebook$ | async"
                >
                </app-jobs-work-order-items>
              </ng-container>
            </ng-template>
            <ng-template #materialLineItems>
              <material-lines
                [initialValue]="initialMaterials$ | async"
                (updated)="onMaterialsUpdate($event)"
              >
              </material-lines>
            </ng-template>
          </ng-container>
        </div>
      </form>
    </div>
  </div>
</div>
</app-error-handler>
</mat-dialog-content>

