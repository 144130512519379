import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditModule } from './edit.module';
import { SharedModule } from './shared.module';
import { NgDragDropModule } from 'ng-drag-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';
import { SharedInputModule } from './../../shared/modules/shared-input.module';
import { ProductFoldersModule } from '../../features/product-folders/product-folders.module';
import { LineItemsComponent } from '../../module/credit-notes/view/line-items/line-items.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    SharedModule.forRoot(),
    SharedInputModule,
    ProductFoldersModule,
    FontAwesomeModule,
  ],
  exports: [
    LineItemsComponent
  ],
  declarations: [
    LineItemsComponent
  ],
  entryComponents: [
    LineItemsComponent
  ]
})
export class CreditNoteItemsModule { }
