<header >
  <div class="d-flex dialog-header">
      <h3 class="dialog-title" translate>
          <fa-icon [icon]="['far', 'tasks']" transform="shrink-3" class="dialog-title"></fa-icon>
          <span class="pl-1">{{ 'available_checklists' | translate }}</span>
      </h3>
      <div class="ml-auto d-flex">
          <ul class="list-inline pull-right">
            <li class="list-inline-item">
              <fieldmagic-primary-button
                id="attach-selected-checklists"
                (click)="attachSelectedChecklists()"
                icon="paperclip"
                label="attach_selected"
              ></fieldmagic-primary-button>
            </li>
            <li class="list-inline-item">
              <fieldmagic-primary-button
                id="exit-dialog"
                variant="error"
                (click)="exitDialog()"
                icon="times"
                label="close"
              ></fieldmagic-primary-button>
            </li>
          </ul>
      </div>
  </div>
  <hr />
</header>
<mat-dialog-content class="content">
    <div>
      <div class="row">
        <div class="col-11 checklist-title"> {{ 'name' | translate}} </div>
        <div class="col-1">
          <span>
            <input type="checkbox" [checked]="true" (click)="selectAllAttachments($event)">
          </span>
        </div>
      </div>

      <div class="row" *ngFor="let assetTypechecklist of assetTypeChecklistResponses; index as i">
        <div class="col-11 default-value"> {{ assetTypechecklist['checklist_name'] }} - #{{ assetTypechecklist['serial_number'] }} </div>
        <div class="col-1">
          <input type="checkbox"
            [checked]="assetTypechecklist['selected']"
            (click)="selectChecklist($event, 'asset_type_checklist', i)">
        </div>
      </div>

      <div class="row" *ngFor="let checklist of jobChecklistResponses; index as i">
        <div class="col-11 default-value"> {{ checklist['name'] }} </div>
        <div class="col-1">
          <input
            type="checkbox"
            [checked]="checklist['selected']"
            (click)="selectChecklist($event, 'job_checklist', i)"/>
        </div>
      </div>
    </div>
</mat-dialog-content>