import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ShContextMenuComponent } from 'ng2-right-click-menu';
import { LooseObject } from '../../../objects/loose-object';

@Component({
  selector: 'fc-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {
  @ViewChild('menu') menu: ShContextMenuComponent;
  @Output() isClicked = new EventEmitter<any>();

  public basicMenu: Array<LooseObject> = [
    { label: 'copy' },
    { label:'paste' },
  ];

  constructor() { }

  ngOnInit() {}

  menuClick(event, menuAction: string) {
    event.action = menuAction;
    this.isClicked.next(event);
  }
}
