<div class="row">
  <div class="col-sm-12 pt-1">
    <span class="title" translate>
      <fa-icon [icon]="['fas', 'signature']" ></fa-icon>
      &nbsp; {{ 'manage_signatures'| translate }}
    </span>
    <span class="buttons">
      <button *ngIf="bIsEdit && strDefaultSignature != strSelectedId" type="button" class="btn btn-warning ml-1" (click)="setAsDefault(true)">
        <fa-icon [icon]="['fas', 'crown']"></fa-icon>
        <span class="pl-1">{{ 'set_as_default' | translate }}</span>
      </button>
      <button *ngIf="bIsEdit && strDefaultSignature == strSelectedId" type="button" class="btn btn-warning ml-1" (click)="setAsDefault(false)">
        <fa-icon [icon]="['fas', 'crown']"></fa-icon>
        <span class="pl-1">{{ 'unset_as_default' | translate }}</span>
      </button>
      <button type="button" class="btn btn-primary ml-1" (click)="save()">
        <fa-icon [icon]="['fas', 'save']"></fa-icon>
        <span class="pl-1">{{ 'save' | translate }}</span>
      </button>
      <button *ngIf="bIsEdit && strDefaultSignature != strSelectedId" type="button" class="btn btn-danger ml-1" (click)="trash()">
        <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
        <span class="pl-1">{{ 'delete' | translate }}</span>
      </button>
      <button *ngIf="bIsEdit" type="button" class="btn btn-info ml-1" (click)="use()">
        <fa-icon [icon]="['fas', 'signature']"></fa-icon>
        <span class="pl-1">{{ 'add_signature' | translate }}</span>
      </button>
      <button *ngIf="bIsEdit" type="button" class="btn btn-success ml-1" (click)="save(true)">
        <fa-icon [icon]="['fas', 'save']"></fa-icon>
        <span class="pl-1">{{ 'save_as_new' | translate }}</span>
      </button>
      <button type="button" class="btn btn-danger ml-1" (click)="cancelDialog()">
        <fa-icon [icon]="['fas', 'times']"></fa-icon>
        <span class="pl-1">{{ 'close' | translate }}</span>
      </button>
    </span>
  </div>
</div>

<hr>

<div class="row">

  <div class="col-9" [formGroup]="objFormSignature">
    <div class="row">
      <div class="col-12 mb-2">
        <input
          type="text"
          class="form-control"
          [ngClass]="{'is-invalid': !objFormSignature.controls.name.valid && objFormSignature.controls.name.dirty}"
          placeholder="{{ 'name' | translate }}"
          formControlName="name">
      </div>
      <div class="col-12">
        <quill-editor
          formControlName="signature"
          placeholder="Enter Text"
          [modules]="quillConfig"
          [style]="{'height':'220px'}"
          [formats]="arFormats">
        </quill-editor>
      </div>
    </div>
  </div>

  <div class="col-3 border-left">
    <div class="list-group">

      <a *ngFor="let objSignature of arSignatures"
        class="list-group-item list-group-item-action"
        [ngClass]="{'active': this.strSelectedId == objSignature.id }"
        (click)="selectSignature(objSignature)">
        <fa-icon *ngIf="strDefaultSignature == objSignature.id" [icon]="['fas', 'crown']" class="text-warning"></fa-icon>
        &nbsp; {{ objSignature.name }}
      </a>
    </div>
  </div>

</div>