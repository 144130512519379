<!-- FORM ELEMENTS -->
<div [formGroup]="form" [ngClass]="{'d-none': item.is_hidden != undefined && item.is_hidden}">
  <div>
      <label *ngIf="hasLabel" class="labels" [attr.for]="item.key" [ngClass]="{'hidden': item.is_hidden != undefined && item.is_hidden}" translate>
        {{item.label}}
        <required-tag *ngIf="hasRequiredValidator" [hasSpace]="false"></required-tag>
        <fa-icon *ngIf="item.note != undefined && item.note != ''" [icon]="['fas', 'info-circle']" class="change-pointer" matTooltip="{{ item.note | translate }}"></fa-icon>

        <fa-icon
          *ngIf="(strItemKey === 'create_job_email_address' || strItemKey === 'create_supplier_invoice_email_address') && strModule === 'customers' && !isActiveClientOnEnterprisePlan"
          [icon]="['fas', 'info-circle']"
          class="ml-1 pointer text-primary"
          matTooltip="{{ 'click_for_more_info' | translate }}"
          (click)="displayUpgradeInfoBoxForNonEnterpriseClients()">
        </fa-icon>

        <ng-container *fcMinSubscriptionPlanRequired="'fieldmagic-enterprise'">
          <fa-icon
            *ngIf="(strItemKey === 'create_job_email_address' || strItemKey === 'create_supplier_invoice_email_address') && strModule === 'customers' && strMode === 'edit'"
            [icon]="['fas', 'info-circle']"
            class="ml-1 pointer text-primary"
            matTooltip="{{ (strItemKey === 'create_job_email_address' ? 'create_job_by_email_info' : 'create_supplier_invoice_by_email_info' ) | translate }}">
          </fa-icon>
        </ng-container>

        <ng-container *fcMinSubscriptionPlanRequired="'fieldmagic-enterprise'">
          <fa-icon
            *ngIf="(strItemKey === 'create_job_email_address' || strItemKey === 'create_supplier_invoice_email_address') && strMode === 'edit'"
            ngxClipboard
            [cbContent]="item.default_value"
            (cbOnSuccess)="notifyCopy()"
            [icon]="['fal', 'copy']"
            class="ml-2 pointer text-primary"
            matTooltip="{{ 'copy_to_clipboard' | translate }}">
          </fa-icon>
        </ng-container>
      </label>
  </div>

  <div [ngSwitch]="item.controlType">
    <div *ngSwitchCase="'notset'">
        <input
          [formControlName]="item.key"
          [id]="item.key"
          type="text"
          class="font-size-11 form-control input-height-40">
    </div>

    <div *ngSwitchCase="'blank'"></div>

    <div *ngSwitchCase="'dialog'">
      <!-- if field is phone -->
      <div *ngIf="item.label == 'phone'">
        <app-phone [form]="form" [value]="item.default_value" [key]="item.key" [label]="item.label"></app-phone>
      </div>
    </div>

    <ng-container *ngSwitchCase="'text'">
      <input
        [formControlName]="item.key"
        [id]="item.key"
        [required]="item.required"
        type="text"
        [readonly]="item.readonly"
        [ngClass]="{'is-invalid': !isValid  && isTouched && !item.readonly, 'hidden': form.controls[item.key].disabled}"
        [maxLength]="(item.max_length) ? item.max_length : '16'"
        (change)="emitValue()"
        class="form-control font-size-11 input-height-40 {{ strCustomClass }}"
        (keypress)="validateInputText($event)">
      <span *ngIf="form.controls[item.key].disabled">--</span>
    </ng-container>

    <ng-container *ngSwitchCase="'email_address'">
      <fc-email-address-input
        [id]="item.key"
        [formControlName]="item.key"
        [customClass]="'form-control'"
        [isInvalid]="!isValid  && isTouched && !item.readonly"
        (change)="emitValue()"
        [isReadonly]="isReadOnlyEmailAddress">
      </fc-email-address-input>
    </ng-container>

    <ng-container *ngSwitchCase="'geocoding'">
      <fc-geolocation-input
      (childToParentEvent)="geolocationComponentEmitter($event)"
      [geolocationData]="geolocationData"
      [withShowMap]="true"
      [formControlName]="item.key">
      </fc-geolocation-input>
    </ng-container>

    <ng-container *ngSwitchCase="'url'">
      <input
        [formControlName]="item.key"
        [id]="item.key"
        [required]="item.required"
        type="text"
        [readonly]="item.readonly"
        [ngClass]="{'is-invalid': !isValid  && isTouched && !item.readonly, 'hidden': form.controls[item.key].disabled}"
        [maxLength]="(item.max_length) ? item.max_length : '256'"
        (change)="emitValue()"
        class="form-control font-size-11 input-height-40 {{ strCustomClass }}">
      <span *ngIf="form.controls[item.key].disabled">--</span>
    </ng-container>

    <input *ngSwitchCase="'picker'"
      class="form-control font-size-11"
      id="strColor"
      [value]="strColor"
      [(colorPicker)]="strColor"
      cpPosition = "bottom"
      [style.background]="strColor"
      (colorPickerChange)="colorPicker()" />

    <input *ngSwitchCase="'email'"
      [formControlName]="item.key"
      [id]="item.key"
      [required]="item.required"
      [email]="true"
      [readonly]="item.readonly"
      [ngClass]="{'is-invalid': !isValid && isTouched && !item.readonly}"
      class="form-control font-size-11">

    <input *ngSwitchCase="'decimal'"
      [formControlName]="item.key"
      [id]="item.key"
      [required]="item.required"
      type="number"
      min="0"
      step ="0.01"
      (change)="formatDecimal($event)"
      (keyup)="fieldChanged($event, item.key)"
      [readonly]="item.readonly"
      [ngClass]="{'is-invalid': !isValid && isTouched && !item.readonly, 'hidden': item.is_hidden != undefined && item.is_hidden}"
      class="form-control font-size-11 input-height-40">

    <fc-currency-input *ngSwitchCase="'currency'"
      (change)="formatCurrency($event)"
      [places]="item.decimal_places"
      [formControlName]="item.key"
      [id]="item.key"
      [required]="item.required"
      min="0"
      step ="0.01"
      (change)="formatCurrency($event)"
      (keyup)="fieldChanged($event, item.key)"
      [isReadonly]="item.readonly"
      [ngClass]="{'is-invalid': !isValid && isTouched && !item.readonly, 'hidden': item.is_hidden != undefined && item.is_hidden}"
      customClass="form-control font-size-11 input-height-40"
      (keypress)="validateCurrency($event)"
    >
    </fc-currency-input>

      <input *ngSwitchCase="'number'"
        [formControlName]="item.key"
        [id]="item.key"
        [required]="item.required"
        type="number"
        [readonly]="item.readonly"
        min="{{ item.min_length }}"
        [ngClass]="{'is-invalid': !isValid  && isTouched && !item.readonly}"
        (change) = "formatNumber($event)"
        class="form-control font-size-11 input-height-40">

      <mat-slide-toggle color="primary" *ngSwitchCase="'checkbox'"
        [id]="checkboxId"
        [required]="item.required"
        [formControlName]="item.key"
        [disabled]="item.readonly"
        class="font-size-11"
        (change) = "onChangeCheckbox($event)"
        [ngClass]="{'is-invalid': !isValid  && isTouched && !item.readonly}"
        name="item.key" >
      </mat-slide-toggle>

    <div *ngSwitchCase="'divider'">
      <hr>
      <h5>{{item.value}}</h5>
    </div>

    <div *ngSwitchCase="'image'">
      <div class="file-drop-container">
        <input class="file-input" type="file" accept="image/*" (change)="onFileChange($event)" />

        <div *ngIf="!isFileDropped && arFiles.name == undefined" class="file-input-overlay">
        {{ 'insert_image' | translate }}
        </div>

        <div *ngIf="isFileDropped" class="file-input-overlay-uploading">
        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon> {{ 'uploading' | translate }}
        </div>

        <div *ngIf="!isFileDropped && arFiles.name !== undefined" class="file-input-overlay-uploaded">
        {{ arFiles.name }}
        </div>
      </div>

      <ng-container *ngIf="strImageUrl">
          <img width="250" height="250" class="img-fluid rounded mx-auto d-block" src="{{ strImageUrl }}"/>
      </ng-container>
      <ng-container *ngIf="!strImageUrl && strImageUrl != null">
        <img width="250" height="250" class="img-fluid rounded mx-auto d-block" src="../assets/img/noimage.png"/>
      </ng-container>
    </div>

    <div *ngSwitchCase="'relate'">
      <ng-select *ngIf="!item.multiple && !item.add_tag"
        [items]="arRelateValues$ | async"
        [id]="item.key"
        [loading]="bRelateLoading"
        [clearable]="true"
        [typeahead]="arRelateValuesInput$"
        [required]="item.required"
        [formControlName]="item.key"
        placeholder="{{ item.placeholder | translate }}"
        [ngClass]="{'is-invalid': !isValid  && isTouched && !item.readonly}"
        class="font-size-11"
        name="item.key"
        [bindLabel]="item.text || 'text'"
        bindValue="id"
        appendTo="body"
        (click)="(item.readonly) ? '' : initRelate(item.module,item.key,item.multiple)"
        (change)="(item.readonly) ? '' : onChangeRelate(item.module, $event, false)">

        <ng-template ng-label-tmp let-item="item">
          {{ item.text | translate }}
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          {{ item.text | translate }}
        </ng-template>

      </ng-select>
      <ng-select *ngIf="!item.multiple && item.add_tag"
        [items]="arRelateValues$ | async"
        [id]="item.key"
        [loading]="bRelateLoading"
        [clearable]="true"
        [typeahead]="arRelateValuesInput$"
        [required]="item.required"
        [ngModelOptions]="{standalone: true}"
        [(ngModel)]="relateAddTag"
        [ngClass]="{'is-invalid': !isValid  && isTouched && !item.readonly}"
        class="font-size-11"
        bindLabel="text"
        [bindValue]
        [addTag]="(item.add_tag) ? true : false"
        (click)="(item.readonly) ? '' : initRelate(item.module,item.key,item.multiple)"
        (change)="(item.readonly) ? '' : onChangeRelate(item.module, $event, true)">
      </ng-select>

      <ng-select *ngIf="item.multiple"
        [items]="arRelateValues$ | async"
        [id]="item"
        [loading]="bRelateLoading"
        [typeahead]="arRelateValuesInput$"
        [clearable]="item.clearable"
        [formControlName]="item.key"
        [multiple]="item.multiple"
        [hideSelected]="item.hideSelected"
        [closeOnSelect]="item.closeOnSelect"
        [maxSelectedItems]="item.maxSelectedItems"
        [required]="item.required"
        [addTag]="(item.add_tag) ? addTag : false"
        (add)="(item.add_tag) ? addTagToOptions($event): false"
        [selectOnTab]="true"
        (change)="onMultiSelectChange($event)"
        (click)="initRelate(item.module,item.key,item.multiple)"
        [ngClass]="{'is-invalid': !isValid  && isTouched && !item.readonly}"
        class="font-size-11"
        name="item.key"
        bindLabel="text"
        appendTo="body">
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          <span class="ng-value-label">
            <span [ngClass]="{'pointer': item.primary != '1'}" (click)="item.primary != '1' && setPrimaryDepartment(item.id)">{{ item.text }}</span>
            <span *ngIf="item.primary == '1'" class="badge badge-success ml-2 pl-2 pr-2">{{ 'primary_department' | translate }}</span>
          </span>
          <span class="ng-value-icon right selected-close" (click)="clear(item)" aria-hidden="true">×</span>
        </ng-template>

        <ng-template ng-option-tmp let-item="item" class="font-size-11">
          {{ item.text }}<span *ngIf="item.primary == '1'" class="badge badge-success ml-2 pl-2 pr-2">{{ 'primary_department' | translate }}</span>
        </ng-template>
    </ng-select>

    </div>

    <div *ngSwitchCase="'dropdown'">
      <ng-select *ngIf="item.tag"
        [items]="item.options"
        [clearable]="item.clearable"
        [id]="item.key"
        [addTag]="addTag"
        (add)="addTagToOptions($event)"
        [selectOnTab]="true"
        [required]="item.required"
        [formControlName]="item.key"
        [ngClass]="{'is-invalid': !isValid  && isTouched && !item.readonly}"
        class="font-size-11"
        name="item.key"
        bindLabel="text"
        translate
        bindValue="id">

        <ng-template ng-option-tmp let-item="item" class="font-size-11">
          {{item.text | translate }}
        </ng-template>

        <ng-template ng-label-tmp let-item="item" class="font-size-11">
          {{item.text | translate }}
        </ng-template>

      </ng-select>

      <ng-select *ngIf="!item.tag"
        [items]="item.options"
        [clearable]="item.clearable"
        [id]="item.key"
        [required]="item.required"
        [formControlName]="item.key"
        (change)="onChange(item.key, $event); fieldChanged($event, item.key)"
        class="font-size-11"
        translate
        [ngClass]="{'is-invalid': !isValid  && isTouched && !item.readonly}"
        name="item.key"
        appendTo="body"
        bindLabel="text"
        bindValue="id">

        <ng-template ng-option-tmp let-item="item" class="font-size-11">
          {{item.text | translate }}
        </ng-template>

        <ng-template ng-label-tmp let-item="item" class="font-size-11">
          {{item.text | translate }}
        </ng-template>

      </ng-select>
    </div>

    <app-address *ngSwitchCase="'address'"
      [strModule]="strModule"
      (addressChange)="updateAddress($event)"
      (addressDirty)="($event) ? markFormControlDirty() : null"
      [parentHasSubmitted]="(isTouched && item.required) ? true : false">
    </app-address>

    <div *ngSwitchCase="'multiselect'">
      <ng-select *ngIf="!item.tag"
        [items]="item.options"
        [clearable]="item.clearable"
        [id]="item.key"
        [formControlName]="item.key"
        [loading]="loading"
        [multiple]="item.multiple"
        [hideSelected]="item.hideSelected"
        [closeOnSelect]="item.closeOnSelect"
        [maxSelectedItems]="item.maxSelectedItems"
        [required]="item.required"
        (change)="onMultiSelectChange($event)"
        [ngClass]="{'is-invalid': !isValid  && isTouched && !item.readonly}"
        class="font-size-11"
        name="item.key"
        appendTo="{{ elemToAppend }}"
        bindLabel="text"
        bindValue="id">
        <ng-template ng-label-tmp let-item="item" let-clear="clear" class="font-size-11">
          <span class="ng-value-label">
            <span (click)="setAsPrimary(item)">&nbsp;{{ item.text | translate }}</span>
            &nbsp;
            <span *ngIf="item.primary" class="badge badge-success">&nbsp;Primary&nbsp;</span>
          </span>
          <span class="ng-value-icon right selected-close" (click)="clear(item)" aria-hidden="true">×</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" class="value-text" class="font-size-11">
          {{ item.text | translate }}
          <span *ngIf="item.primary" class="badge badge-success">&nbsp;Primary&nbsp;</span>
        </ng-template>
      </ng-select>

      <ng-select *ngIf="item.tag"

        [items]="item.options"
        [clearable]="item.clearable"
        [id]="item.key"
        [formControlName]="item.key"
        [loading]="loading"
        [addTag]="addTag.bind(this)"
        [multiple]="item.multiple"
        [hideSelected]="item.hideSelected"
        [closeOnSelect]="item.closeOnSelect"
        [maxSelectedItems]="item.maxSelectedItems"
        [required]="item.required"
        (change)="onMultiSelectChange($event)"
        [ngClass]="{'is-invalid': !isValid  && isTouched && !item.readonly}"
        name="item.key"
        class="font-size-11"
        bindLabel="text"
        (add)="addTagToOptions($event)"
        [selectOnTab]="true"
        [bindValue]>

        <ng-template *ngIf="strItemKey == 'email_address'" ng-label-tmp let-item="item" let-clear="clear">
          <span class="ng-value-label">
            <span [ngClass]="{'pointer': strItemKey == 'email_address' && item.primary != '1'}" (click)="item.primary != '1' && setPrimaryEmail(item.id)">&nbsp;{{ item.text | translate }}</span>
            &nbsp;
            <span *ngIf="item.primary == '1'" class="badge badge-success">&nbsp;Primary&nbsp;</span>
          </span>
          <span class="ng-value-icon right selected-close" (click)="clear(item)" aria-hidden="true">×</span>
        </ng-template>

        <ng-template *ngIf="strItemKey == 'email_address'" ng-option-tmp let-item="item" class="font-size-11">
          {{ item.text | translate }} <span *ngIf="item.primary == '1'" class="badge badge-success">&nbsp;Primary&nbsp;</span>
        </ng-template>

        <ng-template *ngIf="strItemKey != 'email_address'" ng-label-tmp let-item="item" let-clear="clear">
          {{ item.text | translate }}
          <span class="ng-value-icon right selected-close" (click)="clear(item)" aria-hidden="true">×</span>
        </ng-template>

        <ng-template *ngIf="strItemKey != 'email_address'" ng-option-tmp let-item="item" class="font-size-11">
          {{ item.text | translate }}
        </ng-template>
      </ng-select>
    </div>

    <textarea *ngSwitchCase="'textarea'"
      [formControlName]="item.key"
      [id]="item.key"
      [rows]="item.rows"
      [required]="item.required"
      [readonly]="item.readonly"
      [ngClass]="{'is-invalid': !isValid  && isTouched && !item.readonly}"
      [maxLength]="(item.max_length) ? item.max_length : '2056'"
      class="form-control font-size-11">
    </textarea>

    <div *ngIf="bStudio">

      <ng-select *ngSwitchCase="'date'"
        [items]="objDateList.list"
        [clearable]="true"
        [id]="item.key"
        [formControlName]="item.key"
        [required]="item.required"
        [ngClass]="{'is-invalid': !isValid  && isTouched && !item.readonly}"
        class="font-size-11"
        name="item.key"
        bindLabel="text"
        bindValue="id">

        <ng-template ng-option-tmp let-item="item" class="font-size-11">
          {{item.text | translate }}
        </ng-template>

        <ng-template ng-label-tmp let-item="item" class="font-size-11">
          {{item.text | translate }}
        </ng-template>

      </ng-select>

      <div *ngSwitchCase="'datetime'">
        <ng-select
          [items]="objDateList.list"
          [clearable]="true"
          [required]="item.required"
          id="strDate"
          [formControl]="strDate"
          name="strDate"
          bindLabel="text"
          bindValue="id"
          [ngClass]="{'is-invalid': !isValid  && isTouched && !item.readonly}"
          class="font-size-11">

        <ng-template ng-option-tmp let-item="item" class="font-size-11">
          {{item.text | translate}}
        </ng-template>

        <ng-template ng-label-tmp let-item="item" class="font-size-11">
          {{item.text | translate }}
        </ng-template>

        </ng-select>

        <div class="row mt-2">
          <div class="form-group col-3">
            <select [formControl]="strHour" class="form-control font-size-11" id="strHour" name="strHour">
              <option selected></option>
              <option *ngFor="let hour of arHourList" [value]="hour">
                {{ hour }}
              </option>
            </select>
          </div>

          <div class="col-1" class="font-size-11">
            :
          </div>

          <div class="form-group col-3">
            <select [formControl]="strMin" name="strMin" id="strMin" class="form-control font-size-11">
              <option selected></option>
              <option *ngFor="let minute of arMinList" [value]="minute">
                {{ minute }}
              </option>
            </select>
          </div>

          <div class="form-group col-5">
            <select [formControl]="strMeridiem" name="strMeridiem" id="strMeridiem" class="form-control font-size-11">
              <option selected></option>
              <option *ngFor="let meridiem of arMeridianList" [value]="meridiem">
                {{ meridiem }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

  <div *ngIf="!bStudio">

    <div *ngSwitchCase="'date'">
      <input-date
        [objForm]="form"
        [objItem]="item"
        [strMode]="strMode"
        [strModule]="strModule"
        (sendToParent)="childEmitEvent($event)">
      </input-date>
    </div>

    <div *ngSwitchCase="'datetime'">
      <input-date
        [objForm]="form"
        [objItem]="item"
        [strMode]="strMode"
        [strModule]="strModule"
        (sendToParent)="childEmitEvent($event)">
      </input-date>
    </div>

    <div *ngSwitchCase="'datetimerange'">
      <input-date
        [objForm]="form"
        [objItem]="item"
        [strMode]="strMode"
        [strModule]="strModule"
        (sendToParent)="childEmitEvent($event)">
      </input-date>
    </div>

    <div *ngSwitchCase="'json'">
        <div *ngIf="strModule == 'assets'" class="row">
            <ng-container *ngIf="!item.is_loading && item.asset_type_value != undefined">
              <div *ngFor="let item of form.controls[item.key].value" class="col-md-6 col-xs-6 mb-3">
                  <label *ngIf="item.label" class="labels" translate>{{ item.label }}</label>

                  <input *ngIf="item.type == 'text'"
                    type="text"
                    class="form-control font-size-11"
                    [(ngModel)]="item.default_value"
                    [ngModelOptions]="{standalone: true}"
                    [required]="item.required"
                    [ngClass]="{'is-invalid': item.required && item.default_value == '' && isTouched}"
                    (ngModelChange)="markFormControlDirty('attributes')"
                    (keypress)="validateInputText($event)"
                  >

                  <fieldmagic-decimal-input
                    *ngIf="item.type == 'number'"
                    customClass="form-control font-size-11 input-height-40"
                    onPaste="return false"
                    [(ngModel)]="item.default_value"
                    (keypress)="validateInputNumber($event)"
                    [ngModelOptions]="{standalone: true}"
                    [required]="item.required"
                    [ngClass]="{'is-invalid': item.required && item.default_value == '' && isTouched}"
                    (ngModelChange)="markFormControlDirty('attributes')"
                  ></fieldmagic-decimal-input>

                  <ng-select *ngIf="item.type == 'dropdown'"
                    [items]="item.option"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="item.default_value"
                    [clearable]="true"
                    [required]="item.required"
                    bindLabel="text"
                    bindValue="id"
                    [ngClass]="{'is-invalid': item.required && item.default_value == '' && isTouched}"
                    class="asset_dropdown"
                    (ngModelChange)="markFormControlDirty('attributes')"
                  >
                    <ng-template ng-option-tmp let-item="item" class="font-size-11">
                      {{ item.text | translate }}
                    </ng-template>

                    <ng-template ng-label-tmp let-item="item" class="font-size-11">
                      {{ item.text | translate }}
                    </ng-template>
                  </ng-select>

                  <div *ngIf="item.type == 'date'">
                      <div class="input-group">
                        <input
                          [(ngModel)]="item.default_value"
                          [owlDateTime]="dateTimeField"
                          [ngModelOptions]="{standalone: true}"
                          (dateTimeChange)="updateAssetDateTime($event, item.key)"
                          type="text"
                          class="form-control date-input"
                          [required]="item.required"
                          [ngClass]="{'is-invalid': item.required && item.default_value == '' && isTouched}"
                          (ngModelChange)="markFormControlDirty('attributes')"
                          >
                        <button class="calendar-button" [owlDateTimeTrigger]="dateTimeField">
                          <fa-icon [icon]="['fal', 'calendar']" style="color:#fff;" ></fa-icon>
                        </button>
                      </div>

                      <div>
                        <owl-date-time  #dateTimeField [pickerType]="'calendar'"></owl-date-time>
                      </div>
                  </div>
              </div>
            </ng-container>
            <div class="font-size-11 col-md-12 col-xs-12" *ngIf="item.asset_type_value != undefined && form.controls[item.key].value.length < 1 && !item.is_loading">
              <div> {{ 'no_available_attributes' | translate}}</div>
            </div>
            <div *ngIf="item.asset_type_value == undefined || item.is_loading" class="col-md-12 col-xs-12">
              <div> <fa-icon [icon]="['fas', 'spinner']" class="spinner" [spin]="true"></fa-icon></div>
            </div>
        </div>
        <ng-container *fcMinSubscriptionPlanRequired="'fieldmagic-enterprise'">
          <app-editjson *ngIf="strModule == 'asset_types'" [strModule]="strModule" [objConfig]="item" [strMode]="strMode" (jsonChange)="updateJson($event)"></app-editjson>
        </ng-container>
        <div *ngIf="strModule == 'users'" class="row">
          <ng-container>
            <div *ngFor="let field of form.controls[item.key].value | keyvalue : ignoreSort" class="col-md-6 col-xs-6 mb-3">
                <label *ngIf="field.key" class="labels" translate>{{ field.key | translate }}</label>
                <fc-user-availability [form]="form.controls[item.key]" [field]="field"></fc-user-availability>
            </div>
          </ng-container>
        </div>
        <div *ngIf="strItemKey == 'tags'" class="row">
          <div class="col-12">
            <tag-form [form]="form" [item]="item"></tag-form>
          </div>
        </div>
        <div *ngIf="strModule == 'credit_notes' && item.key == 'line_items'">
          <credit-notes-line-items [metadata]="item" (updateLineItemValue)="updateFormValue($event)"></credit-notes-line-items>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'risk_rating'" class="row">
      <div class="input-group col-6" *ngFor="let _ of form.get(item.key).controls; let _index = index" [formArrayName]="item.key">
        <ng-select
          [items]="getRiskRatingOptions(_index)"
          [clearable]="false"
          bindValue="id"
          bindLabel="text"
          [formControlName]="_index"
          [ngClass]="{'is-invalid': ! _.valid && ! _.disabled  && isTouched && !item.readonly}"
          style="width: 100%">
          <ng-template ng-option-tmp let-item="item">
            {{ item.text | translate }}
          </ng-template>
          <ng-template ng-label-tmp let-item="item">
            {{ item.text | translate }}
          </ng-template>
        </ng-select>
      </div>
    </div>

    <!-- Allow to render a custom field component for specific uses cases for each module -->
    <fc-custom-editform-field
      *ngSwitchCase="'custom_form_component'"
      [formMode]="strMode"
      [parentForm]="form"
      [field]="item"
      [additionalData]="additionalCustomFormComponentData">
    </fc-custom-editform-field>

    <!-- multitext -->
    <fieldmagic-multitext-input
        *ngSwitchCase="'multitext'"
        [formControlName]="item.key"
    ></fieldmagic-multitext-input>
  </div>
</div>
