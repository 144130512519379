export class TagConfig {
  allowed_modules = [
    'leads',
    'sites',
    'customers',
    'contacts',
    'jobs',
    'opportunities',
    'items',
    'customer_invoices',
    'supplier_invoices',
    'purchase_orders'
  ];

  show(strModule: string) {
    return this.allowed_modules.includes(strModule);
  }
}