<div class="row">
    <div class="col-sm-12">
      <span class="title" translate>
        <fa-icon [icon]="['fas', 'file-upload']" ></fa-icon>
        &nbsp; {{ 'upload_new_photo'| translate }}
      </span>

      <span class="buttons d-flex d-flex-gap">

        <button (click)="onSubmit()" class="btn btn-primary" [ngClass]="(bShowLoader['submit'])? 'btn-spinner' : ''">
          <fa-icon *ngIf="!bShowLoader['submit']" [icon]="['fas', 'file-upload']"></fa-icon>
          <inline-view-loader [isLoading]="bShowLoader['submit']"></inline-view-loader>
          <!-- <fa-icon *ngIf="bShowLoader['submit']" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon> -->
          <span class="pl-1">{{ 'upload' | translate }}</span>
        </button>

        <fieldmagic-primary-button
          (click)="cancelDialog()"
          icon="times"
          variant="error"
          label="close"
        ></fieldmagic-primary-button>

      </span>
    </div>
  </div>

  <hr class="separator"/>

  <span class="label"> {{ 'photo' | translate }} </span>
  <div class="file-drop-container">
      <file-upload-input (change)="onFileChange($event)"></file-upload-input >

      <div *ngIf="!isFileDropped && objPhotoUploaded == undefined" class="file-input-overlay">
          {{ 'insert_photo' | translate }}
      </div>

      <div *ngIf="isFileDropped">
        <inline-view-loader class="file-input-overlay-uploading" [isLoading]="isFileDropped"></inline-view-loader>
      </div>

      <div *ngIf="!isFileDropped && objPhotoUploaded !== undefined" class="file-input-overlay-uploaded">
          {{ objPhotoUploaded.file_name }}
      </div>

  </div>
