import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule, MatDialogModule, MatIconModule, MatProgressBarModule, MatTooltipModule } from '@angular/material';
import { FontAwesomeModule } from '../../shared/modules/font-awesome.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgSelectModule } from '@ng-select/ng-select';
import { DraggableFoldersComponent } from './draggable-folders/draggable-folders.component';
import { StaticFoldersComponent } from './static-folders/static-folders.component';
import { ListingService } from '../../services/listing.service';
import { ListingNewModule } from '../../shared/components/listing-new/listing-new.module';
import { AddItemComponent } from './draggable-folders/add-item/add-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ItemFoldersService } from './_services/item-folders.service';
import { BrowseProductsComponent } from './static-folders/browse-products/browse-products.component';
import { RelatedProductsComponent } from './static-folders/related-products/related-products.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SharedModule } from '../../shared/modules/shared.module';

@NgModule({
  declarations: [
    DraggableFoldersComponent,
    StaticFoldersComponent,
    AddItemComponent,
    BrowseProductsComponent,
    RelatedProductsComponent,
  ],
  imports: [
    CommonModule,
    MatTreeModule,
    MatIconModule,
    FontAwesomeModule,
    DragDropModule,
    NgSelectModule,
    MatCheckboxModule,
    MatProgressBarModule,
    ListingNewModule,
    FormsModule,
    MatDialogModule,
    TranslateModule,
    MatTooltipModule,
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    SharedModule.forChild(),
  ],
  entryComponents: [
    DraggableFoldersComponent,
    StaticFoldersComponent,
    AddItemComponent,
    RelatedProductsComponent
  ],
  exports: [
    DraggableFoldersComponent,
    StaticFoldersComponent,
    BrowseProductsComponent,
    RelatedProductsComponent
  ],
  providers: [
    ListingService,
    ItemFoldersService
  ]
})
export class ProductFoldersModule {

}
