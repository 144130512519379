import { Component, OnInit } from '@angular/core';
import { MatChipInputEvent, MatDialogRef } from '@angular/material';
import { TagDisplay } from '../../objects/tag-display';
import { RecordService } from '../../../../services/record.service';
import { ListingService } from '../../../../services/listing.service';
import { filter, finalize, map } from 'rxjs/operators';
import { StatusCode } from '../../../../lists/status-code';
import { isEmpty } from 'lodash';
import { NotificationService } from '../../../../services/notification.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-manage-tags',
  templateUrl: './manage-tags.component.html',
  styleUrls: ['./manage-tags.component.scss']
})
export class ManageTagsComponent implements OnInit {

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  /**
   * The add button placeholder which is always
   * located at the right side of the tags input.
   *
   * @var {boolean}
   */
  bAddPlaceHolder: boolean = false;

  /**
   * Loading indicator, its a simple chip with a spinner
   * that should appear when anything is loading.
   *
   * @var {boolean}
   */
  bLoading: boolean = false;

  /**
   * List of tags to display.
   *
   * @var {TagDisplay}
   */
  arTags: TagDisplay[] = [];

  constructor(
    public dialogRef: MatDialogRef<ManageTagsComponent>,
    public record: RecordService,
    public list: ListingService,
    public notify: NotificationService
  ) {}

  ngOnInit() {

    this._toggleLoading(true);

    this.list.fetchData(null, 'tags', null, 100)
    .pipe(
      filter(response => { return response && response['data'] }),
      finalize(() => {
        this._toggleLoading(false);
        this.bAddPlaceHolder = true;
      })
    )
    .subscribe(response => {
      this.arTags = response['data'].map(item => {
        return new TagDisplay(item);
      });
    });

  }

  /**
   * Adds tag to the event.
   *
   * @param {MatChipInputEvent} objEvent
   *
   * @return {void}
   */
  addTag(objEvent: MatChipInputEvent): void {

    if (!isEmpty(objEvent.value.trim())) {

      this._toggleLoading(true);

      let tag = new TagDisplay({name: objEvent.value.trim()});

      this.record.saveRecord('tags', tag)
        .pipe(
          filter(response => {
            return response.status === StatusCode.kResponseCreated;
          }),
          map(response => {
            return new TagDisplay(response['body'] as any);
          }),
          finalize(() => {
            this._toggleLoading(false);
          })
        )
        .subscribe(tag => {
          this._add(objEvent, tag);
        });

    }
  }

  /**
   * Remove tag from list
   *
   * @param {TagDisplay} objFruit
   *
   * @return {void}
   */
  removeTag(objFruit: TagDisplay): void {

    if (objFruit.id) {
      this._toggleLoading(true);
      this.record.deleteRecord('tags', objFruit.id)
      .pipe(
        filter(response => {
          return response.status === StatusCode.kResponseSuccess;
        }),
        finalize(() => {
          this._toggleLoading(false);
        })
      )
      .subscribe(() => {
        this._remove(objFruit);
      });
    } else {
      this._remove(objFruit);
    }

  }

  /**
   * Toggle placeholder.
   *
   * @param {bloolean} bFlag
   *
   * @return {void}
   */
  togglePlaceHolder(bFlag: boolean) {
    this.bAddPlaceHolder = !bFlag;
  }

  /**
   * Focuses on the chip input when clicked.
   *
   * @param element
   *
   * @return {void}
   */
  focusOnChipInput(element){
    element.focus();
  }

  /**
   * Save tags through the API.
   *
   * @return {void}
   */
  saveTag(objTag: TagDisplay) {
    this.record.saveRecord('tags', objTag, objTag.id)
    .pipe(
      filter(response => {
        return response.status === StatusCode.kResponseSuccess;
      }),
    )
    .subscribe(() => {
      this.notify.notifySuccess('tag_updated_successfully');
    });
  }

  /**
   * Closes the dialog.
   *
   * @return {void}
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Remove item from the list of tags.
   *
   * @param {TagDisplay} objFruit
   *
   * @return {void}
   */
  private _remove(objFruit: TagDisplay) {
    const index = this.arTags.indexOf(objFruit);
    if (index >= 0) {
      this.arTags.splice(index, 1);
    }
  }

  /**
   * Add item to the list of tags.
   *
   * @param {MatChipInputEvent} objEvent
   * @param {TagDisplay} objTag
   */
  private _add(objEvent: MatChipInputEvent, objTag: TagDisplay) {
    if ((objEvent.value || '').trim()) {
      this.arTags.push(objTag);
    }

    if (objEvent.input) {
      objEvent.input.value = '';
    }
  }

  /**
   * Toggles the loading indicators.
   *
   * @param {boolean} bValue
   */
  private _toggleLoading(bValue: boolean) {
    this.bLoading = bValue;
  }
}
