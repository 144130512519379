<header>
  <div class="d-flex header-container header-role">
    <span>
      <h3 class="title"><span>{{ strHeaderLabel | translate }}</span></h3>
    </span>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li *ngIf="strViewType == 'manage'" class="list-inline-item"><button id="widgetRoleAddButton" class="btn ml-1 btn-primary"  (click)="addRole()"><fa-icon [icon]="['fas', 'plus']"></fa-icon>&nbsp;&nbsp;<span translate>add</span></button></li>
        <li class="list-inline-item">
          <button id="widgetRoleSaveButton" (click)="onSubmit()" class="btn ml-1 btn-primary" [disabled]="bSubmitted">
            <fa-icon *ngIf="!bSubmitted" [icon]="['fas', 'save']"></fa-icon>
            <inline-view-loader [isLoading]="bSubmitted" [hasText]="false"></inline-view-loader>
            <span class="pl-1">{{ 'save' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <fieldmagic-primary-button
            (click)="cancelDialog()"
            icon="times"
            variant="error"
            label="close"
            id="widgetRoleCancelButton"
          ></fieldmagic-primary-button>
        </li>
      </ul>
    </div>
  </div>
  <br>
</header>

<mat-dialog-content class="{{ strMatDialogContentClass }} mat-dialog-content mt-1">
  <div class="col-lg-12 col-md-12 col-sx-12 pr-0 pl-0">
    <div class="row">
      <div *ngIf="!bLoading" class="col-lg-12">
        <table class="table table-striped border-bottom">
          <thead>
            <tr>
              <th scope="col" width="40%" class="font-12 theader text-center">{{ strRelateModule | translate }}</th>
              <th scope="col" width="35%" class="font-12 theader text-center">{{ 'role' | translate }}</th>
              <th scope="col" width="25%"class="font-12 theader">
                <div class="row">
                  <div class="col-sm-1">
                    <fa-icon [icon]="['fas', 'crown']" class="text-warning fs-11"></fa-icon>
                  </div>
                  <div class="col-sm">
                    <span class="float-right">
                      <ng-container *ngIf="strModule != 'opportunities'">
                        <fa-icon [icon]="['fas', 'shipping-fast']" class="setting-icons"></fa-icon>
                        <fa-icon [icon]="['fas', 'clipboard-check']" class="setting-icons"></fa-icon>
                        <fa-icon [icon]="['fas', 'business-time']" class="setting-icons"></fa-icon>
                      </ng-container>
                      <fa-icon [icon]="['fas', 'clipboard-list']" class="setting-icons"></fa-icon>
                      <fa-icon [icon]="['far', 'shopping-bag']" class="setting-icons"></fa-icon>
                      <fa-icon [icon]="['fas', 'wrench']" class="setting-icons"></fa-icon>
                      <fa-icon *ngIf="strModule != 'opportunities' && strRelateModule == 'customers'" [icon]="['fas', 'map-marker-alt']" class="setting-icons"></fa-icon>
                    </span>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let objForm of arRoleForm; let i = index">
              <tr [formGroup]="objForm['form']">
                <td>
                  <ng-select
                    formControlName="module"
                    [items]="objContactRelate[objForm.id].item | async"
                    [loading]="objContactRelate[objForm.id].loading"
                    [typeahead]="objContactRelate[objForm.id].typeahead"
                    [clearable]="true"
                    (click)="onClickRelate(objForm)"
                    (change)="ngOnChangeRelate($event, objForm)"
                    bindValue="id"
                    bindLabel="text"
                    appendTo="body"
                    style="max-width: 380px;"
                    id="roleRelateField_{{ strRelateModule }}{{ i }}">
                      <ng-template ng-label-tmp let-item="item">{{ item.text | translate }}</ng-template>
                      <ng-template ng-option-tmp let-item="item">{{ item.text | translate }}</ng-template>
                  </ng-select>
                </td>
                <td>
                  <ng-select *ngIf="objForm.metadata.role"
                    formControlName="role"
                    [items]="objForm.metadata.role.options"
                    [multiple]="true"
                    [clearable]="true"
                    [hideSelected]="true"
                    [closeOnSelect]="false"
                    bindValue="id"
                    bindLabel="text"
                    appendTo="body"
                    id="roleField_{{ strRelateModule }}{{ i }}">
                    <ng-template ng-label-tmp let-item="item" let-clear="clear" class="fs-12">
                      <span id="setAsPrimaryRole_{{ item.id }}{{ i }}" class="pl-1" [ngClass]="{'pointer': item.primary != true}" (click)="objForm.editable && setPrimaryRole(item.id, objForm)" aria-hidden="true">{{ item.text | translate }}</span>
                      <span *ngIf="item.primary == true" id="primaryRole{{ i }}" class="ml-1 badge badge-success">{{ 'primary' | translate }}</span>
                      <span id="removeRole_{{ item.id }}{{ i }}" class="pl-1 pr-1 pointer" (click)="objForm.editable && clear(item)" aria-hidden="true">×</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" class="pointer">{{ item.text | translate }}</ng-template>
                  </ng-select>
                </td>
                <td>
                  <div class="row">
                    <div align="center" class="col-sm-1 role_content">
                      <fa-icon class="pointer text-success"
                        *ngIf="objForm['primary_contact_role']"
                        [icon]="['fas', 'check-circle']"
                        (click)="onChangePrimaryRole(i, objForm['primary_contact_role'])">
                      </fa-icon>
                      <input
                        *ngIf="!objForm['primary_contact_role']"
                        class="pointer"
                        type="radio"
                        matTooltip="{{ 'set_as_primary_contact' | translate }}"
                        (click)="onChangePrimaryRole(i, objForm['primary_contact_role'])"
                        [disabled]="!objForm['form'].valid || ! objForm.editable"
                      />
                    </div>
                    <div class="col-sm">
                      <span class="float-right">
                        <ng-container *ngIf="strModule != 'opportunities'">
                          <input type="checkbox" class="ml-2" matTooltip="{{ 'tip_task_transit' | translate }}" formControlName="notify_task_transit">
                          <input type="checkbox" class="ml-2" matTooltip="{{ 'tip_before_schedule' | translate }}" formControlName="notify_before_schedule">
                          <input type="checkbox" class="ml-2" matTooltip="{{ 'tip_job_complete' | translate }}" formControlName="notify_job_complete">
                        </ng-container>
                        <input type="checkbox" class="ml-2" matTooltip="{{ 'tip_default_quotes' | translate }}" formControlName="set_contact_quotes">
                        <input type="checkbox" class="ml-2" matTooltip="{{ 'tip_default_po' | translate }}" formControlName="set_contact_po">
                        <input type="checkbox" class="ml-2" matTooltip="{{ 'tip_default_job_report' | translate }}" formControlName="set_contact_job_report">
                        <input *ngIf="strModule != 'opportunities' && strRelateModule == 'customers'" type="checkbox" class="ml-2" matTooltip="{{ 'tip_all_sites' | translate }}" formControlName="display_all_sites">
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>

      </div>

      <div class="form-group col-lg-12 text-center" *ngIf="bLoading">
        <inline-view-loader [isLoading]="true" [hasText]="false"></inline-view-loader>
      </div>
    </div>
  </div>
</mat-dialog-content>