import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DOCUMENT } from '@angular/platform-browser';

import { WidgetService } from '../../../../services/widget.service';
import { NotificationService } from '../../../../services/notification.service';
import { RecordService } from '../../../../services/record.service';

import * as moment from 'moment';

import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FilesComponent } from './files/files.component';

import { PreviewComponent } from './preview/preview.component';
import { UploadConfig } from '../../../../lists/upload-config';
import { FileService } from '../../../../services/file/file.service';
import { EditComponent } from './edit/edit.component';
import { environment } from '../../../../../environments/environment';
import { isEmpty, uniqBy } from 'lodash';

import ImageViewer from 'awesome-image-viewer';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {

  @Input() strRecordId: string;
  @Input() strModule: string;

  public selectDate: string;
  public selectType: string;
  public selectSortFile: string;
  public strFilterFile: string;
  public arFilesList = [];
  public arImageList = [];
  public uploadConfig = new UploadConfig();
  public bLoading = false;
  private refreshFilesSubscription: Subscription;

  /**
   * list of sort filter
   */
  public arSortFilter: string[] = [
    'created_at', 'created_at'
  ];

  constructor(
    private dialog: MatDialog,
    public widgetService: WidgetService,
    public fileService: FileService,
    private notifService: NotificationService,
    private recordService: RecordService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: any
  ) {
      this.refreshFilesSubscription = fileService.refreshFilesList$.subscribe((refreshList: boolean) => {
        if (refreshList) {
          this.onRefresh();
        }
      });
   }

  ngOnInit() {

    // Set default filter date and type
    this.selectDate = 'all'
    this.selectType = 'all'
    this.selectSortFile = 'created_at'

    var arRequestData = {
      'module' : this.strModule,
      'record_id' : this.strRecordId,
      'filter_clause' : this.selectSortFile,
      'where_clause' : ''
    };

    this.bLoading = true;

    this.fetchList(arRequestData);
  }


    /**
     * Let's format the datetime value.
     * @param date
     */
    formatDate(strDate, arCustomConfig = []) {
        // Convert datetime to utc
        let utcTime = moment.utc(strDate).toDate();
        // Convert to local time zone and display
        return moment(utcTime).local().format('lll');
    }

    setData(data) {
      this.bLoading = false;
      this.arFilesList = data['data'];

      this.arImageList = this.arImageList.concat(
        this.arFilesList.filter(file => file.file_type.startsWith('image/') && !isEmpty(file.file_url)).map(file => {
          return {
            id: file.id,
            mainUrl: file.file_url,
            thumbUrl: file.file_url,
            description: file.description,
          };
        })
      );
      // This will prevent already added image to the list (Ex: When going back to first page)

      this.arImageList = uniqBy(this.arImageList, 'id');
      this.widgetService.afterPageLoad(
        data,
        'files',
        this.selectSortFile || 'created_at'
      );
    }

    previewImage(recordId: string): void {
      const index = this.arImageList.findIndex(image => image.id === recordId);
      if (index > -1) {
        const iv = new ImageViewer({
          images: this.arImageList,
          currentSelected: index,
          buttons: [
            {
              name: this.translateService.instant('download'),
              iconSrc: 'assets/img/icons/download.svg',
              iconSize: '18px auto',
              onSelect: function() {
                const clonedIv = Object.create(iv);
                window.location.href = clonedIv.images[clonedIv.currentSelected].mainUrl;
              }
            },
          ],
        });
      }
    }

    /**
     * To update the page of activity
     *
     * @param string navigation
     */
    setPage(strNav) {
      // Set default filter date and type
      this.selectDate = 'all';
      this.selectType = 'all';
      this.selectSortFile = this.selectSortFile || 'created_at';

        let arRequestData = this.widgetService.beforePageLoad(
          strNav,
          'files',
          this.selectSortFile
        );

        arRequestData['module'] = this.strModule;
        arRequestData['record_id'] = this.strRecordId;
        arRequestData['widget'] = 'files';

        this.fetchList(arRequestData);
    }

    // Set the filters to their proper values.
    setFilters() {

        // Create request parameter
        var arRequestData = {
          'module' : this.strModule,
          'record_id' : this.strRecordId,
        }

        if (this.selectSortFile != '') {
            arRequestData['filter_clause']  = this.selectSortFile;
        }

        if (this.strFilterFile != '') {
            arRequestData['where_clause'] = { 'file_name' : this.strFilterFile };
        }

        // Set to the first page when filtering by name.
        this.widgetService.beforePageLoad(
          'default',
          'files',
          this.selectSortFile || 'created_at'
        );
        this.arFilesList = [];
        this.arImageList = [];
        this.bLoading = true;
        // Call api for getting the list of files (Pass the order by)
        this.fetchList(arRequestData);
  }

  // View file history
  editFile(file) {

    let objFileData = {

      width: '700px',
      height: 'auto',
      // Data to be passed on
      data: {
        "file": file,
        "module": this.strModule,
        "record_id": this.strRecordId
      },

      disableClose: true
    };

    let tabDialogRef;

    tabDialogRef = this.dialog.open(EditComponent, objFileData);

    tabDialogRef.afterClosed().pipe(
      filter((response) => response != 'cancel')
    ).subscribe(
      data => {

        if (data.status != 'cancel' && data) {

          let arRequestData = data;
          arRequestData['upload_name'] = file.upload_name;

          this.fileService.save(arRequestData).subscribe(
            data => {
              if (typeof data === 'string') {
                this.notifService.sendNotification('header_notification.generic_fail', 'content_notification.fail_s3_upload', 'danger');
              } else {
                this.notifService.notifySuccess('file_update_success');
                this.setFilters();
              }
            }
          );
        }
      }
    );

  }

  /**
   * Opens file upload form
   * @param date
   */
  openFileForm() {

    let objFileData = {
      width: '700px',
      height: 'auto',
      data: {
        "module" : this.strModule,
        "record_id" : this.strRecordId
      },
      disableClose: true
    };

    let tabDialogRef;

    tabDialogRef = this.dialog.open(FilesComponent, objFileData);
    // Get response after closing or submitting files dialog
    tabDialogRef.afterClosed().subscribe(
      response => {
        if (response.status === 'save' && response.request) {
          const objRequestData = response.request;
          const uploadName = objRequestData.file.upload_name;

          objRequestData['upload_name'] = objRequestData.file.upload_name;
          objRequestData['id'] = uploadName.split('/')[1];

          this.fileService.save(objRequestData).subscribe(
            data => {
              if (typeof data === 'string') {
                this.notifService.sendNotification('header_notification.generic_fail', 'content_notification.fail_s3_upload', 'danger');
              } else {
                this.notifService.notifySuccess('file_create_success');
                this.setFilters();
              }
            }
          );
        }
      }
    );
  }

  // Assign icon per file type
  getFileType(strType, strFileName) {

    // Get the file type by cutting the first word
    var strFileType = strType.substr(0, strType.indexOf("/"))
    // Get file extensions (Exclusively made for file type application)
    var strFileExtension = strFileName.split('.')[1];

    if (strFileType !== 'application') {

      // Check if file has corresponding icon
      return this.uploadConfig['mimetype'][strFileType.toLowerCase()] != undefined ?
      this.uploadConfig['mimetype'][strFileType.toLowerCase()] : 'file' ;
    } else {

      // Check if application used is spreadsheet, powerpoint, docs, zip, pdf etc.
      if (this.uploadConfig['spreadsheetList'].includes(strFileExtension.toLowerCase())) {
      return 'file-spreadsheet';
      } else if (this.uploadConfig['powerpointList'].includes(strFileExtension.toLowerCase())) {
      return 'file-powerpoint';
      } else if (this.uploadConfig['documentList'].includes(strFileExtension.toLowerCase())) {
      return 'file-word';
      } else if (this.uploadConfig['archiveList'].includes(strFileExtension.toLowerCase())) {
      return 'file-archive';
      } else if (strFileExtension.toLowerCase() === 'pdf') {
      return 'file-pdf';
      } else {
      return 'file';
      }
    }
  }

    // Assign color of file icons per type
  getColorsPerType(strType, strFileName) {

    // Get the file type by cutting the first word
    var strFileType = strType.substr(0, strType.indexOf("/"))
    // Get file extensions (Exclusively made for file type application)
    var strFileExtension = strFileName.split('.')[1];

    let strApplicationType = 'default';
    if (strFileType !== 'application') {

      // Check if file has corresponding icon color, if not return default color
      return this.uploadConfig['iconColors'][strFileType.toLowerCase()] != undefined ?
      this.uploadConfig['iconColors'][strFileType.toLowerCase()] : this.uploadConfig['iconColors']['default'] ;
    } else {

      // Check if application used is spreadsheet, powerpoint, docs, zip, pdf etc.
      if (this.uploadConfig['spreadsheetList'].includes(strFileExtension.toLowerCase())) {
        strApplicationType = 'spreadsheet';
      } else if (this.uploadConfig['powerpointList'].includes(strFileExtension.toLowerCase())) {
        strApplicationType = 'powerpoint';
      } else if (this.uploadConfig['documentList'].includes(strFileExtension.toLowerCase())) {
        strApplicationType = 'document';
      } else if (this.uploadConfig['archiveList'].includes(strFileExtension.toLowerCase())) {
        strApplicationType = 'archive';
      } else if (strFileExtension.toLowerCase() === 'pdf') {
        strApplicationType = 'pdf';
      } else {
        strApplicationType = 'default';
      }

      return this.uploadConfig['iconColors'][strApplicationType];
    }
  }

  /**
   * Triggers when the user clicks download icon
   * Gets presigned url for downloading a file from s3 and open page to download
   *
   * @param strUploadName
   * @param strFileType
   * @param strFileName
   * @returns {void}
   */
  downloadFile(strUploadName: string, strFileType: string, strFileName: string): void {
    this.fileService.download(strUploadName, strFileType, strFileName).subscribe(data => {
      if (data) {
        // FC-3586: open downloaded files in new tab
        const tabOpen = window.open(data.toString(), '_blank');
        // If new tab popups are blocked due to user browser settings,
        // download continues but page is not redirected
        if (!tabOpen) {
          this.document.location.href = data.toString();
        }
      } else {
        this.notifService.sendNotification('header_notification.generic_fail', 'content_notification.fail_download', 'danger');
      }
    });
  }

  /**
   * Triggers when the user clicks download icon
   * Download the image using the link on the record data
   *
   * @param recordId
   * @returns {void}
   */
  downloadImage(recordId: string): void {
    const {file_url: strFileUrl} = this.arFilesList.find(file => file.id === recordId);

    if (!isEmpty(strFileUrl)) {
      // FC-3586: open downloaded files in new tab
      const tabOpen = window.open(strFileUrl, '_blank');
      // If new tab popups are blocked due to user browser settings,
      // download continues but page is not redirected
      if (!tabOpen) {
        this.document.location.href = strFileUrl;
      }
      return;
    }
  }

  /**
  * Preview the uploaded file
  *
  * @param {string} strUploadName
  * @param {string} strFileType
  * @param {string} strFileName
  *
  * @returns {void}
  */
  previewFile(strUploadName: string, strFileName: string): void {
    this.fileService.getObjectSignedUrl(strUploadName, environment.client_bucket).subscribe(
      object => {
        this.dialog.open(PreviewComponent, {
          width: "90%",
          height: "auto",
          data: {
            path: object['url'],
            file_name: strFileName
          },
          disableClose: true
      });
    });
  }

    /**
     * Gets and sets the list of all files
     *
     * @param arRequestData
     * @returns {void}
     */
    fetchList(arRequestData): void {
      this.widgetService.getFiles(JSON.stringify(arRequestData)).subscribe( data => {
        this.setData(data);
        this.bLoading = false;
      });
    }

    /**
     * Deletes a file record depending on the id of the file
     *
     * @param {string} strId
     * @returns {void}
     */
    delete(strId): void {
    this.notifService.sendConfirmation('delete_file_confirmation')
    .filter(confirmation => confirmation.answer === true)
    .subscribe(() => {
      this.recordService.deleteRecord('files', strId).first().subscribe(data => {
        this.fetchList({
          'module' : this.strModule,
          'record_id' : this.strRecordId,
          'filter_clause' : this.selectSortFile,
          'where_clause' : ''
        });

        if (data) {
          this.notifService.notifySuccess('record_delete_success');
        } else {
          this.notifService.notifyError('record_delete_failed');
        }
      });
    });
  }

  /**
   * Handles refresh list event
   *
   * @returns {void}
   */
  onRefresh(): void {
    this.setFilters();
  }

  ngOnChanges(): void {
    if (this.selectDate) {
      this.setFilters();
    }
  }

  ngOnDestroy(): void {
    this.refreshFilesSubscription.unsubscribe();
  }

  /**
	 * Call update API and change the is_public
	 * flag.
	 *
	 * @param {id: string, public: boolean}
	 *
	 * @returns {void}
	 */
	markAsPublic(file: {id: string, public: boolean}): void {
		this.widgetService.update(file.id, file.public).subscribe(response => {
			if (response.message === 'saved') {
				this.notifService.notifySuccess('file_updated');
			}
		})
	}
}
