import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map, filter, skip, first, catchError, tap } from 'rxjs/operators';
import { combineLatest, Observable, BehaviorSubject, forkJoin, of } from 'rxjs';

import { Client } from '../../objects/client';
import { Notification } from '../../objects/notification';
import { ClientStoreService } from '../client-store.service';
import { NotificationService } from '../notification.service';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../local-storage.service';
import { SubscriptionInfo } from '../../objects/subscription-info';
import { SubscriptionCustomer } from '../../objects/subscription-customer';
import { ClientsListStoreService } from '../clients-list-store/clients-list-store.service';

/**
 * Type used for defining the observable used by the global
 * subscription update button.
 *
 * @type {{ show_global_sub_update_button: boolean, subscription_info: SubscriptionInfo, client_info: Client }}
 */
export type GlobalSubscriptionUpdateButtonState = { show_global_sub_update_button: boolean, customer_info: SubscriptionCustomer, subscription_info: SubscriptionInfo, client_info: Client };

export type SubscriptionInfoRequest = {
  /**
   * The subscription info tied to the active client.
   *
   * @type {SubscriptionInfo}
   */
  subscription: SubscriptionInfo;

  /**
   * The customer record data tied to the active client.
   *
   * @type {SubscriptionCustomer}
   */
  customer: SubscriptionCustomer
};

/**
 * @see {@link https://www.chargebee.com/checkout-portal-docs/dropIn.html#chargebee-instance-object}
 * for more information about the functions contained in this object.
 *
 * @type {ChargebeePortalCallbacks}
 */
export type ChargebeePortalCallbacks = {
  loaded?: () => void;
  close?: () => void;
  visit?: (sectionType: string) => void;
  paymentSourceAdd?: () => void;
  paymentSourceUpdate?: () => void;
  paymentSourceRemove?: () => void;
  subscriptionChanged?: (data) => void;
  subscriptionCustomFieldsChanged?: (data) => void;
  subscriptionCancelled?: (data) => void;
  subscriptionPaused?: (data) => void;
  subscriptionResumed?: (data) => void;
  scheduledPauseRemoved?: (data) => void;
  subscriptionReactivated?: (data) => void;
};

@Injectable({
  providedIn: 'root'
})
export class ChargebeeService {

  /**
   * Chargebee singleton.
   *
   * @type {Object}
   */
  private _chargebeeInstance: any = null;

  /**
   * Is the client's subscription under trial?
   *
   * @type {Boolean}
   */
  public isClientOnTrial: boolean = false;

  /**
   * How many days are left til the trial period expires?
   *
   * @type {Number}
   */
  public trialDaysLeft: number;

  /**
   * Is Chargebee's self-serve portal open?
   *
   * @type {BehaviorSubject<boolean>}
   */
  private isPortalDisplayed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Emits an event when the Chargebee's self-serve portal closes.
   *
   * @type {Observable<boolean>}
   */
  public whenPortalClosed$: Observable<boolean> = this.isPortalDisplayed.asObservable().pipe(map(isPortalDisplayed => isPortalDisplayed === false));

  /**
   * Contains the subscription info for the active client.
   *
   * @type {BehaviorSubject<SubscriptionInfo>}
   */
  private subscriptionInfo: BehaviorSubject<SubscriptionInfo> = new BehaviorSubject<SubscriptionInfo>(null);

  /**
   * The publicly accessible observable for the subscription info.
   *
   * @type Observable<SubscriptionInfo>
   */
  public activeClientSubscriptionInfo$: Observable<SubscriptionInfo> = this.subscriptionInfo.asObservable()
    .pipe(
      filter(subscriptionInfo => this.clientStoreService.getActiveClient() !== null && subscriptionInfo !== null)
    );

  /**
   * Contains the subscription info for the active client.
   *
   * @type {BehaviorSubject<SubscriptionCustomer>}
   */
  private customerInfo: BehaviorSubject<SubscriptionCustomer> = new BehaviorSubject<SubscriptionCustomer>(null);

  /**
   * The publicly accessible observable for the active client's customer info.
   *
   * @type Observable<SubscriptionCustomer>
   */
  public customerInfo$: Observable<SubscriptionCustomer> = this.customerInfo.asObservable();

  /**
   * Hides or shows the global subscription update to the user.
   * This contains trial information and allows the user to update
   * their trial information from any page. This gets set to false
   * when the user clicks the (x) button.
   *
   * @type {BehaviorSubject<boolean>}
   */
  public toggleGlobalSubscriptionUpdateDisplay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  /**
   * A combined listener for the following events:
   * 1.) When the user clicks the (x) button on the global subscription update button
   * 2.) When the active client gets updated
   * 3.) When the subscription info gets updated.
   *
   * This will be used mainly used in showing the global subscription update button
   *
   * @type {Observable<GlobalSubscriptionUpdateButtonState>}
   */
  public globalSubscriptionUpdateButtonState$: Observable<GlobalSubscriptionUpdateButtonState> = combineLatest(
    this.toggleGlobalSubscriptionUpdateDisplay$,
    this.activeClientSubscriptionInfo$,
    this.customerInfo$,
    this.clientStoreService.whenActiveClientIsSet$).pipe(
      map(([show_global_sub_update_button, subscription_info, customer_info, client_info]) => {
        return { show_global_sub_update_button: show_global_sub_update_button, customer_info: customer_info, subscription_info: subscription_info, client_info: client_info };
      })
    );

  /**
   * Has the Chargebee object been initialized already via Chargebee.init?
   *
   * @type {Boolean}
   */
  private isChargebeeInitialized: boolean = false;

  constructor(
    public http: HttpClient,
    public translateService: TranslateService,
    public clientStoreService: ClientStoreService,
    public localStorageService: LocalStorageService,
    public notificationService: NotificationService,
    public clientsListStoreService: ClientsListStoreService,
  ) {
    this.preserveSubscriptionDetailsOfActiveClient();
  }

  /**
   * Returns a singleton of the chargebee object.
   *
   * @returns {any}
   */
  getInstance(): any {
    return this._chargebeeInstance || this.initChargebee();
  }

  /**
   * Returns the Chargebee portal session for the active client. This will
   * automatically sign the user up as a customer if it still hasn't.
   *
   * @returns {Observable<HttpResponse<Response>>}
   */
  portalLogin(client_id: string): Observable<HttpResponse<Response>> {
    let body = new URLSearchParams();
    body.append('client_id', client_id);

    return this.http
      .post<Response>(`${environment.url}/subscription_billing/get_portal_session`, body.toString(), { observe: 'response' })
      .pipe(
        catchError(error => of(error).pipe(
          map(error => error.error.errors[0]),
          tap(error => this.notificationService.sendNotification('portal_initialization_error', error.detail, 'danger', 5000))
        )),
      );
  }

  /**
   * Returns the Chargebee portal session for the active client. This will
   * automatically sign the user up as a customer if it still hasn't.
   *
   * @returns {Observable<HttpResponse<Response>>}
   */
  portalLogout(client_id: string): Observable<HttpResponse<Response>> {
    let body = new URLSearchParams();
    body.append('client_id', client_id);

    return this.http.post<Response>(`${environment.url}/subscription_billing/logout_portal_session`, body.toString(), { observe: 'response' });
  }

  /**
   * Updates the client record associated to the customer that owns the
   * subscription that was just cancelled.
   *
   * @returns {Observable<Client>}
   */
  subscriptionCancelled(client_id: string): Observable<Client> {
    return this.http.post<Client>(`${environment.url}/subscription_billing/cancelled`, { observe: 'response' });
  }

  /**
   * Requests for the client's subscription information then
   * broadcasts the data to all subscribers of this.activeClientSubscriptionInfo$
   *
   * @returns {void}
   */
  getSubscriptionInfo(client_id: string): void {
    this.getSubscriptionInfoRequest(client_id)
      .subscribe((subscriptionInfoRequest) => {
        this.setSubscriptionInfo(subscriptionInfoRequest.subscription);
        this.setCustomerInfo(subscriptionInfoRequest.customer);
      });
  }

  /**
   * Sets the subscription info for this client.
   *
   * @todo Currently, we're looking at two sources of subscription info on the client side. Combine them
   * @see https://stackoverflow.com/questions/847185/convert-a-unix-timestamp-to-time-in-javascript
   *
   * @param {SubscriptionInfo} subscriptionInfo
   *
   * @returns {void}
   */
  setSubscriptionInfo(subscriptionInfo: SubscriptionInfo): void {
    if (typeof subscriptionInfo.trial_end == 'number' && String(subscriptionInfo.trial_end).length === 10) {
      // We're multiplying the trial dates by 1000 because the timestamps are only 10 digits
      // (courtesy of our subscription billing service). Please see @see
      subscriptionInfo.trial_end = moment.utc(subscriptionInfo.trial_end * 1000).local().toDate();
    }

    this.subscriptionInfo.next(subscriptionInfo);
  }

  /**
   * Sets the customer info for this client.
   *
   * @param {SubscriptionCustomer} customerInfo
   *
   * @returns {void}
   */
  setCustomerInfo(customerInfo: SubscriptionCustomer): void {
    this.customerInfo.next(customerInfo);
  }

  /**
   * Returns checkout information of the client's checked out plan
   *
   * @param subscription_plan_id
   *
   * @returns {Observable<HttpResponse<Response>>}
   */
  checkout(client_id: string, subscription_plan_id: string): Observable<HttpResponse<Response>> {
    let body = new URLSearchParams();
    body.append('client_id', client_id);
    body.append('subscription_plan_id', subscription_plan_id);

    return this.http
      .post<Response>(`${environment.url}/subscription_billing/checkout`, body.toString(), { observe: 'response' })
      .pipe(
        catchError(error => of(error).pipe(
          map(error => error.error.errors[0]),
          tap(error => this.notificationService.sendNotification('checkout_error', error.detail, 'danger', 5000))
        )),
      );
  }

  /**
   * Gets the subscription information of the user, then stores it
   * locally. As of writing, we only save the subscription plan
   *
   * @returns {Observable<SubscriptionInfoRequest>}
   */
  downloadAndStoreSubInfo(client_id: string): Observable<SubscriptionInfoRequest> {
    let body = new URLSearchParams();
    body.append('client_id', client_id);

    return this.http.post<SubscriptionInfoRequest>(`${environment.url}/subscription_billing/download/subscription_info`, body.toString(), { observe: 'response' }).pipe(map(response => response.body));
  }

  /**
   * Returns the HTTP request for getting a client's
   * subscription information.
   *
   * @param client_id
   *
   * @returns {Observable<SubscriptionInfoRequest>}
   */
  getSubscriptionInfoRequest(client_id: string): Observable<SubscriptionInfoRequest> {
    let body = new URLSearchParams();
    body.append('client_id', client_id);

    return this.http.post<SubscriptionInfoRequest>(`${environment.url}/subscription_billing/details`, body.toString(), { observe: 'response' })
      .pipe(
        map(response => response.body)
      );
  }

  /**
   * Determines whether a client's trial subscription is still active by checking
   * if there's still days, minutes, or even seconds left.
   *
   * @param {Client} client
   *
   * @returns {Boolean}
   */
  subscriptionIsInTrial(client: Client): boolean {
    let today = moment();
    let trialEnd = moment.utc(client.subscription_trial_end).local();

    return client.subscription_status === 'in_trial' && trialEnd.diff(today) > 0;
  }

  /**
   * Determines whether a subscription is still active.
   *
   * @param {Client} client
   *
   * @returns {Boolean}
   */
  subscriptionIsActive(client: Client): boolean {
    return client.subscription_status === 'active';
  }

  /**
   * Observes the currently active client then after receiving a change
   * (e.g., the user logs in to the client), makes a request to the server
   * to determine its subscription info.
   * Along the lifecycle of the app, the user decides to switch between
   * different clients. If the user, some time in the future, goes back to
   * this client, a new step will happen:
   * Prior to making a request to the server for subscription info, it
   * checks first if the subscription info it has seen before has changed
   * and only proceeds with its server-side request if detected.
   *
   * @returns {void}
   */
  initSubscriptionInfoWhenAdmin() {
    this.clientStoreService.whenActiveClientIsRemoved$
      .subscribe(() => {
        // Hide the global subscription update link if there's no active client.
        // This ensures that it is not displayed when the user is in the
        // client selection page.
        this.toggleGlobalSubscriptionUpdateDisplay$.next(false);

        if (this.isChargebeeInitialized) {
          this.getInstance().logout();
        }
      });
    this.clientStoreService.whenActiveClientIsSet$
      .pipe(
        // Only an administrator may be able to request for the subscription
        // details from inside the app. This is because a request to the subscription
        // billing endpoints will trigger a creation of the customer and subscription
        // record, which requires the user info of an administrator. Besides, the
        // subscription info retrieved from the WhoAmI endpoint will suffice for
        // non-admin users.
        filter(activeClient => activeClient.level === 'admin')
      )
      .subscribe((activeClient) => {
        this.setCustomerInfo({
          card_status: activeClient.subscription_card_status,
        });

        this.setSubscriptionInfo({
          id: activeClient.subscription_id,
          plan_id: activeClient.subscription_plan_id,
          status: activeClient.subscription_status,
          trial_start: activeClient.subscription_trial_start,
          trial_end: activeClient.subscription_trial_end,
          next_billing_at: activeClient.expiry_date,
        });

        this.toggleGlobalSubscriptionUpdateDisplay$.next(true);

        if (this.isChargebeeInitialized === false) {
          this.initChargebee();
        }
      });
  }

  /**
   * Determines whether a subscription is set to be paused at the end of the
   * month.
   *
   * @param {Client} client
   *
   * @returns {Boolean}
   */
  subscriptionIsNotRenewing(client: Client): boolean {
    return client.subscription_status === 'non_renewing';
  }

  /**
   * determine if the client has bypass mode
   *
   * @param client
   *
   * @returns {boolean}
   */
  subscriptionIsBypass(client: Client): boolean {
    return client.always_allow_access;
  }

  /**
   * A subscription is considered "live" if its status falls under
   * the following values:
   * 1. in_trial
   * 2. active
   * 3. non_renewing
   *
   * This means that if the client's subscription is "live, they have
   * access to Fieldmagic Cloud.
   *
   * @param {Client} client
   *
   * @returns {Boolean}
   */
  subscriptionIsLive(client: Client): boolean {
    return this.subscriptionIsInTrial(client)
      || this.subscriptionIsActive(client)
      || this.subscriptionIsNotRenewing(client)
      || this.subscriptionIsBypass(client);
  }

  /**
   * Returns an error message based on the subscription
   * status given. It is also assumed that the status
   * is considered to fall under the category of "not live"
   * meaning, the subscription is not running.
   *
   * If the given status is not recognized, a generic
   * subscription-error message will be returned.
   *
   * @param {String} subscriptionStatus
   *
   * @returns {String}
   */
  getSubscriptionNotLiveErrorMessage(subscriptionStatus: string): string {
    let subscriptionStatusErrorMessage;

    switch (subscriptionStatus) {
      case 'future':
      case 'paused':
      case 'cancelled':
        subscriptionStatusErrorMessage = this.translateService.instant(`subscription_status_${subscriptionStatus}_error`);
        break;
      default:
        // The only time we'll be taken here is that if the subscription
        // metadata of the active client is null. e.g., null subscription
        // id, plan id, status, etc.
        subscriptionStatusErrorMessage = this.translateService.instant('there_was_an_error_with_your_subscription');
        break;
    }

    return subscriptionStatusErrorMessage;
  };

  /**
   * Copies specific subscription info onto the client's attributes to make
   * a new client object.
   *
   * @param subscriptionInfo
   * @param client
   */
  associateSubscriptionMetadataToClient(subscriptionInfo: SubscriptionInfo, client: Client): Client {
    client.subscription_id = subscriptionInfo.id;
    client.subscription_plan_id = subscriptionInfo.plan_id;
    client.subscription_status = subscriptionInfo.status;
    client.subscription_trial_end = `${subscriptionInfo.trial_end}`;
    client.subscription_trial_start = `${subscriptionInfo.trial_start}`;

    return client;
  }

  /**
   * Updates the client record's subscription information with the latest data from
   * Chargebee then propagates the changes throughout the application.
   *
   * @param {Client} client
   *
   * @returns {void}
   */
  syncSubscriptionInfoToClient(client: Client): void {
    this.downloadAndStoreSubInfo(client.client_id)
      .subscribe((response) => {
        let updatedClient = this.associateSubscriptionMetadataToClient(
          response.subscription, client
        );

        let updatedClientList = this.clientsListStoreService
          .associateClientMetadataToClientList(
            updatedClient,
            this.clientsListStoreService.getClientList()
          );

        // After updating the client metadata, we must also update the clients list.
        // This is just in case the user has updated their subscription from the
        // clients list, it will automatically be updated without having to refresh
        // the page.
        // Update the active client information as well
        this.clientsListStoreService.setClientList(updatedClientList);
        this.clientStoreService.setActiveClient(updatedClient);
      });
  }

  /**
   * If a change in the active client's subscription is made, we must also
   * update the subscription info attributes tied to our active client's
   * metadata to avoid discrepancies.
   *
   * @returns {void}
   */
  private preserveSubscriptionDetailsOfActiveClient(): void {
    this.activeClientSubscriptionInfo$
      .pipe(
        filter(subscriptionInfo => {
          let activeClient = this.clientStoreService.getActiveClient();
          let subscriptionAttributes = Object.keys(subscriptionInfo);
          let changedClientSubscriptionAttributes = subscriptionAttributes.filter(attr => {
            let activeClientAttr = activeClient[`subscription_${attr}`];

            return activeClientAttr !== undefined && activeClientAttr !== subscriptionInfo[attr];
          });

          return changedClientSubscriptionAttributes.length > 0;
        })
      )
      .subscribe((subscriptionInfo) => {
        this.clientStoreService.setActiveClient(
          this.associateSubscriptionMetadataToClient(subscriptionInfo, this.clientStoreService.getActiveClient()));
      });
  }

  /**
   * Returns an object containing a series of callbacks that Chargebee triggers on specific
   * events that happen inside a portal.
   *
   * @returns {ChargebeePortalCallbacks}
   */
  private getPortalCallbacks(): ChargebeePortalCallbacks {
    return {
      loaded: () => {
        this.isPortalDisplayed.next(true);
      },
      close: () => {
        this.isPortalDisplayed.next(false);
      },
      subscriptionCancelled: (data) => {
        forkJoin(
          this.whenPortalClosed$.pipe(
            // Skip the default value emission so that we don't trigger
            // the subscribe callback because by default, whenPortalClosed$
            // will emit false.
            skip(1),
            first()
          ),
          this.subscriptionCancelled(this.clientStoreService.getActiveClient().client_id).pipe(first())
        )
          .subscribe(([portalClosed, client]) => {
            this.clientStoreService.setActiveClient(client);
          });
      }
    };
  }

  /**
   * Initializes Chargebee via Chargebee.init
   *
   * @returns {any} - Returning any since there's no types available for Chargebee
   */
  private initChargebee(): any {
    this._chargebeeInstance = Chargebee.init({
      site: environment.chargebee.site,
      publishableKey: environment.chargebee.publishableKey
    });

    // Set our listeners for every lifecycle event happening in Chargebee's self
    // -serve portal.
    this._chargebeeInstance.setPortalCallbacks(this.getPortalCallbacks());
    this.isChargebeeInitialized = true;

    return this._chargebeeInstance;
  }

  /**
   * Request to purchase add on under an existing
   * subscription.
   *
   * @param {string} subscription_id
   * @param {string} addon_id
   * @param {number} quantity
   *
   * @returns {Observable<string>}
   */
  purchaseAddOn(
    subscription_id: string,
    addon_id: string,
    quantity: number
  ): Observable<string> {

    let body = new URLSearchParams();
    body.append('subscription_id', subscription_id);
    body.append('addon_id', addon_id);
    body.append('quantity', quantity.toString());

    return this.http.post<string>(`${environment.url}/subscription_billing/purchase_addon`, body.toString(), { observe: 'response' })
      .pipe(
        map(response => response.body)
      );
  }

  /**
   * retrieve the plan list
   */
  getPlanList(client_id: string = null) {
    let body = new URLSearchParams();
    if (client_id) {

        body.append('client_id', client_id);
    }
    return this.http.post<string>(`${environment.url}/subscription_billing/plan`, body.toString(), { observe: 'response' })
      .pipe(
        map(response => response.body['data'])
      );
  }

  /**
   * checkout charge
   *
   * @param subscriptinId
   * @param itemId
   * @param quantity
   */
  checkoutCharge(subscriptinId: string, itemId: string, quantity: number) {

    let body = new URLSearchParams();
    body.append('subscription_id', subscriptinId);
    body.append('item_id', itemId);
    body.append('quantity', quantity.toString());

    return this.http.post<string>(`${environment.url}/subscription_billing/checkout_charge`, body.toString(), { observe: 'response' })
      .pipe(
        map(response => response.body)
      );

  }

  /**
   * Retrieve the url of the hosted page
   * of the manage payment.
   *
   * @return {Observable<string>}
   */
   changePayment(strClientId: string): Observable<string> {
    return this.http.post<string>(`${environment.url}/subscription_billing/change_payment`, new URLSearchParams({
      client_id: strClientId
    }).toString(), { observe: 'response' })
    .pipe(
      map(response => {
        if (response.body['data']['url']) {
          return response.body['data']['url'];
        }
      })
    );
  }

  /**
   * retrieve billing history list
   *
   * @param strClientId
   */
  billingHistory(strClientId: string) {
    return this.http.post(`${environment.url}/subscription_billing/billing_history`, new URLSearchParams({
      client_id: strClientId
    }).toString(), { observe: 'response' })
    .pipe(
      map(response => response.body['data'])
    );
  }

  /**
   * get invoice download link
   *
   * @param strClientId
   */
  downloadInvoice(strClientId: string, strInvoiceId: string) {
    return this.http.post(`${environment.url}/subscription_billing/download_invoice`, new URLSearchParams({
      client_id: strClientId,
      invoice_id: strInvoiceId
    }).toString(), { observe: 'response' })
    .pipe(
      map(response => response.body['data'])
    );
  }
}
