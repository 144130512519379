<header>
  <div class="d-flex dialog-header">
    <div class="form-group">
      <div class="row ml-0">
        <div class="row col-md-6">
          <input
            matTooltip="{{ 'address' | translate }}"
            class="form-control"
            id="searchAddressInput"
            type="text"
            [(ngModel)]="address"
          />
        </div>

        <div class="row col-md-2 ml-0">
          <input
            matTooltip="{{ 'latitude' | translate }}"
            placeholder="{{ 'latitude' | translate }}"
            class="form-control"
            id="latInput"
            type="text"
            [(ngModel)]="markerLatitude"
            (ngModelChange)="geolocationChange($event, 'lat')"
          />
        </div>
        <div class="row col-md-2 ml-0">
          <input
            matTooltip="{{ 'longitude' | translate }}"
            placeholder="{{ 'longitude' | translate }}"
            class="form-control"
            id="lngInput"
            type="text"
            [(ngModel)]="markerLongitude"
            (ngModelChange)="geolocationChange($event, 'lng')"
          />
        </div>

        <div class="row col-md-3 pl-0 d-flex d-flex-gap align-items-center justify-content-end">
          <button
            *ngIf="dialogData.mode !== 'view_only'"
            id="btnSave"
            type="button"
            class="btn btn-primary"
            (click)="saveLocation()"
          >
            <fa-icon [icon]="['fas', 'save']"></fa-icon>
            <span class="pl-1">{{ "save" | translate }}</span>
          </button>
          <fieldmagic-primary-button
            id="btnClose"
            (click)="closeDialog()"
            icon="times"
            label="close"
            variant="error"
          ></fieldmagic-primary-button>
        </div>
      </div>
    </div>
  </div>
</header>

<hr class="separator" />

<agm-map
  [latitude]="lat"
  [longitude]="lng"
  [zoom]="zoom"
  (mapClick)="onMapClick($event)"
>
  <agm-marker
    [latitude]="markerLatitude"
    [longitude]="markerLongitude"
    [markerDraggable]="true"
    (dragEnd)="onMarkerDragEnd($event)"
  >
  </agm-marker>
</agm-map>
