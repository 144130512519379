import { EditItemCodesComponent } from '../../../../admin/items/edit/item-codes.component';
import { RelatedProductsComponent } from '../../../../admin/items/edit/related-products.component';
import { EditReorderConfigComponent } from '../../../../admin/items/edit/reorder-config.component';
import { WarehouseStockLevelsComponent } from '../../../../admin/warehouse/edit/warehouse-stock-levels/warehouse-stock-levels.component';

/**
 * Contains the appropriate component instance for each custom selector that represents the custom field.
 * Each component should be registered in client/src/app/shared/modules/edit.module.ts
 *
 * You should register the component in the following keys
 * - entryComponents - this is used to dynamically resolve components using the ComponentResolverFactory class
 * - declarations - the component should be part of the edit module
 */
export const CUSTOM_SELECTORS = {
  item_codes: EditItemCodesComponent,
  reorder_config: EditReorderConfigComponent,
  related_products: RelatedProductsComponent,
  warehouse_stock_levels: WarehouseStockLevelsComponent
};
