<fieldmagic-dialog>
  <div class="dialog-header-content">
    <fieldmagic-dialog-title
      icon="edit"
      label="save_template_name"
    ></fieldmagic-dialog-title>
  </div>
  <div class="dialog-header-buttons">
    <fieldmagic-primary-button
      (click)="saveTemplate()"
      icon="save"
      label="save"
    ></fieldmagic-primary-button>
    <fieldmagic-primary-button
      (click)="cancel()"
      variant="error"
      icon="times"
      label="close"
    ></fieldmagic-primary-button>
  </div>
  <div class="dialog-content">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-xs-12 form-group">
        <label class="template-name-label">
          <required-tag>{{ 'template_name' | translate }}</required-tag>
        </label>
        <input class="form-control w-100 font-size-12" id="inputTemplateName" [(ngModel)]="strTemplateName"/>
      </div>
    </div>
  </div>
</fieldmagic-dialog>
