<ng-select
  [items]="objTagRelate.source | async"
  [id]="item.key"
  [loading]="objTagRelate.loader"
  [typeahead]="objTagRelate.typehead"
  [hideSelected]="true"
  (open)="objTagRelate.loadDataOnClick()"
  [(ngModel)]="objTagRelate.value"
  (change)="setValue()"
  class="fm-tags"
  multiple="true"
  bindLabel="name">

  <ng-template ng-label-tmp let-item="item" let-clear="clear" class="test">
    <div class="ng-select-tag-option" [style.background-color]="item.color">
      {{ item.name }}
      <span (click)="clear(item)" aria-hidden="true" class="m-1">×</span>
    </div>

  </ng-template>

</ng-select>