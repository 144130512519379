import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as echarts from 'echarts';
import { ECharts } from 'echarts';
import { EChartOption } from 'echarts';

@Directive({
  selector: '[echarts]'
})
export class EchartsDirective implements OnInit, OnChanges, OnDestroy {
  @Input() options!: EChartOption; // Options for the ECharts instance
  @Output() chartInit = new EventEmitter<ECharts>();
  private chartInstance: ECharts;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    // Initialize the chart when the directive is created
    this.initChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Update the chart when input changes
    if (changes['options'] && !changes['options'].firstChange) {
      this.updateChart();
    }
  }

  ngOnDestroy(): void {
    // Dispose of the chart instance to prevent memory leaks
    if (this.chartInstance) {
      this.chartInstance.dispose();
    }
  }

  private initChart(): void {
    if (this.el.nativeElement) {
      this.chartInstance = echarts.init(this.el.nativeElement);
      this.updateChart(); // Set initial options

      // Emit the initialized chart instance
      this.chartInit.emit(this.chartInstance);
    } else {
      console.error('Chart container element is not available.');
    }
  }

  private updateChart(): void {
    if (this.chartInstance && this.options) {
      this.chartInstance.setOption(this.options);
    } else {
      console.error('ECharts instance or chart options are missing.');
    }
  }
}
