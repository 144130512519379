import { Component, OnInit, Inject, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BehaviorSubject, Observable, Subject, concat, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, map } from 'rxjs/operators';
import * as moment from 'moment';
import { cloneDeep, get, isEmpty, isNil, isUndefined, round, set, toNumber, toString } from 'lodash';
import { QuoteLineItem } from '../../../../objects/quote-line-item';
import { QuoteLineGroup } from '../../../../objects/quote-line-group';
import { Select } from '../../../../objects/select';
import { QuoteFormat } from '../../../../lists/quote-format';
import { Opportunity } from '../../../../objects/opportunity';
import { StatusCode } from '../../../../lists/status-code';
import { SelectDocumentComponent } from '../select-document/select-document.component';
import { ViewStockLevelsComponent } from '../view-stock-levels/view-stock-levels.component';
import { NameQuoteComponent } from '../name-quote/name-quote.component';
import { DocumentService } from '../../../../services/document.service';
import { FormService } from '../../../../services/form.service';
import { LambdaService } from '../../../../services/lambda.service';
import { RecordService } from '../../../../services/record.service';
import { FileService } from '../../../../services/file/file.service';
import { NotificationService } from '../../../../services/notification.service';
import { NumberService } from '../../../../services/helpers/number.service';
import { TaxCode } from '../../../../objects/tax-code';
import { FormTemplateService } from '../../../form-templates/shared/services/form-template.service';
import { SelectTemplate } from '../../../../objects/select-template';
import { ArrService } from '../../../../services/helpers/arr.service';
import { Relate } from '../../../../objects/relate';
import { ClientStoreService } from '../../../../services/client-store.service';
import { LooseObject } from '../../../../objects/loose-object';
import { RelatedProductsComponent } from '../../../../features/product-folders/static-folders/related-products/related-products.component';
import { toFormattedNumber } from '../../../../shared/utils/numbers';
import { blank, filled, whenFilled } from '../../../../shared/utils/common';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { sprintf } from 'sprintf-js';
import { MODULE_RELATE_TEXT_FIELDS } from '../../../form-templates/shared/constants';
import { SetUnsavedChangesData } from '../../../../objects/auto-save';
import { ContextMenuComponent } from '../../../../shared/components/context-menu/context-menu.component';
import { ContextMenuService } from '../../../../services/context-menu.service';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-add-quote',
  templateUrl: './add-quote.component.html',
  styleUrls: ['./add-quote.component.scss']
})

export class AddQuoteComponent implements OnInit {

  @ViewChild('quotename') nameInput: ElementRef;
  @ViewChild(ContextMenuComponent) contextMenuComponent: ContextMenuComponent;

  public quoteForm: FormGroup;
  public isFileDropped: boolean = false;
  public isFileUploaded: boolean = false;
  public bProductLoading: boolean = false;
  public bSubmitted: boolean = false;
  public bShowLoader: boolean = false;
  public bDialogLoaded: boolean = false;
  public isTouched: boolean = false;
  public numTotal = 0;
  public numTax = 0;
  public numPrevious = -1;
  public arFiles : any = [];
  public arTemporaryDocument : any = [];
  public arIncludedDocuments : any = [];
  public arDocumentList : any = [
    { id: "1", document_name: "quote_content", is_included: true}
  ];
  public arTaxCodeFilter: any = { is_sales: true };
  public arLineItems: (QuoteLineItem | QuoteLineGroup)[] = [];
  public strDragStart = "";
  public strViewType = "";
  public strQuoteNumber = "";
  public arFormatList = new QuoteFormat;
  public isTemplate = false;
  public editText = false;
  public arPricebookItems = [];
  public objPricebook = {};
  public arRelateRecord: any = [];
  public arTaxCodes: TaxCode[] = [];
  public arDepartments: Select[] = [];
  public strCustomerId = '';
  public bHideTemplateButton = null;
  public strPricebookId = null;
  public bPreviewPDF: boolean = false;
  public objParentOpportunity: Opportunity
  public arAttributesWithPricebook = [];
  public strModule = 'quotes';
  public strLastSelectedTemplateId: string = null;
  public arContactRelateDefaultOption: Array<object> = [];
  public increasedProfit = true;
  public strDocumentType:string = 'quotation';
  public isAutoSave: boolean = false;
  public isFromUnsavedChanges: boolean = false;
  public autoSaveIntervalId;
  public relateChanges: LooseObject = {};
  public relateFields: Array<string> = ['customer_id', 'contact_id', 'pricebook_id'];
  public strRecordId: string = '';
  public selectedLineItems: Array<LooseObject> = [];

  public previewTemplate: Subject<SelectTemplate> = new Subject<SelectTemplate>();
  /**
   * The last focused line, this is were we will insert new lines.
   *
   * @var {number}
   */
  public numLastFocusedIndex: number = 0;

  whenInProgress$ = new BehaviorSubject<boolean>(false);

  /**
   * Wysiwig Config
   */
  quillConfig = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'bullet' }],
    ]
  }

  /**
   * Fields that use centralize edit.
   */
  public objQuotesField = {
    quote_summary: {
      key: 'quote_summary',
      type: 'textarea',
      label: 'quote_summary',
      required: true,
      max_length: 10000,
    },
    expiry_date: {
      key: 'expiry_date',
      type: 'date',
      label: 'expiry_date',
      required: false,
      clearable: false,
      default_value: null
    },
    quote_date: {
      key: 'quote_date',
      type: 'date',
      label: 'quote_date',
      required: false,
      default_value: null
    },
    format: {
      key: 'format',
      type: 'dropdown',
      options: [],
      label: 'select_format',
      required: false,
      default_value: 'show_detail_pricing'
    },
  }

  /**
   * Custom ng-select fields.
   */
    public arNgSelectFields = {
      contacts: {
        obv: new Observable<Select[]>(),
        typehead: new Subject<string>(),
        loader: false,
        placeholder: 'select_contact',
        name: 'contact_id',
        module: 'contacts',
        value: null,
      },
      customers: {
        obv: new Observable<Select[]>(),
        typehead: new Subject<string>(),
        loader: false,
        placeholder: 'select_customer',
        name: 'customer_id',
        module: 'customers',
        value: null,
      },
      pricebooks: {
        obv: new Observable<Select[]>(),
        typehead: new Subject<string>(),
        loader: false,
        placeholder: 'select_pricebook',
        name: 'pricebook_id',
        module: 'pricebooks',
        value: null,
      }
  };

  public objRelateFieldData: {} | [] = {};

  /**
   * A flag if the user hits save and preview.
   *
   * @var {LooseObject | boolean)
   */
  public bSaveAndPreview: boolean = false;

  public realTimePreviewTemplate: Subject<SelectTemplate> = new Subject<SelectTemplate>();

  /**
   * Counts the number of QuoteLineItem instances
   * in the arLineItems list because the arLineItems may
   * contain a QuoteGroupItem instance.
   */
  get countLineItems(): number {
    return this.arLineItems.filter(item => (item instanceof QuoteLineItem)).length;
  }

  /**
   * Computes the total of the line items
   * without the tax.
   */
  get totalWithoutTax(): number {

    let numTotal = 0;

    this.arLineItems.filter(item => (item instanceof QuoteLineItem)).forEach(
      (objLineItem: QuoteLineItem) => {
        numTotal = numTotal + toFormattedNumber(objLineItem.line_item, {
          currency: true,
        });
      }
    );

    return toFormattedNumber(numTotal, {
      currency: true,
    });
  }

  /**
   * Computes only the tax of all the
   * line items.
   */
  get totalTax(): number {

    let numTotal = 0;

    this.arLineItems.filter(item => (item instanceof QuoteLineItem)).forEach(
      (objLineItem: QuoteLineItem) => {
        numTotal +=  (objLineItem.tax_rate / 100) * toFormattedNumber(objLineItem.line_item, {
          currency: true,
        });
      }
    );

    return toFormattedNumber(numTotal, {
      currency: true,
    });
  }

  /**
   * Computes the total of the line items
   * with the tax.
   */
  get totalWithTax(): number {
    let numTotal: number = 0;

    numTotal = this.totalTax + this.totalWithoutTax;

    return toFormattedNumber(numTotal, {
      currency: true,
    });
  }

  /**
   * This will add all line items total
   * except for those cost is 0
   */
  get computeTotal(): number {

    let numTotal = 0;

    this.arLineItems.filter(item => (item instanceof QuoteLineItem)).forEach(
      (objLineItem: QuoteLineItem) => {
        numTotal = numTotal + toFormattedNumber(objLineItem.line_item, {
          currency: true,
        });
      }
    );

    return toFormattedNumber(numTotal, {
      currency: true,
    });
  }

  /**
   * Compute total profit
   */
  get totalProfit(): number {
    let profit = this.computeTotal - this.totalCost;

    return toFormattedNumber(profit, {
      currency: true,
    });
  }

  /**
   * This will add all line items cost
   */
  get totalCost(): number {
    let numTotal: number = 0;

    this.arLineItems.filter(item => (item instanceof QuoteLineItem)).forEach(
      (objLineItem: QuoteLineItem) => {
        let currentTotal = 0;
        let unitCost: number = objLineItem.unit_cost;
        let hourlyCost: number = objLineItem.hourly_cost;

        if (objLineItem.labor === false) {
          currentTotal = (objLineItem.quantity * unitCost);
        } else {
          currentTotal = (objLineItem.quantity * hourlyCost);
        }

        numTotal = numTotal + toFormattedNumber(currentTotal, {
          currency: true,
        });
      }
    );

    return toFormattedNumber(numTotal, {
      currency: true,
    });
  }

  /**
   * Compute profit as percentage
   */
  get profitPercentage() {
    let totalProfitPercentage = (this.totalProfit / this.totalCost) * 100;
    return !isNaN(totalProfitPercentage) && isFinite(totalProfitPercentage) ? toFormattedNumber(totalProfitPercentage) : 0;
  }

  public bFormDirty: boolean = false;

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  /**
   * Instead of the former per line product relate, we reverted into
   * a single product search which they can add to the list.
   *
   * @var {Relate<any>}
   */
  public objProductRelate = new Relate<any>();

  /**
   * Flag for department tracking.
   *
   * @var {boolean}
   */
  public bDepartmentTracking: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddQuoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public formService: FormService,
    public fileService: FileService,
    public recordService: RecordService,
    public notifService: NotificationService,
    public lambdaService: LambdaService,
    public documentService: DocumentService,
    public numberService: NumberService,
    protected service: FormTemplateService,
    public arrService: ArrService,
    public client: ClientStoreService,
    public localStorageService: LocalStorageService,
    public contextMenuService: ContextMenuService,
  ) {
    this.strViewType = this.data['view_type'];
    this.bDepartmentTracking = this.client.isDepartmentTracking();

    this.recordService.getRecordBasedOnParent(!isNil(this.data['record_id'])).subscribe( response => {
      // Set the customer id from the opp.
      if (response.record_details) {
        this.objParentOpportunity = new Opportunity(response.record_details);
        this.strCustomerId = this.objParentOpportunity.getCustomerId();
        this.strRecordId = this.objParentOpportunity.getId();
      }

      // Check if its a template or a real quote.
      if (this.data['template'] === true) {
        this.strModule = this.getModuleFromFlag(this.data['template']);
        this.isTemplate = true;
      }

      this.initContactRelateData();

      /**
       * If the quote was from a convert lead process.
       */
      if (this.strViewType == 'edit' && this.data['quote_id'] != undefined && this.data['quote_id'] != '') {

        this.recordService.getRecord(this.strModule, this.data['quote_id'], true).subscribe( response => {
          this.data['default_config'] = response['related_data'];
          this.data['quote'] = response['record_details'];

          let dataChanges = this.formService.getUnsavedChangesData(get(this.data, ['quote_id'], ''), 'quote_version', this.data['quote'],  this.strRecordId);

          if (dataChanges.length > 0) {
            this.notifService.sendConfirmation('do_you_want_to_apply_unsaved_changes', 'unsaved_changes')
            .subscribe((confirmation) => {
              if (confirmation.answer) {
                this.isFromUnsavedChanges = true;

                dataChanges.forEach(data => {
                  let path = data['path'];
                  let value = data['value1'];
                  set(this.data['quote'], path, value);
                });

                this.initializeComponent();
                this.bDialogLoaded = true;

                setTimeout(() => {
                  Object.keys(this.arNgSelectFields).forEach(moduleField => {
                    this.triggerSubject(this.arNgSelectFields[moduleField]['typehead']);
                  });
                }, 500);
              } else {
                this.initializeComponent();
                this.formService.removeUnsavedChangesDataToLocalStorage(get(this.data, ['quote', 'id'], ''), 'quote_version', this.strRecordId);
                this.bDialogLoaded = true;
              }
            });
          } else {
            this.initializeComponent();
            this.bDialogLoaded = true;
          }
        });

      } else {
        this.data['quote'] = {};
        this.recordService.getRecordConfig('quotes', {}, true, {customer_id: this.strCustomerId}).subscribe(response => {
          this.data['default_config'] = response['related_data'];
          let dataChanges = this.formService.getUnsavedChangesData(get(this.data, ['quote_id'], ''), 'quote_version', this.data['quote'], this.strRecordId);

          if (dataChanges.length > 0) {
            this.notifService.sendConfirmation('do_you_want_to_apply_unsaved_changes', 'unsaved_changes')
            .subscribe((confirmation) => {
              if (confirmation.answer) {
                this.isFromUnsavedChanges = true;
                dataChanges.forEach(data => {
                  let path = data['path'];
                  let value = data['value1'];
                  set(this.data['quote'], path, value);
                });

                this.initializeComponent();
                this.bDialogLoaded = true;

                setTimeout(() => {
                  Object.keys(this.arNgSelectFields).forEach(moduleField => {
                    this.triggerSubject(this.arNgSelectFields[moduleField]['typehead']);
                  });
                }, 500);
              } else {
                this.initializeComponent();
                this.formService.removeUnsavedChangesDataToLocalStorage(get(this.data, ['quote', 'id'], ''), 'quote_version', this.strRecordId);
                this.bDialogLoaded = true;
              }
            });
          } else {
            this.initializeComponent();
            this.bDialogLoaded = true;
          }
        });
      }

      dialogRef.backdropClick().subscribe(_ => {
        this.cancelDialog();
      });

      this.objProductRelate.buildRelates(
        switchMap(term => this.recordService.getRecordRelate('items', term, '', false))
      );

    });
  }

  /**
   * Identify which module uses this component.
   *
   * @param {boolean} bTemplate - flag to know if template or not.
   *
   * @return {string} - can be quotes or quote_templates.
   */
  getModuleFromFlag(bTemplate: boolean): string {
    if (bTemplate) {
      return 'quote_templates';
    } else {
      return 'quotes';
    }
  }

  /**
   * Sets the value of the ng select field.
   *
   * @param {string} - string but only the values indicate on the types.
   * @param {string} - the value of the ng-select which is the id.
   */
  setNgSelectValues(strModules: "contacts" | "pricebooks" | "templates" | "customers" , strId: string): void {
    this.arNgSelectFields[strModules]['value'] = strId;
    if (strModules == "templates") {
      this.strLastSelectedTemplateId = strId;
    }
  }

  /**
   * Sets the default dropdown values of the ng select.
   *
   * @param {string} - string but only the values indicate on the types.
   * @param {Select} - default value of the dropdown with id & text.
   */
  setNgSelectDropdownValues(strModules: "contacts" | "pricebooks" | "templates", objSelect: Select): void {
    this.objRelateFieldData[strModules] = whenFilled(objSelect, {
      then: () => [objSelect],
      else: () => [],
    });
  }

  ngOnInit() {}

  initializeComponent() {

    let quoteForm = {
      cover_letter: new FormControl(''),
    };

    // Set initial values if from a converted lead.
    if (this.data['case'] != undefined && this.data['case'] == 'from_convert') {

      if (this.objParentOpportunity) {
        this.setNgSelectValues('contacts', this.objParentOpportunity.getContactId());
        this.setNgSelectDropdownValues('contacts', new Select(this.objParentOpportunity.getContactId(), this.objParentOpportunity.getContactText()));
      }

      this.objQuotesField.format.default_value = 'show_detail_pricing';
    }

    if (this.data['options']['format'] != undefined) {
      // Set the dropdown options.
      this.objQuotesField['format']['options'] = this.data['options']['format']['config'];
    }

    // Set the list of tax codes.
    this.arTaxCodes = this.data['default_config']['tax_codes'].map(tax_code => {return new TaxCode(tax_code)});
    this.arDepartments = this.data['default_config']['departments'].map(department => {return new Select(department.id, department.text)});

    if (this.data['template'] != undefined && this.data['template']) {
      quoteForm['quote_name'] = new FormControl(this.data['quote']['quote_name'], Validators.maxLength(100));
    } else {

      this.arNgSelectFields['templates'] = {
        obv: new Observable<Select[]>(),
        typehead: new Subject<string>(),
        loader: false,
        placeholder: 'select_template',
        name: 'template',
        module: 'quote_templates',
        value: null,
      };
    }

    // Set date values if edit, use default values if add.
    if (this.strViewType === 'edit') {
      this.objQuotesField['expiry_date']['default_value'] =  moment(this.data['quote']['expiry_date']).format('YYYY-MM-DD');
      this.objQuotesField['quote_date']['default_value'] =  moment(this.data['quote']['quote_date']).format('YYYY-MM-DD');
      this.triggerAutoSave();
    } else {

      if (this.data['default_config']['pricebook'] != undefined && this.data['default_config']['pricebook']['id'] != undefined) {
        this.setNgSelectValues('pricebooks', this.data['default_config']['pricebook']['id']);
        this.strPricebookId = this.arNgSelectFields['pricebooks']['value'];
        this.arAttributesWithPricebook = [];
        this.setNgSelectDropdownValues('pricebooks', new Select(this.data['default_config']['pricebook']['id'], this.data['default_config']['pricebook']['name']));
      }

      this.objQuotesField['expiry_date']['default_value'] = moment().add(30, 'days').format('YYYY-MM-DD');
      this.objQuotesField['quote_date']['default_value'] = moment().format('YYYY-MM-DD');

      if (this.objParentOpportunity.customer) {
        this.setNgSelectValues('customers', this.objParentOpportunity.customer.customer_id || null);
        this.objRelateFieldData["customers"] = [ new Select(this.objParentOpportunity.customer.customer_id, this.objParentOpportunity.customer.customer_text)];
        this.relateChanges['customer_id'] = this.objParentOpportunity.customer.customer_text;
      }
    }

    // Rebuild the object to fit with centralized edit requirements.
    Object.keys(this.objQuotesField).forEach(field => {
      this.objQuotesField[field] = this.formService.tranformFieldObject(this.objQuotesField[field]);
      quoteForm[field] = this.formService.toFormControl(this.objQuotesField[field]);
    });

    // Initialize form control fields.
    this.quoteForm = new FormGroup(quoteForm);

    // FC-2672: When creating quote use the value of opportunity summary as quote summary
    if (this.strViewType == 'add' && this.objParentOpportunity && this.objParentOpportunity.summary) {
      this.quoteForm.controls['quote_summary'].setValue(this.objParentOpportunity.summary);
    }
    // Set the quote.
    this.setQuote(this.data);
    this.triggerAutoSave();
  }

  /**
   * When the value of the custom ng-select fields have changed.
   * @param event - the base object of the field.
   * @param field - the selected dropdown option
   */
  onValueChange(event, field) {

    // Get the pricebook id.
    if (field['name'] == 'pricebook_id') {
      this.strPricebookId = field['value'];
      this.arAttributesWithPricebook = [];
    }

    // FC-1879: Added removing of template
    // Check if template is changed
    if (field['name'] == 'template') {
      // Check if template has value
      if (field['value']) {
        // event should be greater than 2
        if (Object.keys(event).length > 2) {
          // Send a confirmation dialog to the user if they want to use the template selected.
          this.notifService.sendConfirmation('use_quote_template_confirm', 'use_quote_template_header')
            .subscribe(confirmation => {
              if (confirmation.answer) {
                // If the user clicked yes.
                let objRecord = {};

                // FC-1879: Should retain the current expiry and quote date value
                event.expiry_date = this.quoteForm.controls['expiry_date'].value;
                event.quote_date = this.quoteForm.controls['quote_date'].value;

                if (this.arNgSelectFields['contacts']['value']) {
                  event.contact_id = null;
                }
                // Get the selected item.
                objRecord['quote'] = event;

                // Set the selected item as the quote.
                this.setQuote(objRecord, true);

                // Trigger pricebook when using template.
                this.applyPricebook();
              } else {
                this.setNgSelectValues("templates", this.strLastSelectedTemplateId);
              }
            });
        }
      } else {
        this.setNgSelectValues("templates", null);
      }
    }

    if (get(field, 'name') == 'customer_id') {
      this.setNgSelectDropdownValues('pricebooks', whenFilled(get(event, 'pricebook_id'), {
        then: () => new Select(get(event, 'pricebook_id'), get(event, 'pricebook_text')),
      }));
      this.setNgSelectValues('pricebooks', get(event, 'pricebook_id'));
      this.strPricebookId = get(event, 'pricebook_id');
      this.initializeSelectRelateField('pricebooks');
    }

    let fieldText = get(event, 'text', get(event, MODULE_RELATE_TEXT_FIELDS[field['name']]));

    this.relateChanges[field['name']] = fieldText;
  }

  /**
   * Set the quote.
   * @param data - the quote values.
   * @param is_template - if the quote being set is from a template.
   */
  setQuote(data, is_template = false) {

    // If there is a data to be set.
    if (!isEmpty(data['quote'])) {

      if (!is_template) {
        this.strQuoteNumber = (data['quote']['quote_name']) ? data['quote']['quote_name'] : data['quote']['quote_number'] ;
      }

      // Let's map the arLineItems to use the proper object
      // If it's a group, use QuoteLineGroup
      // If it's an item, use QuoteLineItem
      this.arLineItems = data['quote']['line_items'].map(item => {

        if (item['label'] || item['is_group'] === true) {

          return new QuoteLineGroup(item);

        } else {

          // Initialize the quote line item.
          let objNewLineItem: QuoteLineItem = this.initializeTypeheadAndList(new QuoteLineItem(item).withObservablesAndId());

          // Check for global tax codes.
          if (objNewLineItem.tax_code_id == undefined && this.data['default_config']['default_tax_code_sale'].length !== 0) {

            // Assign the potential values of the global tax codes.
            let objTax = (this.data['default_config']['default_tax_code_sale'][0] != undefined) ? this.data['default_config']['default_tax_code_sale'][0] : {};

            // Assign the tax code if there are any.
            if (objTax['id']) {
              objNewLineItem.updateTaxCode(objTax);
            }
          }

          objNewLineItem.select_line_id = UUID.UUID();

          return objNewLineItem;
        }

      });

      // Set the draggable document array.
      this.arDocumentList = data['quote']['quote_documents'];

      if (data['quote']['expiry_date'] == "" || data['quote']['expiry_date'] == null) {
        data['quote']['expiry_date'] = moment().add(30, 'days').format('YYYY-MM-DD')
      } else {
        data['quote']['expiry_date'] = moment(data['quote']['expiry_date']).format('YYYY-MM-DD');
        this.objQuotesField['expiry_date']['default_value'] = data['quote']['expiry_date'];
      }

      if (data['quote']['quote_date'] == "" || data['quote']['quote_date'] == null) {
        data['quote']['quote_date'] = moment().add(30, 'days').format('YYYY-MM-DD')
      } else {
        data['quote']['quote_date'] = moment(data['quote']['quote_date']).format('YYYY-MM-DD');
        this.objQuotesField['quote_date']['default_value'] = data['quote']['quote_date'];
      }

      // Set the values of the form control.
      this.quoteForm.patchValue({
        cover_letter: data['quote']['cover_letter'],
        quote_summary: data['quote']['quote_summary'],
        format: data['quote']['format'],
        expiry_date: data['quote']['expiry_date'],
        quote_date: data['quote']['quote_date'],
      });

      // Set the value of the contact id manually.
      this.setNgSelectValues('pricebooks', (data['quote']['pricebook_id']) ? data['quote']['pricebook_id'] : null);
      this.strPricebookId = this.arNgSelectFields['pricebooks']['value'];
      this.arAttributesWithPricebook = [];

      if (data['quote']['pricebook_id']) {
        this.objRelateFieldData["pricebooks"] = [ new Select(data['quote']['pricebook_id'], data['quote']['pricebook_name'])];
        this.relateChanges['pricebook_id'] = get(data, ['quote', 'pricebook_name']);
      }

      if ((!is_template) || (is_template && data['quote']['contact_id'])) {
        this.setNgSelectValues('contacts', (data['quote']['contact_id']) ? data['quote']['contact_id'] : null);
        if (data['quote']['contact_id']) {
          this.objRelateFieldData["contacts"] = [ new Select(data['quote']['contact_id'], data['quote']['contact_text'])];
          this.relateChanges['contact_id'] = get(data, ['quote', 'contact_text']);
        }
      }

      if ((!is_template) || (is_template && data['quote']['customer_id'])) {
        this.setNgSelectValues('customers', (data['quote']['customer_id']) ? data['quote']['customer_id'] : null);
        if (data['quote']['customer_id']) {
          this.objRelateFieldData["customers"] = [ new Select(data['quote']['customer_id'], data['quote']['customer_text'])];
          this.relateChanges['customer_id'] = get(data, ['quote', 'customer_text']);
        }
      }

      if (!this.isTemplate) {

        let strIdSource = 'id';
        let strTextSource = 'quote_name';

        if (!is_template) {
          strIdSource = 'template';
          strTextSource = 'template_text';
        }

        this.setNgSelectValues('templates', data['quote'][strIdSource]);
        if (data['quote'][strIdSource] != undefined) {
          this.objRelateFieldData['quote_templates'] = [{ id: data['quote'][strIdSource], text: data['quote'][strTextSource] }];
        }
      }
      // If there are no line items, just add a default single line item.
      if (this.arLineItems.length == 0 && !this.isFromUnsavedChanges) {
        this.addAttribute();
      } else {
        this.getRelatedProducts();
      }
    }

    // Loop through the custom ng-select fields.
    Object.keys(this.arNgSelectFields).forEach( name => {
      this.initializeSelectRelateField(name);
    });

    this.isProfitIncreased();
  }

  /**
   * Set the default value of the observable and
   * update the typehead to use the given API
   * to search for products.
   */
  initializeTypeheadAndList(objQuoteLineItems: QuoteLineItem): QuoteLineItem {

    let arItemDefault = [];

    if (objQuoteLineItems.item_id) {
      arItemDefault.push(new Select(objQuoteLineItems.item_id, objQuoteLineItems.item_name))
    }

    objQuoteLineItems.obv = concat(
      of(arItemDefault),
      objQuoteLineItems.typehead.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => objQuoteLineItems.loader = true),
        switchMap(term => this.recordService.getProductRecordData(term, { active: true }, this.strCustomerId, true, this.strPricebookId)
          .pipe(
            tap(() => objQuoteLineItems.loader = false )
          )
        )
      )
    );

    return objQuoteLineItems;
  }

  /**
   * Adds a new line items to the list
   *
   * @returns {void}
   */
  addAttribute(event: any = null, bIsUnshift = true, lineItemIndex: number = -1): void {

    let objTax = get(this.data, ['default_config', 'default_tax_code_sale'], {});
    let objQuoteLineItems: QuoteLineItem = new QuoteLineItem();
    objQuoteLineItems.select_line_id = UUID.UUID();

    if (event) {
      objQuoteLineItems.updateProduct(event);
    }

    if (filled(event) && event['tax_code_id']) {
      objQuoteLineItems.updateTaxCode({
        id: event['tax_code_id'],
        name: event['tax_code_name'],
        code: event['tax_code'],
        rate: event['tax_rate']
      });
    } else {
      objQuoteLineItems.updateTaxCode(objTax);
    }

    if (this.objParentOpportunity && this.objParentOpportunity.department_id) {
      objQuoteLineItems.department_id = this.objParentOpportunity.department_id
      objQuoteLineItems.department_name = this.objParentOpportunity.department_text
    }

    if (bIsUnshift) {
      this.arLineItems.unshift(objQuoteLineItems);
    } else if(lineItemIndex != -1) {
      this.arLineItems.splice(lineItemIndex, 0, objQuoteLineItems);
    } else {
      this.arLineItems.splice(this.numLastFocusedIndex, 0, objQuoteLineItems);
    }

    this.markAsDirty();

    this.isProfitIncreased();
  }

  /**
   * Adds a group instance in the line items
   *
   * @returns {void}
   */
  addGroup(strName: string = ''): void {
    this.arLineItems.splice(this.numLastFocusedIndex, 0, new QuoteLineGroup({
      label: strName,
      is_group: true
    }));
  }

  /**
   * Removes the line item.
   * @param lineItemIndex - index of the object to be deleted
   */
  removeAttribute(numLineItemIndex: number): void {
    this.markAsDirty();
    this.arLineItems.splice(numLineItemIndex, 1);
  }

  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param lineItemIndex - index of the line item
   */
  selectAProduct(event, numLineItemIndex: number): void {
    (this.arLineItems[numLineItemIndex] as QuoteLineItem).updateProduct(event);
    // FC-2199: If tax_code_id has value and same as the default_tax_code_id which is sales set it as default tax code
    if (event && event.tax_code_id && event.tax_code_id === event.default_tax_code_id) {
      this.selectATaxcode(new TaxCode({
        code: event.tax_code,
        id: event.tax_code_id,
        name: event.tax_code_name,
        rate: event.tax_rate
      }), numLineItemIndex);
    }
    this.isProfitIncreased();
    this.markAsDirty();
  }

  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectATaxcode(event, lineItemIndex) {
    this.markAsDirty();
    (this.arLineItems[lineItemIndex] as QuoteLineItem).updateTaxCode(event);
  }

  /**
   * NOTE: This is a method from Angular CDK itself and is not a custom one.
   *
   * Triggered when an item is being dropped.
   * This is used by the quote line editor.
   * @param event - the item being dragged.
   */
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.arLineItems, event.previousIndex, event.currentIndex);
  }

  /**
   * Close the current dialog.
   */
  cancelDialog() {
    if(this.checkFormGroupDirty()) {
      // Pop-up modal for confirmation
      this.notifService.sendConfirmation('confirm_cancel')
        .filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
          this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Open a dialog where user can save template.
   */
  saveTemplateName(): void {
    this.bSaveAndPreview = false;
    this.setLoadersInitial(true);

    if (!this.checkForErrors()) {
      let nameDialog = this.dialog.open(NameQuoteComponent, {
        width: '640px',
        height: 'auto',
      });

      nameDialog.afterClosed().subscribe( name => {
        if (name) {
          this.saveQuote(true, false, name);
        } else {
          this.setLoaders();
        }
      });
    } else {
      this.setLoaders();
    }
  }

  /**
   * This functions checks if the quote has any errors.
   */
  checkForErrors(): boolean {

    let bErrors: boolean = false;

    // FC-845: value should be trim before checking
    let strQuoteSummaryValue: String = this.quoteForm.controls.quote_summary.value || '';
    if (strQuoteSummaryValue.trim() === '') {
      this.quoteForm.controls.quote_summary.setErrors({ 'incorrect': true });
      this.quoteForm.controls.quote_summary.markAsTouched();
      bErrors = true;
    }

    if (this.arLineItems.length > 0) {
      bErrors = true;
      let bHasLineItem = false;
      this.arLineItems.forEach(data => {
        if (data instanceof QuoteLineItem) {
          bHasLineItem = true;
          bErrors = false;
          if (!data.tax_code_id) {
            bErrors = true;
          }
          if (data.quantity < 0) {
            bErrors = true;
          }
          if (!data.description) {
            bErrors = true;
          }
        }

        if (data instanceof QuoteLineGroup) {
          if (!data.label) {
            bErrors = true;
          }
        }
      });

      if (!bHasLineItem) {
        this.notifService.notifyWarning('quote_no_line');
      }
    } else {
      this.notifService.notifyWarning('quote_no_line');
      bErrors = true;
    }

    return bErrors;
  }

  /**
   * Triggers when saving the quote or documents are being previewed.
   * This caters both add and/or update, if save button is clicked,
   * While returns document when preview button is clicked.
   * @param isTemplate - flag to know if the user wants to save it as a template.
   * @param isPreview - flag to know if the button clicked is preview or save. (defaults to save button)
   */
  saveQuote(isTemplate = false, isPreview = false, name = null) {

    // Check if the user saves from admin section's quote template
    if (! isTemplate && this.strModule === 'quote_templates') {
      isTemplate = true;
    }

    this.setLoadersInitial(isTemplate);

    if (!this.checkForErrors()) {

      let objQuote = this.compileSaveRecordData(isTemplate, isPreview, name);

      // Check if button triggered is not preview
      if (isPreview === false) {
        let bProceed = true;

        if (
            (this.data['quote'] && this.data['quote']['id'] && this.strModule === 'quotes' && isTemplate === true) ||
            (this.data['quote'] === undefined)
          ) {
            this.recordService.saveRecord(this.getModuleFromFlag(isTemplate), objQuote, '').subscribe(response => {
              this.afterResponse(response, bProceed, isTemplate, false);
            });

          } else {
            // Include ID in the body for validation.
            objQuote['id'] = this.data['quote']['id'];
            // Submit to API together with the ID for updating.
            this.recordService.saveRecord(this.getModuleFromFlag(isTemplate), objQuote, this.data['quote']['id']).subscribe(response => {
             this.afterResponse(response, bProceed, false, true);
           });
        }

      } else {
        this.setLoaders();
        // If preview is triggered, get all the document data
        return objQuote;
      }
    } else {
      this.setLoaders();
    }

  }

  /**
   * Set the flags and shows the notification after
   * a response (save or create) was made.
   *
   */
  afterResponse(response: any, bProceed: boolean, isTemplate: boolean, isEdit: boolean): void {
    if (response.status == StatusCode.kResponseSuccess) {
      this.formService.removeUnsavedChangesDataToLocalStorage(get(this.data, ['quote', 'id'], ''), 'quote_version', this.strRecordId);
      this.notifService.notifySuccess('record_update_success');
    }
    if (response.status == StatusCode.kResponseCreated) {
      this.formService.removeUnsavedChangesDataToLocalStorage(get(this.data, ['quote', 'id'], ''), 'quote_version', this.strRecordId);
      if (isTemplate) {
        this.notifService.notifySuccess('quote_template_created');
      } else {
        this.notifService.notifySuccess('record_create_success');
        this.strViewType = 'edit';
        this.data = set(this.data, 'quote', get(response, 'body'));
        this.strQuoteNumber = get(response, 'body.quote_number');
      }
    }
    if (response.status == StatusCode.kResponseAccepted) {
      if (isTemplate) {
        this.notifService.notifyWarning(response.body.error.join(), {
          header: 'not_allowed',
        });
      } else {
        this.notifService.promptError(response.body.error);
      }
      bProceed = false;
    }

    if (bProceed && ! isTemplate) {

      this.bSubmitted = false;
      this.bFormDirty = false;
      this.bShowLoader = false;
      this.bHideTemplateButton = null;
      this.quoteForm.markAsPristine();
      if (this.bSaveAndPreview) {
        if (typeof response.body != 'string') {
          this.data['quote'] = response.body;
          this.initializeComponent();
        }
        this.previewQuoteData();
      }
    } else {
      this.setLoaders();
    }
  }

  /**
   * Initializes all of our flags.
   */
  setLoadersInitial(isTemplate: boolean = false): void {
    this.bSubmitted = true;
    this.bShowLoader = true;
    this.bHideTemplateButton = isTemplate;
    this.isTouched = true;
  }

  /**
   * Marks all of our flags.
   */
  setLoaders(): void {
    this.bShowLoader = false;
    this.bSubmitted = false;
    this.bHideTemplateButton = null;
  }

  /**
   * When a file is dropped for uploading.
   * @param objData - the file
   */
  onFileChange(objData) {
    let reader = new FileReader();
    // If file is valid
    if(objData.target.files && objData.target.files.length) {
      this.isFileDropped = true;
      const [file] = objData.target.files;
      reader.readAsDataURL(file);
      // if file size is less than 30mb
      if(file.size/1024/1024 < 30 && file.type === 'application/pdf') {
        reader.onload = () => {
          this.fileService.upload(file).subscribe((response) => {
            let objFile = this.fileService.objFile;
            this.isFileDropped = false;
            this.isFileUploaded = true;
            this.arFiles = {
              'name': objFile.name,
              'size': objFile.size / 1024,
              'type': objFile.type,
              'upload_name' : response['filename']
            };

            let strItemUUID = response['filename'].split('/')[1]
            this.arTemporaryDocument.push({ id: strItemUUID.substring(0, strItemUUID.indexOf('.')), document: this.arFiles, document_name: objFile.name, record_id: this.data.record_id, 'is_library_document' : false });
            this.arDocumentList.push({ id: strItemUUID.substring(0, strItemUUID.indexOf('.')), document_name: objFile.name, document_size: objFile.size / 1024, upload_name: response['filename'], 'is_library_document' : false });

            // FC-1128 - clear after upload to renable file selection
            objData.target.value = null
          });
        };
      } else {
        // FC-1128 display notification and hide spinner when failure to verify for its filesize and mime
        this.isFileDropped = false;
        if (file.size/1024/1024 > 30) {
          this.notifService.notifyWarning('file_size_limit');
        } else {
          this.notifService.notifyWarning('pdf_only');
        }

        objData.target.value = null
      }
    }
  }

  // Adds a document against an opportunity (All quotes within the opportunity would see the document added)
  addDocument() {

    let addDocumentDialog = this.dialog.open(SelectDocumentComponent, {
      width: '700px',
      height: 'auto',
      data: {}
    });

    // Once the dialog closes, apply the changes from the dialog.
    addDocumentDialog.afterClosed().subscribe(
      dialogResult => {
        if (dialogResult) {

          let objDocument = dialogResult.data;
          // Make sure to check the imported document.
          objDocument['is_included'] = true;
          // Push document to the list of documents in the quote.
          this.arDocumentList.push(objDocument);
          this.markAsDirty();
        }
      }
    );
  }

  // Remove a document in quote document table
  removeDocument(strDocumentId) {

    // Get index of documents to be removed
    let numIndexTempDocument = this.arTemporaryDocument.findIndex(item => (item['id'] == strDocumentId));
    let numDocumentList = this.arDocumentList.findIndex(item => (item['id'] == strDocumentId));

    if (numDocumentList > -1) {
      // Remove existing document from lists
      this.arDocumentList.splice(numDocumentList, 1);
    }

    if (numIndexTempDocument > -1) {
      // Remove the temporary document from the list
      this.arTemporaryDocument.splice(numIndexTempDocument, 1);
    }

  }

  /**
   * NOTE: This is a method from Angular CDK itself and is not a custom one.
   *
   * Triggered when a document is being dropped.
   * This is used by quotes document
   * @param event - the item being dragged.
   */
  dropDocument(event: CdkDragDrop<string[]>) {
      moveItemInArray(this.arDocumentList, event.previousIndex, event.currentIndex);
  }

  /**
   * Get value of checked items
   *
   * @param event - Checked item
   */
  updateCheckedOptions(option, event){
    if (event.target.checked) {
      this.arIncludedDocuments.push(option);
    } else {
      let i = this.arIncludedDocuments.indexOf(option);
      this.arIncludedDocuments.splice(i, 1);
    }
  }

  /**
   * Triggers whether name will be plain text or input box.
   * Clicking the name will convert it to an input box.
   *
   * @param isShowing - boolean
   */
  focusInput(isShowing) {
    //If isShowing is true, make title an input box.
    //If its false, just plain text.
    this.editText = isShowing;

    if (isShowing) {
    setTimeout(function() {
      //Focus if an input box.
      this.nameInput.nativeElement.focus();
    }.bind(this), 1);
    }
  }

  initRelateData(field, index) {
    // Initialize a variable for filters.
    let arFilter: any = false;

    // If the field hasf filter, place them in the arFilter.
    if (field['filter'] != undefined) {
      arFilter = field['filter'];
    }

    // Show the loader.
    this.arNgSelectFields[index]['loader'] = true;

    // Get the initial value if there are any, if none, just get the first 10 from API.
    this.recordService.getRecordRelate(field['module'], '', '', false, arFilter).subscribe(response => {
      this.arRelateRecord[field['module']] = response
      // Hide the laoder.
      this.arNgSelectFields[index]['loader'] = false;

      // Initialize the list observable.
      this.arNgSelectFields[index]['obv'] = concat(
        of(response),
        this.arNgSelectFields[index]['typehead'].pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => this.arNgSelectFields[index]['loader'] = true),
          switchMap(term => this.recordService.getRecordRelate(field['module'], term, '', false, arFilter).pipe(
            tap(() => {
              this.arNgSelectFields[index]['loader'] = false;
            })
          ))
        )
      )
    });
  }

  /**
   * Triggered when preview button is clicked
   *
   * @returns {void}
   */
  previewQuoteData(): void
  {
    // Store quote record, saveQuote contains the updated record in Quote, Cover Letter and Document
    let objQuote = this.saveQuote(false, true);

    // Flag that is being used to disable buttons
    this.bPreviewPDF = true;
    this.bShowLoader = true;

    if (objQuote != undefined) {
      this.previewTemplate.next({
        id: this.data.quote.id,
        module: this.strModule,
        document_type: this.strDocumentType
      });

      this.bHideTemplateButton = null;

      this.bSubmitted = false
      this.bShowLoader = false
      this.bPreviewPDF = false;
    }

    this.bShowLoader = false
    this.bPreviewPDF = false;
  }

  /**
   * Updates the pricebook local list.
   *
   * - Checks all the line items and retrieves the pricebook record of the items.
   * - Compiles all the already fetched pricebook records in a local list.
   */
  applyPricebook(): void {

    if (this.arLineItems.length > 0 && this.strPricebookId != "" && this.strPricebookId != null) {
      // Get all the item_id to be fetched with the new pricebook.
      let arAttributesTemp = [];
      this.arLineItems.forEach(tempItem => {
        if (
          tempItem instanceof QuoteLineItem &&
          tempItem.isUuid(tempItem) &&
          this.arAttributesWithPricebook.findIndex(price => (price['id'] == tempItem.item_id)) == -1) {
          arAttributesTemp.push(tempItem.item_id);
        }
      });

      if (arAttributesTemp.length > 0) {
        this.recordService.getProductRecordData('', { active: true }, this.strCustomerId, true, this.strPricebookId, arAttributesTemp).subscribe(response => {
          this.arAttributesWithPricebook = [...response, ...this.arAttributesWithPricebook];
          this.refreshPricesFromPricebook()
        })
      } else {
        this.refreshPricesFromPricebook();
      }
    }
  }

  /**
   * Changes the discounted prices of all the line items
   * based from the local list of pricebook items.
   */
  refreshPricesFromPricebook(): void {
    this.arLineItems.forEach((items, index) => {
      if (items instanceof QuoteLineItem) {
        let objPricebook = this.arAttributesWithPricebook.find(price => (price['id'] == items.item_id));
        if (objPricebook) {
          if (objPricebook['pricebook_unit_price'] != null) {
            (this.arLineItems[index] as QuoteLineItem).discounted_price = parseFloat(objPricebook['pricebook_unit_price']);
          } else {
            (this.arLineItems[index] as QuoteLineItem).discounted_price = parseFloat(objPricebook['unit_price']);
          }
        } else {
          (this.arLineItems[index] as QuoteLineItem).discounted_price = items.unit_price;
        }
      }
    });
  }

  /**
   * Check the quantity if is greater than 0
   */
  checkQuantity(quantity: number): boolean {
    return (quantity >= 0) ? true : false;
  }

  /**
   * Checks if the variable passed
   * is an instance of QuoteLineItem
   */
  public checkIfGroup(objLineItem: QuoteLineGroup | QuoteLineItem): boolean {
    if (objLineItem instanceof QuoteLineGroup) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * When an ng-select is clicked, trigger a fake search
   * to load the ng-select with default options.
   */
  public triggerSubject(typehead: Subject<string>): void {
    typehead.next("");
  }

  /**
   * When the unit price is changed and the focusout on that
   * input field is triggered.
   *
   * - Copies the unit price to the discounted price
   * if the discounted price is 0.
   */
  public onUnitPriceChange(objQuoteLineItem: QuoteLineItem): void {
    objQuoteLineItem.discounted_price = objQuoteLineItem.unit_price;
  }

  /**
   * Validate the date fields.
   *
   * @param objQuote - object to be passed to API.
   */
  private validateDateField(objQuote): any {

    let arDateFieldsToValidate = ['quote_date', 'expiry_date'];

    arDateFieldsToValidate.forEach(strDateFieldName => {
      if (
        objQuote[strDateFieldName] === undefined ||
        objQuote[strDateFieldName] === 'Invalid date'
      ) {
        objQuote[strDateFieldName] = null;
      }
    });

    return objQuote;
  }

  /*
   * When a department is selected, set a name.
   */
  public setDepartmentName(event: any, objQuoteLineItem: QuoteLineItem): void {
    objQuoteLineItem.department_name = (event) ? this.arDepartments.find(department => (department.id == event)).text : null;
    this.markAsDirty();
  }

  /**
   * Check if the form group is changed
   *
   * @returns {boolean}
   */
  checkFormGroupDirty(): boolean {
    return this.quoteForm.dirty || this.bFormDirty;
  }

  /**
   * Mark as dirty
   *
   * @returns {void}
   */
  markAsDirty(): void {
    this.bFormDirty = true;
  }

  /**
   * Initialize the ng select field
   * Field should be listed in this.arNgSelectFields
   *
   * @param field
   */
  initializeSelectRelateField(field: string) {
    // Initialize a variable for filters.
    let objFilter: object = {};
    // If the field hasf filter, place them in the arFilter.
    if (this.arNgSelectFields[field]['filter'] != undefined) {
      objFilter = this.arNgSelectFields[field]['filter'];
    }
    // Get the initial value if there are any, if none, just get the first 10 from API.
    this.arRelateRecord[this.arNgSelectFields[field]['module']] = this.objRelateFieldData[this.arNgSelectFields[field]['module']];
    // Hide the laoder.
    this.arNgSelectFields[field].loader = false;
    // Initialize the list observable.
    this.arNgSelectFields[field].obv = concat(
      of(this.objRelateFieldData[this.arNgSelectFields[field]['module']]),
      this.arNgSelectFields[field].typehead.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => this.arNgSelectFields[field].loader = true),
        switchMap(term => this.recordService.getRecordRelate(this.arNgSelectFields[field]['module'], term, '', false, objFilter).pipe(
          tap(() => {
            this.arNgSelectFields[field].loader = false;
          }),
          map(data => {
            // FC-1937: If the relate field is contact, term is empty and contact roles are not empty
            if (field === "contacts" && !term && this.arContactRelateDefaultOption.length) {
              return this.arContactRelateDefaultOption;
            } else {
              return data;
            }
          })
        ))
      )
    );
  }

  /**
   * Get multiple relate record with joined
   *
   * @param module - Multiple modules separated by |
   * @param filter - Filter by module
   */
  initContactRelateData(): void {
    // FC-2513: We need to make sure that we have parent opportunity
    if (this.objParentOpportunity) {
      var objFilter = {
        contact_roles: { opportunity_id: this.objParentOpportunity.getId() }
      };
      let strOpportunityCustomer = this.objParentOpportunity.getCustomerId();
      if (strOpportunityCustomer) {
        objFilter.contact_roles['customer_id'] = strOpportunityCustomer;
      }
      let strOpportunitySite = this.objParentOpportunity.getSiteId();
      if (strOpportunitySite) {
        objFilter.contact_roles['site_id'] = strOpportunitySite;
      }
      this.recordService.getMultipleModuleRelateRecord('contact_roles', false, objFilter, false, 10).subscribe( response => {

        var arRelatedData = [];
        // FC-1937: We need to set the fields one by one, to customize the displayed orderby
        if (response["contact_opportunities"]) {
          response["contact_opportunities"].forEach( data => {
            arRelatedData.push(this.createCustomOption(data, "opportunities"));
          });
        }
        if (response["contact_customers"]) {
          response["contact_customers"].forEach( data => {
            arRelatedData.push(this.createCustomOption(data, "customers"));
          });
        }
        if (response["contact_sites"]) {
          response["contact_sites"].forEach( data => {
            arRelatedData.push(this.createCustomOption(data, "sites"));
          });
        }
        this.arContactRelateDefaultOption = arRelatedData;
      });
    }
  }

  /**
   * Create custom option for ng select
   *
   * @param data
   * @param module
   *
   * @returns {object}
   */
  createCustomOption(data, module): object {
    return {
      id: data['contact_id'],
      text: data['contact_text'],
      group_name: module,
      role: this.getPrimaryRole(data['role'])
    };
  }

  /**
   * Send a request to either set or unset
   * a quote as primary.
   *
   * @param {boolean} bIsPrimary
   *
   * @returns {void}
   */
  setAsPrimary(bIsPrimary: boolean): void {
    this.recordService.saveRecord('quotes', { is_primary: bIsPrimary }, this.data['quote_id']).subscribe(response => {
      if (response['status'] == 200) {
        if (bIsPrimary) {
          this.notifService.notifySuccess('successfully_set_as_primary');
        } else {
          this.notifService.notifySuccess('successfully_unset_as_primary');
        }
        this.data['refresh'].next({list: true, view: true});
      }
    });
  }

  /**
   * Action to save quote and preview.
   *
   * @void
   */
   saveAndPreview(): void {
    this.bSaveAndPreview = true;
    this.saveQuote();
  }

  /**
   * Saves the quote data.
   *
   * @returns void
   */
  save(): void {
    this.bSaveAndPreview = false;
    this.saveQuote(false);
  }

  /**
   * Previews the quote data into a pdf format.
   *
   * @returns void
   */
  preview(): void {
    this.bSaveAndPreview = false;
    this.previewQuoteData();
  }

  /**
   * Compute cost of given line item
   *
   * @param attr
   * @returns number
   */
  computeCostPerLineItem(attr): number {
    let cost = (attr['labor']) ? attr['hourly_cost'] : attr['unit_cost'];
    let computedCost = attr['quantity'] * cost;

    return toFormattedNumber(computedCost, {
      currency: true,
    });
  }

  /**
   * Compute profit of given line item
   * @param attr
   * @returns
   */
  computeProfitPerLineItem(attr): number {
    let costPerLineItem = this.computeCostPerLineItem(attr);
    let totalPerLineItem = attr.computeLineItem;

    return toFormattedNumber(totalPerLineItem - costPerLineItem, {
      currency: true,
    });
  }

  /**
   * This will set if the profit is
   * increase or decrease
   */
  isProfitIncreased() {
    setTimeout(() => {
      this.increasedProfit = this.totalProfit >= 0;
    }, 100);
  }

  /**
   * Adjusts the markup when the unit price has changed.
   *
   * @param numIndex
   */
  adjustMarkup(numIndex: number = -1) {
    if (numIndex > -1) {
      (this.arLineItems[numIndex] as QuoteLineItem).computeMarkup();
    }
  }

  /**
   * Expands the target element
   *
   * @param {HTMLTextAreaElement} target
   *
   * @returns {void}
   */
  expandInput(target: HTMLTextAreaElement): void {
    target.classList.add('expanded-input');
  }

  /**
   * Shrinks the target element
   *
   * @param {HTMLTextAreaElement} target
   *
   * @returns {void}
   */
  shrinkInput(target: HTMLTextAreaElement): void {
    target.classList.remove('expanded-input');
  }

  /**
   * Open dialog to view stock levels of the
   * items in the quote.
   *
   * @param {string} strProductId
   * @param {string} strProductName
   * @param {string} strProductCode
   *
   * @returns {void}
   */
   viewStockLevels(strProductId: string, strProductName: string, strProductCode: string): void {
    this.recordService.getMultipleModuleRelateRecord('stock_levels', false, {stock_levels: {item_id: strProductId}}, false)
      .subscribe(response => {
        this.dialog.open(ViewStockLevelsComponent, {
          width: '700px',
          height: 'auto',
          data: {
            product_name: strProductName,
            product_code: strProductCode,
            stock_levels: this.arrService.keyFallsBackTo(response, 'stock_levels', [])
          }
        });
      });
  }

  /**
   * get the primary contact role to display in relate option
   *
   * @param roles
   */
  protected getPrimaryRole(roles): string | null {
    if (roles.length) {

        let primaryRole = roles.find( data => data.primary );

        if (typeof primaryRole == 'string') {
          return roles[primaryRole]['id'];
        } else {
          return roles[0]['id']
        }
    }
    return null;
  }

  /**
   * Add the related product to the list of line items.
   *
   * @param {LooseObject} objRecord
   */
  public addRelated(objRecord: LooseObject, lineItemIndex: number) {

    this.dialog.open(RelatedProductsComponent, {
      width: '70%',
      data: objRecord
    }).afterClosed().subscribe(response => {
      if (response && response.length > 0) {

        let strIds: string[] = [];

        response.forEach(item => {
          strIds.push(item['child_item_id'])
        });

        this.recordService.getProductRecordData(null, null, null, true, null, strIds).subscribe(response => {
          if (response && response.length > 0) {
            response.forEach(item => {
              this.addAttribute(item, false, lineItemIndex + 1);
            });
          }
        });
      }
    });

  }

  /**
   * Get the related products and set the properties to
   * line items.
   */
  public getRelatedProducts(bResetProduct: boolean = false) {

    let strIds: string[] = [];

    this.arLineItems.forEach(item => {
      if (item['item_id']) {
        strIds.push(item['item_id']);
      }
    });

    this.recordService.getProductRecordData(null, null, null, false, null, strIds).subscribe(response => {
      if (response && response.length > 0) {
        response.forEach(item => {
          this.arLineItems.forEach((line, index) => {
            if (line['item_id'] && line['item_id'] == item['id']) {
              this.arLineItems[index]['related_products'] = item['related_products'];
              if (this.arLineItems[index] instanceof QuoteLineItem && bResetProduct) {
                (this.arLineItems[index] as QuoteLineItem).updatePrices(
                  item['labor'] == false ? item['unit_cost'] : item['hourly_cost'],
                  item['unit_price'] || 0,
                  (parseFloat(item['pricebook_unit_price']) > 0) ? item['pricebook_unit_price'] : item['unit_price'],
                );
              }
            }
          });
        });
      }
    });
  }

  /**
   * Compute unit price and total price when markup is change (this is for materials)
   * @param event
   * @param attr
   */
  markupChange(event, index) {
    // Find the index of the line item that was selected.
    if (index > -1) {

      let markUp = this.arLineItems[index]['markup'];
      let unitCost = this.arLineItems[index]['labor'] ? parseFloat(this.arLineItems[index]['hourly_cost']) : parseFloat(this.arLineItems[index]['unit_cost']);
      let unitPrice = (unitCost + ( (parseFloat(markUp) / 100) * unitCost ));

      this.arLineItems[index]['unit_price'] = toString(toFormattedNumber(unitPrice, {
        currency: true,
        maxDecimalPlaces: 4,
      }));
      this.arLineItems[index]['markup'] = toString(toFormattedNumber(markUp));

      this.onUnitPriceChange(this.arLineItems[index] as QuoteLineItem);

    }
    this.markAsDirty();
    this.isProfitIncreased();
  }

  onUnitCostChange(event, index) {
    this.markupChange(event, index);
  }

  /**
   * compile form records
   *
   * @param isTemplate
   * @param isPreview
   *
   * @returns
   */
  compileSaveRecordData(isTemplate = false, isPreview = false, name = null, isFromAutoSave: boolean = false): LooseObject|false {

    // Get the raw value of the form.
    let objQuote = this.quoteForm.getRawValue();
    let bInvalidPrice = false;

    // Add more details to be saved in API.
    objQuote['line_items'] = cloneDeep(this.arLineItems.map(items => {
      if (items instanceof QuoteLineItem) {
        // Note: + sign is the unary + operator that converts to number
        if (+items.discounted_price > +items.unit_price) {
          bInvalidPrice = true;
        }

        return items.forSaving();
      } else {
        return items
      }
    }));

    if (bInvalidPrice && !isFromAutoSave) {
      this.setLoaders();
      this.notifService.sendNotification('warning', 'invalid_discounted_price', 'danger');

      return false;
    }

    objQuote['quote_documents'] = this.arDocumentList;
    this.validateDateField(objQuote);

    // Get more details from the ng-select fields.
    Object.keys(this.arNgSelectFields).forEach(field => {
      objQuote[this.arNgSelectFields[field]['name']] = this.arNgSelectFields[field]['value'];
    });

    if (isTemplate === true) {
      objQuote['quote_name'] = (name) ? name : this.quoteForm.controls['quote_name'].value;
      // When saving the quote as template, make sure the quote doesn't have a template.
      delete objQuote['template'];
    } else {
      objQuote['opportunity_id'] = (this.data.record_id) ? this.data.record_id : this.data['quote']['opportunity_id'];
    }

    // Make sure to clean all key/value pair.
    Object.keys(objQuote).forEach(
      clean => {
        // If the value is a string and is empty, remove it from the objQuote.
        if (typeof objQuote[clean] === 'string' && (objQuote[clean] === "")) {
          delete objQuote[clean];
        }
      }
    );

    // If the temporary document array is not empty.
    if (this.arTemporaryDocument.length) {
      // Pass the list of documents for saving.
      objQuote['temporary_document'] = this.arTemporaryDocument
    }

    return objQuote;
  }
  /**
   * for viewing the current record
   *
   * @returns void
   */
  onPreview(): void {
    let quoteRecord = this.compileSaveRecordData();
    if (quoteRecord !== false) {

      this.whenInProgress$.next(true);
      this.realTimePreviewTemplate.next({
        id: this.data['quote_id'] ? this.data['quote_id'] : null,
        module: 'quotes',
        document_type: 'quotation',
        data: {
          ...quoteRecord,
          ...this.calculatePrice(quoteRecord)
        } as LooseObject
      });
    }
  }

  /**
   * get relate fields module and ids
   *
   * @param objRecord
   * @returns
   */
  getRelateFieldModuleIds(objRecord: LooseObject) {

    let arModule = [];
    let objFilter = {};
    let arRelateFields = [
      { id: 'contact_id', module: 'contacts', key: 'contact' },
      { id: 'customer_id', module: 'customers', key: 'customer' },
    ];

    arRelateFields.forEach( relateField => {
      if (objRecord[relateField.id]) {
        arModule.push(relateField.module);
        objFilter[relateField.module] = { id: objRecord[relateField.id] };
      }
    });

    return {
      module: arModule,
      filter: objFilter,
      relate_field: arRelateFields
    }
  }

  /**
   * calculate the prices based on line items
   *
   * @param quoteRecord
   * @returns
   */
  calculatePrice(quoteRecord: LooseObject) {
    let tax = 0;
    let totalNoTax = 0;
    let discount = 0;
    let totalNoDiscount = 0;
    quoteRecord.line_items.forEach( lineItem => {
      if (get(lineItem, 'is_group', false) == false) {
        let totalPrice = round(lineItem.discounted_price * lineItem.quantity, 4);

        totalNoTax += totalPrice;
        tax += round((parseFloat(lineItem.tax_rate) / 100) * totalPrice, 4);
        discount += (lineItem.unit_price - lineItem.discounted_price > 0)
          ? (lineItem.unit_price - lineItem.discounted_price) * lineItem.quantity
          : 0;
        totalNoDiscount += lineItem.unit_price * lineItem.quantity;
      }
    });

    return {
      amount_tax: round(tax, 4),
      amount_tax_ex: round(totalNoTax, 4),
      amount_tax_inc: round(tax + totalNoTax, 4),
      discount: round(discount, 4),
      has_discount: discount != 0,
      amount_total: round(totalNoDiscount, 4),
    }
  }

  doSomethingFromContextMenu(event) {
    let copiedLineItemFromLocalStorage = this.contextMenuService.getCopiedLineItem();

    if (event.action == 'paste' && filled(copiedLineItemFromLocalStorage)) {
      let indexCounter = get(event, ['data', 'index']) + 1;
      copiedLineItemFromLocalStorage.forEach(lineItem => {
        let newQuote = new QuoteLineItem(lineItem);
        newQuote.select_line_id = UUID.UUID();
        newQuote.computeMarkup();

        this.arLineItems.splice(indexCounter, 0, newQuote);

        indexCounter++;
      });
    } else {
      let copiedLineItems = [];
      let eventDataLineItem = get(event, ['data', 'line_item'], []);

      if (this.selectedLineItems.length > 0) {
        copiedLineItems = this.selectedLineItems.map(lineItem => {
          let newLineItem = new QuoteLineItem(lineItem);
          newLineItem['tax_code_name'] = sprintf('%s (%s%%)', lineItem['tax_code_name'], parseFloat(lineItem['tax_rate']));

          if (!isUndefined(newLineItem['id'])) {
            delete newLineItem['id'];
          }

          return newLineItem;
        });
      } else if (filled(eventDataLineItem)) {
        let newLineItem = new QuoteLineItem(eventDataLineItem);
        newLineItem['tax_code_name'] = sprintf('%s (%s%%)', newLineItem['tax_code_name'], newLineItem['tax_rate']);

        if (!isUndefined(newLineItem['id'])) {
          delete newLineItem['id'];
        }

        copiedLineItems = [newLineItem];
      }

      if (filled(copiedLineItems)) {
        this.contextMenuService.setCopiedLineItem(copiedLineItems);
      }
    }
  }

  onClickedLineItem($event, attr) {
    this.selectedLineItems = this.contextMenuService.selectLineItem($event, attr, this.selectedLineItems);
  }

  /**
   * Set index to insert to new products
   *
   * @param numIndex
   */
  public setLastIndex(numIndex: number) {
    this.numLastFocusedIndex = numIndex + 1;
  }

  triggerAutoSave() {
    if (blank(this.autoSaveIntervalId)) {
      this.autoSaveIntervalId = setInterval(() => {
        if (this.checkFormGroupDirty()) {
          this.saveQuoteViaAutoSave();
        }
      }, 5000);
    }
  }

  saveQuoteViaAutoSave() {
    let objQuote = this.compileSaveRecordData(false, false, null, true);

    if (!isEmpty(objQuote)) {
      objQuote['id'] = this.data['quote']['id'];
      let unsavedChangesData: SetUnsavedChangesData = {
        record_id: get(this.data, ['quote', 'id'], ''),
        module: 'quote_version',
        data_to_save: objQuote,
        parent_record_id: this.strRecordId,
        related_fields: this.relateFields,
        related_changes: this.relateChanges,
      };

      this.formService.setUnsavedChangesDataToLocalStorage(unsavedChangesData);
    }
  }

  ngOnDestroy(): void {
    this.formService.removeAutoSaveInterval(this.autoSaveIntervalId);
  }
}
