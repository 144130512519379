import { Component, Inject, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { JsonService } from '../../../../services/helpers/json/json.service';
import { Specifications } from '../../../../contracts/importer/specifications';
import { ImportingStageComponentData } from '../new-import-queue-form/new-import-queue-form.component';
import { FieldMappings, FilteredMetadata, ImporterService, Template } from '../../../../services/importer/importer.service';
import { NotificationService } from '../../../../services/notification.service';
import { DialogSaveTemplateComponent } from './dialog-save-template/dialog-save-template.component';
import { isEmpty, get, isNil, find, filter, matchesProperty, merge, concat, isArray, values, join, toString, findIndex, toArray, endsWith, isString } from 'lodash';
import { Select } from '../../../../objects/select';
import { TranslateService } from '@ngx-translate/core';

const _kRequiredAddressFields = ['street','city','zip','state','country'];

@Component({
  selector: 'field-mappings',
  templateUrl: './field-mappings.component.html',
  styleUrls: ['./field-mappings.component.scss']
})
export class FieldMappingsComponent implements OnInit {

  @Input() strModule: string;

  @Input() bLoading: boolean = false;

  @Input() strRelatedRecordId: string | null = null;

  @Input() objUploadUrl: {
    filename: string,
    url: string,
  };

  /// additional import mappings that considered
  /// as pair fields to the extract parts. typically this are autofilled
  /// related data that are not part of the csv file
  @Input() additionalMappings: Record<string, any> = {};

  @Output() objEventSubmit = new EventEmitter<Specifications>();

  /**
   * Is the component done loading the external resources that it needs to function?
   *
   * @type {BehaviorSubject<Boolean>}
   */
  isLoading: BehaviorSubject<Boolean> = new BehaviorSubject(true);

  /**
   * The id of the saved field mapping that the user has selected.
   *
   * @type {BehaviorSubject<string>}
   */
  selectedFieldMapId: BehaviorSubject<string> = new BehaviorSubject(null);

  strTemplateId = null;
  /**
   * A setter of the field map id, only meant to act as a model for the ng-select
   * input. This will update our behavior subject accordingly.
   *
   * @param {string} id
   *
   * @returns {void}
   */
  set _selectedFieldMapId(id: string) {
    this.selectedFieldMapId.next(id);
  }

  /**
   * A setter of the field map id, only meant to act as a model for the ng-select
   * input. This will update our behavior subject accordingly.
   *
   * @returns {string}
   */
  get _selectedFieldMapId(): string {
    return this.selectedFieldMapId.getValue();
  }

  /**
   * determine if the update template is still in progress
   */
  bUpdateTemplateLoader: boolean = false;

  /**
   * determine if the user will edit the selected template
   */
  bUpdateTemplate: boolean = false;

  /**
   * determine if the selected template is create
   */
  bTemplateCreate: boolean = true;

  /**
   * store the selected template name
   */
  arSelectedTemplate = null;

  /**
   * string for selected template name
   */
  strSelectedTemplateName: string = null;

  /**
   * determine if the create template is still in progress
   */
  bCreateTemplateLoader: boolean = false;

  /**
   * Template list
   */
  objTemplates : Template[] = [];

  objData: ImportingStageComponentData;

  errorMessageOnExtract: string | null = null;

  /**
   * This is for the tooltip where we'll display the required fields per module
   */
  requiredFieldsList: string | null = null;

  protected initialPairedData: any[];

  protected initialUnpairedData: any[];

  protected hiddenPairedData: any[];

  constructor(
    protected importerService: ImporterService,
    protected dialog: MatDialog,
    protected jsonService: JsonService,
    @Inject(MAT_DIALOG_DATA) protected data: ImportingStageComponentData,
    public objElementRef: ElementRef,
    public notificationService: NotificationService,
    protected translator: TranslateService,
  ) {}

  public pairedData: any = [];
  public notPairedData: any = [];
  public additionalPairedData: any = [];
  public pairedSelection: Array<Select> = [];
  public additionalPairedSelection: Array<Select> = [];
  public arAllowedColumns: any[] = [];
  protected objMetadata: FilteredMetadata[] = [];

  ngOnInit(): void {
    this.objData = {
      moduleName: this.strModule,
      importFilePresignedUrl: this.objUploadUrl,
      specifications: null
    };

    this.importerService.extractFieldMappings(
      this.objData.moduleName,
      this.objData.importFilePresignedUrl.filename,
      {
        asset_type_id: this.strRelatedRecordId,
        additional_mappings: this.additionalMappings
      }
    ).pipe(
      tap({
        error: (error) => {
          this.errorMessageOnExtract = get(error, [ 'error', 'errors', 0, 'detail'], 'Cannot map CSV file. Please update and try again.');
        }
      }),
      finalize(() => this.isLoading.next(false))
    ).subscribe((response) => {
      this.errorMessageOnExtract = null;
      this.extractImportData(response);
      this.importerService.getTemplate(this.strModule).subscribe((response) => { this.objTemplates = response });
    });
  }

  /**
   * Receives the data to be extracted and parsed for the UI
   *
   * @param { FieldMappings } data
   */
  extractImportData(data: FieldMappings, opts: {
    from_template?: boolean,
  } = {}): void {
    opts = Object.assign({
      from_template: false,
    }, opts);

    const metadata: FilteredMetadata[] = this.objMetadata;

    /// merge old metadata and new metadata from the template
    for (const attributeName in get(data, 'metadata', {})) {
      const currentMeta = get(this.objMetadata, attributeName, {});
      const newMeta = get(data, ['metadata', attributeName], {});

      metadata[attributeName] = merge(currentMeta, newMeta);
    }

    this.objMetadata = metadata;

    // Get required fields for display
    const objRequired = Object.values(this.objMetadata).map(metadata => {
      if (metadata.required) {
        if ((_kRequiredAddressFields.includes(metadata.label) && this.strModule === 'sites')
            || (this.strModule !== 'sites' && ! _kRequiredAddressFields.includes(metadata.label))) {
          return metadata.import_header;
        }
      }
    }).filter(label => ! isEmpty(label));
    this.requiredFieldsList = 'Required fields: ' + objRequired.join(', ');

    this.pairedData = Object.values(get(data, ['extracted_data','paired_data'], [])).map(
      item => {
        return {
          import_header: item.import_header,
          first_row_value: item.first_row_value,
          metadata: item
        }
      }
    );

    this.notPairedData = Object.values(get(data, ['extracted_data','not_paired_data'], [])).map(
      data => {
        return {
          import_header: data['import_header'],
          first_row_value: data['first_row_value'],
          metadata: {}
        }
      }
    );

    /// hidden fields are computed during the extraction phase and are not part of the
    /// saved template as this are runtime fields
    if (! opts.from_template) {
      this.hiddenPairedData = Object.values(get(data, ['hidden'], [])).map(
        data => {
          return {
            import_header: data['import_header'],
            first_row_value: data['first_row_value'],
            metadata: data
          }
        }
      );
    }

    this.pairedData = this.pairedData.concat(this.notPairedData);
    this.arAllowedColumns = Object.values(this.objMetadata).map(item => {
      return {...item, ...{disabled: false}};
    });

    if (isNil(this.initialPairedData)) {
      this.initialPairedData = this.pairedData;
      this.initialUnpairedData = this.notPairedData;
    }

    this.onSelectOnAllowed();
  }

  /**
   * Triggers when pairing fields using ng-select
   */
  onSelectOnAllowed(): void {
    this.arAllowedColumns.forEach((item, index) => {
      const isPaired = this.pairedData.map(paired =>  paired.metadata.label).includes(item.label);
      const isAdditionallyPaired = this.additionalPairedData.map(addPaired => addPaired.metadata.label ).includes(item.label);
      if (isPaired || isAdditionallyPaired) {
        if (isAdditionallyPaired) {
          this.additionalPairedData.map(addedPair => {
            if (addedPair.metadata.label === this.arAllowedColumns[index]['label']) {
              addedPair.metadata = this.arAllowedColumns[index];
              addedPair.import_header = this.arAllowedColumns[index]['import_header'];
            }

            return addedPair;
          });
        }
          this.arAllowedColumns[index].disabled = true;
      } else {
          this.arAllowedColumns[index].disabled = false;
      }
    })
  }

  /**
   * On Clear of mapping options
   *
   * @param strLabelToRemove
   * @param isTypePaired
   */
  onClear(strLabelToRemove = null, isTypePaired = false) {
    this.arAllowedColumns.forEach((item, index) => {
      if (this.arAllowedColumns[index]['label'] === strLabelToRemove) {
        this.arAllowedColumns[index].disabled = false;
        if (isTypePaired) {
          this.pairedData.map(paired => {
            if (paired.metadata.label === strLabelToRemove){
              paired.metadata.label = null;
            }
            return paired;
          })
        } else  {
          this.additionalPairedData.map(paired => {
            if (paired.metadata.label === strLabelToRemove) {
              paired.import_header = 'Additional Field';
              paired.metadata = [];
              paired.metadata['label'] = '--';
            }
            return paired;
          })
        }
      }
    })
  }

  /**
   * Adds new column for fields that are not yet paired (from metadata)
   */
  addFields(): void {
    this.additionalPairedData.push({
      import_header: 'Additional Field',
      first_row_value: '',
      metadata: {
        label:  '--'
      }
    });
  }

  /**
   * Rebuilds paired fields to match the requirement
   *
   * @returns {
   *  paired_fields: {object},
   *  additional_paired_fields: {object}
   * }
   */
  prepareNeededFields(opts: {
    include_hidden?: boolean,
  } = {}): Template {
    opts = Object.assign({
      include_hidden: true,
    }, opts);

    let unpairedUploadedData = [];
    let pairs = Object.values(this.pairedData);

    let newlyPairedData = pairs.map(field => {
      let fieldMetadata = field['metadata'];

      // Append the newly paired fields (these were probably on the csv but is not paired on our core headers)
      if (get(fieldMetadata, 'import_header', null) === null) {
        // Pair it to the core metadata
        fieldMetadata = Object.values(this.objMetadata).find(metadataOption => metadataOption.label === fieldMetadata.label);

        // Replace the import header (our core) to the header of uploaded csv file
        const csvHeader = get(field, 'import_header', null);
        if (!isNil(fieldMetadata) && ! isNil(csvHeader)) {
          fieldMetadata['import_header'] = field['import_header'];
        } else {
          unpairedUploadedData.push(field);
        }
      }
      // Remove metadata options when saving to database eg. country and phone codes
      if (get(fieldMetadata, 'options', null) !== null) {
        delete fieldMetadata['options'];
      }
      return fieldMetadata;
    }).filter(item =>  item !== undefined && item.label !== null);

    const additional = this.additionalPairedData.map(field => {
      let additinalPairedField = Object.values(this.objMetadata).find(metadataOption => metadataOption.label === field.metadata.label);

      if (additinalPairedField) {
        additinalPairedField['first_row_value'] = field.first_row_value;
        return additinalPairedField;
      }
    }).filter(item => item !== undefined);

    if (opts.include_hidden) {
       additional.push(... this.hiddenPairedData.map(field => field['metadata']));
    }

    return {
      paired_fields: newlyPairedData,
      additional_paired_fields: additional,
      unpaired_fields: unpairedUploadedData
    };
  }

  /**
   * Create a template using the current extracted data
   *
   * @param strTemplateName
   */
  saveTemplate(strTemplateName: string): void {
    this.strSelectedTemplateName = strTemplateName;
    let data = this.prepareNeededFields({
      include_hidden: false,
    });

    this.importerService.saveTemplate(
      this.objData.moduleName,
      strTemplateName,
      this.objUploadUrl.filename,
      this.objMetadata,
      data.paired_fields,
      data.additional_paired_fields,
      this.strTemplateId
    ).subscribe(objResponse => {
      this.notificationService.notifySuccess('field_mapping_template_saved_success');

      if (this.strTemplateId) {
        this.objTemplates = this.objTemplates.map(template => {
          if (template.id === this.arSelectedTemplate.id) {
            template.name = this.strSelectedTemplateName;
          }
          return template;
        });
      } else {
        this.strTemplateId = objResponse.id;
        this.arSelectedTemplate = objResponse;

        this.objTemplates.push({
          id: objResponse.id,
          name: strTemplateName,
          csv_path: this.objUploadUrl.filename,
          paired_fields:  data.paired_fields,
          module: this.objData.moduleName,
          metadata: this.objMetadata,
          additional_paired_fields: data.additional_paired_fields
        });
      }
    });
  }

  /**
   * cancel the editing of a template
   *
   * @returns {void}
   */
  updateTemplateClose(): void {
    this.notificationService.sendConfirmation('confirm_cancel')
    .filter(confirmation => confirmation.answer === true)
    .subscribe(() => {
      this.bUpdateTemplate = false;
    });
  }

  /**
   * Go to next step which imports the mapped data by calling the API to upload the csv file to S3 bucket
   * Paired and additional paired fields must not be null
   *
   * @returns {void}
   */
  onSubmit(): void {
    const data = this.prepareNeededFields();
    const pairs = concat(data.paired_fields, data.additional_paired_fields);
    const missing = [];

    /// validate any required metadata first
    for (const metadata of values(this.objMetadata)) {
      const paired = find(pairs, matchesProperty('label', metadata['label']));

      let firstRowValue = get(paired, 'first_row_value');

      if (isEmpty(this.arSelectedTemplate) && isNil(firstRowValue)) {
        let adhocPair = find(this.additionalPairedData, matchesProperty('metadata.label', metadata['label']));

        if (isNil(adhocPair)) {
          adhocPair = find(this.pairedData, matchesProperty('metadata.label', metadata['label']));
        }

        if (! isNil(adhocPair)) {
          firstRowValue = get(adhocPair, 'first_row_value');
        }
      }

      /// if we have a pair that is an increment attribute, we need to make sure
      /// that the value starts from 1-9 and ends with a number 0-9
      if (
          get(metadata, 'type') == 'increment'
          && toString(firstRowValue).length > 0
          && isEmpty(toString(firstRowValue).match(/^[1-9][0-9]*$/))
      ) {
        this.notificationService.notifyError(
          this.translator.instant('invalid_increment_import_row_value', {field: this.translator.instant(metadata['label'])})
        );

        return;
      }

      if (! get(metadata, 'required', false)) {
        continue;
      }

      let label : string = metadata['label'];

      if (metadata['type'] == 'relate' && ! endsWith(label, '_id')) {
        label = label + '_id';
      }

      const externalIdExists = metadata['type'] == 'relate' && ! isNil(find(pairs, matchesProperty('label', 'external_' + label)));
      const itemCodeExistsAsReference = metadata['type'] == 'relate' && metadata['module'] == 'items' && ! isNil(find(pairs, matchesProperty('label', 'item_code')));

      /// this validation allows a reference to the external_id
      if (externalIdExists || itemCodeExistsAsReference) {
        continue;
      }

      /// if current module is not a site and the current metadata is part of the required address fields
      /// ignore the required validation. since the address data is not a critical part of non site module
      /// ignoring the required rule is safe
      if (this.strModule != 'sites' && _kRequiredAddressFields.includes(metadata['label'])) {
        continue;
      }

      if (! isNil(paired)) {
        continue;
      }

      missing.push(this.translator.instant(metadata['label']));
    }

    if (! isEmpty(missing)) {
      this.notificationService.notifyError(
        this.translator.instant('missing_required_import_fields', {fields: join(missing, ', ')})
      );

      return;
    }

    if ((Object.keys(data.paired_fields).length + Object.keys(data.additional_paired_fields).length) > 0) {
      this.objEventSubmit.next({
        paired_data: data.paired_fields,
        additional_paired_data: data.additional_paired_fields,
        csv_path: this.objUploadUrl.filename
      });
    } else {
      this.notificationService.notifyError('cannot_save_no_paired_column');
    }
  }

  /**
   * When a template is selected, extract its data and set to global variables
   *
   * @param event
   */
  onSelectTemplate(template) : void {
    this.strSelectedTemplateName = template.name;
    const neededData = this.prepareNeededFields({
      include_hidden: false,
    });
    const currentPairedFields = neededData.paired_fields;
    const currentUnpairedFields = neededData.unpaired_fields;
    const currentAdditionalPairedFields = neededData.additional_paired_fields;
    const arMetadata = get(template, 'metadata', []);
    const templatePairedField = get(template, 'paired_fields', []);
    const templateAdditionalField =  get(template, 'additional_paired_fields', []);

    let selectedTemplate: FieldMappings = {
      extracted_data:  {
        not_paired_data: (typeof templateAdditionalField !== 'string' || isEmpty(templateAdditionalField)) ? templateAdditionalField : JSON.parse(templateAdditionalField),
        paired_data: (typeof templatePairedField !== 'string' || isEmpty(templatePairedField)) ? templatePairedField : JSON.parse(templatePairedField)
      },
      metadata: (typeof arMetadata === 'string') ? JSON.parse(arMetadata) : arMetadata
    };

    const fields = concat(toArray(currentPairedFields), selectedTemplate.extracted_data.paired_data, selectedTemplate.extracted_data.not_paired_data);
    const initials = concat(this.initialPairedData, this.initialUnpairedData);

    let pairs = [];

    for (const field of fields) {
      if (isString(field)) {
        continue;
      }

      const notExistsInAdditional = isNil(find(this.additionalPairedData, matchesProperty('metadata.label', field['label'])));

      if (! notExistsInAdditional) {
        continue;
      }

      const position = findIndex(pairs, matchesProperty('label', field['label']));
      const unpaired = find(currentUnpairedFields, matchesProperty('import_header', field['import_header']));

      let defaultMetadata : Record<string, any> = {
        ... (! isNil(unpaired) && {
          first_row_value: unpaired['first_row_value'],
        }),
      };

      const paired = find(toArray(this.pairedData), matchesProperty('import_header', field['import_header']));

      if (isEmpty(defaultMetadata) && ! isNil(paired)) {
        defaultMetadata = {
          first_row_value: get(paired, 'first_row_value', ''),
        };
      }

      const notInitiallyPresent = isNil(find(initials, matchesProperty('import_header', field['import_header'])));

      if (position == -1 && notInitiallyPresent) {
        const idxInAdditional = findIndex(this.additionalPairedData, matchesProperty('metadata.label', field['label']));

        if (idxInAdditional == -1) {
          this.additionalPairedData.push({
            import_header: field['import_header'],
            first_row_value: field['first_row_value'],
            metadata: merge(field, defaultMetadata),
          });
        } else {
          this.additionalPairedData[idxInAdditional] = {
            import_header: field['import_header'],
            first_row_value: field['first_row_value'],
            metadata: merge(field, defaultMetadata),
          };
        }
      } else if (position == -1) {
        pairs.push(merge(field, defaultMetadata));
      } else {
        pairs[position] = merge(pairs[position], field, defaultMetadata);
      }
    }

    selectedTemplate.extracted_data.paired_data = pairs;

    let unpaired = [];

    /// remove already paired fields from the template
    this.additionalPairedData = filter(this.additionalPairedData, (additional) => isNil(find(pairs, matchesProperty('label', get(additional, 'metadata.label')))));

    if (! isEmpty(currentUnpairedFields) && isArray(currentUnpairedFields)) {
      for (const field of currentUnpairedFields) {
        const isUnpaired = isNil(find(pairs, matchesProperty('import_header', field['import_header'])));
        const notExistsInAdditional = isNil(find(this.additionalPairedData, matchesProperty('import_header', field['import_header'])));

        if (! isUnpaired || ! notExistsInAdditional) {
          continue;
        }

        unpaired.push(field);
      }
    }

    selectedTemplate.extracted_data.not_paired_data = unpaired;

    this.arSelectedTemplate = template;
    this.strTemplateId = template.id;

    this.extractImportData(selectedTemplate, {
      from_template: true,
    });
    this.objUploadUrl.filename = template.csv;
  }

  /**
   * Opens dialog for template name create and update
   *
   * @param isUpdate
   * @returns {void}
   */
  openTemplateForm(isUpdate: boolean = false): void {
    let templateNameDialog = this.dialog.open(DialogSaveTemplateComponent, {
      width: '640px',
      height: 'auto',
      data: { selectedTemplateName: this.strSelectedTemplateName, isUpdate: isUpdate }
    });

    templateNameDialog.afterClosed().subscribe(response => {
      if (! response.isCancelled) {
        if (response.name) {
          this.saveTemplate(response.name);
        } else {
          this.notificationService.notifyError('cannot_save_wo_name');
        }
      }
    });
  }
}
