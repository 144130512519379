<header >
    <div class="d-flex dialog-header">
        <h3 class="dialog-title" translate>
            <fa-icon [icon]="['far', 'file-pdf']" transform="shrink-3" class="dialog-title"></fa-icon>
            <span class="pl-2">{{ strLabel | translate }}</span>
        </h3>
        <div class="ml-auto d-flex">
            <ul class="list-inline pull-right">
                <li class="list-inline-item">
                    <button type="button" (click)="sendEmail()" class="btn ml-1 btn-primary" [disabled]="(!bReady || bSendEmail) ? true : false">
                        <fa-icon [icon]="['fas', (bSendEmail) ? 'spinner' : 'envelope']" [spin]="bSendEmail"></fa-icon>
                        <span class="pl-1">{{ 'send_email' | translate }}</span>
                    </button>
                </li>
                <li class="list-inline-item">
                    <button type="button" (click)="downloadPDF()" class="btn ml-1 btn-primary" [disabled]="(!bReady|| bDownload) ? true : false">
                        <fa-icon [icon]="['fas', (bDownload) ? 'spinner' : 'file-download']" [spin]="bDownload"></fa-icon>
                        <span class="pl-1">{{ 'download_pdf' | translate }}</span>
                    </button>
                </li>
                <li class="list-inline-item">
                    <button type="button" (click)="sendViaChat()" class="btn ml-1 btn-primary"
                        [disabled]="(!bReady|| bSendChat) ? true : false">
                        <fa-icon [icon]="['fas', (bSendChat) ? 'spinner' : 'comments']" [spin]="bSendChat"></fa-icon>
                        <span *ngIf="bSendChat" class="pl-1">{{ 'rendering_file' | translate }}</span>
                        <span *ngIf="!bSendChat" class="pl-1">{{ 'send_chat' | translate }}</span>
                    </button>
                </li>
                <li class="list-inline-item">
                    <button type="button" (click)="closeDialog()" class="btn ml-1 btn-danger">
                        <fa-icon [icon]="['fas', 'times']"></fa-icon>
                        <span class="pl-1">{{ 'close' | translate }}</span>
                    </button>
                </li>
            </ul>
        </div>
    </div>
    <hr />
</header>
<mat-dialog-content #content style="overflow-y: hidden;" class="h-100">
    <fa-icon *ngIf="bLoading" [icon]="['fas', 'spinner']" [spin]="true" class="text-center d-inline-block w-100 pt-4 pb-4" size="2x"></fa-icon>
    <div *ngIf="bError && !bLoading" class="text-center d-inline-block w-100 pt-4 pb-4" [ngClass]="myClasses">{{ 'failed_to_preview_html' | translate }}</div>
    <iframe #iframePDF *ngIf="strHTML && !bLoading" [srcdoc]="strHTML" width="100%" height="100%" type='application/pdf' frameborder="0" style="overflow-y: hidden;"></iframe>
</mat-dialog-content>
