export class TagDisplay {
  id?: string;
  name: string;
  color: string;

  constructor(props: {
    id?: string,
    name: string,
    color?: string
  }) {
    this.name = props.name;
    this.id = props.id;
    this.color = props.color || '#e0e0e0';
  }
}