<header>
  <div class="d-flex">
    <h3 class="title">
      <fa-icon [icon]="['fas', 'envelope']" class="mr-2"></fa-icon>
      {{ 'suggestion_box' | translate }}
    </h3>

    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <fieldmagic-primary-button
            (click)="formRef.ngSubmit.emit()"
            data-testid="send-btn"
            [disabled]="isInProgress || form.invalid"
            [isInProgress]="isInProgress"
            icon="envelope"
            label="send_inquiry"
          ></fieldmagic-primary-button>
        </li>
        <li class="list-inline-item">
          <fieldmagic-primary-button
            variant="error"
            (click)="onCancel()"
            data-testid="cancel-btn"
            [disabled]="isInProgress"
            icon="times"
            label="close"
          ></fieldmagic-primary-button>
        </li>
      </ul>
    </div>
  </div>
</header>

<!-- seperator -->
<hr class="seperator"/>
<!-- /seperator -->

<mat-dialog-content>
  <form [formGroup]="form" #formRef="ngForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="form-group col-12">
        <textarea
          formControlName="message"
          rows="5"
          class="form-control"
          id="message"
          data-testid="message-input"
          placeholder="{{ 'suggest' | translate }}"
          [class.is-invalid]="form.controls.message.touched && form.controls.message.invalid"
          maxlength="255"
        ></textarea>
      </div>
    </div>
  </form>
</mat-dialog-content>
