<header>
  <div class="d-flex">
    <!-- title -->
    <h3 class="title" data-testid="dialog_title"><fa-icon [icon]="['fas', 'file-alt']" class="mr-3"></fa-icon>{{ previewHeader | translate }}</h3>

    <!-- actions -->
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button data-testid="compose-email-btn" type="button" class="btn ml-1 btn-primary" (click)="sendEmailInit()" id="email-btn" [disabled]="isProcessing || failedToGeneratePreview">
            <button-icon-loader [isLoading]="isComposingEmail" [defaultIcon]="['fas', 'envelope']"></button-icon-loader>
            <span class="pl-1">{{ 'send_email' | translate }}</span>
          </button>
        </li>

        <!-- download -->
        <li class="list-inline-item">
          <button data-testid="download-btn" type="button" class="btn ml-1 btn-primary" id="download-btn" (click)="downloadPdfInit()" [disabled]="isProcessing || failedToGeneratePreview">
            <button-icon-loader [isLoading]="isDownloading" [defaultIcon]="['fas', 'download']"></button-icon-loader>
            <span class="pl-1">{{ 'download_pdf' | translate }}</span>
          </button>
        </li>
        <!-- /download -->

        <!-- share in portal -->
        <li class="list-inline-item" *ngIf="strModule === 'jobs'">
          <button data-testid="share-btn" type="button" class="btn ml-1 btn-primary" id="share-btn" (click)="shareInPortalInit()" [disabled]="isProcessing || failedToGeneratePreview">
            <button-icon-loader [isLoading]="isSharingToPortal" [defaultIcon]="['fas', 'share']"></button-icon-loader>
            <span class="pl-1">{{ 'share_in_portal' | translate }}</span>
          </button>
        </li>
        <!-- /share in portal -->

        <!-- send via chat -->
        <li class="list-inline-item">
          <button data-testid="send-via-chat-btn" type="button" class="btn ml-1 btn-primary" id="download-btn" (click)="sendViaChat()" [disabled]="isProcessing || failedToGeneratePreview">
            <button-icon-loader [isLoading]="isComposingChat" [defaultIcon]="['fas', 'comments']"></button-icon-loader>
            <span class="pl-1">{{ 'send_chat' | translate }}</span>
          </button>
        </li>
        <!-- /send via chat -->

        <li class="list-inline-item" *ngIf="allowGenerateCustomTemplate">
          <form-templates-generator
            [module-name]="module"
            [exclude-defaults]="true"
            [previous-preview-data]="currentPreviewData">
          </form-templates-generator>
        </li>

        <!-- cancel -->
        <li class="list-inline-item">
          <fieldmagic-primary-button
            data-testid="cancel-btn"
            (click)="closeDialog()"
            icon="times"
            variant="error"
            label="close"
            [disabled]="isProcessing"
          ></fieldmagic-primary-button>
        </li>
        <!-- /cancel -->
      </ul>
    </div>
  </div>
</header>

<!-- seperator -->
<hr class="seperator"/>
<!-- /seperator -->

<!-- dialog content -->
<mat-dialog-content class="mobile-view-dialog">
  <div class="mt-3 text-center">
    <!-- content -->
    <ng-container *ngIf="! isGeneratingPreview">
      <span *ngIf="! previewURL" id="error-message" data-testid="error-message">{{ 'error_generating_preview' | translate }}</span>
      <div *ngIf="previewURL" class="preview-container" [class.hidden]="! isLoaded">
        <pdf-viewer
          id="preview"
          data-testid="preview"
          [src]="previewURL"
          [render-text]="true"
          [external-link-target]="'blank'"
          [original-size]="true"
          [show-all]="true"
          (after-load-complete)="onPreviewComplete()"
        ></pdf-viewer>
      </div>
    </ng-container>

    <!-- spinner -->
    <ng-container *ngIf="isGeneratingPreview || (! isLoaded && previewURL)">
      <div class="text-center" *ngIf="isGeneratingPreview" id="loading-icon">
        <span>{{ 'generating_document' | translate }}</span>
      </div>
      <div class="text-center" *ngIf="! isLoaded && ! isGeneratingPreview && previewURL">
        <span>{{ 'loading_document_preview' | translate }}</span>
      </div>
      <div class="text-center">
        <fa-icon [icon]="['fas','spinner']" [spin]="true" aria-hidden="true"></fa-icon>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>
