import { Component, OnInit, Inject, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { each, set, cloneDeep, isEmpty, pick, isNil, get } from 'lodash';
import { Subscription } from 'rxjs';
import { FileUploader } from 'ng2-file-upload';

import { NotificationService } from '../../../services/notification.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { SmsService } from '../../../services/sms.service';
import { FileService } from '../../../services/file/file.service';
import { EmailService, EmailAttachment } from '../../../services/email.service';
import { finalize } from 'rxjs/operators';
import { Select } from '../../../objects/select';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
  selector: 'app-email-template-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit, OnDestroy {

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  @ViewChild("attachment") fileInput: ElementRef;

  /**
   * Reference to the email body editor
   *
   * @type {QuillEditorComponent}
   */
  @ViewChild("quill") quill: QuillEditorComponent;

  public hasBaseDropZoneOver: boolean = false;
  public attachmentList: Array<object> = [];
  public fileUploader: FileUploader = new FileUploader({
    disableMultipart:true,
    url: ''
  });

  /**
   * Wysiwig Config
   */
  public quillConfig: object = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1"}, { indent: "+1" }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      [{ align: [] }],
      ["clean"],
      ["link"]
    ]
  };

  public modeLabel: string = "add";
  public modeIcon: string = "plus-circle";
  public loading: boolean = true;
  public submitted: boolean = false

  public emailTemplateForm: FormGroup;

  public emailTemplateField = {
    name: {
      key: "name",
      controlType: "text",
      type: "text",
      label: "name",
      max_length: 128,
      required: true,
      default_value: null
    },
    subject: {
      key: "subject",
      controlType: "text",
      type: "text",
      label: "subject",
      max_length: 128,
      required: false,
      default_value: null
    },
    body: {
      key: "body",
      controlType: "textarea",
      type: "textarea",
      label: "body",
      required: false,
      default_value: null
    },
    module: {
      key: "module",
      controlType: "dropdown",
      type: "dropdown",
      label: "module",
      required: false,
      default_value: null,
      clearable: true,
      options: []
    },
    field: {
      key: "field",
      controlType: "dropdown",
      type: "dropdown",
      label: "field",
      required: false,
      default_value: null,
      options: []
    },
    share_template: {
      key: 'share_template',
      controlType: 'checkbox',
      type: 'checkbox',
      label: 'share_template',
      required: false,
      default_value: false
    }
  };

  /**
   * Internal Use: contains list of subscription that is used throughout the component that would be
   * cleaned up when this component is destroyed.
   *
   * @var {Subscription[]}
   */
  protected subscription: Subscription[] = [];

  /**
   * @type {boolean}
   */
  protected uploadingAttachment: boolean = false;

  /**
   * Determines on what module the template will show
   *
   * @type {string}
   */
  public strModule: string = null;

  /**
   * Determines on what module field will be added to the email body
   *
   * @type {string}
   */
  public strField: string = null;

  /**
   * The variable format. Eg. {{ first_name }}
   *
   * @type {string}
   */
  public strVariable: string = null;

  /**
   * Storage of the modules and their fields
   *
   * @type {any}
   */
  public objAvailableModules: any;

  constructor(
    private fileService: FileService,
    private smsService: SmsService,
    private notificationService: NotificationService,
    private localStorageService: LocalStorageService,
    private dialogRef: MatDialogRef<EditComponent>,
    @Inject(MAT_DIALOG_DATA) private data: object,
    private emails: EmailService
  ) {
    this.modeLabel = (this.data["id"]) ? "edit" : "add";
    this.modeIcon = (this.data["id"]) ? "pencil" : "plus-circle";

    let emailTemplate = (this.data["id"]) ? cloneDeep(this.data) : {};
    this.initializeComponent(emailTemplate);

    this.dialogRef.backdropClick().subscribe(_ => {
      this.cancelDialog();
    });
  }

  ngOnInit() {
    this.initModules();
  }

  /**
   * Checks if metadata is in local storage. If not, request for the metadata of all
   * modules that will have the sms widget and store it in the local storage.
   * Also, check the metadata of each fields if they have in_sms_template property
   *
   * @returns {void}
   */
  initModules(): void {
    const ALLOWED_CUSTOM_FIELD_TYPE = ['number', 'text', 'currency', 'textarea', 'date', 'datetime'];
    this.objAvailableModules = this.localStorageService.getJsonItem('sms_related_modules');

    if (isNil(this.objAvailableModules)) {
      this.smsService.getRelatedMetadata().subscribe(metadataList => {
        if (metadataList) {
          let smsRelatedFields = {};

          Object.keys(metadataList).forEach((module) => {
            smsRelatedFields[module] = [];
            Object.keys(metadataList[module]).forEach((field) => {
              if (metadataList[module][field].hasOwnProperty('in_sms_template') ||
                ALLOWED_CUSTOM_FIELD_TYPE.some(allowedfield => field.includes(allowedfield))) {
                smsRelatedFields[module].push({ field: field, label : metadataList[module][field]['label']});
              }
            });
          });

          this.objAvailableModules = smsRelatedFields;
          this.emailTemplateField['module'].options = this.generateModulesOptions();
          this.localStorageService.setJsonItem('sms_related_modules', smsRelatedFields);

          if (this.data['module']) {
            this.generateModuleFields(this.data['module']);
          }
        }
      });
    } else {
      this.emailTemplateField['module'].options = this.generateModulesOptions();

      if (this.data['module']) {
        this.generateModuleFields(this.data['module']);
      }
    }
  }

  /**
   * Generates the options in the 'fields' dropdown portion of the form,
   * the values there will depend on the selected option in the 'module' portion
   *
   * @param {string} strSelectedModule
   *
   * @returns {void}
   */
  generateModuleFields(strSelectedModule: string): void {
    this.strModule = strSelectedModule;
    // clear the fields first
    this.emailTemplateField.field.options = [];
    this.emailTemplateForm.patchValue({ field: null });
    this.strField = null;

    let arModuleFields: ModuleField[] = this.objAvailableModules[strSelectedModule];

    if (strSelectedModule === 'purchase_orders') {
      let numDeliverToSiteIndex: number = arModuleFields.findIndex(moduleField => moduleField.field === 'site_id');
      let numDeliverToWarehouseIndex: number = arModuleFields.findIndex(moduleField => moduleField.field === 'warehouse_id');

      if (numDeliverToSiteIndex > -1) {
        arModuleFields[numDeliverToSiteIndex]['label'] = 'delivery_to_sites';
      }

      if (numDeliverToWarehouseIndex > -1) {
        arModuleFields[numDeliverToWarehouseIndex]['label'] = 'delivery_to_warehouse';
      }
    }

    if (arModuleFields) {
      this.emailTemplateField.field.options = arModuleFields.map(moduleField => new Select(moduleField.field, moduleField.label));
    }
  }

  /**
   * Generates the options in the 'module' dropdown portion of the form
   *
   * @returns {Select[]}
   */
  generateModulesOptions(): Select[] {
    let arModules: string[] = Object.keys(this.objAvailableModules);
    // array is originally unsorted, so we sort the array so that modules appear in alphabetical order
    arModules.sort();
    return arModules.map(strKey => new Select(strKey, strKey));
  }

  /**
   * Sets the value of the selected module field for further use
   *
   * @param {string} strSelectedField
   *
   * @returns {void}
   */
  setField(strSelectedField: string): void {
    this.strField = strSelectedField;
  }

  /**
   * Appends the field variable to the email body
   *
   * @returns {void}
   */
  appendField(): void {
    if (!isNil(this.strModule) && !isNil(this.strField) && this.quill) {
      this.strVariable = ` {{ ${this.strField} }}`;
      // Get the current position of the cursor, otherwise the field variable will be appended to a new line
      let cursorPosition: Range = this.quill.quillEditor.getSelection(true);
      // Update the editor message body
      this.quill.quillEditor.insertText(cursorPosition, this.strVariable);
    } else {
      this.notificationService.notifyWarning('select_field');
    }
  }

  /**
   * cancel dialog
   * if form is touched, send confirmation
   *
   * @returns {void}
   */
  cancelDialog(): void {
    if (this.inProgress) {
      this.notificationService.notifyWarning('form_in_progress');
    } else if (this.emailTemplateForm.touched) {
      this.notificationService.sendConfirmation('confirm_cancel')
        .filter(confirmation => confirmation.answer == true)
        .subscribe(() => {
          this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * initialize form
   *
   * @param {object} data email template record
   *
   * @returns {void}
   */
  initializeComponent(data: object): void {
    this.attachmentList = (data["attachment"]) ? data["attachment"] : [];
    this.emailTemplateForm = new FormGroup({
      name: new FormControl(data["name"] ? data["name"] : null, Validators.required),
      subject:  new FormControl(data["subject"] ? data["subject"] : null),
      attachment: new FormControl(data["attachment"] ? data["attachment"] : null),
      body: new FormControl(data["body"] ? data["body"] : null),
      module: new FormControl(data["module"] ? data["module"] : null),
      field: new FormControl(null),
      share_template: new FormControl(get(data, 'share_template', false))
    });
    this.loading = false;
  }

  fileOverBase(event: boolean): void {
    this.hasBaseDropZoneOver = event;
  }

  /**
   * trigger when user drop's a file in the attachment
   * if we dont have attachment don't continue
   * notify user if the file size exceed or file type is not allowed
   *
   * @param {FileList} attachment
   * @returns {void}
   */
  dropAttachment(attachment): void {
    if (attachment.length < 1) {
      return;
    }
    const regex = new RegExp(["image/*", "application/*", "text/*"].join("|"), "i");
    each(attachment, (file: File) => {

      const { name, type, size } = file;
      if ((size / 1024 / 1024) > 8) {
        this.notificationService.notifyWarning("mail_attachment_size_exceed");
        return;
      } else if (type.search(regex) === -1) {
        this.notificationService.notifyWarning("mail_attachment_file_type_not_allowed");
        return;
      }

      this.uploadingAttachment = true;

      const index = this.attachmentList.push({
        name,
        size: size / 1024,
        type
      });

      let subscription = this.fileService.upload(file)
        .pipe(
          finalize(() => {
            const isUploadingAttachmentsCompleted = this.attachmentList.length === this.attachmentList.filter((file) => !!file['upload_name']).length;

            // if all of the attachments are uploaded successfully we then
            // remove the disable flag on the component action buttons
            if (isUploadingAttachmentsCompleted) {
              this.uploadingAttachment = false;
            }
          })
        )
        .subscribe((result) => {
          this.attachmentList[index - 1] = {
            ...set(this.attachmentList[index - 1], "upload_name", result["filename"]),
            new: true,
          };
      });
      this.subscription.push(subscription);
      this.emailTemplateForm.controls["attachment"].markAsTouched();
    });
  }

  /**
   * remove an attachment
   * get the index and use it to remove in the list
   *
   * @param {string} name attachment name
   * @returns {void}
   */
  removeAttachement(name: string): void {
    var index = this.attachmentList.findIndex(
      item => (item["name"] == name)
    );
    this.attachmentList.splice(index, 1);
  }

  /**
   * Save the record
   *
   * @returns {void}
   */
  onSubmit(): void {
    this.emailTemplateForm.controls.name.markAsDirty();
    this.emailTemplateForm.controls.name.markAsTouched();

    if (this.emailTemplateForm.valid && !this.submitted) {
      this.submitted = true;

      // Update the form
      this.emailTemplateForm.patchValue({ body: get(this.quill, 'quillEditor.root.innerHTML') });

      const data = {
        ...pick(this.emailTemplateForm.getRawValue(), ['name', 'subject', 'body', 'module', 'share_template']),
        attachments: this.attachmentList as EmailAttachment[],
      };

      const action$ = (isEmpty(this.data['id']))
        ? this.emails.createEmailTemplate$(data)
        : this.emails.updateEmailTemplate$(this.data['id'], data);

      this.subscription.push(
        action$.pipe(
          finalize(() => this.submitted = false),
        )
        .subscribe(() => this.dialogRef.close(true))
      );
    } else {
      this.notificationService.notifyError('please_complete_the_form');
    }
  }

  get inProgress(): boolean {
    return this.submitted || this.uploadingAttachment;
  }

  ngOnDestroy(): void {
    // cleanup
    this.subscription.forEach((subscription) => subscription.unsubscribe());
  }
}

interface ModuleField {
  field: string,
  label: string
}