import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationService } from '../../../services/notification.service';
import { ListingService } from '../../../services/listing.service';
import { ClientStoreService } from '../../../services/client-store.service';
import { AccountingSystemService } from '../../../integrations/accounting_systems/shared/services/accounting_system.service';
import { Client } from '../../../objects/client';
import { get } from 'lodash';
import { filled, blank } from '../../../shared/utils/common';
import { LooseObject } from '../../../objects/loose-object';

@Component({
  selector: 'app-sync-invoice-to-accounting',
  templateUrl: './sync-invoice-to-accounting.component.html',
  styleUrls: ['./sync-invoice-to-accounting.component.scss']
})
export class SyncInvoiceToAccountingComponent implements OnInit {
  /**
   * Gets the tooltip displayed in the dialog
   *
   * @returns {string}
   */
  get tooltip(): string {
    return this.strDisplayOption === 'unsynced_invoices'
      ? 'invoices_list_not_yet_synced_to_accounting'
      : 'invoices_list_synced_to_accounting';
  }

  /**
   * Gets the header title
   *
   * @returns {string}
   */
  get title(): string {
    const strModule: string = get(this.data, 'strModule', '');

    if (strModule === 'customer_invoices') {
      return 'sync_customer_invoices'
    } else if (strModule === 'supplier_invoices') {
      return 'sync_supplier_invoices';
    } else {
      return '';
    }
  }

  /**
   * Gets the tab title for the third column in the table
   *
   * @returns {string}
   */
  get customerOrSupplier(): string {
    const strModule: string = get(this.data, 'strModule', '');

    if (strModule === 'customer_invoices') {
      return 'customer'
    } else if (strModule === 'supplier_invoices') {
      return 'supplier';
    } else {
      return '';
    }
  }

  /**
   * Checks if objList is blank
   *
   * @returns {string}
   */
  get isObjListBlank(): boolean {
    return blank(this.objList);
  }

  /**
   * The xero link to view an invoice.
   * Reference: https://developer.xero.com/documentation/guides/how-to-guides/deep-link-xero/#examples-of-deep-links
   *
   * @type {string}
   */
  get xeroRecordBaseLink(): string {
    const strModule: string = get(this.data, 'strModule', '');
    const strXeroCategory: 'AccountsReceivable' | 'AccountsPayable' = strModule === 'customer_invoices'
      ? 'AccountsReceivable'
      : 'AccountsPayable';

    return `https://go.xero.com/organisationlogin/default.aspx?shortcode=${this.strXeroOrgShortCode}&redirecturl=/${strXeroCategory}/View.aspx?InvoiceID=`;
  }

  /**
   * Logic which decides whether to display the Sync Error/Remote Link column.
   * If strDisplayOption is 'unsynced_invoices', it is always displayed.
   * Else, if strDisplayOption is 'synced_invoices' and accounting sytem is xero, it is also displayed.
   * If strDisplayOption is 'synced_invoices' and accounting sytem is NOT xero, it is hidden.
   *
   * @type {boolean}
   */
  get isSyncErrorAndRemoteLinkColDisplayed(): boolean {
    if (this.strDisplayOption === 'unsynced_invoices') {
      return true;
    } else {
      if (get(this.objClient, 'accounting_system_id') === 'xero') {
        return true;
      }
    }

    return false;
  }

  /**
   * Contains the display options for the invoices list
   *
   * @type {string[]}
   */
  arDisplayOptions: string[] = [
    'unsynced_invoices',
    'synced_invoices'
  ];

  /**
   * Tells us whether to display unsynced invoices or vice versa
   *
   * @type {'unsynced_invoices' | 'synced_invoices'}
   */
  strDisplayOption: 'unsynced_invoices' | 'synced_invoices' = 'unsynced_invoices';

  /**
   * Contains the short code for the organization if client is connected to xero accounting.
   *
   * @type {string|null}
   */
  strXeroOrgShortCode: string|null = null;

  /**
   * Contains the data for the items in the list, as well as the pagination metadata
   *
   * @type {LooseObject}
   */
  objList: LooseObject = {};

  /**
   * Tells us whether the select all checkbox is toggled
   *
   * @type {boolean}
   */
  bSelectAllChecked: boolean = false;

  /**
   * Checks if the save button has been clicked.
   *
   * @type {boolean}
   */
  bSubmitted: boolean = false;

  /**
   * If bSelectAllChecked is true, and this has values, then the action will sync all invoices without accounting id EXCEPT the ids contained in the array.
   * Else, if bSelectAllChecked is false, then the action will sync ONLY the ids contained in the array.
   *
   * @type {string[]}
   */
  arIds: string[] = [];

  /**
   * The current active client.
   *
   * @var {Client}
   */
  public objClient: Client;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SyncInvoiceToAccountingComponent>,
    private notificationService: NotificationService,
    private listingService: ListingService,
    private accountingSystemService: AccountingSystemService,
    private clientStoreService: ClientStoreService,
  ) { }

  ngOnInit(): void {
    this.objClient = this.clientStoreService.getActiveClient();

    if (blank(get(this.data, 'strModule'))) {
      this.data['strModule'] = 'customer_invoices';
    }

    if (get(this.objClient, 'accounting_system_id') === 'xero') {
      this.accountingSystemService.getXeroShortCode$().subscribe(res => {
        this.strXeroOrgShortCode = res;
        this.initItems();
      });
    } else {
      this.initItems();
    }
  }

  /**
   * Closes the dialog
   *
   * @returns {void}
   */
  closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * Initializes the items in the list
   *
   * @param {number} numPage
   *
   * @returns {void}
   */
  initItems(numPage: number = 1): void {
    this.objList = {};

    this.listingService.fetchDataAdvanceSearch(
      { pageNum: numPage },
      this.data.strModule,
      this._getListingFilter(),
      { id: "updated_at", sort: "desc" },
      null,
      // Follow listing records per page based on client config. Defaults to 25
      get(this.objClient, 'config.listing_records_per_page', 25),
    ).subscribe(res => {
      res['data'] = get(res, 'data', []).map(item => {
        return {
          ...item,
          is_selected: this._getItemSelectedStatus(item.id)
        }
      });

      this.objList = res;
    });
  }

  /**
   * Formats the job number text, displays '--' if job number is invalid
   *
   * @param {string} strJobNumberText
   *
   * @returns {string}
   */
  formatJobNumberText(strJobNumberText: string): string {
    if (blank(strJobNumberText) || strJobNumberText === '000000') {
      return '--';
    }

    return strJobNumberText;
  }

  /**
   * Triggered whenever the select all checkbox is toggled
   *
   * @returns {void}
   */
  selectAllChanged(): void {
    if (filled(this.objList)) {;
      this.objList.data.forEach(item => item.is_selected = this.bSelectAllChecked);
    }

    this.arIds = [];
  }

  /**
   * Triggered whenever the item select checkbox is toggled
   *
   * @param {LooseObject} item
   *
   * @returns {void}
   */
  itemCheckboxToggled(item: LooseObject): void {
    // If bSelectAllChecked is true, arIds's contents are the values excluded from the sync.
    // If bSelectAllChecked is false, arIds's contents are the only values synced.

    if (this.bSelectAllChecked) {
      if (!item.is_selected && !this.arIds.includes(item.id)) {
        this.arIds.push(item.id);
      }

      if (item.is_selected && this.arIds.includes(item.id)) {
        this.arIds = this.arIds.filter(id => id !== item.id);
      }
    } else {
      if (item.is_selected) {
        if (!this.arIds.includes(item.id)) {
          this.arIds.push(item.id);
        }
      } else {
        this.arIds = this.arIds.filter(id => id !== item.id);
      }
    }
  }

  /**
   * Triggers when the save button is clicked and sends data to the API if valid.
   *
   * @returns {void}
   */
  onSubmit(): void {
    this.bSubmitted = true;

    if (this.bSelectAllChecked) {
      this.callApi();
    } else {
      if (blank(this.arIds)) {
        this.notificationService.notifyError('select_invoices_to_sync');
        this.bSubmitted = false;
      } else {
        this.callApi();
      }
    }
  }

  /**
   * Calls the API to sync the invoices to accounting.
   *
   * @returns {void}
   */
  callApi(): void {
    this.accountingSystemService
      .syncExistingUnsyncedInvoices(this.arIds, this.data.strModule, this.bSelectAllChecked)
      .subscribe(
        res => {
          this.notificationService.notifySuccess(get(res, 'body.message', 'scheduled_sync_successful'));
          this.closeDialog();
        },
        err => {
          this.bSubmitted = false;
          this.notificationService.notifyError('module_sync_error_occured');
        }
      );
  }

  /**
   * Checks if a given item field is filled.
   *
   * @param {any} field
   *
   * @returns {boolean}
   */
  isFieldFilled(field: any): boolean {
    return filled(field);
  }

  /**
   * Displays a notification showing user has copied text to clipboard
   *
   * @returns {void}
   */
  notifyCopy(): void {
    this.notificationService.notifyInfo('copied_to_clipboard');
  }

  switchView() {
    this.bSelectAllChecked = false;
    this.arIds = [];
    this.initItems();
  }

  /**
   * Gets the filter for the invoice listing
   *
   * @returns {LooseObject}
   */
  private _getListingFilter(): LooseObject {
    const strOperator: 'eq' | 'ne' = this.strDisplayOption === 'unsynced_invoices' ? 'eq' : 'ne';

    return {
      accounting_id: {
        op: strOperator,
        value: null
      }
    };
  }

  /**
   * Determines whether the item's checkbox is selected or not when a new page is loaded
   *
   * @param {string} strId
   *
   * @returns {boolean}
   */
  private _getItemSelectedStatus(strId: string): boolean {
    const bIsIdInArray: boolean = this.arIds.includes(strId);

    if (this.bSelectAllChecked) {
      return bIsIdInArray ? false : true;
    }

    return bIsIdInArray;
  }
}

