<!-- header -->
<header>
  <div class="d-flex">
    <!-- title -->
    <h3 class="title">
      <fa-icon [icon]="['fas', 'history']"></fa-icon>&nbsp;{{ 'recurring_invoices_history' | translate }}
    </h3>
    <!-- /end title -->

    <!-- cancel button -->
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <fieldmagic-primary-button

          ></fieldmagic-primary-button>
          <button type="button" class="btn ml-1 btn-primary" (click)="closeDialog()">
            <fa-icon [icon]="['fas', 'times']"></fa-icon><span class="pl-1">{{ 'cancel' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
    <!-- /end cancel -->
  </div>
</header>
<!-- /end header -->

<hr class="seperator"/>

<!-- list -->
<div class="mt-3">
  <table class="table table-bordered table-striped">
    <!-- head -->
    <thead>
      <tr class="text-center">
        <th width="15%">{{ 'invoice_number' | translate }}</th>
        <th width="20%">{{ 'invoice_summary' | translate }}</th>
        <th width="10%">{{ 'total_ex_tax' | translate }}</th>
        <th width="10%">{{ 'total_inc_tax' | translate }}</th>
        <th width="10%">{{ 'tax' | translate }}</th>
        <th width="10%">{{ 'amount_due' | translate }}</th>
      </tr>
    </thead>
    <!-- /end head -->

    <!-- body -->
    <tbody>
      <!-- no data found placeholder -->
      <tr *ngIf="arHistoricalInvoices.length < 1 && ! isLoadingList">
        <td colspan="6" class="text-center">{{ 'no_historical_invoices' | translate }}</td>
      </tr>
      <!-- /end placeholder -->

      <!-- loading placeholder -->
      <tr *ngIf="isLoadingList">
        <td colspan="6" class="text-center">
          <span class="no-content">
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
            {{ 'loading' | translate }}
          </span>
        </td>
      </tr>
      <!-- /end placeholder -->

      <!-- list -->
      <ng-container *ngIf="! isLoadingList && arHistoricalInvoices.length > 0">
        <tr *ngFor="let objHistory of arHistoricalInvoices">
          <!-- invoice number -->
          <td class="text-center" matTooltip="{{ 'invoice_number' | translate }}: {{ objHistory.invoiceNumber }}">
            <a [routerLink]="['/customer_invoices', objHistory.id]" (click)="closeDialog()">#{{ objHistory.invoiceNumber }}</a>
          </td>
          <!-- /end invoice number -->

          <!-- invoice summary -->
          <td matTooltip="{{ 'invoice_summary' | translate }}: {{ objHistory.invoiceSummary }}">
            <div class="truncate">{{ objHistory.invoiceSummary }}</div>
          </td>
          <!-- /end invoice summary -->

          <!-- total amount ex tax -->
          <td matTooltip="{{ 'total_ex_tax' | translate }}: {{ objHistory.amountTaxExcluded | currency }}">
            {{ objHistory.amountTaxExcluded | currency }}
          </td>
          <!-- end total -->

          <!-- total amount inc tax -->
          <td matTooltip="{{ 'total_inc_tax' | translate }}: {{ objHistory.amountTaxIncluded | currency }}">
            {{ objHistory.amountTaxIncluded | currency }}
          </td>
          <!-- end total -->

          <!-- total amount inc tax -->
          <td matTooltip="{{ 'tax' | translate }}: {{ objHistory.amountTax | currency }}">
            {{ objHistory.amountTax | currency }}
          </td>
          <!-- end total -->

          <!-- amount due-->
          <td matTooltip="{{ 'amount_due' | translate }}: {{ objHistory.amountDue | currency }}">
            {{ objHistory.amountDue | currency }}
          </td>
          <!-- end amount due- -->
        </tr>
      </ng-container>
    </tbody>
    <!-- /end body -->
  </table>
</div>
<!-- /end list -->
