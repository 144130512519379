<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <ul class="list-inline float-right mb-0">
          <li class="list-inline-item">
            <button (click)="closeDialog()" [disabled]="!isLoaded" class="btn ml-1 btn-danger">
              <button-icon-loader [defaultIcon]="['fas', 'times']" [isLoading]="!isLoaded"></button-icon-loader>
              <span class="pl-1">{{ 'close' | translate }}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <hr />
    <div *ngIf="isLoaded" class="row">
      <div class="col-12">
        <table class="table table-bordered custom-table">
          <thead>
            <tr>
              <th colspan="4" class="text-center">{{ 'supplier_pricing' | translate }}</th>
            </tr>
            <tr>
              <th>{{ 'supplier_code' | translate }}</th>
              <th>{{ 'supplier_name' | translate }}</th>
              <th>{{ 'default_unit_cost' | translate }}</th>
              <th>{{ 'notes' | translate }}</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngIf="supplierPricingRecord.length < 1">
              <td colspan="4" class="text-center">{{ 'no_record_found' | translate }}</td>
            </tr>
            <ng-container *ngIf="supplierPricingRecord.length > 0">
              <tr *ngFor="let supplierPricing of supplierPricingRecord">
                <td class="supplier-code-content truncate" matTooltip="{{ supplierPricing.code }}">
                  <span class="col-value">{{ supplierPricing.code }}</span>
                </td>

                <td class="supplier-name-content" matTooltip="{{ supplierPricing.customer_text }}">
                  <span class="col-value">{{ supplierPricing.customer_text }}</span>
                </td>

                <td class="default-unit-cost-content" matTooltip="{{ supplierPricing.unit_cost }}">
                  <span class="col-value">{{ supplierPricing.unit_cost }}</span>
                </td>

                <td class="notes-content truncate" matTooltip="{{ supplierPricing.notes | placeholdWithString }}">
                  <span class="col-value">{{ supplierPricing.notes | placeholdWithString }}</span>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <hr />
      <div class="col-12">
        <fieldmagic-item-inventory-levels></fieldmagic-item-inventory-levels>
      </div>
    </div>
  </div>
</div>

