<header>
  <div class="d-flex dialog-header">
    <h3 class="task-title">
      <fa-icon [icon]="['fas', 'calendar']" class="mr-1"></fa-icon>
      <span>{{ strMode | translate }} {{ 'task' | translate }} - {{ taskMetadata['activity_name'] | truncate }}</span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <fieldmagic-primary-button
            id="btnSave"
            (click)="scheduleTask()"
            [disabled]="bSaving"
            [isInProgress]="bSaving"
            icon="save"
            label="save"
          ></fieldmagic-primary-button>
        </li>
        <li class="list-inline-item">
          <fieldmagic-primary-button
            variant="error"
            id="btnClose"
            (click)="closeDialog()"
            [disabled]="bSaving"
            icon="times"
            label="close">
          </fieldmagic-primary-button>
        </li>
      </ul>
    </div>
  </div>
</header>
<hr class="separator"/>
<div class="row">
  <div *ngFor="let data of fields" class="col-{{data.space}} mb-3">
    <ng-container>
      <app-edit-field [behalf-of-client-id]="onBehalfOfClientId" [item]="data" [form]="taskScheduleForm" [bSubmitted]="bSubmitted" [strMode]="strMode"
        [strModule]="data.module" (parentEvent)="setTechnicianText($event)"></app-edit-field>
    </ng-container>
  </div>
</div>