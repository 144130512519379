
<div class="row">
  <div class="col-12 mb-2">
    <browse-products (objProductSelected)="addLineItem($event, true)" [bLabor]="false" [bSales]="false"></browse-products>
  </div>
  <div class="col-12">

    <div class="row">

      <div class="col-12">
        <div class="d-flex flex-row border">
          <div class="w-8p draggable-table-box-header"></div>
          <div class="w-36p draggable-table-box-header">
            <b>{{ 'description' | translate }}</b>
          </div>
          <div class="w-10p draggable-table-box-header">
            <b>{{ 'quantity' | translate }}</b>
          </div>
          <div class="w-10p draggable-table-box-header">
            <b>{{ 'unit_cost' | translate }}</b>
          </div>
          <div class="w-13p draggable-table-box-header">
            <b>{{ 'tax_code' | translate }}<required-tag></required-tag></b>
          </div>
          <div class="w-13p draggable-table-box-header">
            <b>{{ 'account_code' | translate }}<required-tag></required-tag></b>
          </div>
          <div class="w-10p draggable-table-box-header">
            <b>{{ 'amount' | translate }}</b>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div cdkDropList (cdkDropListDropped)="onDropLineItem($event)" class="row">

          <ng-container *ngFor="let lineItem of lineItems; index as i">
            <div cdkDrag class="col-12">
              <div class="d-flex flex-row border border-top-0 bg-white">

                <div class="draggable-table-box center-items w-6p">

                  <fa-icon *ngIf="lineItems.length > 1" (click)="onRemoveLineItem(lineItem)"
                    class="pl-1 pr-1 pb-2 pt-2 text-danger pointer"
                    [icon]="['fal', 'minus-circle']">
                  </fa-icon>

                  <a *ngIf="lineItem.item_id"
                    href="/#/items/{{ lineItem.item_id }}"
                    class="pl-1 pr-1 pb-2 pt-2"
                    target="_blank">
                    <fa-icon
                      matTooltipClass="tooltip-space"
                      matTooltip="
                      {{ 'click_to_view' | translate }}&#13;&#13;
                      {{ 'product' | translate }}: {{ lineItem.item_name }}&#13;
                      {{ 'code' | translate }}: {{ lineItem.item_code }}"
                      class="text-primary"
                      [icon]="['fas', 'info-circle']">
                    </fa-icon>
                  </a>

                </div>
                <div class="draggable-table-box" style="width: 36%">
                  <fieldmagic-textarea-input
                      id="description_{{ i }}"
                      [(ngModel)]="lineItem.description"
                      [ngModelOptions]="{standalone: true}"
                      [expandable]="true"
                      (change)="calculateLineItem(lineItem)"
                  >
                  </fieldmagic-textarea-input>
                </div>
                <div class="draggable-table-box" style="width: 10%">
                  <fieldmagic-decimal-input
                      id="quantity_{{ i }}"
                      [(ngModel)]="lineItem.quantity"
                      customClass="form-control font-size-12 input-height-40"
                      placeholder="{{ 'quantity' | translate }}"
                      [ngModelOptions]="{standalone: true}"
                      (change)="calculateLineItem(lineItem)"
                  >
                  </fieldmagic-decimal-input>
                </div>
                <div class="draggable-table-box" style="width: 10%">
                  <fc-currency-input
                      id="unit_cost_{{ i }}"
                      [(ngModel)]="lineItem.unit_cost"
                      customClass="form-control font-size-12 input-height-40"
                      placeholder="{{ 'unit_cost' | translate }}"
                      [ngModelOptions]="{standalone: true}"
                      (change)="calculateLineItem(lineItem)"
                  >
                  </fc-currency-input>
                </div>
                <div class="draggable-table-box" style="width: 13%">
                  <ng-select
                      id="tax_code_{{ i }}"
                      [(ngModel)]="lineItem.tax_code_id"
                      [items]="lineItem.tax_code_relate['source'] | async"
                      [typeahead]="lineItem.tax_code_relate['typehead']"
                      [loading]="lineItem.tax_code_relate['loader']"
                      bindLabel="name"
                      bindValue="id"
                      [clearable]="false"
                      (open)="lineItem.tax_code_relate['typehead'].next('')"
                      (change)="onChangeTaxCode($event, lineItem)"
                      appendTo="body">
                  </ng-select>
                </div>
                <div class="draggable-table-box" style="width: 13%">
                  <ng-select
                      id="account_code_{{ i }}"
                      [(ngModel)]="lineItem.account_code_id"
                      [items]="lineItem.account_code_relate['source'] | async"
                      [typeahead]="lineItem.account_code_relate['typehead']"
                      [loading]="lineItem.account_code_relate['loader']"
                      bindLabel="name"
                      bindValue="id"
                      [clearable]="false"
                      (open)="lineItem.account_code_relate['typehead'].next('')"
                      (change)="onChangeAccountCode($event, lineItem)"
                      appendTo="body">
                  </ng-select>
                </div>
                <div class="draggable-table-box" style="width: 10%">
                  <fc-currency-input
                    customClass="form-control font-size-12 input-height-40"
                    [(ngModel)]="lineItem.total_price"
                    (change)="onChangeAmount($event, lineItem)"
                  ></fc-currency-input>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="col-12 text-center" *ngIf="lineItems.length == 0">
            <span>{{ 'no_invoice_line' | translate }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="d-flex flex-row border border-top-0">
          <div class="draggable-table-box right-items w-90p">
            <span class="p-2">{{ 'total_ex_tax' | translate }}</span>
          </div>
          <div class="draggable-table-box left-items w-10p">
            <span class="ml-2">{{ amountTaxEx | currency: {decimal_places: 2} }}</span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-row border border-top-0">
          <div class="draggable-table-box right-items w-90p">
            <span class="p-2  float-right">{{ 'tax' | translate }}</span>
          </div>
          <div class="draggable-table-box left-items w-10p">
            <span class="ml-2">{{ amountTax | currency }}</span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-row border border-top-0">
          <div class="draggable-table-box right-items" style="width: 90%;">
            <span class="p-2 float-right">{{ 'tax_adjustment_amount' | translate }}</span>
          </div>
          <div class="draggable-table-box center-items" style="width: 10%;">
            <fc-currency-input
              step="0.01"
              [withControls]="true"
              customClass="form-control font-size-12 input-height-40"
              [(ngModel)]="amountTaxAdjustment"
              (change)="calculateAmount()">
            </fc-currency-input>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-row border border-top-0">
          <div class="draggable-table-box right-items w-90p">
            <span class="p-2 float-right">{{ 'total_inc_tax' | translate }}</span>
          </div>
          <div class="draggable-table-box center-items w-10p">
            <fc-currency-input
              id="amount_total"
              [(ngModel)]="amountTaxInc"
              customClass="form-control input-height-40 font-size-12">
            </fc-currency-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>