import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { WidgetComponent } from '../components/widget/widget.component';
import { TranslateModule } from '@ngx-translate/core';
import { NoteComponent } from '../components/widget/activities/note/note.component';
import { MaterialModule } from './material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DlDateTimePickerDateModule } from 'angular-bootstrap-datetimepicker';
import { OwlDateTimeModule, OwlNativeDateTimeModule, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule, MomentDateTimeAdapter, OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from 'ng-pick-datetime-moment';
import { EditModule } from './edit.module';
import { TaskComponent } from '../components/widget/activities/task/task.component';
import { EventComponent } from '../components/widget/activities/event/event.component';
import { EmailComponent } from '../components/widget/email/email.component';
import { ChecklistAttachmentsComponent } from '../components/widget/email/checklist-attachments/checklist-attachments.component';
import { PreviewComponent } from '../components/widget/file/preview/preview.component';
import { EditComponent } from '../components/widget/file/edit/edit.component';
import { RenderComponent } from '../components/widget/email/render/render.component';
import { ParticipantsListComponent } from '../components/widget/email/participants-list/participants-list.component';
import { FilesComponent } from '../components/widget/file/files/files.component';
import { EditComponent as RolesEditComponent } from '../components/widget/roles/edit/edit.component';

import { LinkChecklistComponent } from '../components/widget/checklists/link-checklist/link-checklist.component';
import { ChecklistResponseComponent } from '../components/widget/checklists/checklist-response/checklist-response.component';
import { QuillModule } from 'ngx-quill';
import { FileUploadModule } from 'ng2-file-upload';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule } from 'ngx-bootstrap/modal';
import { QuotesComponent } from '../../module/opportunities/quotes/quotes.component';
import { ActivitiesComponent } from '../components/widget/activities/activities.component';
import { FileComponent } from '../components/widget/file/file.component';
import { SitesComponent } from '../../module/customers/sites/sites.component';
import { AssetComponent } from '../components/widget/asset/asset.component';
import { AssetJobsComponent } from '../components/widget/asset/asset-jobs/asset-jobs.component';
import { AssetStatusComponent } from '../components/widget/asset/asset-status/asset-status.component';
import { TimeEntryComponent } from '../../module/jobs/time-entry/time-entry.component';
import { InvoicesComponent } from '../../module/jobs/customer-invoices/invoices.component';
import { OpportunitiesComponent } from '../components/widget/opportunities/opportunities.component';
import { JobsComponent } from '../../module/opportunities/jobs/jobs.component';
import { TasksComponent } from '../../module/calendar/tasks/tasks.component';
import { ChecklistsComponent } from '../components/widget/checklists/checklists.component';
import { RouterModule } from '@angular/router';
import { PurchaseOrdersComponent } from '../../module/jobs/purchase-orders/purchase-orders.component';
import { SupplierInvoicesComponent } from '../../module/jobs/supplier-invoices/supplier-invoices.component';
import { RecurringJobsComponent } from '../components/widget/recurring-jobs/recurring-jobs.component';
import { EditRecurringJobsComponent } from '../components/widget/recurring-jobs/edit-recurring-jobs/edit-recurring-jobs.component';
import { RecurringJobsHistoryComponent } from '../components/widget/recurring-jobs/recurring-jobs-history/recurring-jobs-history.component';
import { AssetJobsComponent as JobsAssetJobsComponent } from '../components/widget/jobs/asset-jobs/asset-jobs.component';
import { ListComponent as RoleListComponent } from '../components/widget/roles/list/list.component';
import { SitesComponent as RoleSitesComponent } from '../components/widget/roles/list/sites/sites.component';
import { ContactsComponent as RoleContactsComponent } from '../components/widget/roles/list/contacts/contacts.component';
import { CustomersComponent as RoleCustomersComponent } from '../components/widget/roles/list/customers/customers.component';
import { OpportunitiesComponent as RoleOpportunitiesComponent } from '../components/widget/roles/list/opportunities/opportunities.component';

import { ClipboardModule } from 'ngx-clipboard';
import { GraphqlFilterComponent } from '../components/graphql-filter/graphql-filter.component';
import { RecurringInvoicesComponent } from '../components/widget/recurring_invoices/recurring_invoices.component';
import { EditRecurringInvoiceFormComponent } from '../components/widget/recurring_invoices/form/edit-recurring_invoice-form.component';
import { HistoricalInvoicesComponent } from '../components/widget/recurring_invoices/historical-invoices/historical-invoices.component';
import { ImageViewComponent } from './../components/image-view/image-view.component';
import { PipesModule } from '../../shared/modules/pipes.module';
import { SharedModule } from './shared.module';
import { DirectivesModule } from '../../shared/modules/directives.module';
import { AssetPhotosComponent } from './../components/widget/asset-photos/asset-photos.component';
import { AssetPhotoDialogComponent } from './../components/widget/asset-photos/asset-photo-dialog/asset-photo-dialog.component';

import { ChatComponent } from '../components/widget/activities/messages/chat/chat.component';
import { SmsComponent } from '../components/widget/activities/messages/sms/sms.component';
import { ChatService } from '../../services/chat.service';
import { ListingService } from '../../services/listing.service';
import { ActionComponent } from '../components/widget/activities/messages/action/action.component';
import { MentionModule } from 'angular-mentions';
import { TextInputHighlightModule } from 'angular-text-input-highlight';
import { AngularImageViewerModule } from 'angular-x-image-viewer';
import { SharedModule as FormTemplateSharedModule } from '../../module/form-templates/shared/shared.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CustomerInvoicesFromRecurringInvoicesComponent } from '../../module/recurring-invoices/customer-invoices-from-recurring-invoices/customer-invoices-from-recurring-invoices.component';
import { JobsFromRecurringJobsComponent } from '../../module/recurring-jobs/jobs-from-recurring-jobs/jobs-from-recurring-jobs.component';
import { SaveEmailTemplateDialogComponent } from '../components/widget/email/email-templates/save-template/save-template.component';
import { ErrorHandlerModule } from './error-handler.module';
import { WorkOrderItemsModule } from './work-order-items.module';
import { ContactRolesService } from '../../services/contact-roles.service';
import { DataSharingModule } from '../../features/data-sharing/data-sharing.module';
import { SharedInputModule } from './../../shared/modules/shared-input.module';
import { AssetLocationHistoryComponent } from '../components/widget/asset-location-history/asset-location-history.component';
import { ProductFoldersModule } from '../../features/product-folders/product-folders.module';
import { AssetJobPhotosComponent } from '../components/widget/asset/asset-job-photos/asset-job-photos.component';
import { ChecklistResponsePromptComponent } from '../components/widget/checklists/checklist-response/checklist-response-prompt/checklist-response-prompt.component';
import { SignaturesComponent } from '../components/widget/email/signatures/signatures.component';
import { TaskCalendarModule } from '../../features/task-calendar/task-calendar.module';
import { MaterialsModule } from '../../module/jobs/materials/materials.module';
import { ShContextMenuModule } from 'ng2-right-click-menu';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_MOMENT_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'LL LT',
  datePickerInput: 'LL',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};
@NgModule({
  imports: [
    RouterModule,
    FontAwesomeModule,
    CommonModule,
    EditModule,
    TranslateModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    QuillModule,
    FileUploadModule,
    DlDateTimePickerDateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    ClipboardModule,
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    DragDropModule,
    PipesModule,
    SharedModule.forRoot(),
    DirectivesModule,
    MentionModule,
    TextInputHighlightModule,
    AngularImageViewerModule,
    FormTemplateSharedModule,
    PdfViewerModule,
    ErrorHandlerModule,
    WorkOrderItemsModule,
    DataSharingModule,
    SharedInputModule,
    ProductFoldersModule,
    TaskCalendarModule,
    MaterialsModule.forChild(),
    ShContextMenuModule,
  ],
  declarations: [
    WidgetComponent,
    NoteComponent,
    TaskComponent,
    EventComponent,
    PreviewComponent,
    EmailComponent,
    RenderComponent,
    FilesComponent,
    LinkChecklistComponent,
    ChecklistResponseComponent,
    ChecklistResponsePromptComponent,
    QuotesComponent,
    ActivitiesComponent,
    FileComponent,
    QuotesComponent,
    SitesComponent,
    AssetComponent,
    AssetJobsComponent,
    AssetStatusComponent,
    TimeEntryComponent,
    OpportunitiesComponent,
    SitesComponent,
    JobsComponent,
    InvoicesComponent,
    JobsComponent,
    InvoicesComponent,
    OpportunitiesComponent,
    ChecklistsComponent,
    PurchaseOrdersComponent,
    SupplierInvoicesComponent,
    RecurringJobsComponent,
    GraphqlFilterComponent,
    RecurringInvoicesComponent,
    EditRecurringInvoiceFormComponent,
    HistoricalInvoicesComponent,
    ImageViewComponent,
    EditRecurringJobsComponent,
    RecurringJobsHistoryComponent,
    ParticipantsListComponent,
    AssetPhotosComponent,
    AssetPhotoDialogComponent,
    ChatComponent,
    SmsComponent,
    ActionComponent,
    JobsAssetJobsComponent,
    EditComponent,
    RoleListComponent,
    RoleSitesComponent,
    RoleContactsComponent,
    RoleCustomersComponent,
    RoleOpportunitiesComponent,
    RolesEditComponent,
    JobsAssetJobsComponent,
    CustomerInvoicesFromRecurringInvoicesComponent,
    JobsFromRecurringJobsComponent,
    ChecklistAttachmentsComponent,
    SaveEmailTemplateDialogComponent,
    AssetLocationHistoryComponent,
    AssetJobPhotosComponent,
    SignaturesComponent
  ],
  exports: [
    TaskCalendarModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    EditModule,
    WidgetComponent,
    DlDateTimePickerDateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    MaterialModule,
    MentionModule,
    ActivitiesComponent,
  ],
  entryComponents: [
    SmsComponent,
    ChatComponent,
    NoteComponent,
    TaskComponent,
    PreviewComponent,
    EventComponent,
    EmailComponent,
    RenderComponent,
    FilesComponent,
    LinkChecklistComponent,
    ChecklistResponseComponent,
    ChecklistResponsePromptComponent,
    RolesEditComponent,
    AssetJobsComponent,
    AssetStatusComponent,
    ImageViewComponent,
    EditRecurringInvoiceFormComponent,
    HistoricalInvoicesComponent,
    EditRecurringJobsComponent,
    RecurringJobsHistoryComponent,
    ParticipantsListComponent,
    AssetPhotosComponent,
    ActionComponent,
    JobsAssetJobsComponent,
    EditComponent,
    AssetPhotoDialogComponent,
    ChecklistAttachmentsComponent,
    JobsAssetJobsComponent,
    SaveEmailTemplateDialogComponent,
    ActivitiesComponent,
    AssetLocationHistoryComponent,
    AssetJobPhotosComponent,
    SignaturesComponent
  ],
  providers: [
    ChatService,
    ListingService,
    DatePipe,
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
    { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE]},
    ContactRolesService
  ]
})
export class ActivityModule { }
