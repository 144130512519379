<div class="row">
  <div class="col-sm-12">
    <span class="title" translate>
      <fa-icon [icon]="['fas', 'link']" ></fa-icon>
      {{ 'choose_checklist'| translate }}
    </span>

    <span class="buttons d-flex d-flex-gap">
      <button (click)="linkChecklists()" class="btn btn-primary" >
        <fa-icon [icon]="['fas', 'link']"></fa-icon>
        <span class="pl-1">{{ 'link' | translate }}</span>
      </button>

      <fieldmagic-primary-button
        (click)="cancelDialog()"
        icon="times"
        variant="error"
        label="close"
      ></fieldmagic-primary-button>
    </span>
  </div>
</div>

<hr class="separator"/>

<div class="containers">
    <div class="row">
        <div class="col-md-12">
            <span class="labels">{{ 'available_checklists' | translate }}</span>
            <ng-select
              [items]="objChecklist['obv'] | async"
              [typeahead]="objChecklist['typehead']"
              [loading]="objChecklist['loader']"
              [hideSelected]="true"
              [clearable]="false"
              [ngModelOptions]="{standalone: true}"
              bindLabel="name"
              bindValue="id"
              (change)="chooseChecklist($event)"
              [(ngModel)]="strChecklist"
              (open)="triggerSubject(objChecklist['typehead'])"
              appendTo="body">
            </ng-select>
        </div>
    </div>

    <div class="row period-row" *ngIf="arChecklist['type'] === 'asset'">
        <div class="col-md-12">
            <span class="labels">{{ 'choose_period' | translate }}</span>
            <ng-select
              [items]="arChecklist['available_periods']"
              [clearable]="false"
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="arChosenPeriod"
              [multiple]= true
              appendTo="body" >

              <ng-template ng-option-tmp let-item="item" class="value-text">
                  {{ item | translate }}
              </ng-template>

              <ng-template ng-label-tmp let-item="item" let-clear="clear" class="value-text">
                  {{ item | translate }}
              </ng-template>

            </ng-select>
        </div>
    </div>
</div>