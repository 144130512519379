<ng-container *ngIf="view">
  <span *ngFor="let tag of tags">
    <span class="badge badge-pill mr-1 p-2" [style.background]="tag.color">{{ tag.name }}</span>
    <br>
  </span>
</ng-container>

<mat-chip-list *ngIf="!view">
  <mat-chip *ngFor="let tag of tags" [style.background]="tag.color">{{ tag.name }}</mat-chip>
</mat-chip-list>

<div *ngIf="!view && !tags" class="p-2 border-bottom"><small>--</small></div>
