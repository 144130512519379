import { get } from 'lodash';
import { LooseObject } from '../loose-object';

export class CustomerInvoiceLine {
  item_id?: string;
  item_code?: string;
  item_name?: string;
  account_code?: string;
  account_code_id?: string;
  account_code_name?: string;
  tax_code?: string;
  tax_code_id?: string;
  tax_code_name?: string;
  tax_rate?: number;
  department_id?: string;
  department_name?: string;
  description?: string;
  notes?: string;
  quantity?: number;
  unit_cost?: number;
  unit_price?: number;
  markup?: number;
  exclude_from_invoice?: boolean;
  actual_duration?: number;
  billed_duration?: number;
  end_time?: string;
  start_time?: string;
  user_id?: string;
  user_text?: string;
  item_accounting_id?: string;
  type?: string;
  current_stock_level?: number;
  invoice_type?: string;
  labor?: boolean;
  related_products?: LooseObject[];
  supplier_pricing?: LooseObject[];

  constructor(data: LooseObject){
    this.item_id = get(data, 'item_id');
    this.item_code = get(data, 'item_code');
    this.item_name = get(data, 'item_name');
    this.account_code = get(data, 'account_code');
    this.account_code_id = get(data, 'account_code_id');
    this.account_code_name = get(data, 'account_code_name');
    this.tax_code = get(data, 'tax_code');
    this.tax_code_id = get(data, 'tax_code_id');
    this.tax_code_name = get(data, 'tax_code_name');
    this.tax_rate = get(data, 'tax_rate');
    this.department_id = get(data, 'department_id');
    this.department_name = get(data, 'department_name');
    this.notes = get(data, 'notes');
    this.description = get(data, 'description', get(data, 'notes'));
    this.quantity = get(data, 'quantity');
    this.unit_cost = get(data, 'unit_cost');
    this.markup = get(data, 'markup');

    this.unit_price = get(data, 'unit_price');
    this.exclude_from_invoice = get(data, 'exclude_from_invoice');
    this.actual_duration = get(data, 'actual_duration');
    this.billed_duration = get(data, 'billed_duration');
    this.end_time = get(data, 'end_time');
    this.start_time = get(data, 'start_time');
    this.user_id = get(data, 'user_id');
    this.user_text = get(data, 'user_text');
    this.item_accounting_id = get(data, 'item_accounting_id');
    this.type = get(data, 'type');
    this.current_stock_level = get(data, 'current_stock_level');
    this.invoice_type = get(data, 'invoice_type');
    this.labor = get(data, 'labor');
    this.related_products = get(data, 'related_products', []);
    this.supplier_pricing = get(data, 'supplier_pricing', []);
  }
}